
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { requiredRule } from '~/utils/FormValidation'

@Component({})
export default class TextFileParser extends Vue {
  @Prop({ default: true }) readonly hasLabel!: Boolean
  @Prop({ default: true }) readonly isRequired!: Boolean

  fileAccept: any = [(value:any) => !value || value.type == 'text/plain' || this.$t('onlyTxtFileAllowed')];
  commentFile: any = null;
  extractedText: any = [];
  
  mounted() {
    if(this.isRequired) {
      this.fileAccept.push(requiredRule());
    }
  }

  @Watch('isRequired')
  checkIsRequred() {
    if(this.isRequired) {
      this.fileAccept.push(requiredRule());
    }
  }

  handleFileUpload(event:any) {
    if (event) {
      const reader = new FileReader();

      reader.onload = (e) => {
        const target = e.target as any;
        const content = target.result;
        this.extractedText = content.replace(/\r/g, '').split('\n').filter(Boolean);

        this.$emit('get-data', this.extractedText.length > 0 ? this.extractedText : null);
      };

      reader.readAsText(event);
    } else {
      this.extractedText = [];
      this.$emit('get-data', null);
    }
  }

  formatCount(num:any) {
    const count = (Math.abs(num)/1000).toFixed(1) as any;
    return Math.abs(num) > 999 ? Math.sign(num)*(count) + 'k' : Math.sign(num)*Math.abs(num);
  }
}
