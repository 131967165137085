var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("v-row", { staticClass: "mt-5", attrs: { align: "center" } }, [
    _c(
      "div",
      { staticClass: "col-md-2" },
      [
        _vm.hasOptions
          ? _c("v-select", {
              staticClass: "ml-2 mt-2",
              attrs: {
                items: _vm.perPageOptions,
                label: "Rows Per Page",
                dense: "",
                "hide-details": "",
              },
              model: {
                value: _vm.perPageOption,
                callback: function ($$v) {
                  _vm.perPageOption = $$v
                },
                expression: "perPageOption",
              },
            })
          : _vm._e(),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "col-md-10" },
      [
        _c("v-pagination", {
          staticClass: "float-right",
          attrs: {
            color: "btn-brand",
            length: _vm.totalPages,
            "total-visible": _vm.totalVisibleButtons,
          },
          on: {
            input: function ($event) {
              return _vm.$emit("page-change", _vm.pageOption)
            },
          },
          model: {
            value: _vm.pageOption,
            callback: function ($$v) {
              _vm.pageOption = $$v
            },
            expression: "pageOption",
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }