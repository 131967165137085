
import { Component, Vue } from 'vue-property-decorator'

@Component({})
export default class InstagramCancelSubscriptionModal extends Vue {
  modal: boolean = false;
  loading1: boolean = false;
  loading2: boolean = false;
  disable1: boolean = false;
  disable2: boolean = false;
  accountId: any = null;
  urlType: any = 'instabot';
  status: any = "";

  show(item:any, type: any = 'instabot') {
    this.accountId = item.id;
    this.status = item?.status;
    this.urlType = type;
    this.modal = true;
  }

  async cancel(type:any) {
    if(type == 'immediate') {
      this.loading1 = true;
      this.disable2 = true;
      await this.$axios.post(`/${this.urlType}/instagram-accounts/${this.accountId}/cancel_immediately/`)
      .then((response:any) => {
        this.$emit('success');
        this.modal = false;
      }).catch((error:any) => {
        this.$emit('error', error)
      })
      this.loading1 = false;
      this.disable2 = false;
    } else {
      this.loading2 = true;
      this.disable1 = true;
      await this.$axios.post(`/${this.urlType}/instagram-accounts/${this.accountId}/cancel_on_period_end/`)
      .then((response:any) => {
        this.$emit('success');
        this.modal = false;
      }).catch((error:any) => {
        this.$emit('error', error)
      })
      this.disable1 = false;
      this.loading2 = false;
    }
  }
}
