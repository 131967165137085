
import { Component, Vue, Prop } from 'vue-property-decorator'
import { dateTimeFormat } from '~/utils/DateTimeUtils'

@Component({})
export default class AdminUsersCardList extends Vue {
  @Prop({ default: () => { return null }, required: false }) readonly data!: any

  formatDateTime(data:any) {
    return dateTimeFormat(this, data);
  }

  formatCredits(num:any) {
    return parseFloat(num);
  }
}
