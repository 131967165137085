import { $axios } from '@/utils/Api'
import Store from '@/utils/StoreInstance'
import { Module, VuexModule, Action, Mutation } from 'vuex-module-decorators'

@Module({stateFactory: true, store: Store, name: 'orders', namespaced: true})
export default class OrdersModule extends VuexModule {
  orders: any = {};
  orderTypes: any = [];

  // Orders
  @Mutation
  setOrders(item: any) {
    this.orders = item;
  }
  @Action({commit: 'setOrders', rawError: true})
  async fetchOrders(params: any = {}) {
    const { data: results } = await $axios.get('/instabot/orders/', { params: params });
    return results;
  }
  get getOrders():any {
    return this.orders;
  }

  // Order Types
  @Mutation
  setOrderTypes(item: any) {
    this.orderTypes = item;
  }
  @Action({commit: 'setOrderTypes', rawError: true})
  async fetchOrderTypes() {
    const { data: results } = await $axios.get('/instabot/orders/order_types/');
    return results;
  }
  get getOrderTypes():any {
    return this.orderTypes;
  }
}
