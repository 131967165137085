var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { attrs: { id: "committed-section" } }, [
    _c("div", { staticClass: "custom-content position-relative" }, [
      _c("p", {
        staticClass: "h_title_2",
        domProps: { innerHTML: _vm._s(_vm.$t("committedDesc")) },
      }),
      _vm._v(" "),
      _c("img", {
        staticClass: "piechart",
        attrs: {
          src: require("assets/images/pie-chart.svg"),
          alt: "Pie Chart Icon",
          loading: "lazy",
        },
      }),
      _vm._v(" "),
      _c("img", {
        staticClass: "message",
        attrs: {
          src: require("assets/images/message-callout.svg"),
          alt: "Message Icon",
          loading: "lazy",
        },
      }),
      _vm._v(" "),
      _c("img", {
        staticClass: "rocket",
        attrs: {
          src: require("assets/images/rocket.svg"),
          alt: "Rocket Icon",
          loading: "lazy",
        },
      }),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }