var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-row",
    [
      _c(
        "v-col",
        { staticClass: "pb-0", attrs: { cols: "12", md: "3", sm: "6" } },
        [
          _c(
            "v-card",
            {
              staticClass: "custom-cards bordered",
              attrs: {
                height: "100%",
                ripple: false,
                to: _vm.localePath("/dashboard/admin/users/add"),
              },
            },
            [
              _c(
                "v-card-text",
                { staticClass: "d-flex align-center justify-center" },
                [
                  _c(
                    "div",
                    { staticClass: "text-center" },
                    [
                      _c("v-icon", [_vm._v("mdi-plus-circle-outline")]),
                      _vm._v(" "),
                      _c(
                        "h3",
                        { staticClass: "text-16 font-weight-medium mt-3" },
                        [_vm._v(_vm._s(_vm.$t("addUser")))]
                      ),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm._l(_vm.data, function (item) {
        return [
          _c(
            "v-col",
            { staticClass: "pb-0", attrs: { cols: "12", md: "3", sm: "6" } },
            [
              _c(
                "v-card",
                {
                  staticClass: "custom-cards bordered",
                  attrs: { height: "100%" },
                },
                [
                  _c(
                    "v-card-text",
                    { staticClass: "px-4 pt-3 pb-2" },
                    [
                      _c(
                        "div",
                        { staticClass: "d-flex align-center" },
                        [
                          _c(
                            "v-avatar",
                            { attrs: { size: "35", color: "btn-brand mr-2" } },
                            [
                              _c(
                                "v-icon",
                                {
                                  staticClass: "white--text",
                                  attrs: { size: "20" },
                                },
                                [_vm._v("mdi-account")]
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("div", [
                            item.first_name
                              ? _c("span", [_vm._v(_vm._s(item.first_name))])
                              : _vm._e(),
                            _vm._v(" "),
                            item.first_name && item.last_name
                              ? _c("span")
                              : _vm._e(),
                            _vm._v(" "),
                            item.last_name
                              ? _c("span", [_vm._v(_vm._s(item.last_name))])
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "div",
                              [
                                _c("span", { staticClass: "text-brand" }, [
                                  _vm._v(_vm._s(item.email)),
                                ]),
                                _vm._v(" "),
                                item.email_verified
                                  ? _c(
                                      "v-tooltip",
                                      {
                                        attrs: {
                                          bottom: "",
                                          "content-class": "custom-tooltip",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "activator",
                                              fn: function ({ on, attrs }) {
                                                return [
                                                  _c(
                                                    "v-icon",
                                                    _vm._g(
                                                      _vm._b(
                                                        {
                                                          staticClass:
                                                            "custom-icon-small",
                                                          attrs: {
                                                            size: "14",
                                                            color: "success",
                                                          },
                                                        },
                                                        "v-icon",
                                                        attrs,
                                                        false
                                                      ),
                                                      on
                                                    ),
                                                    [
                                                      _vm._v(
                                                        "\n                      mdi-check-decagram\n                    "
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                      },
                                      [
                                        _vm._v(" "),
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(_vm.$t("emailVerified"))
                                          ),
                                        ]),
                                      ]
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ]),
                          _vm._v(" "),
                          _c(
                            "nuxt-link",
                            {
                              staticClass: "ml-auto",
                              attrs: {
                                to: _vm.localePath(
                                  `/dashboard/settings/transactions?user=${item.id}`
                                ),
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "d-flex align-center justify-end",
                                },
                                [
                                  item.remaining_credits
                                    ? _c("span", { staticClass: "mr-1" }, [
                                        _vm._v(
                                          _vm._s(
                                            _vm.formatCredits(
                                              item.remaining_credits
                                            )
                                          )
                                        ),
                                      ])
                                    : _c("span", { staticClass: "mr-1" }, [
                                        _vm._v("0"),
                                      ]),
                                  _vm._v(" "),
                                  _c("img", {
                                    attrs: {
                                      src: require("assets/images/icons/twemoji_coin.svg"),
                                      width: "15",
                                    },
                                  }),
                                ]
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("v-divider", { staticClass: "mt-2" }),
                      _vm._v(" "),
                      _c("div", { staticClass: "mt-2" }, [
                        _c("label", { staticClass: "font-weight-medium" }, [
                          _vm._v(_vm._s(_vm.$t("signupDate")) + ":"),
                        ]),
                        _vm._v(" "),
                        _c("span", [
                          _vm._v(_vm._s(_vm.formatDateTime(item.date_joined))),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", [
                        _c("label", { staticClass: "font-weight-medium" }, [
                          _vm._v(_vm._s(_vm.$t("lastLogin")) + ":"),
                        ]),
                        _vm._v(" "),
                        item.last_login
                          ? _c("span", [
                              _vm._v(
                                _vm._s(_vm.formatDateTime(item.last_login))
                              ),
                            ])
                          : _c("span", [_vm._v("---")]),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }