var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", [
    _c(
      "div",
      { staticClass: "followers-count mt-2" },
      [
        _c("span", [_vm._v(_vm._s(_vm.$t("followers")))]),
        _vm._v(" "),
        _vm.account.bot_follower_count_active >= _vm.account.max_follower_count
          ? [
              _c(
                "span",
                { staticClass: "count", staticStyle: { color: "#a0a2d2" } },
                [_vm._v(_vm._s(_vm.account.max_follower_count))]
              ),
            ]
          : _vm.account.bot_follower_count_active <
            _vm.account.max_follower_count
          ? [
              _c(
                "span",
                { staticClass: "count", staticStyle: { color: "#7378DF" } },
                [_vm._v(_vm._s(_vm.account.bot_follower_count_active))]
              ),
              _vm._v(" "),
              _c("span", { staticClass: "divider" }, [_vm._v("/")]),
              _vm._v(" "),
              _c(
                "span",
                { staticClass: "count", staticStyle: { color: "#a0a2d2" } },
                [_vm._v(_vm._s(_vm.account.max_follower_count))]
              ),
            ]
          : _vm._e(),
      ],
      2
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "d-flex align-center mt-1" },
      [
        _c(
          "div",
          { staticClass: "follower-progress mr-1 d-flex" },
          [
            _c(
              "v-tooltip",
              {
                attrs: {
                  bottom: "",
                  color: "#a0a2d2",
                  "content-class": "custom-tooltip",
                },
                scopedSlots: _vm._u([
                  {
                    key: "activator",
                    fn: function ({ on, attrs }) {
                      return [
                        _c(
                          "div",
                          _vm._g(
                            _vm._b(
                              {
                                staticClass: "max",
                                class: _vm.checkBorderRadius(
                                  _vm.account.max_follower_count,
                                  _vm.account.current_follower_count
                                ),
                                style: `width:${_vm.getPercentageValue(
                                  _vm.account.max_follower_count,
                                  _vm.account.current_follower_count
                                )}%`,
                              },
                              "div",
                              attrs,
                              false
                            ),
                            on
                          )
                        ),
                      ]
                    },
                  },
                ]),
              },
              [
                _vm._v(" "),
                _c("span", [
                  _vm._v(
                    _vm._s(_vm.$t("maxFollowerCount")) +
                      ": " +
                      _vm._s(_vm.account.max_follower_count)
                  ),
                ]),
              ]
            ),
            _vm._v(" "),
            _c(
              "v-tooltip",
              {
                attrs: {
                  bottom: "",
                  color: "#7378DF",
                  "content-class": "custom-tooltip",
                },
                scopedSlots: _vm._u([
                  {
                    key: "activator",
                    fn: function ({ on, attrs }) {
                      return [
                        _c(
                          "div",
                          _vm._g(
                            _vm._b(
                              {
                                staticClass: "current",
                                class: _vm.checkBorderRadius(
                                  _vm.account.max_follower_count,
                                  _vm.account.current_follower_count
                                ),
                                style: `width:${_vm.getPercentageValue(
                                  _vm.account.bot_follower_count_active,
                                  _vm.account.current_follower_count
                                )}%`,
                              },
                              "div",
                              attrs,
                              false
                            ),
                            on
                          )
                        ),
                      ]
                    },
                  },
                ]),
              },
              [
                _vm._v(" "),
                _vm.account.bot_follower_count_active <=
                _vm.account.max_follower_count
                  ? _c("span", [
                      _vm._v(
                        _vm._s(_vm.$t("followerCount")) +
                          ": " +
                          _vm._s(_vm.account.bot_follower_count_active)
                      ),
                    ])
                  : _c("span", [
                      _vm._v(
                        _vm._s(_vm.$t("followerCount")) +
                          ": " +
                          _vm._s(_vm.account.max_follower_count)
                      ),
                    ]),
              ]
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c("v-icon", { attrs: { small: "" } }, [
          _vm._v("mdi-account-multiple"),
        ]),
        _vm._v(" "),
        _c("span", { staticClass: "followers font-weight-medium" }, [
          _vm._v(_vm._s(_vm.account.current_follower_count)),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }