
import { debounce } from 'lodash'
import { Component, Mixins, Watch } from 'vue-property-decorator'
import { ProxyAPIStore } from '~/store'
import { getErrorMessage } from "~/utils/ErrorMessageUtils"
import TableFiltersMixin from '~/mixins/local-storage-filters'

@Component({})
export default class ProxyApiSubscriptionsAdmin extends Mixins(TableFiltersMixin) {
  breadcrums: any = [
    {
      text: this.$t('socialProduct3Title'),
      disabled: false,
      href: this.localePath('/dashboard/instagram/accounts'),
    },
    {
      text: this.$t('subscriptions'),
      disabled: true,
      href: this.localePath('/dashboard/instagram/accounts'),
    },
  ];
  success: boolean = false;
  hasError: boolean = false;
  progressLoading: boolean = false;
  errorMessage: any = "";
  searchKey: any = "";
  handleFilters = debounce(this.tableFilters, 500);
  page: number = 1;
  pageCount: any = 1;
  perPage: number = 99;
  options: any = {};
  subscriptions: any = [];
  filters: any = { cardView: true };
  user: any = {};
  statusList: any = [
    { id: 'active', text: this.$t('active') },
    { id: 'inactive', text: this.$t('inactive') },
    { id: 'cancelled', text: this.$t('cancelled') }
  ];
  isMobile: boolean = false;
  status: any = null;
  holderFilters: any = {};
  hasFilters: boolean = false;
  loaded: boolean = false;

  mounted() {
    setTimeout(() => {
      if(this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.md) {
        this.isMobile = true;
        this.perPage = 29;
      }
    }, 500)

    this.user = this.$auth?.user || {};
  }

  @Watch('$vuetify.breakpoint.width')
  checkBreakpoint() {
    if(this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.md) {
      this.isMobile = true;
    } else if(this.$vuetify.breakpoint.lg) {
      this.isMobile = false;
    }
  }

  @Watch('holderFilters')
  checkFilters(val:any) {
    if(!val.hasOwnProperty('cardView')) {
      val.cardView = true;
    }
    if(val.status) {
      const status = JSON.parse(val.status);
      this.status = status.length > 0 && status[0] ? status[0] : null;
    }

    this.filters = { ...this.filters, ...val };
  }

  tableFilters(item:any = null) {
    this.page = 1;
    this.filters = { ...this.filters, ...item };
    this.filters.status = this.status ? JSON.stringify([this.status]) : null;

    this.preGetData();
  }

  refreshTable(item:any) {
    this.page = item.page;
    this.perPage = item.perPage;
    this.options = item.options;
    this.loaded = true;

    this.preGetData();
  }

  // Fetch and save filters to local storage
  async preGetData() {
    await this.getLocalFilters(this, 'HH_mobile_proxy_subscriptions');
    await this.getData();

    const params = {
      page: this.page,
      perPage: this.perPage,
      options: this.options,
      filters: { ...this.filters }
    }

    await this.saveLocalFilters(params, 'HH_mobile_proxy_subscriptions');
  }

  async getData() {
    this.progressLoading = true;

    const { sortBy, sortDesc } = this.options
    let orderBy = sortBy ? sortBy[0] : '';
    let orderDesc = sortDesc ? sortDesc[0] : false;

    if(orderBy) {
      this.filters.ordering = orderDesc ? ('-' + orderBy) : orderBy;
    } else {
      this.filters.ordering = null;
    }

    const filters: { [key: string]: any } = this.filters;
    let { cardView, ...newFilters } = filters;

    const params = {
      offset: this.page === 1 ? 0 : (this.page - 1) * this.perPage,
      limit: this.perPage,
      ...newFilters,
    }

    await ProxyAPIStore.fetchSubscriptions(params)
    .then((response:any) => {
      this.subscriptions = response;
    }).catch((error:any) => {
      const message = getErrorMessage(error, this.$t('errorFetchingData'));
      const snackbar = this.$refs.error as any;
      if(snackbar) { snackbar.show(message); }
    })
    this.progressLoading = false;
  }
}
