
import { Vue, Component, Watch, Prop } from 'vue-property-decorator'

@Component({})
export default class CustomerSort extends Vue {
  @Prop({ default: () => { return {} }, required: false }) readonly default!: any

  loading: boolean = false;
  filterShown: boolean = false;
  hasFilter: boolean = false;
  sortField: any = 'username';
  ascending: boolean = false;

  getField(val:any) {
    this.$emit('sort', { ordering: this.ascending ? val : ('-' + val) });
    this.filterShown = false;
  }

  getAsc(val:any) {
    this.$emit('sort', { ordering: val ? this.sortField : ('-' + this.sortField) });
  }

  @Watch('default')
  checkDefaultSort(val:any) {
    if(val) {
      const order = val.split('-');
      if(order.length > 1) {
        this.ascending = false;
        this.sortField = order[1];
      } else {
        this.ascending = true;
        this.sortField = order[0];
      }
    }
  }
}
