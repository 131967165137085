var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-alert",
    {
      staticClass: "text-left",
      attrs: { text: "", prominent: "", dense: "", type: "success" },
    },
    [
      _c("span", {
        staticClass: "text-size-normal",
        domProps: { innerHTML: _vm._s(_vm.successMessage) },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }