
import { Component, Vue, Prop } from 'vue-property-decorator'

@Component({
  layout: 'landing-page',
})
export default class ServicesSection extends Vue {
  items: any = [];
  touchStartX: any = null;
  touchEndX: any = null;

  mounted() {
    this.items = this.$t('servicesList') as any;
    
    const carousel = this.$refs.carousel as any;
    if(carousel) {
      const el = carousel.$el;
      if(el) {
        el.addEventListener('mousedown', this.onTouchStart);
        el.addEventListener('mousemove', this.onTouchMove);
        el.addEventListener('mouseup', this.onTouchEnd);
      }
    }
  }
  
  get groupedItems() {
    let chunkSize = 4;
    if(this.$vuetify.breakpoint.xs) {
      chunkSize = 1;
    } else if(this.$vuetify.breakpoint.sm) {
      chunkSize = 2;
    }
    const grouped = [];
    const { length } = this.items;

    for(let i = 0; i < length; i += chunkSize) {
      const chunk = this.items.slice(i, i + chunkSize);
      if(chunkSize == 4 && grouped.length > 0 && chunk.length > 0) {
          // Add the last item of the previous chunk to the current chunk
          chunk.unshift(grouped[grouped.length - 1][chunkSize - 1]);
      }
      grouped.push(chunk);
    }

    return grouped;
  }

  beforeDestroy() {
    const carousel = this.$refs.carousel as any;
    if(carousel) {
      const el = carousel.$el;
      if(el) {
        el.removeEventListener('mousedown', this.onTouchStart);
        el.removeEventListener('mousemove', this.onTouchMove);
        el.removeEventListener('mouseup', this.onTouchEnd);
      }
    }
  }

  onTouchStart(event:any) {
    this.touchStartX = event.clientX;
  }
  onTouchMove(event:any) {
    this.touchEndX = event.clientX;
  }
  onTouchEnd() {
    if(this.touchStartX - this.touchEndX > 50) {
      const carousel = this.$refs.carousel as any;
      if(carousel) {
        carousel.next();
      }
    } else if(this.touchStartX - this.touchEndX < -50) {
      const carousel = this.$refs.carousel as any;
      if(carousel) {
        carousel.prev();
      }
    }
    this.touchStartX = null;
    this.touchEndX = null;
  }

  getStartedRoute() {
    if(this.$auth.loggedIn) {
      return '/dashboard/home';
    }
    return '/account/register';
  }
}
