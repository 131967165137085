import { Bar } from "vue-chartjs";  

export default {
  extends: Bar,
  props: ["chartData", "options"],
  watch: {
    chartData: {
      handler: function(value) {
        this.renderChart(value, this.options);
      },
      deep: true
    },
  }
};