
import { Component, Vue } from "vue-property-decorator";

@Component({})
export default class BlogsCategoriesModal extends Vue {
  modal: boolean = false;

  async show() {
    this.modal = true;
  }

  leave(): void {
    this.modal = false;
  }
}
