
import { Component, Vue, Prop } from 'vue-property-decorator'
import { dateFormat } from '~/utils/DateTimeUtils'
import { getErrorMessage } from '~/utils/ErrorMessageUtils'

@Component({})
export default class MobileProxyApiActiveSubscription extends Vue {
  @Prop({ default: false }) readonly isAdmin!: boolean
  @Prop({ default: false }) readonly isUpdate!: boolean
  @Prop({ default: () => {} }) readonly subscription!: any

  docuLink: any = process.env.PROXY_API_DOCU_LINK;

  showModal(type:any) {
    const modal = this.$refs.addRemovePorts as any;
    if(modal) {
      modal.show({ type:type, subscription: this.subscription, isAdmin: this.isAdmin });
    }
  }

  cancelSubscriptionModal(id:any = null) {
    const content = {
      id: id,
      title: this.$t('cancelSubscription'),
      errorMsg: this.$t('areUSureCancelSub'),
      successMsg: this.$t('successCancelled'),
      btnLbl: this.$t('proceed')
    }

    const modal = this.$refs.cancelSubscription as any;
    modal.show(content);
  }

  async cancelSubscription() {
    await this.$axios.post(`/proxyapi/subscription/cancel/`, {
      user: this.subscription?.user.id
    }).then(() => {
      const modal = this.$refs.cancelSubscription as any;
      modal.success = true;
      setTimeout(() => {
        modal.leave();
        this.$emit('reload');
      }, 2000)
    }).catch((error:any) => {
      const message = getErrorMessage(error, this.$t('errorUpdateData'));
      const snackbar = this.$refs.error as any;
      if(snackbar) { snackbar.show(message); }
    })
  }

  formatDate(data:any) {
    return dateFormat(this, data);
  }
}
