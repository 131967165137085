var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { attrs: { id: "blackhat-forum" } }, [
    _c(
      "a",
      {
        attrs: {
          href: "https://www.blackhatworld.com/seo/hypeheaven-german-instagram-engagement-system.1458175/#post-16048018",
          target: "_blank",
        },
      },
      [
        _c(
          "v-row",
          { attrs: { align: "center" } },
          [
            _c(
              "v-col",
              { staticClass: "text-right", attrs: { cols: "12", sm: "6" } },
              [
                _c("img", {
                  attrs: {
                    src: _vm.getImage("black-hat.webp", "black-hat.svg"),
                    alt: "Blackhat World logo",
                    width: "375",
                    height: "95",
                    loading: "lazy",
                  },
                }),
              ]
            ),
            _vm._v(" "),
            _c(
              "v-col",
              {
                staticClass: "d-flex align-center",
                attrs: { cols: "12", sm: "6" },
              },
              [
                _c("div", { staticClass: "separator" }),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "d-flex label" },
                  [
                    _c(
                      "v-icon",
                      {
                        staticClass: "mr-3 white--text",
                        attrs: { size: "28" },
                      },
                      [_vm._v("mdi-open-in-new")]
                    ),
                    _vm._v(" "),
                    _c("span", [_vm._v(_vm._s(_vm.$t("blackHatDesc")))]),
                  ],
                  1
                ),
              ]
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }