var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-dialog",
    {
      attrs: { persistent: "", "max-width": "430" },
      model: {
        value: _vm.modal,
        callback: function ($$v) {
          _vm.modal = $$v
        },
        expression: "modal",
      },
    },
    [
      _c(
        "v-card",
        { staticClass: "add-credits" },
        [
          _c(
            "v-card-title",
            { staticClass: "pb-0" },
            [
              _c(
                "h2",
                {
                  staticClass:
                    "h_title_3 font-weight-bold text-black text-center",
                },
                [_vm._v(_vm._s(_vm.$t("cryptoPayment")))]
              ),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  staticClass: "ml-auto",
                  attrs: { icon: "", small: "" },
                  on: {
                    click: function ($event) {
                      _vm.modal = false
                    },
                  },
                },
                [_c("v-icon", [_vm._v("mdi-close")])],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-card-text",
            { staticClass: "pa-6 pt-0" },
            [
              _c("v-divider", { staticClass: "mt-3" }),
              _vm._v(" "),
              _c("p", {
                ref: "description",
                staticClass: "text-14 mt-5 font-weight-medium",
              }),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "text-center mt-5" },
                [
                  _c(
                    "v-tooltip",
                    {
                      attrs: { top: "", "content-class": "custom-tooltip" },
                      scopedSlots: _vm._u([
                        {
                          key: "activator",
                          fn: function ({ on, attrs }) {
                            return [
                              _c(
                                "div",
                                _vm._g(
                                  _vm._b(
                                    { staticClass: "qrCodeContainer" },
                                    "div",
                                    attrs,
                                    false
                                  ),
                                  on
                                ),
                                [
                                  _c(
                                    "span",
                                    {
                                      attrs: { id: "copyId" },
                                      on: { click: _vm.copyText },
                                    },
                                    [_vm._v(_vm._s(_vm.details.wallet))]
                                  ),
                                ]
                              ),
                            ]
                          },
                        },
                      ]),
                    },
                    [_vm._v(" "), _c("span", [_vm._v(_vm._s(_vm.$t("copy")))])]
                  ),
                  _vm._v(" "),
                  _vm.isCopied
                    ? _c("p", { staticClass: "mb-0 mt-1" }, [
                        _vm._v(_vm._s(_vm.$t("copied"))),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("h3", { staticClass: "mb-2 mt-3" }, [
                    _vm._v(_vm._s(_vm.$t("walletAddress"))),
                  ]),
                  _vm._v(" "),
                  _c("h3", { staticClass: "mt-3 text-lowercase" }, [
                    _vm._v(_vm._s(_vm.$t("or"))),
                  ]),
                  _vm._v(" "),
                  _c("img", {
                    ref: "qrCodeImage",
                    attrs: { alt: "Bitcoin QR Code", width: "180" },
                  }),
                  _vm._v(" "),
                  _c("h3", { staticClass: "mb-2" }, [
                    _vm._v(_vm._s(_vm.$t("scanQR"))),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }