
import { Vue, Component, Watch, Prop } from 'vue-property-decorator'
import { debounce } from 'lodash'

@Component({})
export default class SettingsTransactionFilter extends Vue {
  @Prop({ default: () => { return false }, required: false }) readonly isMobile!: Boolean
  @Prop({ default: () => { return {} }, required: false }) readonly user!: any
  @Prop({ default: () => ({}) }) readonly preloadFiters!: any

  hasFilter: boolean = false;
  filters: any = { ordering: '-payment_date' };
  menuDate: any = { from: false, to: false };
  multipleSelect: any = { payment_method: null, currency: null };
  handleSearch = debounce(this.filter, 500);
  handleSelect = debounce(this.checkSelectOptions, 800);
  handleDatePickers = debounce(this.checkDates, 500);
  paymentMethods: any = [
    { value: "bank_transfer", text: this.$t('bankTransfer') },
    { value: "stripe_manual", text: this.$t('stripeManual') },
    { value: "stripe_checkout", text: this.$t('stripeCheckout') },
    { value: "crypto", text: this.$t('crypto') },
    { value: "hh_credit", text: this.$t('hhCredit') },
    { value: "refund", text: this.$t('refund') },
  ];
  currencyList: any = [
    { text: "US Dollar",  value: "usd"},
    { text: "Euro", value: "eur" },
    { text: "United Arab Emirates Dirham", value: "aed" }
  ]
  sortList: any = [
    { id: 'payment_date', text: this.$t('paymentDate') },
    { id: 'price', text: this.$t('price') },
    { id: 'credit_qty', text: this.$t('creditQuantity') },
    { id: 'payment_method', text: this.$t('modePayment') },
    { id: 'payment_meta_data', text: this.$t('addtlInfo') },
  ]

  mounted() {
    if(this.user && this.user.is_admin) {
      this.sortList.push({ id: 'user', text: 'User' });
    }
  }

  clear() {
    this.multipleSelect = { payment_method: null, currency: null };
    this.filters = { search: null, payment_method: null, currency: null, payment_date_before: null, payment_date_after: null };
    this.$emit('get-filters', {...this.filters});
  }

  @Watch('preloadFiters', { deep: true })
  checkLocalFilters(val:any) {
    this.filters = { ...val };
    if(this.filters.payment_method) {
      this.multipleSelect.payment_method = JSON.parse(this.filters.payment_method)
    }
    if(this.filters.currency) {
      this.multipleSelect.currency = JSON.parse(this.filters.currency)
    }
    this.checkIfHasFilter();
  }

  checkDates() {
    if(this.filters.payment_date_after && this.filters.payment_date_before) {
      this.filter();
    }
    this.checkIfHasFilter();
  }

  checkSelectOptions(type:any) {
    if(type == 'payment') {
      this.checkPaymentMethod();
    } else {
      this.checkCurrency();
    }
    this.filter();
  }

  checkPaymentMethod() {
    if(this.multipleSelect.payment_method && this.multipleSelect.payment_method.length > 0) {
      this.filters.payment_method = JSON.stringify(this.multipleSelect.payment_method);
    } else {
      this.filters.payment_method = null;
    }
    this.checkIfHasFilter();
  }

  checkCurrency() {
    if(this.multipleSelect.currency && this.multipleSelect.currency.length > 0) {
      this.filters.currency = JSON.stringify(this.multipleSelect.currency);
    } else {
      delete this.filters.currency;
    }
    this.checkIfHasFilter();
  }

  checkIfHasFilter() {
    this.hasFilter = this.filters.payment_date_after || this.filters.payment_date_before || this.filters.payment_method || this.filters.currency
      ? true
      : false;
  }

  filter() {
    this.$emit('get-filters', {...this.filters});
  }

  manualSortData(item:any) {
    this.filters = { ...this.filters, ...item };
    this.filter();
  }
}
