
import { Component, Vue } from 'vue-property-decorator'
import { AccountsStore } from '@/store'
import { getErrorMessage } from "~/utils/ErrorMessageUtils"

@Component({})
export default class InstagramAddFollowersModal extends Vue {
  modal: boolean = false;
  loading: boolean = false;
  success: boolean = false;
  hasError: boolean = false;
  errorMessage: any = "";
  accountId: any = null;
  urlType: any = 'instabot';
  data: any = { additional_followers: null };

  mounted() {
    if(this.$auth && this.$auth.user && this.$auth.user.is_admin) {
      this.data.user = this.$auth.user.id;
    }
  }

  show(item:any, type: any = 'instabot') {
    this.accountId = item.id;
    this.modal = true;
    this.hasError = false;
    this.urlType = type;
  }

  async purchase() {
    this.loading = true;
    await this.$axios.post(`/${this.urlType}/instagram-accounts/${this.accountId}/upgrade_subscription/`, this.data)
    .then((response:any) => {
      this.success = true;
      this.data = {};
      setTimeout(() => {
        this.success = false;
        this.modal = false;
        this.$emit('success');
      }, 1500)
    }).catch((error:any) => {
      this.errorMessage = getErrorMessage(error, this.$t('errorSaveData'));
      this.hasError = true;
    })
    this.loading = false;
  }

  get currentAccount() {
    return AccountsStore.getCurrentAccount;
  }

  get credits() {
    return AccountsStore.getCredits;
  }
}
