
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { LocalStorageFiltersStore } from '~/store'

@Component({})
export default class Pagination extends Vue {
  @Prop() readonly totalItems!: number
  @Prop() readonly perPage!: number
  @Prop() readonly page!: number
  @Prop() readonly perPageOptions!: Array<number>
  @Prop({ default: () => true, required: false }) readonly hasOptions!: boolean

  pageOption = this.page;
  perPageOption = this.perPage;
  totalVisibleButtons = 5;

  @Watch('page')
  updatePage() {
    this.pageOption = this.page
  }

  @Watch('perPage')
  updatePerPage() {
    this.perPageOption = this.perPage;
  }

  @Watch('perPageOption')
  emitPerPageChange() {
    if(!this.freezeWatchFlag) {
      this.pageOption = 1
      this.$emit('per-page-change', this.perPageOption)
    }
  }

  get totalPages() {
    return Math.ceil(this.totalItems / this.perPage)
  }

  get freezeWatchFlag() {
    return LocalStorageFiltersStore.getFreeWatchFlag;
  }
}
