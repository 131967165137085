
import { Component, Vue } from "vue-property-decorator";
import { getErrorMessage } from "~/utils/ErrorMessageUtils";

@Component({})
export default class OrdersModalCancelRefund extends Vue {
  modal: boolean = false;
  success: boolean = false;
  hasError: boolean = false;
  errorShown: boolean = false;
  order: any = {};
  loading: any = { cancel: false, cancel_refund: false, refund: false };
  successMessage: any = "";
  errorMessage: any = "";

  async show(item: any) {
    this.modal = true;
    this.success = false;
    this.hasError = false;
    this.errorShown = false;
    this.order = item;
  }

  leave(): void {
    this.loading = { cancel: false, cancel_refund: false, refund: false }
    this.modal = false;
  }

  async cancelRefundOrder(type:any) {
    this.hasError = false;
    this.successMessage = this.$t('successCancelOrder');
    let defaultError = this.$t('errorCancelOrder');
    let api = `/instabot/orders/${this.order.id}/cancel_order/`;
    
    if(type == 'cancel_refund') {
      api = `/instabot/orders/${this.order.id}/cancel_refund_order/`;

      defaultError = this.$t('errorCancelRefundOrder');
      this.successMessage = this.$t('successCancelReundOrder');
      this.loading.cancel_refund = true;
    } else if(type == 'refund') {
      api = `/instabot/orders/${this.order.id}/refund_order/`;

      defaultError = this.$t('errorRefundOrder');
      this.successMessage = this.$t('successRefundOrder');
      this.loading.refund = true;
    } else {
      this.loading.cancel = true;
    }

    await this.$axios.post(api)
    .then(() => {
      this.success = true;
      setTimeout(() => {
        this.$emit('success');
        this.success = false;
        this.modal = false;
      }, 2000)
    }).catch((error:any) => {
      this.errorShown = true;
      this.errorMessage = getErrorMessage(error, defaultError);
      this.hasError = this.errorMessage.show;
    })
    this.loading = { cancel: false, cancel_refund: false, refund: false };
  }
}
