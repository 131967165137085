
import { Component, Vue, Watch } from 'vue-property-decorator'
import { AdminStore } from '~/store';
import { getErrorMessage } from "~/utils/ErrorMessageUtils"

@Component({})
export default class SettingsAddCreditsForm extends Vue {
  modal: boolean = false;
  loadingCredit: boolean = false;
  loadingCrypto: boolean = false;
  hasError: boolean = false;
  errorMessage: any = "";
  quantity: any = 10;
  hidePaymentMethods: any = process.env.HIDE_PAYMENT_METHODS;
  hasBonus: boolean = false;
  topupBonus: any = {};
  computedBonus: any = null;

  created() {
    this.hidePaymentMethods = this.hidePaymentMethods ? this.hidePaymentMethods.split(",") : [];
    if(this.discounts && this.discounts.top_up_bonus && this.discounts.top_up_bonus.threshold > 0 && this.discounts.top_up_bonus.percentage > 0) {
      this.hasBonus = true;
      this.topupBonus = this.discounts.top_up_bonus;
    }

    setTimeout(() => {
      const info = this.$t('topupBonusInfo', { percentage: this.topupBonus.percentage, threshold: this.topupBonus.threshold }) as any
      const span1 = document.createElement('span');
      span1.innerHTML = info;

      const topupInfo = this.$refs.topupInfo as any;
      if(topupInfo) {
        topupInfo.innerHTML = '';
        topupInfo.appendChild(span1);
      }
    }, 500)
  }

  @Watch('quantity')
  checkQuantity() {
    if(this.hasBonus && (this.quantity >= this.topupBonus.threshold)) {
      this.computedBonus = this.formatNumber(parseInt(this.quantity) * (parseInt(this.topupBonus.percentage)/100));

      setTimeout(() => {
        const total = this.$t('topupBonusTotal', { total: (parseInt(this.quantity) + parseFloat(this.computedBonus)) }) as any
        const span2 = document.createElement('span');
        span2.innerHTML = total;

        const topupTotal = this.$refs.topupTotal as any;
        if(topupTotal) {
          topupTotal.innerHTML = '';
          topupTotal.appendChild(span2);
        }
      }, 500)
    } else {
      this.computedBonus = null;
    }
  }

  formatNumber(num:any) {
    if (num % 1 !== 0) {
      return num.toFixed(2);
    } else {
      return num.toString();
    }
  } 

  checkToDisable(key:any) {
    const check = this.hidePaymentMethods.find((item:any) => item == key);
    return check ? true : false;
  }

  async payViaCreditCard(type:any) {
    if(type == 'cc') {
      this.loadingCredit = true;
    } else {
      this.loadingCrypto = true;
    }
    
    if(this.quantity) {
      let link = {} as any;
      if(this.$vuetify.breakpoint.lg) {
        link = window.open('_blank') as any;
      }

      await this.$axios.post('/auth/accounts/purchase_credits/', {
        qty: this.quantity,
        payment_method: type
      }).then((response:any) => {
        const url = response?.data.checkout_url;
        if(url) {
          if(this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.md) {
            location.href = url;
          } else {
            link.location = url;
          }
        }
        this.$emit('success');
        this.modal = false;
      }).catch((error:any) => {
        this.errorMessage = getErrorMessage(error, this.$t('errorSaveData'));
        this.hasError = true;
      })
    }

    if(type == 'cc') {
      this.loadingCredit = false;
    } else {
      this.loadingCrypto = false;
    }
  }

  editable() {
    setTimeout(() => {
      const field = this.$refs.field as any;
      if(field) { field.focus(); }
    }, 200)
  }

  allowNumbers() {
    this.quantity = this.quantity.replace(/[^0-9.]+/, '')
  }

  get discounts() {
    return AdminStore.getDiscounts;
  }
}
