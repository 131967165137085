
import { Component, Vue, Prop } from 'vue-property-decorator'
import { convertBytes } from '~/utils/ConvertBytesUtils';

@Component({})
export default class MobileProxyApiDynamicPricing extends Vue {
  @Prop({ default: [] }) readonly pricing!: any;

  convertTime(val:any) {
    const totalMinutes = Math.floor(val / 60);
    const seconds = val % 60;
    const minutes = totalMinutes % 60;
    const hours = Math.floor(totalMinutes / 60);
    
    if(seconds > 0) {
      return seconds + (seconds > 1 ? (" " + this.$t('secs')) : (" " + this.$t('sec')));
    } else if(minutes > 0) {
      return minutes + (minutes > 1 ? (" " + this.$t('mins')) : (" " + this.$t('min')));
    } else if(hours > 0) {
      return hours + (hours > 1 ? (" " + this.$t('hours')) : (" " + this.$t('hour')));
    }
  }

  convertPrice(val:any) {
    return (val/100).toFixed(2);
  }

  convertBytes(val:any, decimals:any = 0) {
    return convertBytes(val, decimals);
  }
}
