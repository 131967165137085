
import { Component, Vue, Prop } from 'vue-property-decorator'
import { requiredRule } from '~/utils/FormValidation'

@Component({
  head() { return { title: 'Instagram Engagement Accounts' } },
})
export default class OrderEmojiSelection extends Vue {
  @Prop({ default: () => 0 }) readonly quantity!: any

  isSelectedAll: boolean = false;
  loading: boolean = false;
  selected: any = [];
  emojis: any = ['😃', '🎉', '❤️', '✨', '🌟', '👍', '😍', '🤩', '🚀', '💯', '🌈', '🍀', '🥳', '🎈', '💖', '💌', '💎', '👌', '💪', '🙌'];
  requiredRule: any = [ requiredRule() ];
  commentLength: any = 5;

  mounted() {
    this.selectAll();
  }

  selectAll() {
    this.isSelectedAll = true;
    this.selected = [];
    this.emojis.forEach((item:any,index:any) => {
      this.selected.push(index);
    });
  }

  clearAll() {
    this.isSelectedAll = false;
    this.selected = [];
    const index = Math.floor(Math.random() * 20);
    this.selected = index < 20 ? [index] : [0];
  }

  getRandomItemsFromArray(arr:any, numItems:any) {
    const randomItems = [];
    const len = arr.length;
    for (let i = 0; i < numItems; i++) {
      const randomIndex = Math.floor(Math.random() * len);
      randomItems.push(arr[randomIndex]);
    }
    return randomItems;
  }

  generateRandom(min:any = 1, max:any = 5) {
    return Math.floor(Math.random() * (this.commentLength - min + 1)) + min;
  }

  generate() {
    if(this.quantity > 0) {
      let emojis = [] as any;
      this.selected.forEach((item:any) => {
        emojis.push(this.emojis[item]);
      });

      let comments = [];
      for(let x=0;x<this.quantity;x++) {
        const arr = this.getRandomItemsFromArray(emojis, this.generateRandom());
        comments.push(arr.join(""));
      }

      this.$emit('get-data', comments);
    }
  }
}
