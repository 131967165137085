var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-alert",
    {
      staticClass: "text-left",
      attrs: { text: "", prominent: "", dense: "", type: "error" },
    },
    [
      _vm._l(
        _vm.errorMessage.message ? _vm.errorMessage.message : _vm.errorMessage,
        function (error) {
          return [
            _c("div", { staticClass: "custom-alert" }, [
              _c("span", { staticClass: "text-size-normal" }, [
                _vm._v(_vm._s(error)),
              ]),
            ]),
          ]
        }
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }