import { $axios } from '@/utils/Api'
import Store from '@/utils/StoreInstance'
import { Module, VuexModule, Action, Mutation } from 'vuex-module-decorators'

@Module({stateFactory: true, store: Store, name: 'blogs', namespaced: true})
export default class BlogsModule extends VuexModule {
  categories: any = [];
  articles: any = [];
  article: any = {};

  // Categories
  @Mutation
  setCategories(item: any) {
    this.categories = item;
  }
  @Action({commit: 'setCategories', rawError: true})
  async fetchCategories(params: any = {}) {
    const api = '/blogs/categories/';
    const { data: news } = await $axios.get(api, {params: params});

    return news;
  }
  get getCategories():any {
    return this.categories;
  }


  // News&Blogs List
  @Mutation
  setArticles(item: any) {
    this.articles = item;
  }
  @Action({commit: 'setArticles', rawError: true})
  async fetchArticles(params: any = {}) {
    let api = '/blogs/articles-read/';
    if(params.is_admin) {
      api = '/blogs/articles/';
    }
    const { data: articles } = await $axios.get(api, {params: params})

    return articles;
  }
  get getArticles():any {
    return this.articles;
  }

  // Article Read
  @Mutation
  setArticleData(item: any) {
    this.article = item;
  }
  @Action({commit: 'setArticleData', rawError: true})
  async fetchArticleData(id:any) {
    const { data: article } = await $axios.get(`/blogs/articles-read/${id}/`)
    return article;
  }
  get getArticleData():any {
    return this.article;
  }
}
