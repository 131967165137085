
import { Component, Vue } from 'vue-property-decorator'
import { AccountsStore } from '~/store';
import { getErrorMessage } from "~/utils/ErrorMessageUtils"

@Component({})
export default class InstagramRemoveFollowersModal extends Vue {
  modal: boolean = false;
  loading: boolean = false;
  success: boolean = false;
  hasError: boolean = false;
  errorMessage: any = "";
  accountDetails: any = { username: '', bot_follower_count: 0, id: null };
  data: any = { reduce_follower_count: null };
  urlType: any = 'instabot';
  fieldErrorMessage: any = {};

  mounted() {
    if(this.$auth && this.$auth.user && this.$auth.user.is_admin) {
      this.data.user = this.$auth.user.id;
    }
  }

  show(item:any, type: any = 'instabot') {
    this.accountDetails = item;
    this.modal = true;
    this.hasError = false;
    this.urlType = type;
    this.fieldErrorMessage = {};
  }

  async downgrade() {
    this.loading = true;
    await this.$axios.post(`/${this.urlType}/instagram-accounts/${this.accountDetails.id}/downgrade_subscription/`, this.data)
    .then((response:any) => {
      this.success = true;
      this.data.reduce_follower_count = null;
      setTimeout(() => {
        this.success = false;
        this.modal = false;
        this.$emit('success');
      }, 1500)
    }).catch((error:any) => {
      this.errorMessage = getErrorMessage(error, this.$t('errorSaveData'));
      this.fieldErrorMessage = this.errorMessage.message;
      this.hasError = this.errorMessage.show;
    })
    this.loading = false;
  }

  get currentAccount() {
    return AccountsStore.getCurrentAccount;
  }
}
