var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "12", md: "3", sm: "6" } },
            [
              _c(
                "v-card",
                { staticClass: "custom-cards", attrs: { height: "100%" } },
                [
                  _c("v-card-text", { staticClass: "pa-7" }, [
                    _c(
                      "div",
                      { staticClass: "d-flex" },
                      [
                        _c("h3", { staticClass: "h_title_3 text-dark-grey" }, [
                          _vm._v(_vm._s(_vm.$t("status"))),
                        ]),
                        _vm._v(" "),
                        _vm.subscription.has_active_subscription &&
                        _vm.subscription.status == "active"
                          ? _c(
                              "v-menu",
                              {
                                attrs: { "offset-y": "", right: "" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "activator",
                                      fn: function ({ on, attrs }) {
                                        return [
                                          _c(
                                            "v-icon",
                                            _vm._g(
                                              _vm._b(
                                                {
                                                  staticClass:
                                                    "text-black ml-auto",
                                                },
                                                "v-icon",
                                                attrs,
                                                false
                                              ),
                                              on
                                            ),
                                            [_vm._v("mdi-dots-horizontal")]
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  2241476469
                                ),
                              },
                              [
                                _vm._v(" "),
                                _c(
                                  "v-list",
                                  { staticClass: "py-0", attrs: { dense: "" } },
                                  [
                                    _c(
                                      "v-list-item",
                                      {
                                        on: {
                                          click: _vm.cancelSubscriptionModal,
                                        },
                                      },
                                      [
                                        _c(
                                          "v-list-item-title",
                                          { staticClass: "red--text" },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t("cancelSubscription")
                                              )
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "h2",
                      {
                        staticClass:
                          "h_title_1 text-black mt-5 d-flex align-center",
                      },
                      [
                        _vm.subscription.status == "cancelled"
                          ? _c("v-icon", { staticClass: "warning--text" }, [
                              _vm._v("mdi-circle-medium"),
                            ])
                          : _vm.subscription.status == "inactive"
                          ? _c("v-icon", { staticClass: "error--text" }, [
                              _vm._v("mdi-circle-medium"),
                            ])
                          : _c("v-icon", { staticClass: "text-green" }, [
                              _vm._v("mdi-circle-medium"),
                            ]),
                        _vm._v(" "),
                        _c("span", { staticClass: "text-capitalize" }, [
                          _vm._v(_vm._s(_vm.subscription.status)),
                        ]),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-col",
            { attrs: { cols: "12", md: "5", sm: "6" } },
            [
              _c(
                "v-card",
                {
                  staticClass: "custom-cards",
                  attrs: { "min-height": "120", disabled: _vm.isUpdate },
                },
                [
                  _c("v-card-text", { staticClass: "pa-7" }, [
                    _c("h3", { staticClass: "h_title_3 text-dark-grey" }, [
                      _vm._v(_vm._s(_vm.$t("pricing"))),
                    ]),
                    _vm._v(" "),
                    _vm.subscription.has_active_subscription
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "d-flex align-center mt-5 cursor-pointer",
                          },
                          [
                            _vm.subscription.pricing_mode == "flat"
                              ? _c(
                                  "h2",
                                  {
                                    staticClass:
                                      "h_title_1 text-black line-height-normal text-left",
                                  },
                                  [_vm._v(_vm._s(_vm.$t("flatratePricing")))]
                                )
                              : _c(
                                  "h2",
                                  {
                                    staticClass:
                                      "h_title_1 text-black text-left",
                                  },
                                  [_vm._v(_vm._s(_vm.$t("dynamicPricing")))]
                                ),
                            _vm._v(" "),
                            _vm.isAdmin
                              ? [
                                  _c(
                                    "span",
                                    {
                                      staticClass: "ml-auto",
                                      on: {
                                        click: function ($event) {
                                          return _vm.$emit("is-update", true)
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "text-purple-2 h_title_3 font-weight-medium",
                                        },
                                        [_vm._v(_vm._s(_vm.$t("change")))]
                                      ),
                                    ]
                                  ),
                                ]
                              : [
                                  _c(
                                    "nuxt-link",
                                    {
                                      staticClass: "ml-auto",
                                      attrs: {
                                        to: _vm.localePath(
                                          `/dashboard/mobile-proxy-api/subscription/${_vm.subscription.id}/pricing`
                                        ),
                                      },
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "text-purple-2 h_title_3 font-weight-medium",
                                        },
                                        [_vm._v(_vm._s(_vm.$t("change")))]
                                      ),
                                    ]
                                  ),
                                ],
                          ],
                          2
                        )
                      : _c(
                          "div",
                          { staticClass: "mt-5" },
                          [
                            _vm.isAdmin
                              ? [
                                  _c(
                                    "span",
                                    {
                                      staticClass: "ml-auto cursor-pointer",
                                      on: {
                                        click: function ($event) {
                                          return _vm.$emit("is-update", true)
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "text-purple-2 h_title_3 font-weight-medium",
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t("restartSubscription")
                                            )
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              : [
                                  _c(
                                    "nuxt-link",
                                    {
                                      attrs: {
                                        to: _vm.localePath(
                                          `/dashboard/mobile-proxy-api/subscription/${_vm.subscription.id}/pricing`
                                        ),
                                      },
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "text-purple-2 h_title_3 font-weight-medium",
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t("restartSubscription")
                                            )
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ],
                          ],
                          2
                        ),
                    _vm._v(" "),
                    _vm.subscription.pricing_mode == "flat" &&
                    _vm.subscription.next_pricing_mode == "dynamic"
                      ? _c(
                          "div",
                          { staticClass: "mt-5" },
                          [
                            _c(
                              "v-alert",
                              {
                                staticClass: "pa-2 py-1 mb-0 text-black",
                                attrs: { outlined: "", color: "warning" },
                              },
                              [
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "text-black text-size-normal font-weight-medium",
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t("subscriptionUpdatedTo", {
                                          date: _vm.formatDate(
                                            _vm.subscription.ended_at
                                          ),
                                        })
                                      )
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ],
                          1
                        )
                      : _vm.subscription.has_active_subscription &&
                        _vm.subscription.status == "cancelled"
                      ? _c(
                          "div",
                          { staticClass: "mt-5" },
                          [
                            _c(
                              "v-alert",
                              {
                                staticClass: "pa-2 py-1 mb-0 text-black",
                                attrs: { outlined: "", color: "warning" },
                              },
                              [
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "text-black text-size-normal font-weight-medium",
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t("subscriptionWillEnd", {
                                          date: _vm.formatDate(
                                            _vm.subscription.ended_at
                                          ),
                                        })
                                      )
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-col",
            { attrs: { cols: "12", md: "3", sm: "6" } },
            [
              _c(
                "v-card",
                {
                  staticClass: "custom-cards",
                  attrs: {
                    height: "100%",
                    disabled:
                      _vm.isUpdate || !_vm.subscription.has_active_subscription,
                  },
                },
                [
                  _c("v-card-text", { staticClass: "pa-7" }, [
                    _c("h3", { staticClass: "h_title_3 text-dark-grey" }, [
                      _vm._v(_vm._s(_vm.$t("numOfPorts"))),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "d-flex align-center mt-5" }, [
                      _c("h2", { staticClass: "h_title_1 text-black" }, [
                        _vm._v(
                          _vm._s(
                            _vm.subscription && _vm.subscription.ports
                              ? _vm.subscription.ports
                              : 0
                          )
                        ),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "ml-auto" },
                        [
                          _c(
                            "v-icon",
                            {
                              staticClass: "text-brand",
                              on: {
                                click: function ($event) {
                                  return _vm.showModal("add")
                                },
                              },
                            },
                            [_vm._v("mdi-plus-circle-outline")]
                          ),
                          _vm._v(" "),
                          _c(
                            "v-icon",
                            {
                              staticClass: "text-brand",
                              on: {
                                click: function ($event) {
                                  return _vm.showModal("remove")
                                },
                              },
                            },
                            [_vm._v("mdi-minus-circle-outline")]
                          ),
                        ],
                        1
                      ),
                    ]),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          !_vm.isAdmin
            ? _c(
                "v-col",
                { attrs: { cols: "12", md: "4", sm: "6" } },
                [
                  _c(
                    "v-card",
                    { staticClass: "custom-cards" },
                    [
                      _c("v-card-text", { staticClass: "pa-7" }, [
                        _c("h3", { staticClass: "h_title_3 text-dark-grey" }, [
                          _vm._v(_vm._s(_vm.$t("apiDocumentation"))),
                        ]),
                        _vm._v(" "),
                        _c(
                          "h3",
                          {
                            staticClass:
                              "h_title_3 font-weight-bold text-purple-2 mt-5",
                          },
                          [
                            _c(
                              "nuxt-link",
                              {
                                attrs: {
                                  target: "_blank",
                                  to: "//" + _vm.docuLink,
                                },
                              },
                              [
                                _c(
                                  "span",
                                  { staticClass: "text-purple-2 text-16" },
                                  [_vm._v(_vm._s(_vm.$t("proxyAPIDocu")))]
                                ),
                                _vm._v(" "),
                                _c(
                                  "v-icon",
                                  {
                                    staticClass: "ml-2 back-link",
                                    attrs: { small: "" },
                                  },
                                  [_vm._v("mdi-arrow-right")]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c("mobile-proxy-api-modal-add-remove-ports", {
        ref: "addRemovePorts",
        on: {
          success: function ($event) {
            return _vm.$emit("reload")
          },
        },
      }),
      _vm._v(" "),
      _c("modal-delete", {
        ref: "cancelSubscription",
        on: { delete: _vm.cancelSubscription },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }