
import { Component, Prop, Mixins } from 'vue-property-decorator'
import { BlogsStore } from '@/store'
import { getErrorMessage } from "~/utils/ErrorMessageUtils";
import { dateFormat } from "~/utils/DateTimeUtils"
import TableFiltersMixin from '~/mixins/local-storage-filters'

@Component({})
export default class NewsBlogsList extends Mixins(TableFiltersMixin) {
  @Prop({ default: false }) readonly isMobile!: Boolean

  dateFormat: any = null;
  articles: any = [];
  page: number = 1;
  pageCount: number = 0;
  perPage: number = 6;
  perPageOptions: any = [6, 30, 50, 100, 300, 500];
  isFetching: boolean = true;
  totalData: number = 0;
  filters: any = {};

  mounted() {
    this.preGetData();
  }

  handlePageChange(page: number) {
    if (page !== this.page) {
      this.page = page;
      this.preGetData();
    }
  }

  handlePerPageChange(perPage: number) {
    this.page = 1;
    this.perPage = perPage;
    this.preGetData();
  }

  filterAccounts(item:any) {
    this.filters = { ...this.filters, ...item };
    this.page = 1;

    this.preGetData();
  }

   // Fetch and save filters to local storage
   async preGetData(filters:any = null) {
    await this.getLocalFilters(this, "HH_blogs");

    if(filters) { this.filters = filters; }
    await this.getData();

    const params = {
      page: this.page,
      perPage: this.perPage,
      filters: { ...this.filters }
    }
    await this.saveLocalFilters(params, "HH_blogs");
  }

  async getData() {
    this.isFetching = true;
    this.$emit('loading', true);

    const params = {
      offset: this.page === 1 ? 0 : (this.page - 1) * this.perPage,
      limit: this.perPage,
      is_admin: this.$auth?.user?.is_admin,
      ...this.filters,
    }

    await BlogsStore.fetchArticles(params)
    .then((response:any) => {
      if(response != null) {
        this.articles = response.results;
        this.totalData = response?.count;
        const count = response.count/this.perPage;
        this.pageCount = count == Math.floor(count) ? count : (Math.floor(count) + 1);

        this.$emit('get-count', this.articles.length)
      }
    }).catch((error:any) => {
      const message = getErrorMessage(error, this.$t('errorFetchingArticles'));
      const snackbar = this.$refs.error as any;
      if(snackbar) { snackbar.show(message); }
    })
    
    this.isFetching = false;
    this.$emit('loading', false);
  }

  createOverview(str: any) {
    const el = document.createElement('span');
    el.innerHTML = str;
    return el.innerText.substring(0,200);;
  }

  getColor(str:any) {
    var hash = 0;
    for (var i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }

    var value = (hash >> (i * 8)) & 0xFF;
    return "hsla(" + ~~(value) + "," + "100%,"+ "83%, 1)";
  }

  openDeleteModal(id: any) {
    const content = {
      id: id,
      ...this.$t('articleModalDelete') as any
    }

    const modal = this.$refs.deleteArticle as any;
    modal.show(content);
  }

  async deleteArticle(item:any) {
    const modal = this.$refs.deleteArticle as any;
    await this.$axios.delete(`/blogs/articles/${item.id}/`)
    .then(() => {
      modal.success = true;
      setTimeout(() => {
        modal.leave();
      }, 2000)

      this.preGetData();
    }).catch((error:any) => {
      const message = getErrorMessage(error, this.$t('errorDeleteArticle'));
      const snackbar = this.$refs.error as any;
      if(snackbar) { snackbar.show(message); }
    })
  }

  formatDate(date:any) {
    return dateFormat(this, date);
  }
}
