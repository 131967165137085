
import { Component, Vue } from 'vue-property-decorator'
import { getErrorMessage } from '~/utils/ErrorMessageUtils';

@Component({})
export default class AdminUserDiscounts extends Vue {
  modal: boolean = false;
  success: boolean = false;
  hasError: boolean = false;
  saving: boolean = false;
  errorMessage: any = "";
  user: any = {};
  loading: any = { topup: false, turnover: false };
  
  topupBonus: any = {};
  progressTopupLoading: boolean = false;
  fieldErrorMessageTopup: any = {};

  fieldErrorMessageTurnover: any = {};
  progressTurnOverListLoading: boolean = false;
  savingTurnoverEdit: boolean = false;
  isAddTurnover: boolean = false;
  turnoverBonus: any = {};
  turnoverBonusList: any = [];
  editId: any = null;
  isEditing: boolean = false;
  holderValue: any = { threshold: null, percentage: null };

  show(item:any) {
    this.user = item;
    this.success = false;
    this.hasError = false;
    this.editId = null;
    this.isAddTurnover = false;
    this.turnoverBonusList = [];
    
    this.modal = true;
    this.getData();
  }

  leave() {
    const form1 = this.$refs.formTopup as any;
    form1.reset();
    const form2 = this.$refs.formTurnover as any;
    form2.reset();
    this.modal = false;
  }

  async getData() {
    if(this.user.has_topup_bonus) {
      await this.getTopupBonus();
    }
    if(this.user.has_turnover_bonuses) {
      await this.getTurnoverBonus();
    } else {
      this.isAddTurnover = true;
    }
  }

  async getTopupBonus() {
    this.progressTopupLoading = true;
    await this.$axios.get(`/management/topup-bonus/${this.user.id}/`)
    .then((response:any) => {
      this.topupBonus = { ...response.data };
    }).catch((error:any) => {
      this.errorMessage = getErrorMessage(error, this.$t('errorFetchingData'))
      this.hasError = true;
    })
    this.progressTopupLoading = false;
  }

  async saveTopupBonus() {
    this.fieldErrorMessageTopup = {};
    this.hasError = false;

    const form = this.$refs.formTopup as any;
    if(form.validate()) {
      this.saving = true;
      this.loading.topup = true;

      if(this.user.has_topup_bonus) {
        const data = { threshold: this.topupBonus.threshold, percentage: this.topupBonus.percentage };
        await this.$axios.patch(`/management/topup-bonus/${this.user.id}/`, data)
        .then(() => {
          this.success = true;
          setTimeout(() => {
            this.success = false;
          }, 1500)
        }).catch((error:any) => {
          this.errorMessage = getErrorMessage(error, this.$t('errorSaveData'))
          this.fieldErrorMessageTopup = this.errorMessage.message;
          this.hasError = this.errorMessage.show;
        })
      } else {
        await this.$axios.post('/management/topup-bonus/', { ...this.topupBonus, user: this.user.id})
        .then(() => {
          this.user.has_topup_bonus = true;
          this.success = true;
          setTimeout(() => {
            this.$emit('success');
            this.success = false;
          }, 1500)
        }).catch((error:any) => {
          this.errorMessage = getErrorMessage(error, this.$t('errorSaveData'))
          this.fieldErrorMessageTopup = this.errorMessage.message;
          this.hasError = this.errorMessage.show;
        })
      }
      this.saving = false;
      this.loading.topup = false;
    }
  }

  async deleteTopupDiscount(id:any) {
    await this.$axios.delete(`/management/topup-bonus/${id}/`)
    .then(() => {
      this.user.has_topup_bonus = false;
      this.topupBonus = {};
      this.$emit('deleted');
    }).catch((error:any) => {
      this.errorMessage = getErrorMessage(error, this.$t('errorSaveData'))
      this.fieldErrorMessageTopup = this.errorMessage.message;
      this.hasError = this.errorMessage.show;
    })
  }

  // Turnover Bonus

  editItem(item:any) {
    this.isEditing = true;
    this.editId = item.id;
    this.holderValue = { threshold: item.threshold, percentage: item.percentage };
  }

  async getTurnoverBonus() {
    this.progressTurnOverListLoading = true;
    await this.$axios.get(`/management/turnover-bonuses/?user=${this.user.id}`)
    .then((response:any) => {
      this.turnoverBonusList = response?.data?.results;
    }).catch((error:any) => {
      this.errorMessage = getErrorMessage(error, this.$t('errorFetchingData'))
      this.hasError = true;
    })
    this.progressTurnOverListLoading = false;
  }

  async saveTurnoverBonus() {
    this.fieldErrorMessageTurnover = {};
    this.hasError = false;

    const form = this.$refs.formTurnover as any;
    if(form.validate()) {
      this.saving = true;
      this.loading.turnover = true;

      await this.$axios.post('/management/turnover-bonuses/', { ...this.turnoverBonus, user: this.user.id})
      .then(() => {
        this.success = true;
        this.isAddTurnover = false;
        this.user.has_turnover_bonuses = true;
        const form2 = this.$refs.formTurnover as any;
        form2.reset();

        this.getTurnoverBonus();
        setTimeout(() => {
          this.$emit('success');
          this.success = false;
        }, 1500)
      }).catch((error:any) => {
        this.errorMessage = getErrorMessage(error, this.$t('errorSaveData'))
        this.fieldErrorMessageTurnover = this.errorMessage.message;
        this.hasError = this.errorMessage.show;
      })
      this.saving = false;
      this.loading.turnover = false;
    }
  }

  async updateTurnover(item:any) {
    this.fieldErrorMessageTurnover = {};
    this.hasError = false;
    this.saving = true;
    this.savingTurnoverEdit = true;

    await this.$axios.patch(`/management/turnover-bonuses/${item.id}/`, {
      threshold: item.threshold,
      percentage: item.percentage
    }).then(() => {
      this.isEditing = false;
      this.editId = null;
      this.holderValue = { threshold: null, percentage: null };
      this.success = true;
      setTimeout(() => {
        this.success = false;
      }, 1500)
    }).catch((error:any) => {
      this.errorMessage = getErrorMessage(error, this.$t('errorSaveData'))
      this.fieldErrorMessageTurnover = this.errorMessage.message;
      this.hasError = this.errorMessage.show;
    })
    this.saving = false;
    this.savingTurnoverEdit = false;
  }

  async deleteTurnoverDiscount(id:any) {
    await this.$axios.delete(`/management/turnover-bonuses/${id}/`)
    .then(() => {
      this.getTurnoverBonus();
      this.turnoverBonus = {};
      this.$emit('deleted');
    }).catch((error:any) => {
      this.errorMessage = getErrorMessage(error, this.$t('errorSaveData'))
      this.fieldErrorMessageTopup = this.errorMessage.message;
      this.hasError = this.errorMessage.show;
    })
  }
}
