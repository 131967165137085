
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({})
export default class SnackbarError extends Vue {
  errorSnackbar: boolean = false;
  errorMessage: any = "";

  show(msg: any) {
    this.errorMessage = msg ? msg : [this.$t('errorSubmitData')];
    this.errorSnackbar = true;
  } 
}
