import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _2dfb1658 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _10ba8d22 = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _0f7d08ae = () => interopDefault(import('../pages/account/forgot-password.vue' /* webpackChunkName: "pages/account/forgot-password" */))
const _76c01baf = () => interopDefault(import('../pages/account/register.vue' /* webpackChunkName: "pages/account/register" */))
const _16b8f736 = () => interopDefault(import('../pages/account/reset-password.vue' /* webpackChunkName: "pages/account/reset-password" */))
const _41200785 = () => interopDefault(import('../pages/account/verify.vue' /* webpackChunkName: "pages/account/verify" */))
const _2f9e5638 = () => interopDefault(import('../pages/dashboard/home.vue' /* webpackChunkName: "pages/dashboard/home" */))
const _4349046c = () => interopDefault(import('../pages/dashboard/admin/revenue-report.vue' /* webpackChunkName: "pages/dashboard/admin/revenue-report" */))
const _2a841ca8 = () => interopDefault(import('../pages/dashboard/admin/settings.vue' /* webpackChunkName: "pages/dashboard/admin/settings" */))
const _ef2b7ed4 = () => interopDefault(import('../pages/dashboard/admin/users/index.vue' /* webpackChunkName: "pages/dashboard/admin/users/index" */))
const _6a7a116b = () => interopDefault(import('../pages/dashboard/instagram/accounts/index.vue' /* webpackChunkName: "pages/dashboard/instagram/accounts/index" */))
const _0252b82a = () => interopDefault(import('../pages/dashboard/instagram/orders/index.vue' /* webpackChunkName: "pages/dashboard/instagram/orders/index" */))
const _d196dcc8 = () => interopDefault(import('../pages/dashboard/instagram/reporting.vue' /* webpackChunkName: "pages/dashboard/instagram/reporting" */))
const _799781f4 = () => interopDefault(import('../pages/dashboard/mobile-proxy-api/reporting.vue' /* webpackChunkName: "pages/dashboard/mobile-proxy-api/reporting" */))
const _12adc38a = () => interopDefault(import('../pages/dashboard/mobile-proxy-api/subscription/index.vue' /* webpackChunkName: "pages/dashboard/mobile-proxy-api/subscription/index" */))
const _e109d874 = () => interopDefault(import('../pages/dashboard/settings/account.vue' /* webpackChunkName: "pages/dashboard/settings/account" */))
const _2b0157ed = () => interopDefault(import('../pages/dashboard/settings/blogs/index.vue' /* webpackChunkName: "pages/dashboard/settings/blogs/index" */))
const _7bc00a0f = () => interopDefault(import('../pages/dashboard/settings/transactions/index.vue' /* webpackChunkName: "pages/dashboard/settings/transactions/index" */))
const _9a40a836 = () => interopDefault(import('../pages/dashboard/admin/users/add.vue' /* webpackChunkName: "pages/dashboard/admin/users/add" */))
const _76e7adfa = () => interopDefault(import('../pages/dashboard/instagram/accounts/add.vue' /* webpackChunkName: "pages/dashboard/instagram/accounts/add" */))
const _6c2e0f0e = () => interopDefault(import('../pages/dashboard/instagram/orders/add.vue' /* webpackChunkName: "pages/dashboard/instagram/orders/add" */))
const _380250ba = () => interopDefault(import('../pages/dashboard/settings/blogs/categories-configuration.vue' /* webpackChunkName: "pages/dashboard/settings/blogs/categories-configuration" */))
const _5cddf071 = () => interopDefault(import('../pages/dashboard/settings/blogs/create.vue' /* webpackChunkName: "pages/dashboard/settings/blogs/create" */))
const _016302eb = () => interopDefault(import('../pages/dashboard/settings/transactions/add-credits.vue' /* webpackChunkName: "pages/dashboard/settings/transactions/add-credits" */))
const _423d58f6 = () => interopDefault(import('../pages/dashboard/settings/transactions/add-manual.vue' /* webpackChunkName: "pages/dashboard/settings/transactions/add-manual" */))
const _b53f22aa = () => interopDefault(import('../pages/dashboard/admin/users/_id/edit.vue' /* webpackChunkName: "pages/dashboard/admin/users/_id/edit" */))
const _e5ab1474 = () => interopDefault(import('../pages/dashboard/admin/users/_id/view.vue' /* webpackChunkName: "pages/dashboard/admin/users/_id/view" */))
const _c8ad0e5e = () => interopDefault(import('../pages/dashboard/instagram/accounts/_id/view.vue' /* webpackChunkName: "pages/dashboard/instagram/accounts/_id/view" */))
const _70aaa2e0 = () => interopDefault(import('../pages/dashboard/mobile-proxy-api/subscription/_id/account.vue' /* webpackChunkName: "pages/dashboard/mobile-proxy-api/subscription/_id/account" */))
const _1e2d60ae = () => interopDefault(import('../pages/dashboard/mobile-proxy-api/subscription/_id/pricing.vue' /* webpackChunkName: "pages/dashboard/mobile-proxy-api/subscription/_id/pricing" */))
const _720583f4 = () => interopDefault(import('../pages/dashboard/settings/blogs/_id/edit.vue' /* webpackChunkName: "pages/dashboard/settings/blogs/_id/edit" */))
const _59cf8b0f = () => interopDefault(import('../pages/dashboard/settings/blogs/_id/view.vue' /* webpackChunkName: "pages/dashboard/settings/blogs/_id/view" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/de",
    component: _2dfb1658,
    name: "index___de"
  }, {
    path: "/en",
    component: _2dfb1658,
    name: "index___en"
  }, {
    path: "/login",
    component: _10ba8d22,
    name: "login___en___default"
  }, {
    path: "/account/forgot-password",
    component: _0f7d08ae,
    name: "account-forgot-password___en___default"
  }, {
    path: "/account/register",
    component: _76c01baf,
    name: "account-register___en___default"
  }, {
    path: "/account/reset-password",
    component: _16b8f736,
    name: "account-reset-password___en___default"
  }, {
    path: "/account/verify",
    component: _41200785,
    name: "account-verify___en___default"
  }, {
    path: "/dashboard/home",
    component: _2f9e5638,
    name: "dashboard-home___en___default"
  }, {
    path: "/de/login",
    component: _10ba8d22,
    name: "login___de"
  }, {
    path: "/en/login",
    component: _10ba8d22,
    name: "login___en"
  }, {
    path: "/dashboard/admin/revenue-report",
    component: _4349046c,
    name: "dashboard-admin-revenue-report___en___default"
  }, {
    path: "/dashboard/admin/settings",
    component: _2a841ca8,
    name: "dashboard-admin-settings___en___default"
  }, {
    path: "/dashboard/admin/users",
    component: _ef2b7ed4,
    name: "dashboard-admin-users___en___default"
  }, {
    path: "/dashboard/instagram/accounts",
    component: _6a7a116b,
    name: "dashboard-instagram-accounts___en___default"
  }, {
    path: "/dashboard/instagram/orders",
    component: _0252b82a,
    name: "dashboard-instagram-orders___en___default"
  }, {
    path: "/dashboard/instagram/reporting",
    component: _d196dcc8,
    name: "dashboard-instagram-reporting___en___default"
  }, {
    path: "/dashboard/mobile-proxy-api/reporting",
    component: _799781f4,
    name: "dashboard-mobile-proxy-api-reporting___en___default"
  }, {
    path: "/dashboard/mobile-proxy-api/subscription",
    component: _12adc38a,
    name: "dashboard-mobile-proxy-api-subscription___en___default"
  }, {
    path: "/dashboard/settings/account",
    component: _e109d874,
    name: "dashboard-settings-account___en___default"
  }, {
    path: "/dashboard/settings/blogs",
    component: _2b0157ed,
    name: "dashboard-settings-blogs___en___default"
  }, {
    path: "/dashboard/settings/transactions",
    component: _7bc00a0f,
    name: "dashboard-settings-transactions___en___default"
  }, {
    path: "/de/account/forgot-password",
    component: _0f7d08ae,
    name: "account-forgot-password___de"
  }, {
    path: "/de/account/register",
    component: _76c01baf,
    name: "account-register___de"
  }, {
    path: "/de/account/reset-password",
    component: _16b8f736,
    name: "account-reset-password___de"
  }, {
    path: "/de/account/verify",
    component: _41200785,
    name: "account-verify___de"
  }, {
    path: "/de/dashboard/home",
    component: _2f9e5638,
    name: "dashboard-home___de"
  }, {
    path: "/en/account/forgot-password",
    component: _0f7d08ae,
    name: "account-forgot-password___en"
  }, {
    path: "/en/account/register",
    component: _76c01baf,
    name: "account-register___en"
  }, {
    path: "/en/account/reset-password",
    component: _16b8f736,
    name: "account-reset-password___en"
  }, {
    path: "/en/account/verify",
    component: _41200785,
    name: "account-verify___en"
  }, {
    path: "/en/dashboard/home",
    component: _2f9e5638,
    name: "dashboard-home___en"
  }, {
    path: "/dashboard/admin/users/add",
    component: _9a40a836,
    name: "dashboard-admin-users-add___en___default"
  }, {
    path: "/dashboard/instagram/accounts/add",
    component: _76e7adfa,
    name: "dashboard-instagram-accounts-add___en___default"
  }, {
    path: "/dashboard/instagram/orders/add",
    component: _6c2e0f0e,
    name: "dashboard-instagram-orders-add___en___default"
  }, {
    path: "/dashboard/settings/blogs/categories-configuration",
    component: _380250ba,
    name: "dashboard-settings-blogs-categories-configuration___en___default"
  }, {
    path: "/dashboard/settings/blogs/create",
    component: _5cddf071,
    name: "dashboard-settings-blogs-create___en___default"
  }, {
    path: "/dashboard/settings/transactions/add-credits",
    component: _016302eb,
    name: "dashboard-settings-transactions-add-credits___en___default"
  }, {
    path: "/dashboard/settings/transactions/add-manual",
    component: _423d58f6,
    name: "dashboard-settings-transactions-add-manual___en___default"
  }, {
    path: "/de/dashboard/admin/revenue-report",
    component: _4349046c,
    name: "dashboard-admin-revenue-report___de"
  }, {
    path: "/de/dashboard/admin/settings",
    component: _2a841ca8,
    name: "dashboard-admin-settings___de"
  }, {
    path: "/de/dashboard/admin/users",
    component: _ef2b7ed4,
    name: "dashboard-admin-users___de"
  }, {
    path: "/de/dashboard/instagram/accounts",
    component: _6a7a116b,
    name: "dashboard-instagram-accounts___de"
  }, {
    path: "/de/dashboard/instagram/orders",
    component: _0252b82a,
    name: "dashboard-instagram-orders___de"
  }, {
    path: "/de/dashboard/instagram/reporting",
    component: _d196dcc8,
    name: "dashboard-instagram-reporting___de"
  }, {
    path: "/de/dashboard/mobile-proxy-api/reporting",
    component: _799781f4,
    name: "dashboard-mobile-proxy-api-reporting___de"
  }, {
    path: "/de/dashboard/mobile-proxy-api/subscription",
    component: _12adc38a,
    name: "dashboard-mobile-proxy-api-subscription___de"
  }, {
    path: "/de/dashboard/settings/account",
    component: _e109d874,
    name: "dashboard-settings-account___de"
  }, {
    path: "/de/dashboard/settings/blogs",
    component: _2b0157ed,
    name: "dashboard-settings-blogs___de"
  }, {
    path: "/de/dashboard/settings/transactions",
    component: _7bc00a0f,
    name: "dashboard-settings-transactions___de"
  }, {
    path: "/en/dashboard/admin/revenue-report",
    component: _4349046c,
    name: "dashboard-admin-revenue-report___en"
  }, {
    path: "/en/dashboard/admin/settings",
    component: _2a841ca8,
    name: "dashboard-admin-settings___en"
  }, {
    path: "/en/dashboard/admin/users",
    component: _ef2b7ed4,
    name: "dashboard-admin-users___en"
  }, {
    path: "/en/dashboard/instagram/accounts",
    component: _6a7a116b,
    name: "dashboard-instagram-accounts___en"
  }, {
    path: "/en/dashboard/instagram/orders",
    component: _0252b82a,
    name: "dashboard-instagram-orders___en"
  }, {
    path: "/en/dashboard/instagram/reporting",
    component: _d196dcc8,
    name: "dashboard-instagram-reporting___en"
  }, {
    path: "/en/dashboard/mobile-proxy-api/reporting",
    component: _799781f4,
    name: "dashboard-mobile-proxy-api-reporting___en"
  }, {
    path: "/en/dashboard/mobile-proxy-api/subscription",
    component: _12adc38a,
    name: "dashboard-mobile-proxy-api-subscription___en"
  }, {
    path: "/en/dashboard/settings/account",
    component: _e109d874,
    name: "dashboard-settings-account___en"
  }, {
    path: "/en/dashboard/settings/blogs",
    component: _2b0157ed,
    name: "dashboard-settings-blogs___en"
  }, {
    path: "/en/dashboard/settings/transactions",
    component: _7bc00a0f,
    name: "dashboard-settings-transactions___en"
  }, {
    path: "/de/dashboard/admin/users/add",
    component: _9a40a836,
    name: "dashboard-admin-users-add___de"
  }, {
    path: "/de/dashboard/instagram/accounts/add",
    component: _76e7adfa,
    name: "dashboard-instagram-accounts-add___de"
  }, {
    path: "/de/dashboard/instagram/orders/add",
    component: _6c2e0f0e,
    name: "dashboard-instagram-orders-add___de"
  }, {
    path: "/de/dashboard/settings/blogs/categories-configuration",
    component: _380250ba,
    name: "dashboard-settings-blogs-categories-configuration___de"
  }, {
    path: "/de/dashboard/settings/blogs/create",
    component: _5cddf071,
    name: "dashboard-settings-blogs-create___de"
  }, {
    path: "/de/dashboard/settings/transactions/add-credits",
    component: _016302eb,
    name: "dashboard-settings-transactions-add-credits___de"
  }, {
    path: "/de/dashboard/settings/transactions/add-manual",
    component: _423d58f6,
    name: "dashboard-settings-transactions-add-manual___de"
  }, {
    path: "/en/dashboard/admin/users/add",
    component: _9a40a836,
    name: "dashboard-admin-users-add___en"
  }, {
    path: "/en/dashboard/instagram/accounts/add",
    component: _76e7adfa,
    name: "dashboard-instagram-accounts-add___en"
  }, {
    path: "/en/dashboard/instagram/orders/add",
    component: _6c2e0f0e,
    name: "dashboard-instagram-orders-add___en"
  }, {
    path: "/en/dashboard/settings/blogs/categories-configuration",
    component: _380250ba,
    name: "dashboard-settings-blogs-categories-configuration___en"
  }, {
    path: "/en/dashboard/settings/blogs/create",
    component: _5cddf071,
    name: "dashboard-settings-blogs-create___en"
  }, {
    path: "/en/dashboard/settings/transactions/add-credits",
    component: _016302eb,
    name: "dashboard-settings-transactions-add-credits___en"
  }, {
    path: "/en/dashboard/settings/transactions/add-manual",
    component: _423d58f6,
    name: "dashboard-settings-transactions-add-manual___en"
  }, {
    path: "/",
    component: _2dfb1658,
    name: "index___en___default"
  }, {
    path: "/de/dashboard/admin/users/:id/edit",
    component: _b53f22aa,
    name: "dashboard-admin-users-id-edit___de"
  }, {
    path: "/de/dashboard/admin/users/:id/view",
    component: _e5ab1474,
    name: "dashboard-admin-users-id-view___de"
  }, {
    path: "/de/dashboard/instagram/accounts/:id/view",
    component: _c8ad0e5e,
    name: "dashboard-instagram-accounts-id-view___de"
  }, {
    path: "/de/dashboard/mobile-proxy-api/subscription/:id?/account",
    component: _70aaa2e0,
    name: "dashboard-mobile-proxy-api-subscription-id-account___de"
  }, {
    path: "/de/dashboard/mobile-proxy-api/subscription/:id?/pricing",
    component: _1e2d60ae,
    name: "dashboard-mobile-proxy-api-subscription-id-pricing___de"
  }, {
    path: "/de/dashboard/settings/blogs/:id/edit",
    component: _720583f4,
    name: "dashboard-settings-blogs-id-edit___de"
  }, {
    path: "/de/dashboard/settings/blogs/:id/view",
    component: _59cf8b0f,
    name: "dashboard-settings-blogs-id-view___de"
  }, {
    path: "/en/dashboard/admin/users/:id/edit",
    component: _b53f22aa,
    name: "dashboard-admin-users-id-edit___en"
  }, {
    path: "/en/dashboard/admin/users/:id/view",
    component: _e5ab1474,
    name: "dashboard-admin-users-id-view___en"
  }, {
    path: "/en/dashboard/instagram/accounts/:id/view",
    component: _c8ad0e5e,
    name: "dashboard-instagram-accounts-id-view___en"
  }, {
    path: "/en/dashboard/mobile-proxy-api/subscription/:id?/account",
    component: _70aaa2e0,
    name: "dashboard-mobile-proxy-api-subscription-id-account___en"
  }, {
    path: "/en/dashboard/mobile-proxy-api/subscription/:id?/pricing",
    component: _1e2d60ae,
    name: "dashboard-mobile-proxy-api-subscription-id-pricing___en"
  }, {
    path: "/en/dashboard/settings/blogs/:id/edit",
    component: _720583f4,
    name: "dashboard-settings-blogs-id-edit___en"
  }, {
    path: "/en/dashboard/settings/blogs/:id/view",
    component: _59cf8b0f,
    name: "dashboard-settings-blogs-id-view___en"
  }, {
    path: "/dashboard/admin/users/:id/edit",
    component: _b53f22aa,
    name: "dashboard-admin-users-id-edit___en___default"
  }, {
    path: "/dashboard/admin/users/:id/view",
    component: _e5ab1474,
    name: "dashboard-admin-users-id-view___en___default"
  }, {
    path: "/dashboard/instagram/accounts/:id/view",
    component: _c8ad0e5e,
    name: "dashboard-instagram-accounts-id-view___en___default"
  }, {
    path: "/dashboard/mobile-proxy-api/subscription/:id?/account",
    component: _70aaa2e0,
    name: "dashboard-mobile-proxy-api-subscription-id-account___en___default"
  }, {
    path: "/dashboard/mobile-proxy-api/subscription/:id?/pricing",
    component: _1e2d60ae,
    name: "dashboard-mobile-proxy-api-subscription-id-pricing___en___default"
  }, {
    path: "/dashboard/settings/blogs/:id/edit",
    component: _720583f4,
    name: "dashboard-settings-blogs-id-edit___en___default"
  }, {
    path: "/dashboard/settings/blogs/:id/view",
    component: _59cf8b0f,
    name: "dashboard-settings-blogs-id-view___en___default"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
