import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);

export default ({ app }, inject) => {
  let userTimezone = 'Europe/Berlin';
  try {
    userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  } catch (error) {
    userTimezone = 'Europe/Berlin';
  }
  
  inject('dayjs', (date) => {
    return dayjs(date).tz(userTimezone);
  });
};
