
import { Component, Prop, Watch, Mixins } from 'vue-property-decorator'
import { dateFormat, timeFormat } from "~/utils/DateTimeUtils"
import { TransactionsStore } from '~/store'
import { LocalStorageFiltersStore } from '~/store';
import LocalStorageFiltersMixin from '~/mixins/local-storage-filters';

@Component({})
export default class InstagramSubscriptionTable extends Mixins(LocalStorageFiltersMixin) {
  @Prop({ default: () => false }) readonly disabled!: any
  @Prop({ default: () => false }) readonly currentUser!: any
  @Prop({ default: 'instabot' }) readonly urlType!: any

  tableItems: any = [];
  totalData: number = 0;
  page: number = 1;
  perPage: number = 15;
  perPageOptions = [15, 30, 50, 100, 300, 500];
  options: any = {};
  loading: boolean = false;
  headers = [
    { text: this.$t('date'), value: "created" },
    { text: this.$t('time'), value: "time", sortable: false },
    { text: this.$t('followers'), value: "purchased_bot_count", align: 'right' },
    { text: this.$t('credits'), value: "credits", align: 'right' },
  ];
  filters: any = {};

  @Watch('currentUser')
  checkCurrentUser() {
    this.preGetData();
  }

  handlePageChange(page: number) {
    if (page !== this.page) {
      this.page = page;
      if(this.currentUser && this.currentUser.id) {
        this.preGetData();
      }
    }
  }

  handlePerPageChange(perPage: number) {
    this.page = 1;
    this.perPage = perPage;
    if(this.currentUser && this.currentUser.id) {
      this.preGetData();
    }
  }

  @Watch('options', { deep: true })
  query() {
    if(!this.freezeWatchFlag && this.currentUser && this.currentUser.id) {
      this.preGetData();
    }
  }

  // Fetch and save filters to local storage
  async preGetData(filters:any = null) {
    await this.getLocalFilters(this, 'HH_accounts_transactions');

    if(filters) { this.filters = filters; }
    await this.getData();

    const params = {
      options: this.options,
      page: this.page,
      perPage: this.perPage,
      filters: { ...this.filters }
    }
    await this.saveLocalFilters(params, 'HH_accounts_transactions');
  }

  async getData() {
    this.tableItems = [];
    this.loading = true;

    const { sortBy, sortDesc } = this.options
    let orderBy = sortBy ? sortBy[0] : '';
    let orderDesc = sortDesc ? sortDesc[0] : false;
    
    if(orderBy !== '' && orderDesc) {
      orderBy = '-' + orderBy
    }

    const params = {
      offset: this.page === 0 ? 0 : (this.page - 1) * this.perPage,
      limit: this.perPage,
      ordering: orderBy,
      ...this.filters
    }
    
    const param = { type: this.urlType, id: this.currentUser?.id, params: params };
    await TransactionsStore.fetchAccounts(param)
    .then((response:any) => {
      const result = response;
      this.totalData = result.count;
      this.tableItems = result.count > 0 ? result.results : [];
    }).catch((error:any) => {
      this.$emit('error', { error: error, default: this.$t('errorFetchingData') })
    })
    this.loading = false;
  }

  getCreditValue(value:any) {
    value = Math.abs(value);
    if (typeof value === 'string') {
      value = parseFloat(value);
    }
    value = value || 0;

    if(value == Math.floor(value)) {
      return value.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
    } else {
      return value.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
    }
  }

  formatDateTime(data:any, type:any) {
    if(type == 'date') {
      return dateFormat(this, data);
    } else {
      return timeFormat(this, data);
    }
  }

  get freezeWatchFlag() {
    return LocalStorageFiltersStore.getFreeWatchFlag;
  }
}
