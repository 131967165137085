var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.editor
    ? _c(
        "div",
        { staticClass: "tipTapEditor" },
        [
          _c(
            "v-row",
            { staticClass: "pb-3", attrs: { "no-gutters": "" } },
            [
              _c(
                "v-col",
                { attrs: { cols: "4" } },
                [
                  _c(
                    "v-btn-toggle",
                    {
                      attrs: { mandatory: "" },
                      model: {
                        value: _vm.currentTextAlign,
                        callback: function ($$v) {
                          _vm.currentTextAlign = $$v
                        },
                        expression: "currentTextAlign",
                      },
                    },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            outlined: "",
                            title: "Justify left",
                            value: "left",
                            color: "primary",
                          },
                          on: {
                            click: function ($event) {
                              _vm.editor
                                .chain()
                                .focus()
                                .setTextAlign("left")
                                .run()
                            },
                          },
                        },
                        [_c("v-icon", [_vm._v("mdi-format-align-left")])],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            outlined: "",
                            title: "Justify center",
                            value: "centered",
                            color: "primary",
                          },
                          on: {
                            click: function ($event) {
                              _vm.editor
                                .chain()
                                .focus()
                                .setTextAlign("center")
                                .run()
                            },
                          },
                        },
                        [_c("v-icon", [_vm._v("mdi-format-align-center")])],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            outlined: "",
                            title: "Justify right",
                            value: "right",
                            color: "primary",
                          },
                          on: {
                            click: function ($event) {
                              _vm.editor
                                .chain()
                                .focus()
                                .setTextAlign("right")
                                .run()
                            },
                          },
                        },
                        [_c("v-icon", [_vm._v("mdi-format-align-right")])],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            outlined: "",
                            title: "Justify full",
                            value: "justified",
                            color: "primary",
                          },
                          on: {
                            click: function ($event) {
                              _vm.editor
                                .chain()
                                .focus()
                                .setTextAlign("justify")
                                .run()
                            },
                          },
                        },
                        [_c("v-icon", [_vm._v("mdi-format-align-justify")])],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-col",
                { attrs: { align: "right", cols: "8" } },
                [
                  _c(
                    "v-btn-toggle",
                    {
                      model: {
                        value: _vm.isBold,
                        callback: function ($$v) {
                          _vm.isBold = $$v
                        },
                        expression: "isBold",
                      },
                    },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            outlined: "",
                            title: "Bold",
                            value: "bold",
                            color: "primary",
                          },
                          on: {
                            click: function ($event) {
                              _vm.editor.chain().focus().toggleBold().run()
                            },
                          },
                        },
                        [_c("v-icon", [_vm._v("mdi-format-bold")])],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-btn-toggle",
                    {
                      staticClass: "ml-1",
                      model: {
                        value: _vm.isItalic,
                        callback: function ($$v) {
                          _vm.isItalic = $$v
                        },
                        expression: "isItalic",
                      },
                    },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            outlined: "",
                            title: "Italic",
                            value: "italic",
                            color: "primary",
                          },
                          on: {
                            click: function ($event) {
                              _vm.editor.chain().focus().toggleItalic().run()
                            },
                          },
                        },
                        [_c("v-icon", [_vm._v("mdi-format-italic")])],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-btn-toggle",
                    {
                      staticClass: "ml-1",
                      model: {
                        value: _vm.isUnderline,
                        callback: function ($$v) {
                          _vm.isUnderline = $$v
                        },
                        expression: "isUnderline",
                      },
                    },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            outlined: "",
                            title: "Underline",
                            value: "underline",
                            color: "primary",
                          },
                          on: {
                            click: function ($event) {
                              _vm.editor.chain().focus().toggleUnderline().run()
                            },
                          },
                        },
                        [_c("v-icon", [_vm._v("mdi-format-underline")])],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-btn-toggle",
                    {
                      staticClass: "ml-1",
                      model: {
                        value: _vm.isStrikeThrough,
                        callback: function ($$v) {
                          _vm.isStrikeThrough = $$v
                        },
                        expression: "isStrikeThrough",
                      },
                    },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            outlined: "",
                            title: "Strikethrough",
                            value: "strikethrough",
                            color: "primary",
                          },
                          on: {
                            click: function ($event) {
                              _vm.editor.chain().focus().toggleStrike().run()
                            },
                          },
                        },
                        [_c("v-icon", [_vm._v("mdi-format-strikethrough")])],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-btn-toggle",
                    { staticClass: "ml-1" },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: { outlined: "", title: "Text color" },
                          on: {
                            click: function ($event) {
                              return _vm.$refs.colorPicker.show(_vm.curColor)
                            },
                          },
                        },
                        [
                          _c("v-icon", { style: { color: _vm.curColor } }, [
                            _vm._v(" mdi-square "),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("color-picker-modal", {
                    ref: "colorPicker",
                    on: { colorPicked: _vm.textColorPicked },
                  }),
                  _vm._v(" "),
                  _c(
                    "v-btn-toggle",
                    {
                      staticClass: "ml-1",
                      model: {
                        value: _vm.isNumberedList,
                        callback: function ($$v) {
                          _vm.isNumberedList = $$v
                        },
                        expression: "isNumberedList",
                      },
                    },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            outlined: "",
                            title: "Numbered list",
                            value: "numberedlist",
                            color: "primary",
                          },
                          on: {
                            click: function ($event) {
                              _vm.editor
                                .chain()
                                .focus()
                                .toggleOrderedList()
                                .run()
                            },
                          },
                        },
                        [_c("v-icon", [_vm._v("mdi-format-list-numbered")])],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-btn-toggle",
                    {
                      staticClass: "ml-1",
                      model: {
                        value: _vm.isBulletedList,
                        callback: function ($$v) {
                          _vm.isBulletedList = $$v
                        },
                        expression: "isBulletedList",
                      },
                    },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            outlined: "",
                            title: "Bulleted list",
                            value: "bulletedlist",
                            color: "primary",
                          },
                          on: {
                            click: function ($event) {
                              _vm.editor
                                .chain()
                                .focus()
                                .toggleBulletList()
                                .run()
                            },
                          },
                        },
                        [_c("v-icon", [_vm._v("mdi-format-list-bulleted")])],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-btn-toggle",
                    {
                      staticClass: "ml-1",
                      model: {
                        value: _vm.isH1,
                        callback: function ($$v) {
                          _vm.isH1 = $$v
                        },
                        expression: "isH1",
                      },
                    },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            outlined: "",
                            title: "Heading 1",
                            value: "h1",
                            color: "primary",
                          },
                          on: {
                            click: function ($event) {
                              _vm.editor
                                .chain()
                                .focus()
                                .toggleHeading({ level: 1 })
                                .run()
                            },
                          },
                        },
                        [_c("v-icon", [_vm._v("mdi-format-header-1")])],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-btn-toggle",
                    {
                      staticClass: "ml-1",
                      model: {
                        value: _vm.isH2,
                        callback: function ($$v) {
                          _vm.isH2 = $$v
                        },
                        expression: "isH2",
                      },
                    },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            outlined: "",
                            title: "Heading 2",
                            value: "h2",
                            color: "primary",
                          },
                          on: {
                            click: function ($event) {
                              _vm.editor
                                .chain()
                                .focus()
                                .toggleHeading({ level: 2 })
                                .run()
                            },
                          },
                        },
                        [_c("v-icon", [_vm._v("mdi-format-header-2")])],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-btn-toggle",
                    {
                      staticClass: "ml-1",
                      model: {
                        value: _vm.isH3,
                        callback: function ($$v) {
                          _vm.isH3 = $$v
                        },
                        expression: "isH3",
                      },
                    },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            outlined: "",
                            title: "Heading 3",
                            value: "h3",
                            color: "primary",
                          },
                          on: {
                            click: function ($event) {
                              _vm.editor
                                .chain()
                                .focus()
                                .toggleHeading({ level: 3 })
                                .run()
                            },
                          },
                        },
                        [_c("v-icon", [_vm._v("mdi-format-header-3")])],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "v-btn-toggle v-item-group theme--light ml-1",
                    },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            outlined: "",
                            title: "Clear format",
                            color: "primary",
                          },
                          on: {
                            click: function ($event) {
                              _vm.editor
                                .chain()
                                .focus()
                                .clearNodes()
                                .unsetColor()
                                .run()
                            },
                          },
                        },
                        [_c("v-icon", [_vm._v("mdi-format-clear")])],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-row",
            { attrs: { "no-gutters": "" } },
            [
              _c(
                "v-col",
                [
                  _c(
                    "client-only",
                    [
                      _c("editor-content", {
                        ref: "editor",
                        attrs: { id: "editorContent", editor: _vm.editor },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }