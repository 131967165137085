
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'

@Component({})
export default class AdminSettingsFields extends Vue {
  @Prop({ default: () => {} }) readonly item!: any
  @Prop({ default: () => null }) readonly holder!: any
  @Prop({ default: () => false }) readonly saving!: any
  @Prop({ default: () => false }) readonly isEditing!: any
  @Prop({ default: () => 'insta' }) readonly type!: any

  editId: any = null;
  holderValue: any = null;

  @Watch('holder')
  checkHolderValue(val:any) {
    this.holderValue = val;
  }

  editItem(item:any) {
    this.editId = item.id;
    this.holderValue = item.value
    this.$emit('is-edit', true);
  }

  cancelEdit() {
    this.editId = null;
    this.$emit('is-edit', false);
  }
}
