var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-dialog",
    {
      attrs: { persistent: "", "max-width": "400" },
      model: {
        value: _vm.modal,
        callback: function ($$v) {
          _vm.modal = $$v
        },
        expression: "modal",
      },
    },
    [
      _c(
        "v-card",
        [
          _c("v-card-text", { staticClass: "pa-6" }, [
            _c(
              "h2",
              { staticClass: "h_title_2 font-weight-bold text-black mb-2" },
              [_vm._v(_vm._s(_vm.$t("restartSubscription")))]
            ),
            _vm._v(" "),
            _c("label", { staticClass: "text-16 text-dark-grey" }, [
              _vm._v(_vm._s(_vm.$t("creditsPerFollower"))),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "mt-8" },
              [
                _c(
                  "label",
                  { staticClass: "text-16 font-weight-medium text-black" },
                  [_vm._v(_vm._s(_vm.$t("numberOfFollowers")))]
                ),
                _vm._v(" "),
                _c("v-text-field", {
                  staticClass: "mt-2",
                  attrs: { outlined: "", type: "number", "hide-details": "" },
                  model: {
                    value: _vm.data.max_follower_count,
                    callback: function ($$v) {
                      _vm.$set(_vm.data, "max_follower_count", $$v)
                    },
                    expression: "data.max_follower_count",
                  },
                }),
                _vm._v(" "),
                _vm.data.max_follower_count < _vm.igSubMinFollowers
                  ? _c("p", { staticClass: "error--text mt-2" }, [
                      _vm._v(
                        _vm._s(
                          _vm.$t("minOrderAmount", {
                            min: _vm.igSubMinFollowers,
                          })
                        ) + "."
                      ),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.$auth && _vm.$auth.user && _vm.$auth.user.is_admin
                  ? _c(
                      "div",
                      { staticClass: "mt-5" },
                      [
                        _c("users-credits-dropdown", {
                          attrs: {
                            "user-filter": _vm.currentAccount,
                            "is-filtered": true,
                          },
                          on: {
                            selected: function ($event) {
                              _vm.data.user = $event
                            },
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.hasError
                  ? _c("alert-error", {
                      staticClass: "mt-5",
                      attrs: { "error-message": _vm.errorMessage },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.success
                  ? _c("alert-success", {
                      staticClass: "mt-5",
                      attrs: { "success-message": _vm.$t("successUpdate") },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "v-btn",
                  {
                    staticClass: "btn-brand mt-8",
                    attrs: {
                      depressed: "",
                      block: "",
                      large: "",
                      disabled:
                        !_vm.data.max_follower_count ||
                        !_vm.accountId ||
                        _vm.data.max_follower_count < _vm.igSubMinFollowers,
                      loading: _vm.loading,
                    },
                    on: { click: _vm.renew },
                  },
                  [
                    _vm._v(
                      "\n          " + _vm._s(_vm.$t("restartSubscription"))
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "v-btn",
                  {
                    staticClass: "text-brand mt-3",
                    attrs: {
                      outlined: "",
                      depressed: "",
                      block: "",
                      large: "",
                    },
                    on: {
                      click: function ($event) {
                        _vm.modal = false
                      },
                    },
                  },
                  [_vm._v("\n          " + _vm._s(_vm.$t("cancel")))]
                ),
              ],
              1
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }