var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-card",
    { staticClass: "custom-cards" },
    [
      _c(
        "v-card-text",
        { staticClass: "pt-1" },
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { col: "12" } },
                [
                  _c("v-data-table", {
                    staticClass: "elevation-0 accounts-table",
                    attrs: {
                      headers: _vm.headers,
                      items: _vm.tableItems,
                      "items-per-page": _vm.perPage,
                      "server-items-length": _vm.totalData,
                      loading: _vm.loading,
                      options: _vm.options,
                      "sort-desc": true,
                      "sort-by": "created",
                      "hide-default-footer": "",
                    },
                    on: {
                      "update:options": function ($event) {
                        _vm.options = $event
                      },
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "item.id",
                        fn: function ({ item }) {
                          return [
                            _c("div", { staticClass: "pr-3" }, [
                              _c("span", [
                                _vm._v(_vm._s(_vm.formatId(item.id))),
                              ]),
                            ]),
                          ]
                        },
                      },
                      {
                        key: "item.created",
                        fn: function ({ item }) {
                          return [
                            _c("div", { staticClass: "cw-40" }, [
                              _c("span", [
                                _vm._v(
                                  _vm._s(_vm.formatDateTime(item.created))
                                ),
                              ]),
                            ]),
                          ]
                        },
                      },
                      {
                        key: "item.user",
                        fn: function ({ item }) {
                          return [
                            _c(
                              "div",
                              { staticClass: "line-height-normal" },
                              [
                                _c(
                                  "nuxt-link",
                                  {
                                    attrs: {
                                      to: `/dashboard/admin/users/${item.user.id}/view`,
                                      target: "_blank",
                                    },
                                  },
                                  [
                                    item.user.first_name
                                      ? _c(
                                          "span",
                                          { staticClass: "text-black" },
                                          [_vm._v(_vm._s(item.user.first_name))]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    item.user.first_name && item.user.last_name
                                      ? _c("span", {
                                          staticClass: "text-black",
                                        })
                                      : _vm._e(),
                                    _vm._v(" "),
                                    item.user.last_name
                                      ? _c(
                                          "span",
                                          { staticClass: "text-black" },
                                          [_vm._v(_vm._s(item.user.last_name))]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _c("div", [
                                      _c(
                                        "span",
                                        { staticClass: "text-brand" },
                                        [_vm._v(_vm._s(item.user.email))]
                                      ),
                                    ]),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                      {
                        key: "item.order_type",
                        fn: function ({ item }) {
                          return [
                            _c("div", { staticClass: "cw-25" }, [
                              _c("span", { staticClass: "text-capitalize" }, [
                                _vm._v(
                                  _vm._s(item.order_type.replace(/_/g, " "))
                                ),
                              ]),
                            ]),
                          ]
                        },
                      },
                      {
                        key: "item.quantity",
                        fn: function ({ item }) {
                          return [
                            _c(
                              "div",
                              { staticClass: "cw-15 d-flex align-center" },
                              [
                                _c("instagram-orders-order-types-selection", {
                                  attrs: {
                                    item: {
                                      value: item.order_type,
                                      count: item.quantity,
                                    },
                                    size: 16,
                                    "custom-class": "mr-1",
                                  },
                                }),
                                _vm._v(" "),
                                _vm.checkIfHasDownloadBtn(item.comments_text)
                                  ? [
                                      _c(
                                        "v-icon",
                                        {
                                          staticClass: "ml-auto",
                                          staticStyle: { "margin-top": "2px" },
                                          attrs: { small: "" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.writeToFile(item)
                                            },
                                          },
                                        },
                                        [_vm._v("mdi-download")]
                                      ),
                                    ]
                                  : _vm._e(),
                              ],
                              2
                            ),
                          ]
                        },
                      },
                      {
                        key: "item.price",
                        fn: function ({ item }) {
                          return [
                            item.price
                              ? _c(
                                  "div",
                                  { staticClass: "cw-15 d-flex" },
                                  [
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "font-weight-medium text-black",
                                      },
                                      [_vm._v(_vm._s(item.price))]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "v-icon",
                                      {
                                        staticClass: "text-brand ml-1",
                                        attrs: { size: "12" },
                                      },
                                      [_vm._v("mdi-tag-outline")]
                                    ),
                                  ],
                                  1
                                )
                              : _c("div", { staticClass: "ml-2" }, [
                                  _c("span", [_vm._v("---")]),
                                ]),
                          ]
                        },
                      },
                      {
                        key: "item.drip_feed",
                        fn: function ({ item }) {
                          return [
                            _c(
                              "div",
                              { staticClass: "cw-25" },
                              [
                                item.drip_feed
                                  ? _c(
                                      "v-tooltip",
                                      {
                                        attrs: {
                                          bottom: "",
                                          "content-class": "custom-tooltip",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "activator",
                                              fn: function ({ on, attrs }) {
                                                return [
                                                  item.drip_feed
                                                    ? _c(
                                                        "div",
                                                        _vm._g(
                                                          _vm._b(
                                                            {
                                                              staticClass:
                                                                "cw-25 d-flex",
                                                            },
                                                            "div",
                                                            attrs,
                                                            false
                                                          ),
                                                          on
                                                        ),
                                                        [
                                                          _c(
                                                            "v-icon",
                                                            {
                                                              staticClass:
                                                                "text-brand",
                                                              attrs: {
                                                                small: "",
                                                                color:
                                                                  "blue lighten-2",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                "mdi-water"
                                                              ),
                                                            ]
                                                          ),
                                                          _vm._v(" "),
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "font-weight-medium text-black",
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  item.drip_feed +
                                                                    " " +
                                                                    _vm.$t(
                                                                      "secs"
                                                                    )
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                      },
                                      [
                                        _vm._v(" "),
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.convertSecondsToDHMS(
                                                item.drip_feed
                                              )
                                            )
                                          ),
                                        ]),
                                      ]
                                    )
                                  : _c("div", { staticClass: "ml-5" }, [
                                      _c("span", [_vm._v("---")]),
                                    ]),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                      {
                        key: "item.service_details",
                        fn: function ({ item }) {
                          return [
                            item.order_type == "followers"
                              ? _c(
                                  "span",
                                  { staticClass: "font-weight-medium" },
                                  [_vm._v("@" + _vm._s(item.ig_username))]
                                )
                              : _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      width: "300px",
                                      "overflow-wrap": "break-word",
                                    },
                                  },
                                  [
                                    _c(
                                      "span",
                                      { staticClass: "font-weight-medium" },
                                      [_vm._v(_vm._s(item.target_url))]
                                    ),
                                  ]
                                ),
                          ]
                        },
                      },
                      {
                        key: "item.status",
                        fn: function ({ item }) {
                          return [
                            _c(
                              "div",
                              { staticClass: "cw-60" },
                              [
                                item.status == "processing" ||
                                item.status == "cancelled" ||
                                item.status == "refunded"
                                  ? [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "custom-status mx-auto",
                                          class: item.status,
                                        },
                                        [
                                          _c(
                                            "span",
                                            { staticClass: "text-capitalize" },
                                            [
                                              _vm._v(
                                                _vm._s(item.status) + " - "
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                item.task_execution_count +
                                                  "/" +
                                                  item.quantity
                                              )
                                            ),
                                          ]),
                                        ]
                                      ),
                                    ]
                                  : [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "custom-status mx-auto",
                                          class: item.status
                                            ? item.status
                                            : "created",
                                        },
                                        [
                                          _c(
                                            "span",
                                            { staticClass: "text-capitalize" },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  item.status
                                                    ? item.status
                                                    : _vm.$t("created")
                                                )
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ],
                              ],
                              2
                            ),
                          ]
                        },
                      },
                      {
                        key: "item.actions",
                        fn: function ({ item }) {
                          return [
                            _c(
                              "div",
                              { staticClass: "d-flex align-center" },
                              [
                                _c("ActionButton", {
                                  attrs: {
                                    disabled:
                                      item.status == "refunded" ||
                                      item.status == "fulfilled",
                                    data: {
                                      icon:
                                        item.status == "cancelled"
                                          ? "mdi-cash-refund"
                                          : "mdi-cancel",
                                      icon_color: "text-brand-error",
                                      label:
                                        item.status == "cancelled"
                                          ? _vm.$t("refund")
                                          : _vm.$t("cancel"),
                                    },
                                  },
                                  on: {
                                    clicked: function ($event) {
                                      return _vm.$emit("cancel-order", item)
                                    },
                                  },
                                }),
                                _vm._v(" "),
                                _c("ActionButton", {
                                  attrs: {
                                    disabled:
                                      item.status != "created" &&
                                      item.status != "processing",
                                    data: {
                                      icon: "mdi-pencil-outline",
                                      icon_color: "warning--text",
                                      label: "Edit",
                                    },
                                  },
                                  on: {
                                    clicked: function ($event) {
                                      return _vm.$emit("edit", {
                                        id: item.id,
                                        target_url: item.target_url,
                                        drip_feed: item.drip_feed,
                                        order_type: item.order_type,
                                      })
                                    },
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("pagination", {
                    attrs: {
                      "total-items": _vm.totalData,
                      page: _vm.page,
                      "per-page": _vm.perPage,
                      "per-page-options": _vm.perPageOptions,
                    },
                    on: {
                      "page-change": _vm.handlePageChange,
                      "per-page-change": _vm.handlePerPageChange,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }