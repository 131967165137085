var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { attrs: { id: "process-steps-section" } }, [
    _c("div", { staticClass: "custom-content" }, [
      _c("label", { staticClass: "text-16 text-brand ff-bold" }, [
        _vm._v(_vm._s(_vm.$t("processTop"))),
      ]),
      _vm._v(" "),
      _c("h2", {
        staticClass: "h_title_2 mb-10",
        domProps: { innerHTML: _vm._s(_vm.$t("processTitle")) },
      }),
      _vm._v(" "),
      _c(
        "div",
        {
          class: {
            "d-flex":
              !_vm.$vuetify.breakpoint.xs && !_vm.$vuetify.breakpoint.sm,
          },
        },
        [
          _c(
            "div",
            { staticClass: "d-flex inner" },
            [
              _vm._l(_vm.processList, function (item, index) {
                return [
                  _c(
                    "v-card",
                    {
                      staticClass: "step-card",
                      attrs: { elevation: "0", height: "100%" },
                    },
                    [
                      _c("div", { staticClass: "step-header step-1" }, [
                        _c("div", { staticClass: "step-number" }, [
                          _vm._v(_vm._s(item.num)),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "step-title" }, [
                          _vm._v(_vm._s(item.title)),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("v-card-text", { staticClass: "px-6" }, [
                        _c(
                          "p",
                          {
                            staticClass:
                              "text-deep-purple text-16 line-height-p mt-3",
                          },
                          [_vm._v(_vm._s(item.content))]
                        ),
                      ]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  index < _vm.processList.length - 1
                    ? _c("div", { staticClass: "arrow-container" }, [
                        _c("img", {
                          staticClass: "mx-5",
                          attrs: {
                            src: _vm.getImage("arrow.webp", "arrow.svg"),
                            alt: "Arrow Icon",
                            width: "40",
                            height: "10",
                            loading: "lazy",
                          },
                        }),
                      ])
                    : _vm._e(),
                ]
              }),
            ],
            2
          ),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }