var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "v-app-bar",
        { staticClass: "nav-top-bar", attrs: { flat: "" } },
        [
          _vm._t("topBarLeftSection", function () {
            return [
              _c(
                "div",
                { staticClass: "d-flex align-center ml-10 ml-md-0" },
                [
                  _c(
                    "v-avatar",
                    { attrs: { size: "35", color: "btn-brand" } },
                    [
                      _vm.profilePic
                        ? _c("img", { attrs: { src: _vm.profilePic } })
                        : _c("v-icon", { staticClass: "white--text" }, [
                            _vm._v("mdi-account"),
                          ]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "label",
                    {
                      staticClass:
                        "font-weight-medium text-black ml-3 default-view",
                    },
                    [_vm._v(_vm._s(_vm.name))]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.$vuetify.breakpoint.xs,
                          expression: "$vuetify.breakpoint.xs",
                        },
                      ],
                    },
                    [
                      !_vm.showName
                        ? _c(
                            "v-icon",
                            {
                              staticClass: "ml-1",
                              attrs: { size: "30" },
                              on: {
                                click: function ($event) {
                                  _vm.showName = true
                                },
                              },
                            },
                            [_vm._v("mdi-menu-right")]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.showName
                        ? _c(
                            "label",
                            {
                              staticClass:
                                "font-weight-medium text-black ml-3 mr-5",
                            },
                            [_vm._v(_vm._s(_vm.name))]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("v-divider", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: !_vm.$vuetify.breakpoint.xs,
                        expression: "!$vuetify.breakpoint.xs",
                      },
                    ],
                    staticClass: "ml-5 my-1",
                    attrs: { vertical: "" },
                  }),
                  _vm._v(" "),
                  _c("language-selection", {
                    attrs: {
                      "is-icon-only": _vm.$vuetify.breakpoint.xs ? true : false,
                    },
                    on: {
                      overlay: function ($event) {
                        _vm.overlay = $event
                      },
                    },
                  }),
                ],
                1
              ),
            ]
          }),
          _vm._v(" "),
          _c("v-spacer"),
          _vm._v(" "),
          _vm._t("topBarRightSection", function () {
            return [
              !_vm.showName
                ? _c(
                    "div",
                    { staticClass: "coins-container d-flex align-center" },
                    [
                      _c(
                        "v-icon",
                        {
                          staticClass: "mr-2",
                          on: {
                            click: function ($event) {
                              return _vm.showModal("addCredits")
                            },
                          },
                        },
                        [_vm._v("mdi-plus-circle-outline")]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "d-flex align-center ml-auto" },
                        [
                          _c("h3", { staticClass: "mr-2 text-truncate" }, [
                            _vm._v(_vm._s(_vm.credits)),
                          ]),
                          _vm._v(" "),
                          _vm.hasCreditsNotif
                            ? _c(
                                "v-menu",
                                {
                                  attrs: {
                                    "offset-y": "",
                                    left: "",
                                    "max-width": "450",
                                    "content-class": "mt-2 credit-notification",
                                  },
                                  on: { input: _vm.readNotification },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function ({ on, attrs }) {
                                          return [
                                            _c(
                                              "div",
                                              _vm._g(
                                                _vm._b(
                                                  { staticClass: "mt-1" },
                                                  "div",
                                                  attrs,
                                                  false
                                                ),
                                                on
                                              ),
                                              [
                                                _c("img", {
                                                  staticStyle: {
                                                    "margin-top": "2px",
                                                  },
                                                  attrs: {
                                                    src: require("assets/images/icons/twemoji_coin.svg"),
                                                    width: "20",
                                                  },
                                                }),
                                                _vm._v(" "),
                                                _vm.hasCreditsNotifBadge
                                                  ? _c("div", {
                                                      staticClass:
                                                        "credit-badge",
                                                    })
                                                  : _vm._e(),
                                              ]
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    1865886049
                                  ),
                                },
                                [
                                  _vm._v(" "),
                                  _c(
                                    "v-list",
                                    { attrs: { dense: "" } },
                                    [
                                      _vm._l(
                                        _vm.newCredits,
                                        function (item, index) {
                                          return [
                                            _c(
                                              "v-list-item",
                                              [
                                                _c(
                                                  "v-list-item-icon",
                                                  { staticClass: "ma-auto" },
                                                  [
                                                    _c(
                                                      "v-avatar",
                                                      {
                                                        attrs: {
                                                          size: "27",
                                                          color: "btn-brand",
                                                        },
                                                      },
                                                      [
                                                        item.payment_method ==
                                                        "stripe_checkout"
                                                          ? _c(
                                                              "v-icon",
                                                              {
                                                                staticClass:
                                                                  "white--text",
                                                                attrs: {
                                                                  size: "16",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "mdi-credit-card-outline"
                                                                ),
                                                              ]
                                                            )
                                                          : _c(
                                                              "v-icon",
                                                              {
                                                                staticClass:
                                                                  "white--text",
                                                                attrs: {
                                                                  size: "16",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "mdi-bitcoin"
                                                                ),
                                                              ]
                                                            ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "v-list-item-content",
                                                  { staticClass: "ml-5 mt-1" },
                                                  [
                                                    _c("v-list-item-title", [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "text-14 font-weight-medium",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "youReceivedCredits",
                                                                {
                                                                  credits:
                                                                    item.credit_qty /
                                                                    100,
                                                                }
                                                              )
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "float-right",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.formatDateTime(
                                                                item.payment_date
                                                              )
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                    ]),
                                                    _vm._v(" "),
                                                    _c("v-list-item-subtitle", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t(
                                                            "modeOfPayment"
                                                          )
                                                        ) + ": "
                                                      ),
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "text-capitalize",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              item.payment_method.replace(
                                                                /_/g,
                                                                " "
                                                              )
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                    ]),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            index < _vm.newCredits.length - 1
                                              ? _c("v-divider", {
                                                  staticClass: "mx-5 my-1",
                                                })
                                              : _vm._e(),
                                          ]
                                        }
                                      ),
                                    ],
                                    2
                                  ),
                                ],
                                1
                              )
                            : _c("img", {
                                attrs: {
                                  src: require("assets/images/icons/twemoji_coin.svg"),
                                  width: "20",
                                },
                              }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _c(
                    "div",
                    { staticClass: "d-flex align-center mobile-view" },
                    [
                      _vm.showName
                        ? _c(
                            "v-icon",
                            {
                              staticClass: "ml-1",
                              attrs: { size: "30" },
                              on: {
                                click: function ($event) {
                                  _vm.showName = false
                                },
                              },
                            },
                            [_vm._v("mdi-menu-left")]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c("img", {
                        attrs: {
                          src: require("assets/images/icons/twemoji_coin.svg"),
                          width: "20",
                        },
                      }),
                    ],
                    1
                  ),
            ]
          }),
        ],
        2
      ),
      _vm._v(" "),
      _c("settings-modal-add-credits", { ref: "addCredits" }),
      _vm._v(" "),
      _c("snackbar-error", { ref: "error" }),
      _vm._v(" "),
      _c(
        "v-overlay",
        { attrs: { value: _vm.overlay, opacity: "0.2", "z-index": "6" } },
        [
          _c("v-progress-circular", {
            attrs: { indeterminate: "", size: "64" },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }