var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "3" } },
            [
              _c(
                "v-card",
                { staticClass: "custom-cards" },
                [
                  _c("v-card-text", { staticClass: "pa-7" }, [
                    _c("h3", { staticClass: "h_title_3 text-dark-grey" }, [
                      _vm._v(_vm._s(_vm.$t("status"))),
                    ]),
                    _vm._v(" "),
                    _c(
                      "h2",
                      { staticClass: "h_title_1 text-black mt-5" },
                      [
                        _c("v-icon", { staticClass: "text-brand-error" }, [
                          _vm._v("mdi-circle-medium"),
                        ]),
                        _vm._v(_vm._s(_vm.$t("inactive"))),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-col",
            { attrs: { cols: "5" } },
            [
              _c(
                "v-card",
                { staticClass: "custom-cards" },
                [
                  _c(
                    "v-card-text",
                    { staticClass: "pa-7" },
                    [
                      _c("h3", { staticClass: "h_title_3 text-dark-grey" }, [
                        _vm._v(_vm._s(_vm.$t("pricing"))),
                      ]),
                      _vm._v(" "),
                      _c(
                        "nuxt-link",
                        {
                          staticClass: "d-flex align-center mt-5",
                          attrs: {
                            to: _vm.localePath(
                              "/dashboard/mobile-proxy-api/subscription/new/pricing"
                            ),
                          },
                        },
                        [
                          _c(
                            "label",
                            {
                              staticClass:
                                "text-purple-2 h_title_3 font-weight-medium cursor-pointer",
                            },
                            [_vm._v(_vm._s(_vm.$t("buyMobileProxy")))]
                          ),
                          _vm._v(" "),
                          _c(
                            "v-icon",
                            {
                              staticClass: "ml-2 back-link cursor-pointer",
                              attrs: { small: "" },
                            },
                            [_vm._v("mdi-arrow-right")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-col",
            { attrs: { cols: "3" } },
            [
              _c(
                "v-card",
                { staticClass: "custom-cards", attrs: { disabled: "" } },
                [
                  _c("v-card-text", { staticClass: "pa-7" }, [
                    _c("h3", { staticClass: "h_title_3 text-dark-grey" }, [
                      _vm._v(_vm._s(_vm.$t("numOfPorts"))),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "d-flex align-center mt-5" }, [
                      _c("h2", { staticClass: "h_title_1 text-black" }, [
                        _vm._v("0"),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "ml-auto" },
                        [
                          _c("v-icon", { staticClass: "text-brand" }, [
                            _vm._v("mdi-plus-circle-outline"),
                          ]),
                        ],
                        1
                      ),
                    ]),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-col",
            { attrs: { cols: "4" } },
            [
              _c(
                "v-card",
                { staticClass: "custom-cards" },
                [
                  _c("v-card-text", { staticClass: "pa-7" }, [
                    _c("h3", { staticClass: "h_title_3 text-dark-grey" }, [
                      _vm._v(_vm._s(_vm.$t("apiDocumentation"))),
                    ]),
                    _vm._v(" "),
                    _c(
                      "h3",
                      {
                        staticClass:
                          "h_title_3 font-weight-bold text-purple-2 mt-5",
                      },
                      [
                        _c(
                          "nuxt-link",
                          {
                            attrs: {
                              target: "_blank",
                              to: "//" + _vm.docuLink,
                            },
                          },
                          [
                            _c(
                              "span",
                              { staticClass: "text-purple-2 text-16" },
                              [_vm._v(_vm._s(_vm.$t("proxyAPIDocu")))]
                            ),
                            _vm._v(" "),
                            _c(
                              "v-icon",
                              {
                                staticClass: "ml-2 back-link",
                                attrs: { small: "" },
                              },
                              [_vm._v("mdi-arrow-right")]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }