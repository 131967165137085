import { Store } from 'vuex'
import { getModule } from 'vuex-module-decorators'
import Accounts from '~/store/accounts'
import Blogs from '~/store/blogs'
import ProxyAPI from '~/store/proxy-api'
import Admin from '~/store/admin'
import Orders from '~/store/orders'
import Transactions from '~/store/transactions'
import LocalStorageFilters from '~/store/local-filters'

let AccountsStore: Accounts
let BlogsStore: Blogs
let ProxyAPIStore: ProxyAPI
let AdminStore: Admin
let OrdersStore: Orders
let TransactionsStore: Transactions
let LocalStorageFiltersStore: LocalStorageFilters

function initializeStores(store: Store<any>): void {
  AccountsStore = getModule(Accounts, store)
  BlogsStore = getModule(Blogs, store)
  ProxyAPIStore = getModule(ProxyAPI, store)
  AdminStore = getModule(Admin, store)
  OrdersStore = getModule(Orders, store)
  TransactionsStore = getModule(Transactions, store)
  LocalStorageFiltersStore = getModule(LocalStorageFilters, store)
}

export { 
  AccountsStore,
  BlogsStore,
  ProxyAPIStore,
  AdminStore,
  OrdersStore,
  TransactionsStore,
  LocalStorageFiltersStore,
  initializeStores, 
}
