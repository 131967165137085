var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "v-chip-group",
        {
          staticClass: "emojis",
          attrs: {
            column: "",
            multiple: "",
            mandatory: "",
            "active-class": "text-brand",
          },
          model: {
            value: _vm.selected,
            callback: function ($$v) {
              _vm.selected = $$v
            },
            expression: "selected",
          },
        },
        [
          _vm._l(_vm.emojis, function (item) {
            return [
              _c(
                "v-chip",
                {
                  staticClass: "pa-1 py-4",
                  attrs: { small: "", outlined: "", ripple: false },
                },
                [_vm._v(_vm._s(item))]
              ),
            ]
          }),
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "v-row",
        {
          class: { "flex-column-reverse": _vm.$vuetify.breakpoint.xs },
          attrs: { "no-gutters": "" },
        },
        [
          _c(
            "v-col",
            {
              class: _vm.$vuetify.breakpoint.xs ? "pr-0" : "pr-2",
              attrs: { cols: "12", md: "7", sm: "6" },
            },
            [
              _c(
                "div",
                { staticClass: "d-flex align-center mt-2" },
                [
                  _c("v-text-field", {
                    staticClass: "mr-2 mb-2",
                    attrs: {
                      dense: "",
                      outlined: "",
                      label: _vm.$t("commentLength"),
                      type: "number",
                      "background-color": "white",
                      "hide-details": "auto",
                      rules: _vm.requiredRule,
                    },
                    model: {
                      value: _vm.commentLength,
                      callback: function ($$v) {
                        _vm.commentLength = $$v
                      },
                      expression: "commentLength",
                    },
                  }),
                ],
                1
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "v-col",
            {
              class: _vm.$vuetify.breakpoint.xs ? "pl-0 mb-2" : "px-2",
              attrs: { cols: "12", md: "5", sm: "6" },
            },
            [
              _c(
                "div",
                {
                  staticClass: "mt-2",
                  class: _vm.$vuetify.breakpoint.xs
                    ? "text-left"
                    : "text-right",
                },
                [
                  _c(
                    "v-chip",
                    {
                      staticClass: "px-2 font-weight-medium",
                      class: _vm.isSelectedAll
                        ? "text-brand"
                        : "grey--text text--darken-1",
                      attrs: { small: "", outlined: "", ripple: false },
                      on: { click: _vm.selectAll },
                    },
                    [
                      _c(
                        "v-icon",
                        { staticClass: "mr-1", attrs: { size: "14" } },
                        [_vm._v("mdi-check")]
                      ),
                      _vm._v(_vm._s(_vm.$t("selectAll")) + "\n        "),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-chip",
                    {
                      staticClass:
                        "px-2 font-weight-medium grey--text text--darken-1",
                      attrs: { small: "", outlined: "", ripple: false },
                      on: { click: _vm.clearAll },
                    },
                    [
                      _c(
                        "v-icon",
                        { staticClass: "mr-1", attrs: { size: "14" } },
                        [_vm._v("mdi-close")]
                      ),
                      _vm._v(_vm._s(_vm.$t("clear")) + "\n        "),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }