var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-dialog",
    {
      attrs: { persistent: "", "max-width": "500" },
      model: {
        value: _vm.modal,
        callback: function ($$v) {
          _vm.modal = $$v
        },
        expression: "modal",
      },
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-card-title",
            [
              _c(
                "span",
                { staticClass: "h_title_3 font-weight-medium text-black" },
                [_vm._v(_vm._s(_vm.$t("cancelSubscription")))]
              ),
              _vm._v(" "),
              _c(
                "v-icon",
                {
                  staticClass: "ml-auto",
                  on: {
                    click: function ($event) {
                      _vm.modal = false
                    },
                  },
                },
                [_vm._v("mdi-close")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-card-text",
            [
              _c("v-divider", { staticClass: "mb-3" }),
              _vm._v(" "),
              _c("alert-error", {
                attrs: { "error-message": [_vm.$t("areUSureCancelSub")] },
              }),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "text-center mt-7" },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "btn-brand-error mb-2",
                      attrs: {
                        depressed: "",
                        disabled: _vm.disable1,
                        loading: _vm.loading1,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.cancel("immediate")
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n          " + _vm._s(_vm.$t("cancelImmediately"))
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _vm.status == "active"
                    ? _c(
                        "v-btn",
                        {
                          staticClass: "btn-brand-error mb-2",
                          attrs: {
                            depressed: "",
                            disabled: _vm.disable2,
                            loading: _vm.loading2,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.cancel("period")
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n          " + _vm._s(_vm.$t("cancelOnPeriodEnd"))
                          ),
                        ]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }