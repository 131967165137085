
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { AccountsStore, AdminStore } from '~/store'
import { getErrorMessage } from '~/utils/ErrorMessageUtils'
import { requiredRule } from '~/utils/FormValidation'

@Component({})
export default class UsersCreditsDropdown extends Vue {
  @Prop({ default: () => {} }) readonly userFilter!: any
  @Prop({ default: () => false }) readonly isFiltered!: boolean
  @Prop({ default: () => {} }) readonly fieldError!: any

  creditsLoading: boolean = false;
  remainingCedits: number = 0;
  user: any = {};
  selectedUser: any = null;
  userList: any = [];
  requiredRule: any = [ requiredRule() ];
  fieldErrorMessage: any = {};
  hasError: boolean = false;
  errorMessage: any = null;
  isSelectedAdmin: boolean = false;
  isOnlyAdmin: boolean = false;
  allList: any = [];

  mounted() {
    this.user = this.$auth?.user || {};
    if(this.user?.is_admin) {
      if(this.isFiltered) {
        this.getFilteredList();
        this.getRemainingCredits(this.userFilter?.user);
      } else {
        this.isSelectedAdmin = true;
        this.getList();
        this.getRemainingCredits(this.user);
      }

      if(this.userFilter?.user?.id == this.user?.id) {
        this.isOnlyAdmin = true;
      }
    }
  }

  @Watch('fieldError')
  checkFieldError(val:any) {
    this.fieldErrorMessage = val;
  }

  async getList() {
    await AdminStore.fetchENUM()
    .then((response:any) => {
      response?.users.forEach((item:any) => {
        const arr = {
          id: item.id,
          name: (!item.first_name && !item.last_name) ? '' : (item?.first_name || "") + " " + (item?.last_name || ""),
          email: item.email
        }
        this.userList.push(arr);
      });
      this.allList = this.userList;
    })
  }

  getFilteredList() {
    this.userList.push({
      id: this.user.id,
      name: (!this.user.first_name && !this.user.last_name) ? '' : (this.user?.first_name || "") + " " + (this.user?.last_name || ""),
      email: this.user.email
    });

    const user = this.userFilter?.user || null;
    if(user && (this.user?.id != user.id)) {
      const userItem = {
        id: user.id,
        name: (!user.first_name && !user.last_name) ? '' : (user?.first_name || "") + " " + (user?.last_name || ""),
        email: user.email
      };
      this.userList.push(userItem);
    }
  }

  async getRemainingCredits(user:any) {
    this.isSelectedAdmin = this.user?.id == user?.id ? true : false;
    this.hasError = false;

    if(user && user?.id) {
      this.creditsLoading = true;
      await AccountsStore.fetchUserByAdmin(user?.id)
      .then((response:any) => {
        this.remainingCedits = response?.remaining_credits || 0;
      }).catch((error:any) => {
        this.errorMessage = getErrorMessage(error, this.$t('errorFetchCredits'))
        this.fieldErrorMessage = this.errorMessage.message;
        this.hasError = this.errorMessage.show;
      })
      this.creditsLoading = false;
      this.selectedUser = user;
      this.$emit('selected', this.selectedUser?.id);
    } else {
      this.remainingCedits = 0;
      this.$emit('selected', null);
    }
  }

  formatAmount(num:any) {
    return parseFloat(num);
  }

  filterItems($event:any) {
    const searchTerm = $event.target.value;
    const filtered = this.allList.filter((item:any) => 
      item.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item.email.toLowerCase().includes(searchTerm.toLowerCase())
    );
    this.userList = filtered;
  }
}
