var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return !_vm.isOnlyAdmin
    ? _c(
        "div",
        [
          _c("div", { staticClass: "d-flex align-center" }, [
            _c(
              "label",
              { staticClass: "text-16 font-weight-medium text-black" },
              [_vm._v(_vm._s(_vm.$t("user")))]
            ),
            _vm._v(" "),
            _vm.isSelectedAdmin
              ? _c("div", { staticClass: "text-16 custom-alert" }, [
                  _c("span", [_vm._v(": ")]),
                  _vm._v(" "),
                  _c("label", { staticClass: "text-brand text-14" }, [
                    _vm._v(_vm._s(_vm.$t("admin"))),
                  ]),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.user && _vm.user.is_admin
              ? _c(
                  "div",
                  { staticClass: "d-flex align-center ml-auto" },
                  [
                    _c("label", { staticClass: "mr-2 text-14" }, [
                      _vm._v(_vm._s(_vm.$t("available")) + ":"),
                    ]),
                    _vm._v(" "),
                    _c("img", {
                      attrs: {
                        src: require("assets/images/icons/twemoji_coin.svg"),
                        width: "13",
                      },
                    }),
                    _vm._v(" "),
                    _vm.creditsLoading
                      ? _c("v-progress-circular", {
                          staticClass: "ml-1",
                          attrs: {
                            size: 16,
                            width: 2,
                            indeterminate: _vm.creditsLoading,
                            color: "btn-brand",
                          },
                        })
                      : _c("label", { staticClass: "ml-1 text-14" }, [
                          _vm._v(_vm._s(_vm.formatAmount(_vm.remainingCedits))),
                        ]),
                  ],
                  1
                )
              : _vm._e(),
          ]),
          _vm._v(" "),
          _c("v-autocomplete", {
            ref: "autocompleteRef",
            staticClass: "mt-2",
            attrs: {
              "background-color": "white",
              placeholder: _vm.$t("selectUser"),
              items: _vm.userList,
              "item-text": "name",
              "item-value": "id",
              outlined: "",
              "hide-details": "auto",
              clearable: "",
              "no-filter": "",
              "return-object": "",
              rules: _vm.requiredRule,
              "error-messages": _vm.fieldErrorMessage.user,
            },
            on: { keyup: _vm.filterItems, change: _vm.getRemainingCredits },
            scopedSlots: _vm._u(
              [
                {
                  key: "item",
                  fn: function ({ item, on, attrs }) {
                    return [
                      _c(
                        "v-list-item",
                        _vm._g(_vm._b({}, "v-list-item", attrs, false), on),
                        [
                          _c(
                            "v-list-item-content",
                            { staticClass: "py-2" },
                            [
                              _c("v-list-item-title", [
                                _c("span", [_vm._v(_vm._s(item.name))]),
                                _vm._v(" "),
                                _c("div", [
                                  _c("span", { staticClass: "text-brand" }, [
                                    _vm._v(_vm._s(item.email)),
                                  ]),
                                ]),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  },
                },
                {
                  key: "selection",
                  fn: function ({ item }) {
                    return [
                      item.name
                        ? _c("span", [_vm._v(_vm._s(item.name))])
                        : _c("span", [_vm._v(_vm._s(item.email))]),
                    ]
                  },
                },
              ],
              null,
              false,
              3963854289
            ),
            model: {
              value: _vm.selectedUser,
              callback: function ($$v) {
                _vm.selectedUser = $$v
              },
              expression: "selectedUser",
            },
          }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }