var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-row",
    [
      _vm._l(_vm.data, function (item) {
        return [
          _c(
            "v-col",
            { staticClass: "pb-0", attrs: { cols: "12", md: "3", sm: "6" } },
            [
              _c(
                "v-card",
                {
                  staticClass: "custom-cards bordered",
                  attrs: { height: "100%" },
                },
                [
                  _c(
                    "v-card-text",
                    { staticClass: "px-4 pt-3 pb-2" },
                    [
                      _c("div", { staticClass: "d-flex align-center" }, [
                        _c(
                          "h1",
                          { staticClass: "text-14 username text-capitalize" },
                          [_vm._v(_vm._s(_vm.$t("sessionNum") + item.id))]
                        ),
                        _vm._v(" "),
                        _c(
                          "label",
                          { staticClass: "ml-auto mr-1 font-weight-medium" },
                          [_vm._v(_vm._s(_vm.convertBytes(item.traffic_data)))]
                        ),
                      ]),
                      _vm._v(" "),
                      _c("v-divider", { staticClass: "mt-2" }),
                      _vm._v(" "),
                      _c("div", { staticClass: "mt-2 d-flex" }, [
                        _c(
                          "label",
                          { staticClass: "font-weight-medium mr-1" },
                          [_vm._v(_vm._s(_vm.$t("startSession")) + ":")]
                        ),
                        _vm._v(" "),
                        item.started_at
                          ? _c(
                              "div",
                              { staticClass: "d-flex justify-center" },
                              [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.formatDateTime(
                                        item.started_at,
                                        "date"
                                      )
                                    )
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("v-divider", {
                                  staticClass: "mx-2",
                                  attrs: { vertical: "" },
                                }),
                                _vm._v(" "),
                                _c("span", { staticClass: "text-brand" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.formatDateTime(
                                        item.started_at,
                                        "time"
                                      )
                                    )
                                  ),
                                ]),
                              ],
                              1
                            )
                          : _c("span", [_vm._v("---")]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "d-flex" }, [
                        _c(
                          "label",
                          { staticClass: "font-weight-medium mr-1" },
                          [_vm._v(_vm._s(_vm.$t("endSession")) + ":")]
                        ),
                        _vm._v(" "),
                        item.ended_at
                          ? _c(
                              "div",
                              { staticClass: "d-flex justify-center" },
                              [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.formatDateTime(item.ended_at, "date")
                                    )
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("v-divider", {
                                  staticClass: "mx-2",
                                  attrs: { vertical: "" },
                                }),
                                _vm._v(" "),
                                _c("span", { staticClass: "text-brand" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.formatDateTime(item.ended_at, "time")
                                    )
                                  ),
                                ]),
                              ],
                              1
                            )
                          : _c("span", [_vm._v("---")]),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }