import { Vue, Component } from 'vue-property-decorator'
import { LocalStorageFiltersStore } from '~/store';

@Component
export default class LocalStorageFilters extends Vue {
  firstLoad: boolean = true;

  async getLocalFilters(component: Vue, table:any) {
    if(this.firstLoad) {
      LocalStorageFiltersStore.setFlag(true);

      let localFilters: any = {};
      if(!this.localFilters[table]) {
        const data = await LocalStorageFiltersStore.fetchFilters(table) as any;
        localFilters = JSON.parse(data?.filters) || null;
      } else {
        localFilters = this.localFilters[table] || null;
      }

      component.$data.page = localFilters?.pagination?.page || 1;
      component.$data.perPage = localFilters?.pagination?.perPage || 100;
      component.$data.filters = { ...localFilters?.filters } || null;
      
      if(component.$data.holderFilters) { // for query call in index page
        component.$data.holderFilters = { ...localFilters?.filters };
      }

      for (let key in localFilters?.sort) {
        if (localFilters?.sort.hasOwnProperty(key)) {
          component.$data.options[key] = [localFilters?.sort[key]];
        }
      }
      
      const hasFilters = localFilters?.filters ? true : false;
      component.$data.hasFilters = hasFilters;

      this.$emit('get-local-filters', { ...localFilters?.filters, hasFilters: hasFilters });
    }
  }

  async saveLocalFilters(item:any, table:any) {
    const filters = {
      sort: item?.options ? {
        sortBy: item?.options?.sortBy ? item.options.sortBy[0] : '',
        sortDesc: item?.options?.sortDesc ? item?.options?.sortDesc[0] : true
      } : null,
      pagination: {
        page: item.page,
        perPage: item.perPage,
      },
      filters: item.filters
    }

    await LocalStorageFiltersStore.saveFilters({ tableName: table, filters: { ...filters } })
    LocalStorageFiltersStore.setFlag(false);
    this.firstLoad = false;
  }

  async saveOnlyFilters(item:any, table:any) {
    const data = this.localFilters[table];
    let filters = typeof data === 'object' ? data : JSON.parse(data) as any;
    if(filters) {
      filters.filters = { ...filters?.filters, ...item };
    } else {
      filters = { filters: item };
    }

    await LocalStorageFiltersStore.saveFilters({ tableName: table, filters: { ...filters } })
  }

  get localFilters() {
    return LocalStorageFiltersStore.localFilters;
  }
  
  get getFreezeWatchFlag() {
    return LocalStorageFiltersStore.getFreeWatchFlag;
  }
}