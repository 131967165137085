export function requiredRule(message = "This field is required"): (value: any) => boolean|string {
  return (value) => (!!value || value === 0) || message;
}

export function validEmailRule(message = "Please enter a valid email address"): (value: any) => boolean|string {
  const pattern = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)
  return (value) => pattern.test(value) || message
}

export function passwordLengthRule(message = "Please enter at least 8 characters"): (value: any) => boolean|string {
  return (value) => value.length >= 8 || message
}

export function validateURL(message = "Please enter a valid URL"): (value: any) => boolean|string {
  const pattern = new RegExp('^(https?:\\/\\/)?((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|((\\d{1,3}\\.){3}\\d{1,3}))(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*(\\?[;&a-z\\d%_.~+=-]*)?(\\#[-a-z\\d_]*)?$','i')
  return (value) => pattern.test(value) || message
}

export function phoneNumberRule(message = "Invalid phone number"): (value: any) => boolean|string {
  const pattern = new RegExp(/^\+\d([0-9 \-\(\)]{0,10}\d)*$/)
  return (value) => pattern.test(value) ||
    value == null || value == "" ||
    (value.includes("+") ? message : `Please include country code (e.g. +43 for Austria)`)
}

export function usernameCharCountRule(message = "The maximum 30 characters is reached"): (value: any) => boolean|string {
  return (value) => value && value.length <= 30 || message
}

export function usernameCharRule(message = "Username should not start or end with period"): (value: any) => boolean|string {
  return (value) => value && (value.charAt(0) != "." && value.charAt(value.length - 1) != ".") || message
}

export function usernameCharAllowed(message = "Only letters, numbers, dots, and underscores are allowed"): (value: any) => boolean|string {
  const pattern = new RegExp(/^[a-zA-Z0-9._]+$/);
  return (value) => pattern.test(value) || message;
}