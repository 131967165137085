var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { attrs: { id: "boost-section" } }, [
    _c(
      "div",
      {
        staticClass: "custom-content d-flex",
        class: { "flex-column": _vm.isMobile() },
      },
      [
        _c(
          "div",
          { staticClass: "mt-10 mr-5" },
          [
            _c("label", { staticClass: "text-16 white--text ff-bold" }, [
              _vm._v(_vm._s(_vm.$t("boostTitle"))),
            ]),
            _vm._v(" "),
            _c("h2", { staticClass: "h_title_2 fw-bold white--text mt-3" }, [
              _vm._v(_vm._s(_vm.$t("boostDescription"))),
            ]),
            _vm._v(" "),
            _c(
              "v-btn",
              {
                staticClass: "mt-5 custom-btn",
                attrs: { depressed: "", color: "btn-yellow" },
                on: {
                  click: function ($event) {
                    _vm.$router.push(_vm.localePath(_vm.getStartedRoute()))
                  },
                },
              },
              [_vm._v(_vm._s(_vm.$t("getStarted")) + "\n      ")]
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c("v-img", {
          staticClass: "ml-auto",
          attrs: {
            src: _vm.getImage("boost-cover.webp", "boost-cover.svg"),
            alt: "collage social media growth interfaces displaying charts of data analytics, percentage metrics of engagement, and new comments from authentic German profiles.",
            width: "425",
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }