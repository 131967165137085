
import { Component, Vue, Prop } from 'vue-property-decorator'
import { requiredRule, validEmailRule } from '~/utils/FormValidation';
import { getErrorMessage } from "~/utils/ErrorMessageUtils"

@Component({})
export default class AdminAddUserForm extends Vue {
  @Prop({ default: () => { return true }, required: false }) readonly hasCancel!: Boolean

  loading: boolean = false;
  success: boolean = false;
  hasError: boolean = false;
  errorMessage: any = "";
  successMessage: any = "";
  user: any = { automatically_verify: true };
  fieldErrorMessage: any = {};
  emailRule: any = [ requiredRule(this.$t('emailRequired') as any), validEmailRule() ];
  passwordRule: any = [ requiredRule(this.$t('passRequired') as any) ];
  showPassword: boolean = false;
  showConfirmPassword: boolean = false;

  leave() {
    const form = this.$refs.form as any;
    form.reset();
    this.$emit('leave')
  }

  async save() {
    this.fieldErrorMessage = "";
    this.hasError = false;
    const form = this.$refs.form as any;

    if(form.validate()) {
      this.loading = true;
      await this.$axios.post("/management/users/create_user/", this.user)
      .then(() => {
        this.successMessage = this.$t('signUpSuccessTitle');
        this.success = true;

        setTimeout(() => {
          this.success = false;
          this.$emit('success');
          this.leave();
        }, 1500)
      }).catch((error) => {
        if (error.response) {
          this.hasError = true;
          this.errorMessage = getErrorMessage(error, this.$t('errorSubmitForm'));
          this.fieldErrorMessage = this.errorMessage.message;
          this.hasError = this.errorMessage.show;
        }
      })
      this.loading = false;
    }
  }
}
