
import { Component, Prop, Vue } from 'vue-property-decorator'
import { Editor } from '@tiptap/vue-2'
import StarterKit from '@tiptap/starter-kit'
import TextAlign from '@tiptap/extension-text-align'
import Underline from '@tiptap/extension-underline'
import { Color } from '@tiptap/extension-color'
import TextStyle from '@tiptap/extension-text-style'

import { Node, mergeAttributes } from '@tiptap/core'
import { DOMParser  } from 'prosemirror-model'

@Component({})
export default class TipTapEditor extends Vue {
  editor: any = null

  mounted() {
    const Placeholder = Node.create({
      name: 'agreegatePlaceholder',
      // group: 'block',
      // group: 'inline',
      // inline: true,
      // selectable: false,
      // content: 'inline*',
      group: 'inline',
      inline: true,
      content: 'text*',

      addOptions() {
        return {
          HTMLAttributes: {},
        }
      },
      addAttributes() {
        return {
          class: null,
          contenteditable: false,
          'data-contract-attribute': null,
          'data-sub-field': null,
          'data-meta-placeholder': null,
          'data-placeholder-name': null,
          'data-param-id': null,
        }
      },
      parseHTML() {
        return [
          { tag: 'span[class]' },
        ]
      },
      renderHTML({ HTMLAttributes }) {
        return ['span', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes), 0]
      },
      // addCommands() {
      //   return {
      //     insertTest: (attributes) => ({ commands }) => {
      //       return commands.insertContent({ type: this.name });
      //     }
      //   };
      // }
    })

    this.editor = new Editor({
      content: '',
      extensions: [
        StarterKit,
        TextAlign.configure({
          types: ['heading', 'paragraph'],
        }),
        Underline,
        TextStyle,
        Color,
        Placeholder,
      ],
    })
  }

  getHTML(): string {
    return this.editor.getHTML()
  }

  setContent(content: string): void {
    this.editor.commands.setContent(content)
  }

  insertPlaceholder(attributes: any, color: string): void {
    this.editor.commands.insertContent({
      type: 'agreegatePlaceholder',
      attrs: {
        class: `placeholder ${color}`,
        contenteditable: false,
        ...attributes.data_attributes,
      },
      content: [
        {
          type: 'text',
          text: attributes.label
        }
      ],
    })
  }

  insertEmailTemplatePlaceholder(placeholder: any, color: string): void {
    this.editor.commands.insertContent({
      type: 'agreegatePlaceholder',
      attrs: {
        class: `placeholder ${color}`,
        contenteditable: false,
        'data-placeholder-name': placeholder.value,
      },
      content: [
        {
          type: 'text',
          text: placeholder.name
        }
      ],
    })
  }

  get currentTextAlign(): string {
    if (this.editor == null) {
      return ''
    }
    if (this.editor.isActive({ textAlign: 'left' })) {
      return 'left'
    } else if (this.editor.isActive({ textAlign: 'center'  })) {
      return 'centered'
    } else if (this.editor.isActive({ textAlign: 'right'  })) {
      return 'right'
    } else if (this.editor.isActive({ textAlign: 'justify'  })) {
      return 'justified'
    } else {
      return ''
    }
  }
  set currentTextAlign(newValue: string) {}

  get isBold(): string {
    return this.editor.isActive('bold') ? 'bold' : ''
  }
  set isBold(newValue: string) {}

  get isItalic(): string {
    return this.editor.isActive('italic') ? 'italic' : ''
  }
  set isItalic(newValue: string) {}

  get isUnderline(): string {
    return this.editor.isActive('underline') ? 'underline' : ''
  }
  set isUnderline(newValue: string) {}

  get isStrikeThrough(): string {
    return this.editor.isActive('strike') ? 'strike' : ''
  }
  set isStrikeThrough(newValue: string) {}

  get isNumberedList(): string {
    return this.editor.isActive('orderedList') ? 'orderedList' : ''
  }
  set isNumberedList(newValue: string) {}

  get isBulletedList(): string {
    return this.editor.isActive('bulletList') ? 'bulletList' : ''
  }
  set isBulletedList(newValue: string) {}

  get isH1(): string {
    return this.editor.isActive('heading', { level: 1 }) ? 'h1' : ''
  }
  set isH2(newValue: string) {}

  get isH2(): string {
    return this.editor.isActive('heading', { level: 2 }) ? 'h1' : ''
  }
  set isH3(newValue: string) {}

  get isH3(): string {
    return this.editor.isActive('heading', { level: 3 }) ? 'h1' : ''
  }
  set isH1(newValue: string) {}

  get curColor() {
    return this.editor.getAttributes('textStyle').color || "rgba(0, 0, 0, 0.87)";
  }

  textColorPicked(color: string) {
    this.editor.chain().focus().setColor(color).run()
  }

}
