var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("v-autocomplete", {
    ref: "autocompleteRef",
    staticClass: "search-input users-dropdown ml-auto custom-width",
    class: _vm.customClass,
    attrs: {
      placeholder: _vm.$t("allUsers"),
      dense: _vm.isMobile ? false : true,
      items: _vm.userList,
      "item-text": "name",
      "item-value": "id",
      outlined: "",
      "hide-details": "",
      clearable: "",
      "no-filter": "",
      "return-object": "",
    },
    on: {
      keyup: _vm.filterItems,
      change: function ($event) {
        return _vm.$emit("get-data", _vm.user)
      },
    },
    scopedSlots: _vm._u([
      {
        key: "item",
        fn: function ({ item, on, attrs }) {
          return [
            _c(
              "v-list-item",
              _vm._g(_vm._b({}, "v-list-item", attrs, false), on),
              [
                _c(
                  "v-list-item-content",
                  { staticClass: "py-2" },
                  [
                    _c("v-list-item-title", [
                      _c("span", [_vm._v(_vm._s(item.name))]),
                      _vm._v(" "),
                      _c("div", [
                        _c("span", { staticClass: "text-brand" }, [
                          _vm._v(_vm._s(item.email)),
                        ]),
                      ]),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        },
      },
      {
        key: "selection",
        fn: function ({ item }) {
          return [
            item.name
              ? _c("span", [_vm._v(_vm._s(item.name))])
              : _c("span", [_vm._v(_vm._s(item.email))]),
          ]
        },
      },
    ]),
    model: {
      value: _vm.user,
      callback: function ($$v) {
        _vm.user = $$v
      },
      expression: "user",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }