
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'

@Component({})
export default class StandardCardList extends Vue {
  @Prop({ default: () => { return null }, required: false }) readonly data!: any
  @Prop({ default: () => { return null }, required: false }) readonly loading!: any
  @Prop({ default: () => { return 1 }, required: false }) readonly currentPage!: any
  @Prop({ default: () => { return 100 }, required: false }) readonly currentPerPage!: number
  @Prop({ default: () => { return "" }, required: false }) readonly componentName!: any
  @Prop({ default: () => { return false }, required: false }) readonly noAddBtn!: any

  totalData: number = 0;
  page: number = 1;
  perPage: number = 100;
  perPageOptions = [15, 30, 50, 100, 300, 500];
  customData: any = [];

  mounted() {
    this.page = this.currentPage;
    this.perPage = this.currentPerPage;

    this.getData()
    this.setParams();
  }

  @Watch('data')
  getcustomData() {
    this.getData();
  }

  @Watch('currentPage')
  checkPage() {
    this.page = this.currentPage;
  }

  @Watch('currentPerPage')
  checkPerPage() {
    this.perPage = this.currentPerPage;
  }

  handlePageChange(page: number) {
    if (page !== this.page) {
      this.page = page;
      this.setParams();
    }
  }

  handlePerPageChange(perPage: number) {
    this.page = 1;
    this.perPage = perPage;
    this.setParams();
  }

  getData() {
    if(this.data) {
      this.customData = this.data.results;
      this.totalData = this.data.count ? this.data.count : 0;
      
      const count = this.data.count/this.perPage;
      const pageCount = count == Math.floor(count) ? count : (Math.floor(count) + 1);
      this.page = this.page > pageCount ? 1 : this.page;
    }
  }

  setParams() {
    this.$vuetify.goTo(0);
    this.$emit('refresh', { page: this.page, perPage: this.perPage, options: {} });
  }
}
