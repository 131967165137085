var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "mt-5" },
    [
      _c(
        "v-form",
        {
          ref: "form",
          attrs: { onSubmit: "return false;" },
          on: { submit: _vm.save },
        },
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "12", md: "6" } },
                [
                  _c("label", { staticClass: "font-weight-medium text-14" }, [
                    _vm._v(_vm._s(_vm.$t("username"))),
                  ]),
                  _vm._v(" "),
                  _c("v-text-field", {
                    staticClass: "mt-1",
                    attrs: {
                      dense: "",
                      outlined: "",
                      placeholder: _vm.$t("username"),
                      rules: _vm.requiredRule,
                      "background-color": "white",
                      "hide-details": "auto",
                    },
                    model: {
                      value: _vm.data.username,
                      callback: function ($$v) {
                        _vm.$set(_vm.data, "username", $$v)
                      },
                      expression: "data.username",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-col",
                { attrs: { cols: "12", md: "6" } },
                [
                  _c("label", { staticClass: "font-weight-medium text-14" }, [
                    _vm._v(_vm._s(_vm.$t("maxFollowerCount"))),
                  ]),
                  _vm._v(" "),
                  _c("v-text-field", {
                    staticClass: "mt-1",
                    attrs: {
                      dense: "",
                      outlined: "",
                      type: "number",
                      placeholder: _vm.$t("maxFollowerCount"),
                      rules: _vm.requiredRule,
                      "background-color": "white",
                      "hide-details": "auto",
                    },
                    model: {
                      value: _vm.data.max_follower_count,
                      callback: function ($$v) {
                        _vm.$set(_vm.data, "max_follower_count", $$v)
                      },
                      expression: "data.max_follower_count",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-row",
            [
              _c("v-col", { attrs: { cols: "12", md: "6" } }, [
                _c("label", { staticClass: "font-weight-medium text-14" }, [
                  _vm._v(_vm._s(_vm.$t("paidUntil"))),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "mt-1" },
                  [
                    _c(
                      "v-menu",
                      {
                        attrs: {
                          "close-on-content-click": false,
                          "nudge-right": 40,
                          transition: "scale-transition",
                          "offset-y": "",
                          "min-width": "auto",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "activator",
                            fn: function ({ on, attrs }) {
                              return [
                                _c(
                                  "v-text-field",
                                  _vm._g(
                                    _vm._b(
                                      {
                                        attrs: {
                                          "hide-details": "auto",
                                          readonly: "",
                                          dense: "",
                                          outlined: "",
                                          "background-color": "white",
                                          rules: _vm.requiredRule,
                                        },
                                        model: {
                                          value: _vm.data.paid_until,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.data,
                                              "paid_until",
                                              $$v
                                            )
                                          },
                                          expression: "data.paid_until",
                                        },
                                      },
                                      "v-text-field",
                                      attrs,
                                      false
                                    ),
                                    on
                                  )
                                ),
                              ]
                            },
                          },
                        ]),
                        model: {
                          value: _vm.menuDate,
                          callback: function ($$v) {
                            _vm.menuDate = $$v
                          },
                          expression: "menuDate",
                        },
                      },
                      [
                        _vm._v(" "),
                        _c("v-date-picker", {
                          attrs: { "no-title": "" },
                          on: {
                            input: function ($event) {
                              _vm.menuDate = false
                            },
                          },
                          model: {
                            value: _vm.data.paid_until,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "paid_until", $$v)
                            },
                            expression: "data.paid_until",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c(
                "v-col",
                { attrs: { cols: "12", md: "6" } },
                [
                  _c("label", { staticClass: "font-weight-medium text-14" }, [
                    _vm._v(_vm._s(_vm.$t("owner"))),
                  ]),
                  _vm._v(" "),
                  _c("v-autocomplete", {
                    staticClass: "mt-1",
                    attrs: {
                      dense: "",
                      outlined: "",
                      "background-color": "white",
                      placeholder: _vm.$t("selectUser"),
                      items: _vm.list.users,
                      "item-text": _vm.getUser,
                      "item-value": "id",
                      rules: _vm.requiredRule,
                      "hide-details": "auto",
                    },
                    model: {
                      value: _vm.data.user,
                      callback: function ($$v) {
                        _vm.$set(_vm.data, "user", $$v)
                      },
                      expression: "data.user",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "text-right mt-7" },
            [
              _c(
                "v-btn",
                {
                  staticClass: "text-brand",
                  attrs: { depressed: "", outlined: "" },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("cancel")
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("cancel")))]
              ),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  staticClass: "btn-brand px-7",
                  attrs: {
                    depressed: "",
                    type: "submit",
                    loading: _vm.loading,
                  },
                },
                [_vm._v(_vm._s(_vm.$t("save")) + "\n      ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }