
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { AdminStore } from '~/store'

@Component({})
export default class UsersDropdown extends Vue {
  @Prop({ default: false }) readonly isMobile!: Boolean
  @Prop({ default: "" }) readonly customClass!: any
  @Prop({ default: () => null, required: false }) readonly default!: any

  user: any = {};
  userList: any = [];
  allList: any = [];

  mounted() {
    const user = this.$auth?.user || {};
    if(user?.is_admin) {
      this.getList();
    }
  }

  async getList() {
    await AdminStore.fetchENUM()
    .then((response:any) => {
      response?.users.forEach((item:any) => {
        const arr = {
          id: item.id,
          name: (!item.first_name && !item.last_name) ? '' : (item?.first_name || "") + " " + (item?.last_name || ""),
          email: item.email
        }
        this.userList.push(arr);
      });
      this.allList = this.userList;
    })
  }

  @Watch('user')
  checkUserModel() {
    const field = this.$refs.autocompleteRef as any;
    field.isFocused = false;
  }

  @Watch('default')
  checkDefault(val:any) {
    this.user = val;
  }

  filterItems($event:any) {
    const searchTerm = $event.target.value;
    const filtered = this.allList.filter((item:any) => 
      item.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item.email.toLowerCase().includes(searchTerm.toLowerCase())
    );
    this.userList = filtered;
  }
}
