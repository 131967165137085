
import { Component, Vue, Prop } from 'vue-property-decorator'

@Component({
  layout: 'landing-page',
})
export default class FaqSection extends Vue {
  @Prop({ default: () => {}, required: false }) readonly content!: any

  faqPanel: any = 1;
}
