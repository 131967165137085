var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-dialog",
    {
      attrs: { persistent: "", "max-width": "500" },
      model: {
        value: _vm.modal,
        callback: function ($$v) {
          _vm.modal = $$v
        },
        expression: "modal",
      },
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-card-title",
            [
              _c(
                "span",
                { staticClass: "h_title_3 font-weight-medium text-black" },
                [_vm._v("Order Details")]
              ),
              _vm._v(" "),
              _c(
                "v-icon",
                {
                  staticClass: "ml-auto",
                  on: {
                    click: function ($event) {
                      _vm.modal = false
                    },
                  },
                },
                [_vm._v("mdi-close")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _vm.details
            ? _c(
                "v-card-text",
                { staticClass: "px-7 pb-7" },
                [
                  _c("v-divider", { staticClass: "mb-3" }),
                  _vm._v(" "),
                  _c(
                    "v-row",
                    { staticClass: "text-14" },
                    [
                      _c("v-col", { attrs: { cols: "6" } }, [
                        _c("label", { staticClass: "font-weight-medium" }, [
                          _vm._v("Reference #: "),
                        ]),
                        _vm._v(" "),
                        _vm.details.id
                          ? _c("div", { staticClass: "mb-3" }, [
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm.formatReferenceNum(_vm.details.id, 3)
                                  )
                                ),
                              ]),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _c("label", { staticClass: "font-weight-medium" }, [
                          _vm._v("Service: "),
                        ]),
                        _vm._v(" "),
                        _vm.details.order_type
                          ? _c("div", { staticClass: "mb-3" }, [
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm.details.order_type.replace(/_/g, " ")
                                  )
                                ),
                              ]),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _c("label", { staticClass: "font-weight-medium" }, [
                          _vm._v("Username: "),
                        ]),
                        _vm._v(" "),
                        _vm.details.ig_username
                          ? _c("div", [
                              _c("span", [
                                _vm._v("@" + _vm._s(_vm.details.ig_username)),
                              ]),
                            ])
                          : _vm._e(),
                      ]),
                      _vm._v(" "),
                      _c("v-col", { attrs: { cols: "6" } }, [
                        _c("label", { staticClass: "font-weight-medium" }, [
                          _vm._v("Created: "),
                        ]),
                        _vm._v(" "),
                        _vm.details.created
                          ? _c("div", { staticClass: "mb-3" }, [
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm.formatDateTime(_vm.details.created)
                                  )
                                ),
                              ]),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _c("label", { staticClass: "font-weight-medium" }, [
                          _vm._v("Order: "),
                        ]),
                        _vm._v(" "),
                        _vm.details.order_type
                          ? _c(
                              "div",
                              [
                                _vm.details.order_type == "story_views"
                                  ? [
                                      _c(
                                        "v-icon",
                                        {
                                          staticClass: "custom-icon-small",
                                          attrs: {
                                            small: "",
                                            color: "warning",
                                          },
                                        },
                                        [_vm._v("mdi-eye-check")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "font-weight-medium warning--text",
                                        },
                                        [_vm._v(_vm._s(_vm.details.quantity))]
                                      ),
                                    ]
                                  : _vm.details.order_type == "post_likes"
                                  ? [
                                      _c(
                                        "v-icon",
                                        {
                                          staticClass: "custom-icon-small",
                                          attrs: { small: "", color: "info" },
                                        },
                                        [_vm._v("mdi-thumb-up-outline")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "font-weight-medium info--text",
                                        },
                                        [_vm._v(_vm._s(_vm.details.quantity))]
                                      ),
                                    ]
                                  : _vm.details.order_type == "post_comments"
                                  ? [
                                      _c(
                                        "v-icon",
                                        {
                                          staticClass: "custom-icon-small",
                                          attrs: {
                                            small: "",
                                            color: "success",
                                          },
                                        },
                                        [_vm._v("mdi-comment-text-multiple")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "font-weight-medium success--text",
                                        },
                                        [_vm._v(_vm._s(_vm.details.quantity))]
                                      ),
                                    ]
                                  : _vm.details.order_type == "followers"
                                  ? [
                                      _c(
                                        "v-icon",
                                        {
                                          staticClass:
                                            "custom-icon-small text-brand",
                                          attrs: { small: "" },
                                        },
                                        [_vm._v("mdi-account-multiple-outline")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "font-weight-medium text-brand",
                                        },
                                        [_vm._v(_vm._s(_vm.details.quantity))]
                                      ),
                                    ]
                                  : _vm._e(),
                              ],
                              2
                            )
                          : _vm._e(),
                      ]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "d-flex align-center mt-7" },
                    [
                      _c("div", { staticClass: "text-center" }, [
                        _c(
                          "div",
                          { staticClass: "progress-icon" },
                          [
                            _c(
                              "v-icon",
                              {
                                staticClass: "custom-icon-small text-brand",
                                attrs: { size: "20" },
                              },
                              [_vm._v("mdi-note-edit-outline")]
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("p", [_vm._v("Created")]),
                      ]),
                      _vm._v(" "),
                      _c("v-divider", { staticClass: "mx-4 mb-7" }),
                      _vm._v(" "),
                      _c("div", { staticClass: "text-center" }, [
                        _c(
                          "div",
                          { staticClass: "progress-icon active" },
                          [
                            _c(
                              "v-icon",
                              {
                                staticClass: "custom-icon-small text-brand",
                                attrs: { size: "20" },
                              },
                              [_vm._v("mdi-progress-check")]
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("p", [_vm._v("Processing")]),
                      ]),
                      _vm._v(" "),
                      _c("v-divider", { staticClass: "mx-4 mb-7" }),
                      _vm._v(" "),
                      _c("div", { staticClass: "text-center" }, [
                        _c(
                          "div",
                          { staticClass: "progress-icon inactive" },
                          [
                            _c(
                              "v-icon",
                              {
                                staticClass: "custom-icon-small text-brand",
                                attrs: { size: "20" },
                              },
                              [_vm._v("mdi-check-all")]
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("p", [_vm._v("Fulfilled")]),
                      ]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "mt-3 text-14" }, [
                    _c("label", { staticClass: "font-weight-medium" }, [
                      _vm._v("Other Details"),
                    ]),
                  ]),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }