var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "d-flex align-center custom-status-container" },
    [
      _vm.item.value == "story_views"
        ? [
            _c(
              "v-icon",
              {
                class: _vm.customClass,
                attrs: { size: _vm.size, color: "warning" },
              },
              [_vm._v("mdi-eye-check")]
            ),
            _vm._v(" "),
            !_vm.iconOnly
              ? _c(
                  "span",
                  { staticClass: "font-weight-medium warning--text" },
                  [_vm._v(_vm._s(_vm.item.count))]
                )
              : _vm._e(),
          ]
        : _vm.item.value == "post_likes"
        ? [
            _c(
              "v-icon",
              {
                class: _vm.customClass,
                attrs: { size: _vm.size, color: "info" },
              },
              [_vm._v("mdi-thumb-up-outline")]
            ),
            _vm._v(" "),
            !_vm.iconOnly
              ? _c("span", { staticClass: "font-weight-medium info--text" }, [
                  _vm._v(_vm._s(_vm.item.count)),
                ])
              : _vm._e(),
          ]
        : _vm.item.value == "post_comments" || _vm.item.value == "post_comment"
        ? [
            _c(
              "v-icon",
              {
                class: _vm.customClass,
                attrs: { size: _vm.size, color: "success" },
              },
              [_vm._v("mdi-comment-text-multiple")]
            ),
            _vm._v(" "),
            !_vm.iconOnly
              ? _c(
                  "span",
                  { staticClass: "font-weight-medium success--text" },
                  [_vm._v(_vm._s(_vm.item.count))]
                )
              : _vm._e(),
          ]
        : _vm.item.value == "followers"
        ? [
            _c(
              "v-icon",
              {
                staticClass: "text-brand",
                class: _vm.customClass,
                attrs: { size: _vm.size },
              },
              [_vm._v("mdi-account-multiple-outline")]
            ),
            _vm._v(" "),
            !_vm.iconOnly
              ? _c("span", { staticClass: "font-weight-medium text-brand" }, [
                  _vm._v(_vm._s(_vm.item.count)),
                ])
              : _vm._e(),
          ]
        : _vm.item.value == "post_saves"
        ? [
            _c(
              "v-icon",
              {
                class: _vm.customClass,
                attrs: { size: _vm.size, color: "yellow darken-1" },
              },
              [_vm._v("mdi-bookmark-multiple-outline")]
            ),
            _vm._v(" "),
            !_vm.iconOnly
              ? _c(
                  "span",
                  {
                    staticClass:
                      "font-weight-medium yellow--text text--darken-2",
                  },
                  [_vm._v(_vm._s(_vm.item.count))]
                )
              : _vm._e(),
          ]
        : _vm.item.value == "like_story_sequences"
        ? [
            _c(
              "v-icon",
              {
                class: _vm.customClass,
                attrs: { size: _vm.size, color: "#73cddf" },
              },
              [_vm._v("mdi-tune-variant")]
            ),
            _vm._v(" "),
            !_vm.iconOnly
              ? _c(
                  "span",
                  {
                    staticClass: "font-weight-medium",
                    staticStyle: { color: "#73cddf" },
                  },
                  [_vm._v(_vm._s(_vm.item.count))]
                )
              : _vm._e(),
          ]
        : _vm.item.value == "reel_views"
        ? [
            _c(
              "v-icon",
              {
                class: _vm.customClass,
                attrs: { size: _vm.size, color: "#df7373" },
              },
              [_vm._v("mdi-play-box-outline")]
            ),
            _vm._v(" "),
            !_vm.iconOnly
              ? _c(
                  "span",
                  {
                    staticClass: "font-weight-medium",
                    staticStyle: { color: "#df7373" },
                  },
                  [_vm._v(_vm._s(_vm.item.count))]
                )
              : _vm._e(),
          ]
        : _vm.item.value == "reach_impressions"
        ? [
            _c(
              "v-icon",
              {
                class: _vm.customClass,
                attrs: { size: _vm.size, color: "#df73d6" },
              },
              [_vm._v("mdi-broadcast")]
            ),
            _vm._v(" "),
            !_vm.iconOnly
              ? _c(
                  "span",
                  {
                    staticClass: "font-weight-medium",
                    staticStyle: { color: "#df73d6" },
                  },
                  [_vm._v(_vm._s(_vm.item.count))]
                )
              : _vm._e(),
          ]
        : _vm.item.value == "story_link_clicks"
        ? [
            _c(
              "v-icon",
              {
                class: _vm.customClass,
                attrs: { size: _vm.size, color: "#fbb600" },
              },
              [_vm._v("mdi-gesture-tap")]
            ),
            _vm._v(" "),
            !_vm.iconOnly
              ? _c(
                  "span",
                  {
                    staticClass: "font-weight-medium",
                    staticStyle: { color: "#fbb600" },
                  },
                  [_vm._v(_vm._s(_vm.item.count))]
                )
              : _vm._e(),
          ]
        : _vm.item.value == "post_shares"
        ? [
            _c(
              "v-icon",
              {
                class: _vm.customClass,
                attrs: { size: _vm.size, color: "#4caf99" },
              },
              [_vm._v("mdi-share-all")]
            ),
            _vm._v(" "),
            !_vm.iconOnly
              ? _c(
                  "span",
                  {
                    staticClass: "font-weight-medium",
                    staticStyle: { color: "#4caf99" },
                  },
                  [_vm._v(_vm._s(_vm.item.count))]
                )
              : _vm._e(),
          ]
        : [
            _c(
              "v-icon",
              {
                staticClass: "text-brand",
                class: _vm.customClass,
                attrs: { size: _vm.size },
              },
              [_vm._v("mdi-lightbulb-outline")]
            ),
            _vm._v(" "),
            !_vm.iconOnly
              ? _c("span", { staticClass: "font-weight-medium text-brand" }, [
                  _vm._v(_vm._s(_vm.item.count)),
                ])
              : _vm._e(),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }