var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { attrs: { id: "blogs-create" } },
    [
      _c(
        "v-form",
        { ref: "articleForm" },
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "12", md: "8" } },
                [
                  _c(
                    "v-card",
                    { staticClass: "custom-cards" },
                    [
                      _c(
                        "v-card-text",
                        { staticClass: "py-5 px-7" },
                        [
                          _c(
                            "label",
                            {
                              staticClass:
                                "text-16 font-weight-medium text-black",
                            },
                            [_vm._v(_vm._s(_vm.$t("title")))]
                          ),
                          _vm._v(" "),
                          _c("v-text-field", {
                            staticClass: "mt-2",
                            attrs: {
                              outlined: "",
                              "background-color": "white",
                              rules: _vm.requiredRule,
                              "error-messages": _vm.fieldErrorMessage.title,
                              placeholder: _vm.$t("title"),
                              "hide-details": "auto",
                            },
                            model: {
                              value: _vm.article.title,
                              callback: function ($$v) {
                                _vm.$set(_vm.article, "title", $$v)
                              },
                              expression: "article.title",
                            },
                          }),
                          _vm._v(" "),
                          _c("v-text-field", {
                            staticClass: "mt-2 mb-4",
                            attrs: {
                              outlined: "",
                              "background-color": "white",
                              "error-messages": _vm.fieldErrorMessage.title,
                              placeholder: _vm.$t("germanTranslation"),
                              "hide-details": "auto",
                            },
                            model: {
                              value: _vm.article.german_title,
                              callback: function ($$v) {
                                _vm.$set(_vm.article, "german_title", $$v)
                              },
                              expression: "article.german_title",
                            },
                          }),
                          _vm._v(" "),
                          _c(
                            "label",
                            {
                              staticClass:
                                "text-16 font-weight-medium text-black",
                            },
                            [_vm._v(_vm._s(_vm.$t("categories")))]
                          ),
                          _vm._v(" "),
                          _c("v-select", {
                            staticClass: "mt-2 mb-4",
                            attrs: {
                              outlined: "",
                              multiple: "",
                              "background-color": "white",
                              items: _vm.categories,
                              "item-text": "name",
                              "item-value": "id",
                              "hide-details": "auto",
                              rules: _vm.requiredRule,
                              "error-messages":
                                _vm.fieldErrorMessage.categories,
                            },
                            model: {
                              value: _vm.article.categories,
                              callback: function ($$v) {
                                _vm.$set(_vm.article, "categories", $$v)
                              },
                              expression: "article.categories",
                            },
                          }),
                          _vm._v(" "),
                          _c(
                            "label",
                            {
                              staticClass:
                                "text-16 font-weight-medium text-black",
                            },
                            [_vm._v(_vm._s(_vm.$t("content")))]
                          ),
                          _vm._v(" "),
                          _c("v-divider", { staticClass: "mt-2" }),
                          _vm._v(" "),
                          _c(
                            "v-expansion-panels",
                            {
                              staticClass: "content-expand",
                              attrs: { flat: "", mandatory: "", multiple: "" },
                              model: {
                                value: _vm.contentExpand,
                                callback: function ($$v) {
                                  _vm.contentExpand = $$v
                                },
                                expression: "contentExpand",
                              },
                            },
                            [
                              _c(
                                "v-expansion-panel",
                                [
                                  _c(
                                    "v-expansion-panel-header",
                                    { staticClass: "pa-0" },
                                    [
                                      _c("label", [
                                        _vm._v(
                                          _vm._s(_vm.$t("englishTranslation"))
                                        ),
                                      ]),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-expansion-panel-content",
                                    [_c("tip-tap-editor", { ref: "editorEn" })],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c("v-divider"),
                              _vm._v(" "),
                              _c(
                                "v-expansion-panel",
                                { staticClass: "mt-0" },
                                [
                                  _c(
                                    "v-expansion-panel-header",
                                    { staticClass: "pa-0" },
                                    [
                                      _c("label", [
                                        _vm._v(
                                          _vm._s(_vm.$t("germanTranslation"))
                                        ),
                                      ]),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-expansion-panel-content",
                                    [_c("tip-tap-editor", { ref: "editorDe" })],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-col",
                { attrs: { cols: "12", md: "4" } },
                [
                  _c(
                    "v-card",
                    { staticClass: "custom-cards" },
                    [
                      _c(
                        "v-card-text",
                        [
                          _c(
                            "div",
                            { staticClass: "d-flex align-center" },
                            [
                              _c(
                                "label",
                                {
                                  staticClass:
                                    "text-16 font-weight-medium text-black",
                                },
                                [_vm._v(_vm._s(_vm.$t("status")))]
                              ),
                              _vm._v(" "),
                              _c("v-switch", {
                                staticClass: "mt-0 ml-auto",
                                attrs: {
                                  label: _vm.$t("publish"),
                                  inset: "",
                                  "hide-details": "auto",
                                  ripple: false,
                                },
                                model: {
                                  value: _vm.article.publish,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.article, "publish", $$v)
                                  },
                                  expression: "article.publish",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("v-divider", { staticClass: "my-4" }),
                          _vm._v(" "),
                          _c(
                            "v-row",
                            { attrs: { "no-gutters": "" } },
                            [
                              _c(
                                "v-col",
                                { attrs: { align: "center" } },
                                [
                                  _c(
                                    "v-card",
                                    {
                                      staticClass: "custom-cards",
                                      on: {
                                        drop: _vm.drop,
                                        dragover: _vm.dragover,
                                        dragleave: _vm.dragleave,
                                      },
                                    },
                                    [
                                      _c(
                                        "v-card-text",
                                        { staticClass: "pa-2" },
                                        [
                                          _vm.hasThumbnail
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "thumbnail-container",
                                                },
                                                [
                                                  _c(
                                                    "v-icon",
                                                    {
                                                      staticClass: "ma-1",
                                                      staticStyle: {
                                                        position: "absolute",
                                                        right: "0",
                                                      },
                                                      attrs: {
                                                        color: "black",
                                                        size: "20",
                                                      },
                                                      on: {
                                                        click: _vm.clearImage,
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                        mdi-close-circle-outline\n                      "
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _vm.fileUploadUrl != null
                                                    ? _c("img", {
                                                        attrs: {
                                                          id: "uploadPreview",
                                                          src: _vm.fileUploadUrl,
                                                          height: "115",
                                                          width: "auto",
                                                        },
                                                      })
                                                    : _vm._e(),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            { staticClass: "mt-2" },
                                            [
                                              _c(
                                                "p",
                                                {
                                                  staticClass:
                                                    "mb-0 text-14 text-black",
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t("dragOrDropImage")
                                                    )
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "v-btn",
                                                {
                                                  staticClass:
                                                    "ma-2 white--text",
                                                  attrs: { color: "blue-grey" },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.$refs.fileUpload.$refs.input.click()
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                        " +
                                                      _vm._s(_vm.$t("upload")) +
                                                      "\n                        "
                                                  ),
                                                  _c(
                                                    "v-icon",
                                                    {
                                                      attrs: {
                                                        right: "",
                                                        dark: "",
                                                        size: "20",
                                                      },
                                                    },
                                                    [_vm._v("mdi-cloud-upload")]
                                                  ),
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c("v-file-input", {
                                                ref: "fileUpload",
                                                staticClass: "d-none",
                                                attrs: {
                                                  id: "fileUpload",
                                                  accept: "image/*",
                                                },
                                                on: { change: _vm.fileChanged },
                                              }),
                                            ],
                                            1
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("snackbar-success", { ref: "success" }),
      _vm._v(" "),
      _c("snackbar-error", { ref: "error" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }