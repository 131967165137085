var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return !_vm.hasConsent
    ? _c(
        "v-card",
        {
          staticClass: "consent border-0",
          attrs: { outlined: "", elevation: "1", width: "350" },
        },
        [
          _c(
            "v-card-text",
            { staticClass: "d-flex align-start py-3 pl-3 pr-5" },
            [
              _c(
                "v-icon",
                { staticClass: "text-brand", attrs: { large: "" } },
                [_vm._v("mdi-cookie")]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "mt-1" }, [
                _c(
                  "h1",
                  [
                    _c("span", [
                      _vm._v(_vm._s(_vm.$t("cookieConsentContent"))),
                    ]),
                    _vm._v(" "),
                    _c(
                      "nuxt-link",
                      {
                        staticClass: "text-14 text-purple-2",
                        attrs: { to: _vm.localePath("/") },
                      },
                      [_vm._v(_vm._s(_vm.$t("learnMore")))]
                    ),
                    _vm._v(" "),
                    _c(
                      "v-btn",
                      {
                        staticClass: "ml-auto btn-brand py-3 mt-5 float-right",
                        attrs: {
                          depressed: "",
                          "x-small": "",
                          loading: _vm.loading,
                        },
                        on: { click: _vm.consent },
                      },
                      [_vm._v(_vm._s(_vm.$t("okGotIt")))]
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }