import { $axios } from '@/utils/Api'
import Store from '@/utils/StoreInstance'
import { Module, VuexModule, Action, Mutation } from 'vuex-module-decorators'

@Module({stateFactory: true, store: Store, name: 'accounts', namespaced: true})
export default class AccountsModule extends VuexModule {
  credits: any = {};
  accountList: any = []
  customAccountList: any = [];
  user: any = {};
  currentAccount: any = {};
  syncTimestamps: any = null;
  report: any = {};

  // Credits
  @Mutation
  setCredits(item: any) {
    this.credits = item;
  }
  @Action({commit: 'setCredits', rawError: true})
  async fetchCredits() {
    const { data: results } = await $axios.get('/auth/accounts/credits/');
    return results;
  }
  get getCredits():any {
    return this.credits;
  }

  // All accounts
  @Mutation
  setAccountList(item: any) {
    this.accountList = item;
  }
  @Action({commit: 'setAccountList', rawError: true})
  async fetchAccountList(item:any = {}) {
    const { data: results } = await $axios.get(`/${item.type}/instagram-accounts/`, { params: item.params });
    return results;
  }
  get getAccountList():any {
    return this.accountList;
  }

  // All accounts Not Paginated
  @Mutation
  setCustomAccountList(item: any) {
    this.customAccountList = item;
  }
  @Action({commit: 'setCustomAccountList', rawError: true})
  async fetchCustomAccountList(item:any = {}) {
    const { data: results } = await $axios.get(`/${item.type}/instagram-accounts/all`);
    return results;
  }
  get getCustomAccountList():any {
    return this.customAccountList;
  }

  // Get management users
  @Mutation
  setUserByAdmin(item: any) {
    this.user = item;
  }
  @Action({commit: 'setUserByAdmin', rawError: true})
  async fetchUserByAdmin(id:any) {
    const { data: results } = await $axios.get(`/management/users/${id}`);
    return results;
  }
  get getUserByAdmin():any {
    return this.user;
  }

  // Get current account
  @Mutation
  setCurrentAccount(item: any) {
    this.currentAccount = item;
  }
  @Action({commit: 'setCurrentAccount', rawError: true})
  async fetchCurrentAccount(item:any = {}) {
    const { data: results } = await $axios.get(`/${item.type}/instagram-accounts/${item.id}/`);
    return results;
  }
  get getCurrentAccount():any {
    return this.currentAccount;
  }

  // Get account report sync timestamps
  @Mutation
  setTimestamps(item: any) {
    this.syncTimestamps = item;
  }
  @Action({commit: 'setTimestamps', rawError: true})
  async fetchTimestamps() {
    const { data: results } = await $axios.get('/instabot/instagram-accounts/sync_time_stamps');
    return results;
  }
  get getTimestamps():any {
    return this.syncTimestamps;
  }

  // Get account report
  @Mutation
  setReport(item: any) {
    this.report = item;
  }
  @Action({commit: 'setReport', rawError: true})
  async fetchReport(item:any) {
    const { data: results } = await $axios.get(`/instabot/instagram-accounts/${item.account}/reports/`, { params: item.params });
    return results;
  }
  get getReport():any {
    return this.report;
  }
}
