var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "flatrate-pricing" },
    [
      _c(
        "v-row",
        [
          _vm._l(_vm.pricing, function (price, index) {
            return [
              _c(
                "v-col",
                { attrs: { cols: "12", md: _vm.isModal ? "12" : "6" } },
                [
                  _c(
                    "v-card",
                    { staticClass: "package" },
                    [
                      _c("v-card-text", { staticClass: "content" }, [
                        _c(
                          "div",
                          {
                            staticClass: "d-flex align-center",
                            class: {
                              "flex-wrap justify-center text-center":
                                _vm.$vuetify.breakpoint.xs,
                            },
                          },
                          [
                            _c(
                              "div",
                              { class: { "mb-5": _vm.$vuetify.breakpoint.xs } },
                              [
                                _c("h3", { staticClass: "name" }, [
                                  _vm._v("Package " + _vm._s(index + 1)),
                                ]),
                                _vm._v(" "),
                                _c("h3", { staticClass: "amount my-3" }, [
                                  _vm._v("$" + _vm._s(price.price / 100)),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "label",
                                  {
                                    staticClass:
                                      "text-16 font-weight-medium text-dark-grey",
                                  },
                                  [_vm._v(_vm._s(_vm.$t("perMonth")))]
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c("div", { staticClass: "ml-auto" }, [
                              _c(
                                "div",
                                { staticClass: "text-left" },
                                [
                                  _c(
                                    "v-icon",
                                    { attrs: { color: "success" } },
                                    [_vm._v("mdi-check")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "label",
                                    {
                                      staticClass:
                                        "text-16 font-weight-medium text-black ml-3",
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.$t("unlimitedSessions"))
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "mt-5 text-left" },
                                [
                                  _c(
                                    "v-icon",
                                    { attrs: { color: "success" } },
                                    [_vm._v("mdi-check")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "label",
                                    {
                                      staticClass:
                                        "text-16 font-weight-medium text-black ml-3",
                                    },
                                    [_vm._v(_vm._s(_vm.$t("mobileAtATime")))]
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "mt-5 text-left" },
                                [
                                  _c(
                                    "v-icon",
                                    { attrs: { color: "success" } },
                                    [_vm._v("mdi-check")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "label",
                                    {
                                      staticClass:
                                        "text-16 font-weight-medium text-black ml-3",
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t("upToTraffic", {
                                            traffic: _vm.convertBytes(
                                              price.data_limit
                                            ),
                                          })
                                        )
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ]),
                          ]
                        ),
                        _vm._v(" "),
                        _vm.isModal
                          ? _c(
                              "div",
                              { staticClass: "text-right" },
                              [
                                _c(
                                  "v-btn",
                                  {
                                    staticClass: "btn-brand mt-3",
                                    attrs: { depressed: "" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.selectPackage(price.id)
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t("selectPackage") +
                                          " " +
                                          index +
                                          1
                                      )
                                    ),
                                  ]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }