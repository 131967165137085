
import { Component, Vue } from 'vue-property-decorator'
import qrcode from 'qrcode';

@Component({})
export default class SettingsAddCreditsModal extends Vue {
  modal: boolean = false;
  isCopied: boolean = false;
  details: any = { amount: 0, wallet: null };

  show(item:any) {
    this.isCopied = false;
    this.modal = true;
    const urlParams = new URLSearchParams(new URL(item.checkout_url).search);
    const publicKey = urlParams.get("public_key") || null;

    this.details = {
      amount: item?.order?.qty || 0,
      wallet: publicKey
    }

    if(publicKey) {
      setTimeout(() => {
        const dd = this.$t('cryptoPaymentDesc', { amount: this.details.amount }) as any
        const span = document.createElement('span');
        span.innerHTML = dd;

        const description = this.$refs.description as any;
        description.innerHTML = '';
        description.appendChild(span);
        
        this.generate(publicKey);
      }, 500)
    }
  }

  async generate(key:any) {
    try {
      const target = this.$refs.qrCodeImage as HTMLImageElement;
      const canvas = document.createElement('canvas');

      await qrcode.toCanvas(canvas, key, (error: any) => {
        if (error) {
          console.error('Error generating QR code:', error);
        } else {
          target.src = canvas.toDataURL('image/png');
        }
      });
    } catch (error) {
      console.error('Error loading qrcode library:', error);
    }
  }

  copyText() {
    this.isCopied = false;
    const button = document.getElementById('copyId') as any;
    const range = document.createRange() as any;
    range.selectNode(button);

    // Get the current selection
    const selection = window.getSelection() as any;
    selection.removeAllRanges();
    selection.addRange(range);

    try {
      document.execCommand('copy');
      this.isCopied = true;
    } catch (error) {
      console.error('Unable to copy to clipboard:', error);
    } finally {
      // Deselect the text (optional)
      selection.removeAllRanges();
    }
  }
}
