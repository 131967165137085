import { Line } from "vue-chartjs";  

export default {
  extends: Line,
  props: ["chartData", "options"],
  watch: {
    chartData: {
      handler: function(value) {
        this.renderChart(value, this.options);
        const htmlLegend = this.generateLegend();
        this.$emit('html-legend', htmlLegend);
      },
      deep: true
    },
  }
};