var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-snackbar",
    {
      attrs: { color: "red", shaped: "", elevation: "24", right: "" },
      scopedSlots: _vm._u([
        {
          key: "action",
          fn: function ({ attrs }) {
            return [
              _c(
                "v-btn",
                _vm._b(
                  {
                    attrs: { color: "white", text: "" },
                    on: {
                      click: function ($event) {
                        _vm.errorSnackbar = false
                      },
                    },
                  },
                  "v-btn",
                  attrs,
                  false
                ),
                [_vm._v(_vm._s(_vm.$t("close")))]
              ),
            ]
          },
        },
      ]),
      model: {
        value: _vm.errorSnackbar,
        callback: function ($$v) {
          _vm.errorSnackbar = $$v
        },
        expression: "errorSnackbar",
      },
    },
    [
      _vm._l(
        _vm.errorMessage.message ? _vm.errorMessage.message : _vm.errorMessage,
        function (error) {
          return [
            _c(
              "div",
              [
                _c("v-icon", { attrs: { small: "" } }, [
                  _vm._v("mdi-alert-circle"),
                ]),
                _vm._v(" "),
                _c("span", [_vm._v(_vm._s(error))]),
              ],
              1
            ),
          ]
        }
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }