
import { Component, Mixins, Watch } from 'vue-property-decorator'
import TableFiltersMixin from '~/mixins/local-storage-filters'
import { AccountsStore } from '~/store';

@Component({})
export default class AdminAccountsTable extends Mixins(TableFiltersMixin) {
  accountsData: any = [];
  progressLoading: boolean = false;
  page: number = 1;
  perPage: number = 100;
  options: any = {};
  filters: any = {};
  loaded: boolean = false;
  showActive: any = 1;
  isMobile: boolean = false;
  holderFilters: any = {};

  mounted() {
    setTimeout(() => {
      if(this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.md) {
        this.isMobile = true;
      }
    }, 500)
  }

  @Watch('$vuetify.breakpoint.width')
  checkBreakpoint() {
    if(this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.md) {
      this.isMobile = true;
    } else if(this.$vuetify.breakpoint.lg) {
      this.isMobile = false;
    }
  }

  @Watch('holderFilters')
  checkFilters(val:any) {
    if(!val.hasOwnProperty('ordering')) {
      val.ordering = "username";
    }

    this.filters = { ...val };
    this.$emit('filters', this.filters);
  }

  tableFilters(item:any = null) {
    this.page = 1;
    this.filters = { ...this.filters, ...item };

    this.preGetData();
  }

  refreshTable(item:any) {
    this.page = item.page;
    this.perPage = item.perPage;
    this.options = item.options;
    this.loaded = true;

    this.preGetData();
  }

  // Fetch and save filters to local storage
  async preGetData() {
    await this.getLocalFilters(this, 'HH_admin_view_accounts');

    await this.getData();

    const params = {
      page: this.page,
      perPage: this.perPage,
      options: this.options,
      filters: { ...this.filters }
    }

    await this.saveLocalFilters(params, 'HH_admin_view_accounts');
  }

  async getData() {
    this.progressLoading = true;

    const filters: { [key: string]: any } = this.filters;
    let { showActive, ...newFilters } = filters;

    const params = {
      offset: this.page === 1 ? 0 : (this.page - 1) * this.perPage,
      limit: this.perPage,
      status_list: this.filters.showActive == 1 ? JSON.stringify(["active", "cancel_on_period_end"]) : [],
      ...newFilters,
      user: this.$route.params.id,
    }

    const item = { params: params, type: 'management' };
    await AccountsStore.fetchAccountList(item)
    .then((response:any) => {
      this.accountsData = response;
      this.$emit('get-count', this.accountsData?.count || null);
    }).catch((error:any) => {
      this.$emit('error', { error: error, default:  this.$t('errorFetchingData')})
    })
    this.progressLoading = false;
  }
}
