var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { attrs: { id: "star-testimonial" } }, [
    _c(
      "div",
      { staticClass: "custom-content" },
      [
        _c(
          "v-card",
          { staticClass: "custom-card", attrs: { elevation: "0" } },
          [
            _vm.testimonial
              ? _c(
                  "v-card-text",
                  { staticClass: "position-relative text-center py-10" },
                  [
                    _c(
                      "v-avatar",
                      { attrs: { size: "85" } },
                      [
                        _c("v-img", {
                          attrs: {
                            src: _vm.getImage(
                              _vm.testimonial.image + ".webp",
                              _vm.testimonial.image + ".svg"
                            ),
                            width: "85",
                            height: "85",
                            alt: "Star Testimonial Icon",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "h1",
                      { staticClass: "mt-3 mb-2 h_title_4 text-deep-purple" },
                      [_vm._v(_vm._s(_vm.testimonial.name))]
                    ),
                    _vm._v(" "),
                    _c("label", { staticClass: "text-16 text-grey" }, [
                      _vm._v(_vm._s(_vm.testimonial.role)),
                    ]),
                    _vm._v(" "),
                    _c("p", { staticClass: "ff-bold mb-7 mt-5 comment" }, [
                      _vm._v('"' + _vm._s(_vm.testimonial.text) + '"'),
                    ]),
                    _vm._v(" "),
                    _c("img", {
                      staticClass: "stars",
                      attrs: {
                        src: require("assets/images/stars.svg"),
                        alt: "Starts Icon",
                        width: "180",
                        height: "100%",
                        loading: "lazy",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }