var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "d-flex align-center",
      class: { "custom-margin-top": _vm.isCustomTop, "w-100": _vm.fullWidth },
    },
    [
      _c("v-autocomplete", {
        staticClass: "outlined",
        attrs: {
          placeholder: _vm.$t("sortBy"),
          outlined: "",
          dense: _vm.isMobile ? false : true,
          items: _vm.sortList,
          "item-text": "text",
          "item-value": "id",
          "hide-details": "",
          clearable: "",
        },
        on: { change: _vm.manualSortData },
        model: {
          value: _vm.sortData.value,
          callback: function ($$v) {
            _vm.$set(_vm.sortData, "value", $$v)
          },
          expression: "sortData.value",
        },
      }),
      _vm._v(" "),
      _c(
        "v-btn",
        {
          staticClass: "ml-2 px-3 sort-btn btn-brand",
          attrs: { depressed: "" },
          on: { click: _vm.manualSortDataOrder },
        },
        [
          _vm.sortData.asc
            ? _c("v-icon", { attrs: { size: "28" } }, [
                _vm._v("mdi-sort-ascending"),
              ])
            : _c("v-icon", { attrs: { size: "28" } }, [
                _vm._v("mdi-sort-descending"),
              ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }