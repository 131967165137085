import { $axios } from '@/utils/Api'
import Store from '@/utils/StoreInstance'
import { Module, VuexModule, Action, Mutation } from 'vuex-module-decorators'

@Module({stateFactory: true, store: Store, name: 'proxy-api', namespaced: true})
export default class ProxyAPIModule extends VuexModule {
  subscription: any = {};
  noSubscriptionUsers: any = [];
  disabledMenu: any = { proxy_api_subscription: false, proxy_api_reporting: false };
  proxyPricing: any = null;
  subscriptionList: any = {};
  userSubscription: any = null;
  sessions: any = [];

  // Subscription
  @Mutation
  resetSubscription() {
    this.subscription = {};
  }
  @Mutation
  setSubscription(item: any) {
    this.subscription = item;
  }
  @Action({commit: 'setSubscription', rawError: true})
  async fetchSubscription() {
    const { data: results } = await $axios.get('/proxyapi/subscription/');
    return results;
  }
  get getSubscription():any {
    return this.subscription;
  }

  @Mutation
  setNoSubscriptionUsers(item: any) {
    this.noSubscriptionUsers = item;
  }
  @Action({commit: 'setNoSubscriptionUsers', rawError: true})
  async fetchNoSubscriptionUsers() {
    const { data: results } = await $axios.get('/proxyapi/subscriptions/users/');
    const data = results.filter((item:any) => item.subscription_id == null);
    return data;
  }
  get getNoSubscriptionUsers():any {
    return this.noSubscriptionUsers;
  }

  // Set Disabled Menu
  @Mutation
  setDisabledMenu(item: any) {
    this.disabledMenu[item.key] = item.value; 
  }
  get getDisabledMenu():any {
    return this.disabledMenu;
  }

  // Get Proxy Pricing
  @Mutation
  setProxyPricing(item: any) {
    this.proxyPricing = item;
  }
  @Action({commit: 'setProxyPricing', rawError: true})
  async fetchProxyPricing() {
    const { data: results } = await $axios.get('/proxyapi/pricing/');
    return results;
  }
  get getProxyPricing():any {
    return this.proxyPricing;
  }

  // Get Subscriptions - for admin list
  @Mutation
  setSubscriptions(item: any) {
    this.subscriptionList = item;
  }
  @Action({commit: 'setSubscriptions', rawError: true})
  async fetchSubscriptions(params:any) {
    const { data: results } = await $axios.get('/proxyapi/subscriptions/', { params: params });
    return results;
  }
  get getSubscriptions():any {
    return this.subscriptionList;
  }

  // Get Subscriptions - for admin list
  @Mutation
  setSubscriptionPerUser(item: any) {
    this.userSubscription = item;
  }
  @Action({commit: 'setSubscriptionPerUser', rawError: true})
  async fetchSubscriptionPerUser(id:any) {
    const { data: results } = await $axios.get(`/proxyapi/subscriptions/${id}/`);
    return results;
  }
  get getSubscriptionPerUser():any {
    return this.userSubscription;
  }


  // Get Proxy API Sessions
  @Mutation
  setSessions(item: any) {
    this.sessions = item;
  }
  @Action({commit: 'setSessions', rawError: true})
  async fetchSessions(params:any) {
    const { data: results } = await $axios.get('/proxyapi/sessions/', { params: params });
    return results;
  }
  get getSessions():any {
    return this.sessions;
  }
}
