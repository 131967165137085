var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "dynamic-pricing" }, [
    _c(
      "div",
      { staticClass: "pricing-list" },
      [
        _c(
          "v-row",
          [
            _vm._l(_vm.pricing, function (price, index) {
              return [
                _c(
                  "v-col",
                  {
                    staticClass: "amount-container",
                    attrs: { cols: "12", md: "4" },
                  },
                  [
                    _c(
                      "v-card",
                      {
                        class: {
                          "mx-5": index > 0 && index < _vm.pricing.length - 1,
                          "ml-7": index == _vm.pricing.length - 1,
                        },
                        attrs: { elevation: "0" },
                      },
                      [
                        _c(
                          "v-card-text",
                          { staticClass: "text-left px-7 py-5" },
                          [
                            _c("h3", { staticClass: "mb-3" }, [
                              _vm._v(
                                "$" + _vm._s(_vm.convertPrice(price.price))
                              ),
                            ]),
                            _vm._v(" "),
                            _c(
                              "h4",
                              {
                                staticClass:
                                  "text-16 font-weight-regular text-dark-grey",
                              },
                              [_vm._v(_vm._s(_vm.$t("perProxySession")))]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "mt-8" },
                              [
                                _c("v-icon", { attrs: { color: "success" } }, [
                                  _vm._v("mdi-check"),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "label",
                                  {
                                    staticClass:
                                      "text-16 font-weight-medium text-black ml-3",
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t("durationUpTo", {
                                          duration: _vm.convertTime(
                                            price.duration_seconds
                                          ),
                                        })
                                      )
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "mt-3" },
                              [
                                _c("v-icon", { attrs: { color: "success" } }, [
                                  _vm._v("mdi-check"),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "label",
                                  {
                                    staticClass:
                                      "text-16 font-weight-medium text-black ml-3",
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t("lessThan", {
                                          data: _vm.convertBytes(
                                            price.data_limit
                                          ),
                                        })
                                      )
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    index < _vm.pricing.length - 1
                      ? _c("img", {
                          staticClass: "arrow-left",
                          attrs: {
                            src: require("assets/images/arrow-left.svg"),
                            alt: "Arrow Left",
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
              ]
            }),
          ],
          2
        ),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "p",
      { staticClass: "text-16 font-weight-medium text-dark-grey text-center" },
      [_vm._v(_vm._s(_vm.$t("usageExceeds")))]
    ),
    _vm._v(" "),
    _c(
      "p",
      { staticClass: "text-16 font-weight-medium text-black text-center" },
      [
        _c("span", { staticClass: "text-purple-2" }, [_vm._v("$0.10")]),
        _vm._v(" " + _vm._s(_vm.$t("additionalGB"))),
      ]
    ),
    _vm._v(" "),
    _c(
      "p",
      { staticClass: "text-16 font-weight-medium text-black text-center" },
      [
        _c("span", { staticClass: "text-purple-2" }, [_vm._v("$0.10")]),
        _vm._v(" " + _vm._s(_vm.$t("additionalHour"))),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }