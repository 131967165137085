var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "custom-view-accounts" },
    [
      !_vm.isMobile
        ? [
            _c("instagram-accounts-table", {
              attrs: {
                data: _vm.accountsData,
                loading: _vm.progressLoading,
                "current-page": _vm.page,
                "current-per-page": _vm.perPage,
                loaded: _vm.loaded,
              },
              on: {
                refresh: function ($event) {
                  return _vm.refreshTable($event)
                },
              },
            }),
          ]
        : [
            _c("v-progress-linear", {
              staticClass: "mb-1",
              attrs: {
                indeterminate: _vm.progressLoading,
                active: _vm.progressLoading,
                color: "btn-brand",
              },
            }),
            _vm._v(" "),
            _c("instagram-accounts-card-list", {
              attrs: {
                data: _vm.accountsData,
                loading: _vm.progressLoading,
                "current-page": _vm.page,
                "current-per-page": _vm.perPage,
                loaded: _vm.loaded,
                "no-add-btn": true,
              },
              on: {
                refresh: function ($event) {
                  return _vm.refreshTable($event)
                },
              },
            }),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }