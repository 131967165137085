
import { Component, Vue } from 'vue-property-decorator'
import { getImage } from "~/utils/ImageUtils"

@Component({})
export default class FollowersCompute extends Vue {
  followersCount: number = 0;
  followersCountLabel: any = ['500', '750', '1K', '2.5K', '5K', '10K'];
  followersCountAmount: any = [500, 750, 1000, 2500, 5000, 10000];
  estimateAmount: any = 0;
  customCount: any = null;

  mounted() {
    this.calculateAmount();
  }

  calculateAmount() {
    this.estimateAmount = this.followersCountAmount[this.followersCount] * 0.15;
  }

  calculateCustomAmount() {
    if(this.customCount) {
      this.followersCount = 0;
      this.estimateAmount = this.customCount * 0.15;
    } else {
      this.followersCount = 3;
      this.calculateAmount();
    }
  }

  getStartedRoute() {
    if(this.$auth.loggedIn) {
      return '/dashboard/home';
    }
    return '/account/register';
  }
}
