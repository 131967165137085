
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { ProxyAPIStore } from '~/store'
import { getErrorMessage } from '~/utils/ErrorMessageUtils'

@Component({})
export default class MobileProxyApiSubscriptionPricing extends Vue {
  @Prop({ default: false }) readonly isAdmin!: boolean
  @Prop({ default: () => {} }) readonly subscription!: any

  selectedPlan: string = "dynamic";
  active: any = { dynamic: true, flatrate: false }
  progressLoading: boolean = false;
  loading: boolean = false;
  pricing: any = { flat: [], dynamic: []};
  cancelLink: any = "/dashboard/mobile-proxy-api/subscription";

  mounted() {
    this.getPricing();
    this.checkActive();
  }

  @Watch('subscription')
  getSubscription(val:any) {
    this.checkActive();
  }

  checkActive() {
    if(this.subscription && this.subscription.id) {
      if(this.subscription.pricing_mode == 'dynamic') {
        this.active = { dynamic: true, flatrate: false };
      } else {
        this.active = { dynamic: false, flatrate: true };
      }
    }
    if(this.isAdmin) {
      this.cancelLink = "dashboard/mobile-proxy-api/subscription/";
    }
  }

  cancelClicked() {
    if(this.isAdmin) {
      this.$emit("cancel");
    } else {
      this.$router.push(this.localePath("/dashboard/mobile-proxy-api/subscription"));
    }
  }

  async getPricing() {
    this.progressLoading = true;
    await ProxyAPIStore.fetchProxyPricing()
    .then((response:any) => {
      this.pricing = response;
    }).catch((error:any) => {
      const message = getErrorMessage(error, this.$t('errorFetchingData'));
      const snackbar = this.$refs.error as any;
      if(snackbar) { snackbar.show(message); }
    })
    this.progressLoading = false;
  }

  selectPlan(item:any) {
    if(item == 'dynamic') {
      this.active = { dynamic: true, flatrate: false };
    } else {
      this.active = { dynamic: false, flatrate: true };
    }
  }

  subscribe(userId: any = null) {
    if(this.isAdmin && this.$route.params.id == 'new') {
      this.subscription.user = { id: userId };
    }

    const modal = this.$refs.flatratePricing as any;
    if(this.active.flatrate) {
      modal.show();
    } else {
      this.saveSubscription();
    }
  }

  async saveSubscription(id:any = null) {
    this.loading = true;
    this.$emit('load', true);

    if(this.$route.params.id == 'new') {
      let data = { ports: 1, pricing_mode: this.active.flatrate ? 'flat' : 'dynamic' } as any;
      if(this.active.flatrate && id) {
        data.flat_pricing = id;
      }
      if(this.isAdmin) {
        data.user = this.subscription?.user.id
      }

      await this.$axios.post('/proxyapi/subscription/', data)
      .then((response:any) => {
        const snackbar = this.$refs.success as any;
        snackbar.show(this.$t('successSubscribed'));
        
        setTimeout(() => {
          if(this.isAdmin) {
            this.$router.push(this.localePath(`/dashboard/mobile-proxy-api/subscription/${response?.data?.id}/account`));
          } else {
            this.$router.push(this.localePath('/dashboard/mobile-proxy-api/subscription'));
          }
        }, 1000)
      }).catch((error:any) => {
        const message = getErrorMessage(error, this.$t('errorSaveData'));
        const snackbar = this.$refs.error as any;
        if(snackbar) { snackbar.show(message); }
      })
    } else {
      let data = { pricing_mode: this.active.flatrate ? 'flat' : 'dynamic' } as any;
      if(this.isAdmin) {
        data.user = this.subscription?.user.id
      }
      if(this.active.flatrate && id) {
        data.flat_pricing = id;
      }

      await this.$axios.patch(`/proxyapi/subscription/`, data)
      .then(() => {
        const snackbar = this.$refs.success as any;
        snackbar.show( this.$t('successUpdatePricing'));
        
        setTimeout(() => {
          if(this.isAdmin) {
            this.$emit("updated");
          } else {
            this.$router.push(this.localePath('/dashboard/mobile-proxy-api/subscription'));
          }
        }, 1000)
      }).catch((error:any) => {
        const message = getErrorMessage(error, this.$t('errorUpdateData'));
        const snackbar = this.$refs.error as any;
        if(snackbar) { snackbar.show(message); }
      })
    }
    
    this.loading = false;
    this.$emit('load', false);
  }
}
