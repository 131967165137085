
import { Component, Vue } from 'vue-property-decorator'

@Component({})
export default class SettingsAddManualTransaction extends Vue {
  modal: boolean = false;

  show() {
    this.modal = true;
  }

  leave() {
    this.modal = false;
  }

  success() {
    this.leave();
    this.$emit('success');
  }
}
