var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-dialog",
    {
      attrs: { persistent: "", "max-width": "600" },
      model: {
        value: _vm.modal,
        callback: function ($$v) {
          _vm.modal = $$v
        },
        expression: "modal",
      },
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-card-text",
            { staticClass: "pa-7 pt-5" },
            [
              _c(
                "div",
                { staticClass: "d-flex align-center" },
                [
                  _c(
                    "h2",
                    { staticClass: "h_title_3 font-weight-bold text-black" },
                    [_vm._v(_vm._s(_vm.$t("userDiscounts")))]
                  ),
                  _vm._v(" "),
                  _c("v-divider", {
                    staticClass: "mx-3",
                    attrs: { vertical: "" },
                  }),
                  _vm._v(" "),
                  _c(
                    "v-icon",
                    { staticClass: "custom-icon-small", attrs: { small: "" } },
                    [_vm._v("mdi-account")]
                  ),
                  _vm._v(" "),
                  _c("label", { staticClass: "text-brand text-14 ml-1" }, [
                    _vm._v(_vm._s(_vm.user.name)),
                  ]),
                  _vm._v(" "),
                  _c(
                    "v-icon",
                    {
                      staticClass: "ml-auto",
                      attrs: { disabled: _vm.saving },
                      on: { click: _vm.leave },
                    },
                    [_vm._v("mdi-close")]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("v-divider", { staticClass: "mt-3" }),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "mt-7" },
                [
                  _c(
                    "v-form",
                    {
                      ref: "formTopup",
                      attrs: { onSubmit: "return false;" },
                      on: { submit: _vm.saveTopupBonus },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "d-flex mb-4" },
                        [
                          _c("h2", { staticClass: "text-brand" }, [
                            _vm._v(_vm._s(_vm.$t("topupBonus"))),
                          ]),
                          _vm._v(" "),
                          _c(
                            "v-btn",
                            {
                              staticClass: "btn-brand ml-auto",
                              attrs: {
                                depressed: "",
                                small: "",
                                type: "submit",
                                disabled:
                                  _vm.saving ||
                                  !_vm.topupBonus.threshold ||
                                  !_vm.topupBonus.percentage,
                                loading: _vm.loading.topup,
                              },
                            },
                            [_vm._v(_vm._s(_vm.$t("save")))]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("v-progress-linear", {
                        staticClass: "mb-2",
                        attrs: {
                          indeterminate: _vm.progressTopupLoading,
                          active: _vm.progressTopupLoading,
                          color: "btn-brand",
                        },
                      }),
                      _vm._v(" "),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            [
                              _c(
                                "label",
                                {
                                  staticClass:
                                    "text-16 font-weight-medium text-black",
                                },
                                [_vm._v(_vm._s(_vm.$t("threshold")))]
                              ),
                              _vm._v(" "),
                              _c("v-text-field", {
                                staticClass: "mt-1 mb-5",
                                attrs: {
                                  outlined: "",
                                  placeholder: _vm.$t("enterThreshold"),
                                  "hide-details": "auto",
                                  type: "number",
                                  "error-messages":
                                    _vm.fieldErrorMessageTopup.threshold,
                                },
                                model: {
                                  value: _vm.topupBonus.threshold,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.topupBonus, "threshold", $$v)
                                  },
                                  expression: "topupBonus.threshold",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-col",
                            [
                              _c(
                                "label",
                                {
                                  staticClass:
                                    "text-16 font-weight-medium text-black",
                                },
                                [_vm._v(_vm._s(_vm.$t("percentage")))]
                              ),
                              _vm._v(" "),
                              _c("v-text-field", {
                                staticClass: "mt-1 mb-5",
                                attrs: {
                                  outlined: "",
                                  placeholder: _vm.$t("enterPercentage"),
                                  "hide-details": "auto",
                                  type: "number",
                                  "append-icon": "mdi-percent",
                                  "error-messages":
                                    _vm.fieldErrorMessageTopup.percentage,
                                },
                                model: {
                                  value: _vm.topupBonus.percentage,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.topupBonus, "percentage", $$v)
                                  },
                                  expression: "topupBonus.percentage",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _vm.user.has_topup_bonus
                            ? _c(
                                "v-col",
                                {
                                  staticClass: "text-center",
                                  attrs: { cols: "2" },
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "mt-8" },
                                    [
                                      _c("ActionButton", {
                                        attrs: {
                                          disabled: _vm.progressTopupLoading,
                                          data: {
                                            icon: "mdi-delete-outline",
                                            icon_color: "error--text",
                                            label: _vm.$t("delete"),
                                          },
                                        },
                                        on: {
                                          clicked: function ($event) {
                                            return _vm.$emit("delete", {
                                              type: "topup",
                                              id: _vm.user.id,
                                            })
                                          },
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("v-divider", { staticClass: "mt-3 mb-6" }),
                  _vm._v(" "),
                  _c(
                    "v-form",
                    {
                      ref: "formTurnover",
                      attrs: { onSubmit: "return false;" },
                      on: { submit: _vm.saveTurnoverBonus },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "d-flex mb-4" },
                        [
                          _c("h2", { staticClass: "text-brand" }, [
                            _vm._v(_vm._s(_vm.$t("turnoverBonus"))),
                          ]),
                          _vm._v(" "),
                          _vm.isAddTurnover
                            ? [
                                _vm.user.has_turnover_bonuses
                                  ? _c(
                                      "v-btn",
                                      {
                                        staticClass: "text-brand ml-auto",
                                        attrs: {
                                          depressed: "",
                                          small: "",
                                          disabled: _vm.saving,
                                        },
                                        on: {
                                          click: function ($event) {
                                            _vm.isAddTurnover = false
                                          },
                                        },
                                      },
                                      [_vm._v(_vm._s(_vm.$t("cancel")))]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _c(
                                  "v-btn",
                                  {
                                    staticClass: "btn-brand",
                                    class: _vm.user.has_turnover_bonuses
                                      ? "ml-2"
                                      : "ml-auto",
                                    attrs: {
                                      depressed: "",
                                      small: "",
                                      type: "submit",
                                      disabled:
                                        _vm.saving ||
                                        !_vm.turnoverBonus.threshold ||
                                        !_vm.turnoverBonus.percentage,
                                      loading: _vm.loading.turnover,
                                    },
                                  },
                                  [_vm._v(_vm._s(_vm.$t("save")))]
                                ),
                              ]
                            : [
                                _c(
                                  "v-btn",
                                  {
                                    staticClass: "btn-brand ml-auto",
                                    attrs: {
                                      depressed: "",
                                      small: "",
                                      disabled: _vm.saving || _vm.isEditing,
                                    },
                                    on: {
                                      click: function ($event) {
                                        _vm.isAddTurnover = true
                                      },
                                    },
                                  },
                                  [_vm._v(_vm._s(_vm.$t("add")))]
                                ),
                              ],
                        ],
                        2
                      ),
                      _vm._v(" "),
                      _vm.isAddTurnover
                        ? _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                [
                                  _c(
                                    "label",
                                    {
                                      staticClass:
                                        "text-16 font-weight-medium text-black",
                                    },
                                    [_vm._v(_vm._s(_vm.$t("threshold")))]
                                  ),
                                  _vm._v(" "),
                                  _c("v-text-field", {
                                    staticClass: "mt-1 mb-5",
                                    attrs: {
                                      outlined: "",
                                      placeholder: _vm.$t("enterThreshold"),
                                      "hide-details": "auto",
                                      type: "number",
                                      "error-messages":
                                        _vm.fieldErrorMessageTurnover.threshold,
                                    },
                                    model: {
                                      value: _vm.turnoverBonus.threshold,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.turnoverBonus,
                                          "threshold",
                                          $$v
                                        )
                                      },
                                      expression: "turnoverBonus.threshold",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                [
                                  _c(
                                    "label",
                                    {
                                      staticClass:
                                        "text-16 font-weight-medium text-black",
                                    },
                                    [_vm._v(_vm._s(_vm.$t("percentage")))]
                                  ),
                                  _vm._v(" "),
                                  _c("v-text-field", {
                                    staticClass: "mt-1 mb-5",
                                    attrs: {
                                      outlined: "",
                                      placeholder: _vm.$t("enterPercentage"),
                                      "hide-details": "auto",
                                      type: "number",
                                      "append-icon": "mdi-percent",
                                      "error-messages":
                                        _vm.fieldErrorMessageTurnover
                                          .percentage,
                                    },
                                    model: {
                                      value: _vm.turnoverBonus.percentage,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.turnoverBonus,
                                          "percentage",
                                          $$v
                                        )
                                      },
                                      expression: "turnoverBonus.percentage",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("v-progress-linear", {
                    staticClass: "mb-2",
                    attrs: {
                      indeterminate: _vm.progressTurnOverListLoading,
                      active: _vm.progressTurnOverListLoading,
                      color: "btn-brand",
                    },
                  }),
                  _vm._v(" "),
                  _vm.user.has_turnover_bonuses &&
                  _vm.turnoverBonusList.length > 0
                    ? _c("v-simple-table", {
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function () {
                                return [
                                  _c("thead", [
                                    _c("tr", [
                                      _c("th", { staticClass: "text-left" }, [
                                        _vm._v(_vm._s(_vm.$t("threshold"))),
                                      ]),
                                      _vm._v(" "),
                                      _c("th", { staticClass: "text-left" }, [
                                        _vm._v(_vm._s(_vm.$t("percentage"))),
                                      ]),
                                      _vm._v(" "),
                                      _c("th"),
                                    ]),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "tbody",
                                    [
                                      _vm._l(
                                        _vm.turnoverBonusList,
                                        function (item) {
                                          return [
                                            _c("tr", [
                                              _c(
                                                "td",
                                                [
                                                  _vm.editId != item.id
                                                    ? _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "text-14",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              item.threshold
                                                            )
                                                          ),
                                                        ]
                                                      )
                                                    : _c("v-text-field", {
                                                        attrs: {
                                                          outlined: "",
                                                          dense: "",
                                                          type: "number",
                                                          "hide-details": "",
                                                          "background-color":
                                                            "white",
                                                        },
                                                        model: {
                                                          value: item.threshold,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              item,
                                                              "threshold",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "item.threshold",
                                                        },
                                                      }),
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "td",
                                                [
                                                  _vm.editId != item.id
                                                    ? _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "text-14",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              item.percentage
                                                            ) + "%"
                                                          ),
                                                        ]
                                                      )
                                                    : _c("v-text-field", {
                                                        attrs: {
                                                          outlined: "",
                                                          dense: "",
                                                          type: "number",
                                                          "hide-details": "",
                                                          "append-icon":
                                                            "mdi-percent",
                                                          "background-color":
                                                            "white",
                                                        },
                                                        model: {
                                                          value:
                                                            item.percentage,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              item,
                                                              "percentage",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "item.percentage",
                                                        },
                                                      }),
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "td",
                                                {
                                                  staticClass:
                                                    "d-flex align-center justify-center",
                                                },
                                                [
                                                  _vm.editId != item.id
                                                    ? _c("ActionButton", {
                                                        attrs: {
                                                          disabled:
                                                            _vm.isAddTurnover,
                                                          data: {
                                                            icon: "mdi-pencil",
                                                            icon_color:
                                                              "warning--text",
                                                            label:
                                                              _vm.$t("edit"),
                                                          },
                                                        },
                                                        on: {
                                                          clicked: function (
                                                            $event
                                                          ) {
                                                            return _vm.editItem(
                                                              item
                                                            )
                                                          },
                                                        },
                                                      })
                                                    : _vm._e(),
                                                  _vm._v(" "),
                                                  _vm.editId != item.id
                                                    ? _c("ActionButton", {
                                                        attrs: {
                                                          disabled:
                                                            _vm.isAddTurnover,
                                                          data: {
                                                            icon: "mdi-delete-outline",
                                                            icon_color:
                                                              "error--text",
                                                            label:
                                                              _vm.$t("delete"),
                                                          },
                                                        },
                                                        on: {
                                                          clicked: function (
                                                            $event
                                                          ) {
                                                            return _vm.$emit(
                                                              "delete",
                                                              {
                                                                type: "turnover",
                                                                id: item.id,
                                                              }
                                                            )
                                                          },
                                                        },
                                                      })
                                                    : _vm._e(),
                                                  _vm._v(" "),
                                                  _vm.editId == item.id
                                                    ? [
                                                        _vm.editId == item.id &&
                                                        _vm.savingTurnoverEdit
                                                          ? _c(
                                                              "v-progress-circular",
                                                              {
                                                                staticClass:
                                                                  "mt-1 ml-2 mr-1",
                                                                attrs: {
                                                                  size: 15,
                                                                  width: 2,
                                                                  indeterminate:
                                                                    _vm.savingTurnoverEdit,
                                                                  color:
                                                                    "btn-brand",
                                                                },
                                                              }
                                                            )
                                                          : _c("ActionButton", {
                                                              attrs: {
                                                                disabled:
                                                                  !item.percentage ||
                                                                  !item.threshold,
                                                                data: {
                                                                  icon: "mdi-check-circle",
                                                                  icon_color:
                                                                    "success--text",
                                                                  label:
                                                                    _vm.$t(
                                                                      "save"
                                                                    ),
                                                                },
                                                              },
                                                              on: {
                                                                clicked:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.updateTurnover(
                                                                      item
                                                                    )
                                                                  },
                                                              },
                                                            }),
                                                        _vm._v(" "),
                                                        _c("ActionButton", {
                                                          attrs: {
                                                            disabled:
                                                              _vm.savingTurnoverEdit,
                                                            data: {
                                                              icon: "mdi-close",
                                                              icon_color:
                                                                "error--text",
                                                              label:
                                                                _vm.$t(
                                                                  "cancel"
                                                                ),
                                                            },
                                                          },
                                                          on: {
                                                            clicked: function (
                                                              $event
                                                            ) {
                                                              _vm.editId = null
                                                              _vm.isEditing = false
                                                              item.threshold =
                                                                _vm.holderValue.threshold
                                                              item.percentage =
                                                                _vm.holderValue.percentage
                                                            },
                                                          },
                                                        }),
                                                      ]
                                                    : _vm._e(),
                                                ],
                                                2
                                              ),
                                            ]),
                                          ]
                                        }
                                      ),
                                    ],
                                    2
                                  ),
                                ]
                              },
                              proxy: true,
                            },
                          ],
                          null,
                          false,
                          1536713913
                        ),
                      })
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _vm.success
                ? _c("alert-success", {
                    staticClass: "mt-5",
                    attrs: { "success-message": _vm.$t("successSave") },
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.hasError
                ? _c("alert-error", {
                    staticClass: "mt-5",
                    attrs: { "error-message": _vm.errorMessage },
                  })
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }