
import { Component, Vue, Watch, Prop } from 'vue-property-decorator'
import { requiredRule } from '~/utils/FormValidation'
import { getErrorMessage } from "~/utils/ErrorMessageUtils"
import { AccountsStore } from '~/store'

@Component({
  head(this: SettingsAccountView): object { return { title: this.$t('settingsAccountPageTitle') } },
})
export default class SettingsAccountView extends Vue {
  @Prop({ default: 'instabot' }) readonly urlType!: any

  isEdit: boolean = false;
  currentUser: any = { name: "", email: "", modified: "" };
  editUser: any = { first_name: null, last_name: null };
  loading: boolean = false;
  hasError: boolean = false;
  success: boolean = false;
  errorMessage: any = "";
  passwordRule: any = [ requiredRule(this.$t('passRequired') as any) ];
  profilePic: any = "";
  showOldPassword: boolean = false;
  showPassword: boolean = false;
  showConfirmPassword: boolean = false;
  isChangePass: boolean = false;
  fieldErrorMessage: any = {};
  userLoading: boolean = false;

  mounted() {
    this.getCurrentUser();

    const user = this.$auth?.user as any;
    this.profilePic = user?.profile_pic || null;
  }

  @Watch('$auth.user')
  getUser() {
    this.getCurrentUser();
  }

  async getCurrentUser() {
    const id = this.urlType == "management" ? this.$route.params.id : (this.$auth?.user?.id || null);
    const user = await this.getUserByAdmin(id) as any;

    if(user) {
      this.currentUser = {
        name: (user?.first_name || "") + " " + (user?.last_name || ""),
        email: user.email,
        modified: user.modified
      }
      this.editUser = {
        id: user.id,
        first_name: user.first_name,
        last_name: user.last_name,
        email: user.email
      };
    }
  }

  async getUserByAdmin(id:any) {
    let data = null;
    this.userLoading = true;

    await AccountsStore.fetchUserByAdmin(id)
    .then((response:any) => {
      data = response;
    }).catch((error:any) => {
      const message = getErrorMessage(error, this.$t('errorFetchingData'));
      const snackbar = this.$refs.error as any;
      if(snackbar) { snackbar.show(message); }
    })

    this.userLoading = false;
    return data;
  }

  editForm() {
    this.fieldErrorMessage = {};
    this.isChangePass = false;
  }

  async save() {
    this.fieldErrorMessage = {};
    this.hasError = false;
    const form = this.$refs.editForm as any;

    if(form.validate()) {
      this.loading = true;
      let api = `/auth/accounts/${this.editUser.id}/`; 
      let params = {
        first_name: this.editUser.first_name,
        last_name: this.editUser.last_name,
      } as any;

      if(this.urlType == "management") {
        api = `/management/users/${this.editUser.id}/update_user/`;
        if(this.isChangePass) {
          params.password = this.editUser.password;
          params.confirm_password = this.editUser.confirm_password;
        }
      }

      await this.$axios.patch(api, params)
      .then(async (response:any) => {
        if(this.urlType == "instabot" && this.isChangePass) {
          await this.changePassword()
        }

        if(!this.fieldErrorMessage.password_error) {
          if(this.urlType == "instabot") {
            const user = {...response.data, is_admin: this.$auth?.user?.is_admin };
            await this.$auth.setUser(user);
          } else {
            this.currentUser = {
              name: (response.data?.first_name || "") + " " + (response.data?.last_name || ""),
              email: response.data.email,
              modified: response.data.modified
            }
          }
          
          this.isChangePass = false;
          this.success = true;
          this.loading = false;
          setTimeout(() => {
            this.success = false;
          }, 2000)
        }
      }).catch((error) => {
        if (error.response) {
          this.errorMessage = getErrorMessage(error, this.$t('errorSubmitForm'));
          this.fieldErrorMessage = this.errorMessage.message;
          this.hasError = this.errorMessage.show;
          this.fieldErrorMessage.password_error = true;
        }
      })
      this.loading = false;
    }
  }

  async changePassword() {
    await this.$axios.post(`/auth/accounts/change_password/`, {
      old_password: this.editUser.old_password,
      password: this.editUser.password,
      confirm_password: this.editUser.confirm_password,
    }).then(() => {
      this.fieldErrorMessage.password_error = false;
    }).catch((error) => {
      if (error.response) {
        this.errorMessage = getErrorMessage(error, this.$t('errorChangePass'));
        this.fieldErrorMessage = this.errorMessage.message;
        this.hasError = this.errorMessage.show;
        this.fieldErrorMessage.password_error = true;
      }
    })
  }
}
