import Vue from 'vue'
import locale from 'element-ui/lib/locale'
import { DatePicker, Icon } from 'element-ui'
import en from 'element-ui/lib/locale/lang/en'
import 'element-ui/lib/theme-chalk/icon.css';
import 'element-ui/lib/theme-chalk/date-picker.css';
import 'element-ui/lib/theme-chalk/fonts/element-icons.ttf';
import 'element-ui/lib/theme-chalk/fonts/element-icons.woff';

locale.use(en)

Vue.component(DatePicker.name, DatePicker)
Vue.component(Icon.name, Icon)
