var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "v-card",
        { staticClass: "custom-cards" },
        [
          _c(
            "v-card-text",
            { staticClass: "pt-1" },
            [
              _c("v-data-table", {
                staticClass: "elevation-0 accounts-table",
                attrs: {
                  headers: _vm.headers,
                  items: _vm.tableItems,
                  "items-per-page": _vm.perPage,
                  "server-items-length": _vm.totalData,
                  loading: _vm.loading,
                  "hide-default-footer": "",
                },
                scopedSlots: _vm._u([
                  {
                    key: "item.username",
                    fn: function ({ item }) {
                      return [
                        _c("div", { staticClass: "d-flex align-center" }, [
                          item.profile_pic
                            ? _c("div", [
                                _c("div", { staticClass: "img-border" }, [
                                  _c("img", {
                                    staticClass: "img-container",
                                    attrs: {
                                      src:
                                        "data:image/jpeg;base64," +
                                        item.profile_pic,
                                    },
                                  }),
                                ]),
                              ])
                            : _c("div", {}, [
                                _c(
                                  "div",
                                  { staticClass: "img-border default-avatar" },
                                  [
                                    _c(
                                      "v-icon",
                                      {
                                        staticClass: "img-container",
                                        attrs: { color: "white" },
                                      },
                                      [_vm._v("mdi-account")]
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                          _vm._v(" "),
                          _c(
                            "h4",
                            { staticClass: "username ml-2" },
                            [
                              _vm._v(
                                "@" +
                                  _vm._s(item.username) +
                                  " \n              "
                              ),
                              _c(
                                "v-tooltip",
                                {
                                  attrs: {
                                    bottom: "",
                                    "content-class": "custom-tooltip",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function ({ on, attrs }) {
                                          return [
                                            _c(
                                              "v-icon",
                                              _vm._g(
                                                _vm._b(
                                                  {
                                                    attrs: {
                                                      color:
                                                        item.status ==
                                                          "active" ||
                                                        item.status ==
                                                          "cancel_on_period_end"
                                                          ? "success"
                                                          : "",
                                                      small: "",
                                                    },
                                                  },
                                                  "v-icon",
                                                  attrs,
                                                  false
                                                ),
                                                on
                                              ),
                                              [
                                                _vm._v(
                                                  "\n                    mdi-check-decagram\n                  "
                                                ),
                                              ]
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                },
                                [
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    { staticClass: "text-capitalize" },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          item.status
                                            ? item.status.replace(/_/g, " ")
                                            : ""
                                        )
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ]),
                      ]
                    },
                  },
                  {
                    key: "item.subscription",
                    fn: function ({ item }) {
                      return [
                        item.paid_until
                          ? _c(
                              "div",
                              { staticClass: "d-flex align-center" },
                              [
                                item.status == "active"
                                  ? [
                                      _c(
                                        "v-icon",
                                        {
                                          staticClass: "mr-1",
                                          attrs: {
                                            size: "14",
                                            color: "success",
                                          },
                                        },
                                        [_vm._v("mdi-autorenew")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "font-weight-medium mr-1",
                                        },
                                        [_vm._v(_vm._s(_vm.$t("renew")) + ":")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "datetime mb-0 success--text",
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.formatDate(item.paid_until)
                                            )
                                          ),
                                        ]
                                      ),
                                    ]
                                  : item.status == "cancel_on_period_end"
                                  ? [
                                      _c(
                                        "v-icon",
                                        {
                                          staticClass: "mr-1",
                                          attrs: {
                                            size: "14",
                                            color: _vm.checkActiveExpiring(
                                              item.paid_until
                                            )
                                              ? "warning"
                                              : "success",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                mdi-checkbox-marked-circle-outline\n              "
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "font-weight-medium mr-1",
                                        },
                                        [_vm._v(_vm._s(_vm.$t("expire")) + ":")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "span",
                                        {
                                          staticClass: "datetime mb-0",
                                          class: _vm.checkActiveExpiring(
                                            item.paid_until
                                          )
                                            ? "warning--text"
                                            : "success--text",
                                        },
                                        [
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(
                                                _vm.formatDate(item.paid_until)
                                              ) +
                                              "\n              "
                                          ),
                                        ]
                                      ),
                                    ]
                                  : item.status == "unpaid" ||
                                    item.status == "cancelled"
                                  ? [
                                      _c(
                                        "v-icon",
                                        {
                                          staticClass: "mr-1",
                                          attrs: { size: "14", color: "error" },
                                        },
                                        [
                                          _vm._v(
                                            "mdi-alert-circle-check-outline"
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "span",
                                        { staticClass: "font-weight-medium" },
                                        [_vm._v(_vm._s(_vm.$t("inactive")))]
                                      ),
                                    ]
                                  : _vm._e(),
                              ],
                              2
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                  {
                    key: "item.followers",
                    fn: function ({ item }) {
                      return [
                        _c("instagram-accounts-progress-bar", {
                          staticClass: "mb-1",
                          attrs: { account: item },
                        }),
                      ]
                    },
                  },
                  {
                    key: "item.actions",
                    fn: function ({ item }) {
                      return [
                        _c("ActionButton", {
                          attrs: {
                            data: {
                              icon: "mdi-cogs",
                              icon_color: "warning--text text--lighten-1",
                              label: _vm.$t("manage"),
                            },
                          },
                          on: {
                            clicked: function ($event) {
                              _vm.$router.push(
                                _vm.localePath(
                                  `/dashboard/instagram/accounts/${item.id}/view`
                                )
                              )
                            },
                          },
                        }),
                        _vm._v(" "),
                        _c("ActionButton", {
                          attrs: {
                            data: {
                              icon: "mdi-poll",
                              icon_color: "text-brand",
                              label: _vm.$t("viewReport"),
                            },
                          },
                          on: {
                            clicked: function ($event) {
                              _vm.$router.push(
                                _vm.localePath(
                                  `/dashboard/instagram/reporting?account=${item.username}`
                                )
                              )
                            },
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("pagination", {
                attrs: {
                  "total-items": _vm.totalData,
                  page: _vm.page,
                  "per-page": _vm.perPage,
                  "per-page-options": _vm.perPageOptions,
                },
                on: {
                  "page-change": _vm.handlePageChange,
                  "per-page-change": _vm.handlePerPageChange,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }