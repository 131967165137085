
import { Component, Vue, Watch } from 'vue-property-decorator'
import { getErrorMessage } from "~/utils/ErrorMessageUtils"
import { AccountsStore, AdminStore } from '@/store'
import { dateTimeFormat } from '~/utils/DateTimeUtils'

@Component({})
export default class NavigationTopBar extends Vue {
  credits: any = 0;
  user: any = {};
  name: any = "";
  profilePic: any = "";
  showName: boolean = false;
  overlay: boolean = false;
  newCredits: any = [];
  hasCreditsNotif: boolean = false;
  hasCreditsNotifBadge: boolean = false;

  created() {
    this.getRemainingCredits();
  }

  @Watch('$auth.user', { immediate: true })
  checkUser(newUser: any, oldUser: any) {
    if (newUser !== oldUser) {
      this.getUser();
    }
  }

  getUser() {
    const user = this.$auth?.user || null;
    this.profilePic = user?.profile_pic || null;

    if(user) {
      if(!user.first_name && !user.last_name) {
        this.name = user.email;
      } else {
        this.name = (user?.first_name || "") + " " + (user?.last_name || "");
      }
    }
  }

  async getRemainingCredits() {
    try {
      const response = await AccountsStore.fetchCredits();
      this.credits = response?.remaining_credits;
      this.newCredits = response?.new_credits_received;
      if(this.newCredits.length > 0) {
        this.hasCreditsNotif = true;
        this.hasCreditsNotifBadge = true;
      }

      const discounts = { top_up_bonus: response?.top_up_bonus, turnover_bonuses: response?.turnover_bonuses };
      AdminStore.setDiscounts(discounts);
    } catch (error) {
      const message = getErrorMessage(error, this.$t('errorFetchingData'));
      const snackbar = this.$refs.error as any;
      if (snackbar) { snackbar.show(message); }
    }
  }

  showModal(ref:any) {
    if(this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.md) {
      this.$router.push('/dashboard/settings/transactions/add-credits');
    } else {
      const modal = this.$refs[ref] as any;
      if(modal) {
        modal.show();
      }
    }
  }

  async readNotification(val:any) {
    if(val && this.hasCreditsNotifBadge) {
      await this.$axios.post('/auth/accounts/credits/', { notifs_read: true })
      .then(() => {
        setTimeout(() => {
          this.hasCreditsNotifBadge = false;
        }, 1000)
      }).catch((error:any) => {
        const message = getErrorMessage(error, this.$t('errorSaveData'));
        const snackbar = this.$refs.error as any;
        if (snackbar) { snackbar.show(message); }
      })
    }
  }

  formatDateTime(data:any) {
    return dateTimeFormat(this, data);
  }
}
