var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "custom-view-orders" },
    [
      !_vm.isMobile
        ? [
            _c("instagram-orders-table", {
              ref: "ordersTable",
              attrs: {
                data: _vm.allData,
                loading: _vm.progressLoading,
                "current-page": _vm.page,
                "current-per-page": _vm.perPage,
                "is-per-user": true,
              },
              on: {
                refresh: function ($event) {
                  return _vm.refreshTable($event)
                },
                "cancel-order": function ($event) {
                  return _vm.$refs.cancelRefund.show($event)
                },
                edit: function ($event) {
                  return _vm.$refs.editModal.show($event)
                },
              },
            }),
          ]
        : [
            _c("v-progress-linear", {
              staticClass: "mb-1",
              attrs: {
                indeterminate: _vm.progressLoading,
                active: _vm.progressLoading,
                color: "btn-brand",
              },
            }),
            _vm._v(" "),
            _c("standard-card-list", {
              attrs: {
                componentName: "instagram-orders-card-list",
                data: _vm.allData,
                loading: _vm.progressLoading,
                "current-page": _vm.page,
                "current-per-page": _vm.perPage,
                "no-add-btn": true,
              },
              on: {
                refresh: function ($event) {
                  return _vm.refreshTable($event)
                },
                "cancel-event": function ($event) {
                  return _vm.$refs.cancelRefund.show($event)
                },
                "edit-event": function ($event) {
                  return _vm.$refs.editModal.show($event)
                },
              },
            }),
          ],
      _vm._v(" "),
      _c("instagram-orders-modal-cancel-refund", {
        ref: "cancelRefund",
        on: { success: _vm.getData },
      }),
      _vm._v(" "),
      _c("instagram-orders-modal-edit", {
        ref: "editModal",
        on: { success: _vm.getData },
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }