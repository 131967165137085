var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-row",
    { attrs: { align: "center" } },
    [
      _c("v-col", { attrs: { cols: "6" } }, [
        _c("label", { staticClass: "text-capitalize" }, [
          _vm._v(_vm._s(_vm.item.key.replace(/_/g, " "))),
        ]),
      ]),
      _vm._v(" "),
      _c("v-col", { attrs: { cols: "6" } }, [
        _c(
          "div",
          { staticClass: "d-flex align-center" },
          [
            _c("v-text-field", {
              staticClass: "mt-2 value-input",
              class: { "custom-disabled": !_vm.isEditing },
              attrs: {
                dense: "",
                outlined: "",
                label: _vm.type == "proxy" ? _vm.$t("price") : _vm.$t("value"),
                type: "number",
                "hide-details": "",
                "background-color": "white",
                disabled: _vm.editId != _vm.item.id,
              },
              on: { input: function ($event) {} },
              model: {
                value: _vm.item.value,
                callback: function ($$v) {
                  _vm.$set(_vm.item, "value", $$v)
                },
                expression: "item.value",
              },
            }),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "ml-3 mt-2 d-flex" },
              [
                _vm.editId != _vm.item.id
                  ? _c("ActionButton", {
                      attrs: {
                        disabled: _vm.isEditing,
                        data: {
                          icon: "mdi-pencil",
                          icon_color: "warning--text",
                          label: _vm.$t("edit"),
                        },
                      },
                      on: {
                        clicked: function ($event) {
                          return _vm.editItem(_vm.item)
                        },
                      },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.isEditing && _vm.editId == _vm.item.id
                  ? [
                      _vm.editId == _vm.item.id && _vm.saving
                        ? _c("v-progress-circular", {
                            staticClass: "mt-1 ml-2 mr-1",
                            attrs: {
                              size: 15,
                              width: 2,
                              indeterminate: _vm.saving,
                              color: "btn-brand",
                            },
                          })
                        : _c("ActionButton", {
                            attrs: {
                              disabled: _vm.editId != _vm.item.id,
                              data: {
                                icon: "mdi-check-circle",
                                icon_color: "success--text",
                                label: _vm.$t("save"),
                              },
                            },
                            on: {
                              clicked: function ($event) {
                                return _vm.$emit("update", _vm.item)
                              },
                            },
                          }),
                      _vm._v(" "),
                      _vm.editId == _vm.item.id
                        ? _c("ActionButton", {
                            attrs: {
                              disabled: _vm.saving,
                              data: {
                                icon: "mdi-close",
                                icon_color: "error--text",
                                label: _vm.$t("cancel"),
                              },
                            },
                            on: {
                              clicked: function ($event) {
                                _vm.cancelEdit()
                                _vm.item.value = _vm.holderValue
                              },
                            },
                          })
                        : _vm._e(),
                    ]
                  : _vm._e(),
              ],
              2
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }