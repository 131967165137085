var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "mt-5" },
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            [
              _c("h3", { staticClass: "mb-2" }, [
                _vm._v(_vm._s(_vm.$t("createNew"))),
              ]),
              _vm._v(" "),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12", md: "6" } },
                    [
                      _c("v-text-field", {
                        attrs: {
                          dense: "",
                          outlined: "",
                          "hide-details": "auto",
                          "background-color": "white",
                          label: _vm.$t("name"),
                          "error-messages": _vm.fieldErrorMessage.add.name,
                        },
                        model: {
                          value: _vm.categoryName,
                          callback: function ($$v) {
                            _vm.categoryName = $$v
                          },
                          expression: "categoryName",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", md: "6" } },
                    [
                      _c("v-text-field", {
                        attrs: {
                          dense: "",
                          outlined: "",
                          "hide-details": "auto",
                          "background-color": "white",
                          label: _vm.$t("germanTranslation"),
                          "error-messages":
                            _vm.fieldErrorMessage.add.german_name,
                        },
                        model: {
                          value: _vm.categoryGermanName,
                          callback: function ($$v) {
                            _vm.categoryGermanName = $$v
                          },
                          expression: "categoryGermanName",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "text-right mt-3" },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "btn-brand px-7",
                      class: _vm.$vuetify.breakpoint.xs ? "mt-2" : "ml-auto",
                      attrs: {
                        depressed: "",
                        disabled: !_vm.categoryName,
                        loading: _vm.loading,
                      },
                      on: { click: _vm.save },
                    },
                    [_vm._v(_vm._s(_vm.$t("save")))]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _vm.hasError
                ? _c("alert-error", {
                    staticClass: "mt-5 py-2",
                    attrs: { "error-message": _vm.errorMessage },
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.success
                ? _c("alert-success", {
                    staticClass: "mt-5",
                    attrs: { "success-message": _vm.$t("successSave") },
                  })
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("v-divider", { staticClass: "mt-3" }),
      _vm._v(" "),
      _c("v-progress-linear", {
        staticClass: "mb-5",
        attrs: {
          indeterminate: _vm.progressLoading,
          active: _vm.progressLoading,
          color: "btn-brand",
        },
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "category-list mb-3" },
        [
          !_vm.$vuetify.breakpoint.xs
            ? [
                _c("v-simple-table", {
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function () {
                          return [
                            _c("thead", [
                              _c("tr", [
                                _c("th", { staticClass: "text-left" }, [
                                  _vm._v(_vm._s(_vm.$t("name"))),
                                ]),
                                _vm._v(" "),
                                _c("th", { staticClass: "text-left" }, [
                                  _vm._v(_vm._s(_vm.$t("germanTranslation"))),
                                ]),
                                _vm._v(" "),
                                _c("th"),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c(
                              "tbody",
                              [
                                _vm._l(_vm.categories, function (item) {
                                  return [
                                    _c("tr", [
                                      _c(
                                        "td",
                                        [
                                          _vm.editId == item.id
                                            ? _c("v-text-field", {
                                                class: _vm.$vuetify.breakpoint
                                                  .xs
                                                  ? "w-100"
                                                  : "mr-2",
                                                attrs: {
                                                  outlined: "",
                                                  dense: "",
                                                  "background-color": "white",
                                                  "hide-details": "auto",
                                                  placeholder: _vm.$t("name"),
                                                  "error-messages":
                                                    _vm.fieldErrorMessage.edit
                                                      .name,
                                                },
                                                model: {
                                                  value: _vm.editData.name,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.editData,
                                                      "name",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "editData.name",
                                                },
                                              })
                                            : _c(
                                                "label",
                                                { staticClass: "text-14" },
                                                [_vm._v(_vm._s(item.name))]
                                              ),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "td",
                                        [
                                          _vm.editId == item.id
                                            ? _c("v-text-field", {
                                                class: _vm.$vuetify.breakpoint
                                                  .xs
                                                  ? "w-100"
                                                  : "mr-2",
                                                attrs: {
                                                  outlined: "",
                                                  dense: "",
                                                  "background-color": "white",
                                                  "hide-details": "auto",
                                                  placeholder:
                                                    _vm.$t("germanTranslation"),
                                                  "error-messages":
                                                    _vm.fieldErrorMessage.edit
                                                      .german_name,
                                                },
                                                model: {
                                                  value:
                                                    _vm.editData.german_name,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.editData,
                                                      "german_name",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "editData.german_name",
                                                },
                                              })
                                            : _c("div", [
                                                item.german_name
                                                  ? _c(
                                                      "label",
                                                      {
                                                        staticClass: "text-14",
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            item.german_name
                                                          )
                                                        ),
                                                      ]
                                                    )
                                                  : _c("label", [
                                                      _vm._v("---"),
                                                    ]),
                                              ]),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "td",
                                        { staticClass: "text-center" },
                                        [
                                          item
                                            ? [
                                                _vm.isEdit &&
                                                _vm.editId == item.id
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticClass: "d-flex",
                                                        class: _vm.$vuetify
                                                          .breakpoint.xs
                                                          ? "justify-end"
                                                          : "justify-center",
                                                      },
                                                      [
                                                        !_vm.editLoading
                                                          ? _c("ActionButton", {
                                                              attrs: {
                                                                data: {
                                                                  icon: "mdi-check-bold",
                                                                  icon_color:
                                                                    "text-brand",
                                                                  label:
                                                                    _vm.$t(
                                                                      "save"
                                                                    ),
                                                                },
                                                              },
                                                              on: {
                                                                clicked:
                                                                  _vm.editCategory,
                                                              },
                                                            })
                                                          : _vm._e(),
                                                        _vm._v(" "),
                                                        _vm.editLoading
                                                          ? _c(
                                                              "v-progress-circular",
                                                              {
                                                                attrs: {
                                                                  size: 20,
                                                                  width: 2,
                                                                  indeterminate:
                                                                    _vm.editLoading,
                                                                  color:
                                                                    "primary",
                                                                },
                                                              }
                                                            )
                                                          : _vm._e(),
                                                        _vm._v(" "),
                                                        _c("ActionButton", {
                                                          attrs: {
                                                            data: {
                                                              icon: "mdi-close",
                                                              icon_color:
                                                                "error--text",
                                                              label:
                                                                _vm.$t("close"),
                                                            },
                                                            disabled:
                                                              _vm.editLoading,
                                                          },
                                                          on: {
                                                            clicked: function (
                                                              $event
                                                            ) {
                                                              _vm.isEdit = false
                                                              _vm.editId = 0
                                                              _vm.fieldErrorMessage.edit =
                                                                {}
                                                            },
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    )
                                                  : _c(
                                                      "div",
                                                      {
                                                        staticClass: "d-flex",
                                                        class: _vm.$vuetify
                                                          .breakpoint.xs
                                                          ? "justify-end"
                                                          : "justify-center",
                                                      },
                                                      [
                                                        _c("ActionButton", {
                                                          attrs: {
                                                            data: {
                                                              icon: "mdi-pencil",
                                                              icon_color:
                                                                "warning--text",
                                                              label:
                                                                _vm.$t("edit"),
                                                            },
                                                          },
                                                          on: {
                                                            clicked: function (
                                                              $event
                                                            ) {
                                                              return _vm.editItem(
                                                                item
                                                              )
                                                            },
                                                          },
                                                        }),
                                                        _vm._v(" "),
                                                        _c("ActionButton", {
                                                          attrs: {
                                                            data: {
                                                              icon: "mdi-delete-outline",
                                                              icon_color:
                                                                "error--text",
                                                              label:
                                                                _vm.$t(
                                                                  "delete"
                                                                ),
                                                            },
                                                          },
                                                          on: {
                                                            clicked: function (
                                                              $event
                                                            ) {
                                                              return _vm.deleteCategoryModal(
                                                                item.id
                                                              )
                                                            },
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                              ]
                                            : _vm._e(),
                                        ],
                                        2
                                      ),
                                    ]),
                                  ]
                                }),
                              ],
                              2
                            ),
                          ]
                        },
                        proxy: true,
                      },
                    ],
                    null,
                    false,
                    1447456114
                  ),
                }),
              ]
            : [
                _vm._l(_vm.categories, function (item) {
                  return [
                    _c(
                      "v-card",
                      { staticClass: "mb-2", attrs: { outlined: "" } },
                      [
                        _c(
                          "v-card-text",
                          { staticClass: "py-2" },
                          [
                            _c("label", { staticClass: "font-weight-medium" }, [
                              _vm._v(_vm._s(_vm.$t("name"))),
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              [
                                _vm.editId == item.id
                                  ? _c("v-text-field", {
                                      class: _vm.$vuetify.breakpoint.xs
                                        ? "w-100"
                                        : "mr-2",
                                      attrs: {
                                        outlined: "",
                                        dense: "",
                                        "background-color": "white",
                                        "hide-details": "auto",
                                        placeholder: _vm.$t("name"),
                                        "error-messages":
                                          _vm.fieldErrorMessage.edit.name,
                                      },
                                      model: {
                                        value: _vm.editData.name,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.editData, "name", $$v)
                                        },
                                        expression: "editData.name",
                                      },
                                    })
                                  : _c("label", { staticClass: "text-14" }, [
                                      _vm._v(_vm._s(item.name)),
                                    ]),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c("v-divider", { staticClass: "my-2" }),
                            _vm._v(" "),
                            _c("label", { staticClass: "font-weight-medium" }, [
                              _vm._v(_vm._s(_vm.$t("germanTranslation"))),
                            ]),
                            _vm._v(" "),
                            _vm.editId == item.id
                              ? _c("v-text-field", {
                                  class: _vm.$vuetify.breakpoint.xs
                                    ? "w-100"
                                    : "mr-2",
                                  attrs: {
                                    outlined: "",
                                    dense: "",
                                    "background-color": "white",
                                    "hide-details": "auto",
                                    placeholder: _vm.$t("germanTranslation"),
                                    "error-messages":
                                      _vm.fieldErrorMessage.edit.german_name,
                                  },
                                  model: {
                                    value: _vm.editData.german_name,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.editData, "german_name", $$v)
                                    },
                                    expression: "editData.german_name",
                                  },
                                })
                              : _c("div", [
                                  item.german_name
                                    ? _c("label", { staticClass: "text-14" }, [
                                        _vm._v(_vm._s(item.german_name)),
                                      ])
                                    : _c("label", [_vm._v("---")]),
                                ]),
                            _vm._v(" "),
                            item
                              ? _c("div", { staticClass: "text-right mt-2" }, [
                                  _vm.isEdit && _vm.editId == item.id
                                    ? _c(
                                        "div",
                                        {
                                          staticClass: "d-flex",
                                          class: _vm.$vuetify.breakpoint.xs
                                            ? "justify-end"
                                            : "justify-center",
                                        },
                                        [
                                          !_vm.editLoading
                                            ? _c("ActionButton", {
                                                attrs: {
                                                  data: {
                                                    icon: "mdi-check-bold",
                                                    icon_color: "text-brand",
                                                    label: _vm.$t("save"),
                                                  },
                                                },
                                                on: {
                                                  clicked: _vm.editCategory,
                                                },
                                              })
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _vm.editLoading
                                            ? _c("v-progress-circular", {
                                                attrs: {
                                                  size: 20,
                                                  width: 2,
                                                  indeterminate:
                                                    _vm.editLoading,
                                                  color: "primary",
                                                },
                                              })
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _c("ActionButton", {
                                            attrs: {
                                              data: {
                                                icon: "mdi-close",
                                                icon_color: "error--text",
                                                label: _vm.$t("close"),
                                              },
                                              disabled: _vm.editLoading,
                                            },
                                            on: {
                                              clicked: function ($event) {
                                                _vm.isEdit = false
                                                _vm.editId = 0
                                                _vm.fieldErrorMessage.edit = {}
                                              },
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    : _c(
                                        "div",
                                        {
                                          staticClass: "d-flex",
                                          class: _vm.$vuetify.breakpoint.xs
                                            ? "justify-end"
                                            : "justify-center",
                                        },
                                        [
                                          _c("ActionButton", {
                                            attrs: {
                                              data: {
                                                icon: "mdi-pencil",
                                                icon_color: "warning--text",
                                                label: _vm.$t("edit"),
                                              },
                                            },
                                            on: {
                                              clicked: function ($event) {
                                                return _vm.editItem(item)
                                              },
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("ActionButton", {
                                            attrs: {
                                              data: {
                                                icon: "mdi-delete-outline",
                                                icon_color: "error--text",
                                                label: _vm.$t("delete"),
                                              },
                                            },
                                            on: {
                                              clicked: function ($event) {
                                                return _vm.deleteCategoryModal(
                                                  item.id
                                                )
                                              },
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                ])
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                }),
              ],
        ],
        2
      ),
      _vm._v(" "),
      _c("modal-delete", {
        ref: "deleteCategory",
        on: {
          delete: function ($event) {
            return _vm.deleteCategory($event)
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }