
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({})
export default class ModalNotification extends Vue {
  @Prop({ default: '' }) readonly data!: any;
  modal: boolean = false;

  async show() {
    this.modal = true;
  }

  leave(): void {
    this.modal = false;
    this.$emit("leave")
  }
}
