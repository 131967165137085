
import { Component, Vue } from 'vue-property-decorator'

@Component({})
export default class PostPreview extends Vue {
  progressLoading: boolean = false;
  hasPreview: boolean = false;
  hasError: boolean = false;
  errorMessage: any = "";
  preview: any = { image: null, description: null };

  async fetchPost(shortcode:any = "") {
    this.hasPreview = false;
    this.errorMessage = "";
    this.progressLoading = true;

    this.$emit('loading', true);
    const domain = window.location?.origin;

    await this.$axios.get(`${domain}/instagram-api/${shortcode}`, { timeout: 8000 })
    .then(async (response:any) => {
      this.hasPreview = true;
      const data = response?.data?.graphql || null;
      if(data) {
        const edges =  data.shortcode_media?.edge_media_to_caption?.edges;
        this.preview.description = edges.length > 0 ? edges[0].node?.text : '';

        await this.getImage(data.shortcode_media?.display_url);
      }
    }).catch((error:any) => {
      this.hasError = true;
      this.errorMessage = "Failed to load preview";
    })

    this.$emit('loading', false);
    this.progressLoading = false;
  }

  async getImage(imageUrl:any) {
    const encodedUrl = encodeURIComponent(imageUrl);
    const domain = window.location?.origin;

    await this.$axios.get(`${domain}/fetch-instagram-image?url=${encodedUrl}`)
    .then((response:any) => {
      this.preview.image = response.data;
    }).catch(() => {})
  }

  bufferToDataUrl(buffer:any, contentType:any) {
    return `data:${contentType};base64,${buffer.toString('base64')}`;
  }

  removePreview() {
    this.hasError = false;
    this.hasPreview = false;
  }
}
