var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _vm.articles.length > 0
        ? _c(
            "v-row",
            _vm._l(_vm.articles, function (item) {
              return _c(
                "v-col",
                { key: item.id, attrs: { cols: "12", md: "4", sm: "6" } },
                [
                  _c(
                    "v-card",
                    {
                      staticClass: "card-banner custom-cards",
                      attrs: { outlined: "" },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "news-banner",
                          style:
                            "background: " +
                            (item.thumbnail
                              ? `url(${item.thumbnail})`
                              : _vm.getColor(item.title)),
                        },
                        [
                          _vm.$auth && _vm.$auth.user && _vm.$auth.user.is_admin
                            ? _c(
                                "div",
                                {
                                  staticClass: "action-buttons",
                                  class: { "d-block": _vm.isMobile },
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "text-right" },
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          attrs: {
                                            small: "",
                                            depressed: "",
                                            color: "white",
                                          },
                                          on: {
                                            click: function ($event) {
                                              _vm.$router.push(
                                                _vm.localePath(
                                                  `/dashboard/settings/blogs/${item.slug}/edit/`
                                                )
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "v-icon",
                                            {
                                              attrs: {
                                                small: "",
                                                color: "warning",
                                              },
                                            },
                                            [_vm._v("mdi-pencil-outline")]
                                          ),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-btn",
                                        {
                                          attrs: {
                                            small: "",
                                            depressed: "",
                                            color: "white",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.openDeleteModal(
                                                item.id
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "v-icon",
                                            {
                                              attrs: {
                                                small: "",
                                                color: "red",
                                              },
                                            },
                                            [_vm._v("mdi-delete-outline")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          !item.thumbnail
                            ? _c(
                                "v-icon",
                                {
                                  staticClass: "white--text",
                                  attrs: { size: "50" },
                                },
                                [_vm._v("mdi-newspaper-variant-multiple")]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("v-card-text", { staticClass: "pt-2 details" }, [
                        _c("div", { staticClass: "d-flex align-center" }, [
                          _c(
                            "label",
                            {
                              staticClass:
                                "text-brand font-weight-medium category-name text-truncate mr-5",
                            },
                            [
                              _vm._l(
                                item.categories,
                                function (category, index) {
                                  return [
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$i18n.locale == "en"
                                            ? category.name
                                            : category.german_name
                                            ? category.german_name
                                            : category.name
                                        )
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    index < item.categories.length - 1
                                      ? _c("span", { staticClass: "dot" }, [
                                          _vm._v("•"),
                                        ])
                                      : _vm._e(),
                                  ]
                                }
                              ),
                            ],
                            2
                          ),
                          _vm._v(" "),
                          _c(
                            "label",
                            {
                              staticClass:
                                "ml-auto created d-flex align-center mb-0",
                            },
                            [
                              item.publish
                                ? _c(
                                    "v-icon",
                                    { attrs: { small: "", color: "success" } },
                                    [_vm._v("mdi-publish")]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _c(
                                "span",
                                { staticClass: "mt-1 font-weight-medium" },
                                [_vm._v(_vm._s(_vm.formatDate(item.created)))]
                              ),
                            ],
                            1
                          ),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "cursor-pointer mt-1",
                            on: {
                              click: function ($event) {
                                _vm.$router.push(
                                  _vm.localePath(
                                    `/dashboard/settings/blogs/${item.slug}/view/`
                                  )
                                )
                              },
                            },
                          },
                          [
                            _c(
                              "h3",
                              {
                                staticClass:
                                  "text-truncate font-weight-bold text-16",
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.$i18n.locale == "en"
                                      ? item.title
                                      : item.german_title
                                      ? item.german_title
                                      : item.title
                                  )
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "p",
                              { staticClass: "overview mb-0 mt-1 text-14" },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.createOverview(
                                      _vm.$i18n.locale == "en"
                                        ? item.content
                                        : item.german_content
                                        ? item.german_content
                                        : item.content
                                    )
                                  )
                                ),
                              ]
                            ),
                          ]
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              )
            }),
            1
          )
        : !_vm.isFetching
        ? _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "12" } },
                [
                  _c(
                    "v-card",
                    {
                      staticClass: "card-banner custom-cards",
                      attrs: { outlined: "" },
                    },
                    [
                      _c("v-card-text", { staticClass: "text-center" }, [
                        _c("label", [_vm._v(_vm._s(_vm.$t("noBlogsFound")))]),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            [
              _c("pagination", {
                attrs: {
                  "total-items": _vm.totalData,
                  page: _vm.page,
                  "per-page": _vm.perPage,
                  "per-page-options": _vm.perPageOptions,
                },
                on: {
                  "page-change": _vm.handlePageChange,
                  "per-page-change": _vm.handlePerPageChange,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("modal-delete", {
        ref: "deleteArticle",
        on: { delete: _vm.deleteArticle },
      }),
      _vm._v(" "),
      _c("snackbar-error", { ref: "error" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }