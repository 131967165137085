var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "v-row",
        [
          !_vm.noAddBtn
            ? _c(
                "v-col",
                { staticClass: "pb-0", attrs: { cols: "12", md: "3" } },
                [
                  _c(
                    "v-card",
                    {
                      staticClass: "custom-cards bordered",
                      attrs: {
                        height: "100%",
                        ripple: false,
                        to: _vm.localePath(
                          "/dashboard/mobile-proxy-api/subscription/new/account"
                        ),
                      },
                    },
                    [
                      _c(
                        "v-card-text",
                        { staticClass: "d-flex align-center justify-center" },
                        [
                          _c(
                            "div",
                            { staticClass: "text-center" },
                            [
                              _c("v-icon", { staticClass: "text-dark-grey" }, [
                                _vm._v("mdi-plus-circle-outline"),
                              ]),
                              _vm._v(" "),
                              _c(
                                "h3",
                                {
                                  staticClass:
                                    "text-16 font-weight-medium text-dark-grey mt-3",
                                },
                                [_vm._v(_vm._s(_vm.$t("addSubscription")))]
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm._l(_vm.subsItems, function (subsItem) {
            return [
              _c(
                "v-col",
                { staticClass: "pb-0", attrs: { cols: "12", md: "3" } },
                [
                  _c(
                    "v-card",
                    {
                      staticClass: "custom-cards bordered",
                      attrs: { height: "100%" },
                    },
                    [
                      _c("v-card-text", { staticClass: "px-4 pt-3 pb-2" }, [
                        _c("div", { staticClass: "d-flex" }, [
                          _c(
                            "div",
                            { staticClass: "text-center" },
                            [
                              subsItem.pricing_mode
                                ? [
                                    _c(
                                      "v-avatar",
                                      {
                                        attrs: {
                                          size: "50",
                                          color: "btn-brand",
                                        },
                                      },
                                      [
                                        subsItem.pricing_mode == "dynamic"
                                          ? _c(
                                              "v-icon",
                                              {
                                                staticClass: "white--text",
                                                attrs: { size: "30" },
                                              },
                                              [_vm._v("mdi-sync-circle")]
                                            )
                                          : _c(
                                              "v-icon",
                                              {
                                                staticClass: "white--text",
                                                attrs: { size: "30" },
                                              },
                                              [_vm._v("mdi-package")]
                                            ),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "h1",
                                      {
                                        staticClass:
                                          "text-12 mt-1 username text-capitalize",
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            subsItem.pricing_mode == "flat"
                                              ? "Flatrate"
                                              : subsItem.pricing_mode
                                          )
                                        ),
                                      ]
                                    ),
                                  ]
                                : [
                                    _c(
                                      "v-avatar",
                                      {
                                        attrs: { size: "50", color: "#EF5350" },
                                      },
                                      [
                                        _c(
                                          "v-icon",
                                          {
                                            staticClass: "white--text",
                                            attrs: { size: "30" },
                                          },
                                          [_vm._v("mdi-close-circle")]
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "h1",
                                      {
                                        staticClass:
                                          "text-12 mt-1 username text-capitalize",
                                      },
                                      [_vm._v(_vm._s(_vm.$t("inactive")))]
                                    ),
                                  ],
                            ],
                            2
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "ml-3 overview d-flex flex-column w-100",
                            },
                            [
                              subsItem.user.first_name ||
                              subsItem.user.first_name
                                ? _c(
                                    "div",
                                    { staticClass: "font-weight-medium" },
                                    [
                                      subsItem.user.first_name
                                        ? _c("span", [
                                            _vm._v(
                                              _vm._s(subsItem.user.first_name)
                                            ),
                                          ])
                                        : _vm._e(),
                                      _vm._v(" "),
                                      subsItem.user.first_name &&
                                      subsItem.user.last_name
                                        ? _c("span")
                                        : _vm._e(),
                                      _vm._v(" "),
                                      subsItem.user.last_name
                                        ? _c("span", [
                                            _vm._v(
                                              _vm._s(subsItem.user.last_name)
                                            ),
                                          ])
                                        : _vm._e(),
                                    ]
                                  )
                                : _c(
                                    "div",
                                    { staticClass: "font-weight-medium" },
                                    [
                                      _c("span", [
                                        _vm._v(_vm._s(subsItem.user.email)),
                                      ]),
                                    ]
                                  ),
                              _vm._v(" "),
                              subsItem.status == "active"
                                ? [
                                    subsItem.auto_renew
                                      ? _c(
                                          "div",
                                          [
                                            _c(
                                              "v-icon",
                                              {
                                                attrs: {
                                                  size: "14",
                                                  color: "success",
                                                },
                                              },
                                              [_vm._v("mdi-autorenew")]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "font-weight-medium",
                                                staticStyle: {
                                                  "white-space": "nowrap",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(_vm.$t("renew")) + ": "
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "datetime mb-0 success--text",
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.formatDate(
                                                      subsItem.paid_until
                                                    )
                                                  )
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        )
                                      : !subsItem.auto_renew &&
                                        subsItem.pricing_mode == "dynamic"
                                      ? _c(
                                          "div",
                                          [
                                            _c(
                                              "v-icon",
                                              {
                                                attrs: {
                                                  size: "14",
                                                  color: "success",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "mdi-checkbox-marked-circle-outline"
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "font-weight-medium",
                                                staticStyle: {
                                                  "white-space": "nowrap",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(_vm.$t("noExpiry"))
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                  ]
                                : _vm._e(),
                              _vm._v(" "),
                              subsItem.status == "inactive"
                                ? _c(
                                    "div",
                                    [
                                      _c(
                                        "nuxt-link",
                                        {
                                          attrs: {
                                            to: _vm.localePath(
                                              `/dashboard/mobile-proxy-api/subscription/${subsItem.id}/account`
                                            ),
                                          },
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "font-weight-medium text-purple-2 text-12",
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t("restartSubscription")
                                                )
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              subsItem.status == "active"
                                ? _c(
                                    "div",
                                    { staticClass: "text-right mt-auto" },
                                    [
                                      _c(
                                        "nuxt-link",
                                        {
                                          attrs: {
                                            to: _vm.localePath(
                                              `/dashboard/mobile-proxy-api/subscription/${subsItem.id}/account`
                                            ),
                                          },
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "font-weight-medium text-purple-2 text-12",
                                            },
                                            [_vm._v(_vm._s(_vm.$t("manage")))]
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            2
                          ),
                        ]),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          }),
          _vm._v(" "),
          _vm.subsItems.length == 0
            ? [
                _c(
                  "v-col",
                  { staticClass: "pb-0", attrs: { cols: "12", md: "3" } },
                  [
                    _c(
                      "v-card",
                      {
                        staticClass: "custom-cards bordered",
                        attrs: { height: "100%", ripple: false },
                      },
                      [
                        _c(
                          "v-card-text",
                          { staticClass: "d-flex align-center justify-center" },
                          [
                            _c("div", { staticClass: "text-center" }, [
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "text-14 font-weight-regular text-dark-grey",
                                },
                                [_vm._v(_vm._s(_vm.$t("noDataAvail")))]
                              ),
                            ]),
                          ]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            : _vm._e(),
        ],
        2
      ),
      _vm._v(" "),
      _vm.pageCount > 1
        ? _c(
            "div",
            { staticClass: "mt-10" },
            [
              _c("pagination", {
                attrs: {
                  "total-items": _vm.totalData,
                  page: _vm.page,
                  "per-page": _vm.perPage,
                  "per-page-options": _vm.perPageOptions,
                },
                on: {
                  "page-change": _vm.handlePageChange,
                  "per-page-change": _vm.handlePerPageChange,
                },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }