var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "v-card",
        { staticClass: "custom-cards" },
        [
          _c(
            "v-card-text",
            { staticClass: "pt-1" },
            [
              _c("v-data-table", {
                staticClass: "elevation-0",
                attrs: {
                  headers: _vm.headers,
                  items: _vm.tableItems,
                  "items-per-page": _vm.perPage,
                  "server-items-length": _vm.totalData,
                  loading: _vm.loading,
                  options: _vm.options,
                  "sort-desc": true,
                  "sort-by": "started_at",
                  "hide-default-footer": "",
                },
                on: {
                  "update:options": function ($event) {
                    _vm.options = $event
                  },
                },
                scopedSlots: _vm._u([
                  {
                    key: "item.id",
                    fn: function ({ item }) {
                      return [
                        _c("span", [
                          _vm._v(_vm._s(_vm.$t("sessionNum") + item.id)),
                        ]),
                      ]
                    },
                  },
                  {
                    key: "item.traffic_data",
                    fn: function ({ item }) {
                      return [
                        _c("span", [
                          _vm._v(_vm._s(_vm.convertBytes(item.traffic_data))),
                        ]),
                      ]
                    },
                  },
                  {
                    key: "item.started_at",
                    fn: function ({ item }) {
                      return [
                        item.started_at
                          ? _c(
                              "div",
                              { staticClass: "d-flex justify-center" },
                              [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.formatDateTime(
                                        item.started_at,
                                        "date"
                                      )
                                    )
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("v-divider", {
                                  staticClass: "mx-2",
                                  attrs: { vertical: "" },
                                }),
                                _vm._v(" "),
                                _c("span", { staticClass: "text-brand" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.formatDateTime(
                                        item.started_at,
                                        "time"
                                      )
                                    )
                                  ),
                                ]),
                              ],
                              1
                            )
                          : _c("span", [_vm._v("---")]),
                      ]
                    },
                  },
                  {
                    key: "item.ended_at",
                    fn: function ({ item }) {
                      return [
                        item.ended_at
                          ? _c(
                              "div",
                              { staticClass: "d-flex justify-center" },
                              [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.formatDateTime(item.ended_at, "date")
                                    )
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("v-divider", {
                                  staticClass: "mx-2",
                                  attrs: { vertical: "" },
                                }),
                                _vm._v(" "),
                                _c("span", { staticClass: "text-brand" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.formatDateTime(item.ended_at, "time")
                                    )
                                  ),
                                ]),
                              ],
                              1
                            )
                          : _c("span", [_vm._v("---")]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("pagination", {
                attrs: {
                  "total-items": _vm.totalData,
                  page: _vm.page,
                  "per-page": _vm.perPage,
                  "per-page-options": _vm.perPageOptions,
                },
                on: {
                  "page-change": _vm.handlePageChange,
                  "per-page-change": _vm.handlePerPageChange,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("snackbar-error", { ref: "error" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }