var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "text-center nothing-show" },
    [
      _c("v-icon", { attrs: { "x-large": "" } }, [_vm._v("mdi-alert-outline")]),
      _vm._v(" "),
      _c("h2", { staticClass: "h_title_2" }, [
        _vm._v(_vm._s(_vm.$t("nothingToShowHere")) + "."),
      ]),
      _vm._v(" "),
      _vm._t("message", function () {
        return [_c("p", { staticClass: "h_title_3" })]
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }