
import { Component, Vue, Prop } from 'vue-property-decorator'
import { AdminStore } from '~/store'
import { dateFormat } from '~/utils/DateTimeUtils';
import { getErrorMessage } from '~/utils/ErrorMessageUtils';
import { requiredRule } from '~/utils/FormValidation';

@Component({})
export default class SettingsAddManualForm extends Vue {
  @Prop({ default: () => { return true }, required: false }) readonly hasCancel!: Boolean

  modal: boolean = false;
  success: boolean = false;
  hasError: boolean = false;
  errorMessage: any = "";
  loading: boolean = false;
  requiredRule: any = [ requiredRule() ];
  transaction: any = { payment_date: dateFormat(this, this.$dayjs().format('YYYY-MM-DD')) };
  fieldErrorMessage: any = "";
  menuDate: boolean = false;

  cancel() {
    const form = this.$refs.form as any;
    form.reset();
    this.$emit('cancel');
  }

  async save() {
    this.fieldErrorMessage = "";
    const form = this.$refs.form as any;
    if(form.validate()) {
      this.loading = true;
      await this.$axios.post('/management/manual_transaction/', this.transaction)
      .then(() => {
        this.success = true;
        setTimeout(() => {
          this.success = false;
          this.$emit('success');
        }, 1500)
      }).catch((error:any) => {
        this.errorMessage = getErrorMessage(error, this.$t('errorSaveData'))
        this.fieldErrorMessage = this.errorMessage.message;
        this.hasError = this.errorMessage.show;
      })
      this.loading = false;
    }
  }

  get list() {
    return AdminStore.getENUM;
  }
}
