import { $axios } from '@/utils/Api'
import Store from '@/utils/StoreInstance'
import { Module, VuexModule, Action, Mutation } from 'vuex-module-decorators'

@Module({stateFactory: true, store: Store, name: 'transactions', namespaced: true})
export default class TransactionsModule extends VuexModule {
  orders: any = {};
  accounts: any = [];

  // Orders
  @Mutation
  setOrders(item: any) {
    this.orders = item;
  }
  @Action({commit: 'setOrders', rawError: true})
  async fetchOrders(params: any = {}) {
    const { data: results } = await $axios.get('/management/reports/transactions', { params: params });
    return results;
  }
  get getOrders():any {
    return this.orders;
  }

  // Accounts
  @Mutation
  setAccounts(item: any) {
    this.accounts = item;
  }
  @Action({commit: 'setAccounts', rawError: true})
  async fetchAccounts(item: any = {}) {
    const { data: results } = await $axios.get(`/${item.type}/instagram-accounts/${item.id}/transactions/`, { params: item.params });
    return results;
  }
  get getAccounts():any {
    return this.accounts;
  }
}
