var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { attrs: { id: "services-section" } }, [
    _c(
      "div",
      { staticClass: "custom-content" },
      [
        _c("div", { staticClass: "title-section" }, [
          _c(
            "label",
            { staticClass: "text-16 ff-bold white--text text-uppercase" },
            [_vm._v(_vm._s(_vm.$t("services")))]
          ),
          _vm._v(" "),
          _c("h2", { staticClass: "h_title_2 text-center white--text mt-1" }, [
            _vm._v(_vm._s(_vm.$t("servicesTitle"))),
          ]),
          _vm._v(" "),
          _c("p", { staticClass: "white--text text-16 mt-3" }, [
            _vm._v(_vm._s(_vm.$t("servicesDescription"))),
          ]),
        ]),
        _vm._v(" "),
        _vm.items.length > 0
          ? [
              _c(
                "v-carousel",
                {
                  ref: "carousel",
                  staticClass: "custom-carousel",
                  attrs: { "show-arrows": false },
                },
                _vm._l(_vm.groupedItems, function (slide, index) {
                  return _c(
                    "v-carousel-item",
                    { key: index, attrs: { ripple: false } },
                    [
                      _c(
                        "v-row",
                        _vm._l(slide, function (item, itemIndex) {
                          return _c(
                            "v-col",
                            {
                              key: itemIndex,
                              staticClass: "d-flex",
                              attrs: { cols: "12", md: "3", sm: "6" },
                            },
                            [
                              _c(
                                "v-card",
                                {
                                  staticClass:
                                    "d-flex flex-column card-equal-height",
                                  attrs: { elevation: "0" },
                                },
                                [
                                  _c(
                                    "v-card-text",
                                    {
                                      staticClass:
                                        "d-flex flex-column flex-grow-1 pa-0",
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "pa-5 top-section" },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "text-right mb-2" },
                                            [
                                              _c("img", {
                                                attrs: {
                                                  src: require("@/assets/images/" +
                                                    item.icon),
                                                  alt: "Proccess Step Icon",
                                                  width: "25",
                                                  height: "100%",
                                                  loading: "lazy",
                                                },
                                              }),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c("div", { staticClass: "mx-5" }, [
                                            _c(
                                              "h2",
                                              { staticClass: "text-brand" },
                                              [_vm._v(_vm._s(item.title))]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "label",
                                              { staticClass: "amount" },
                                              [_vm._v(_vm._s(item.amount))]
                                            ),
                                          ]),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "services-cont flex-grow-1 d-flex flex-column",
                                        },
                                        [
                                          _vm._l(
                                            item.services,
                                            function (service, serviceIndex) {
                                              return _c(
                                                "div",
                                                {
                                                  key: serviceIndex,
                                                  staticClass: "mb-4 fw-medium",
                                                },
                                                [
                                                  _c(
                                                    "v-icon",
                                                    {
                                                      staticClass:
                                                        "text-brand mr-2",
                                                      attrs: { small: "" },
                                                    },
                                                    [_vm._v("mdi-check-circle")]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "span",
                                                    { staticClass: "service" },
                                                    [_vm._v(_vm._s(service))]
                                                  ),
                                                ],
                                                1
                                              )
                                            }
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            { staticClass: "mt-auto" },
                                            [
                                              _c(
                                                "v-btn",
                                                {
                                                  staticClass: "fw-medium",
                                                  attrs: {
                                                    block: "",
                                                    depressed: "",
                                                    color: "btn-brand",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      _vm.$router.push(
                                                        _vm.localePath(
                                                          _vm.getStartedRoute()
                                                        )
                                                      )
                                                    },
                                                  },
                                                },
                                                [_vm._v(_vm._s(_vm.$t("buy")))]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        2
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  )
                }),
                1
              ),
            ]
          : _vm._e(),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }