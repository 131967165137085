
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'

@Component({})
export default class InstagramOrderDripFeed extends Vue {
  @Prop({ default: () => [] }) readonly order!: any

  dripFeed: any = { days: [], hours: [], minutes: [], seconds: [] };
  selectedDripFeed: any = { days: 0, hours: 0, minutes: 0, seconds: 0 };
  commentsSecondsError: boolean = false;
  commentsSecondsErrorMsg: any = "";
  hasCorrectDripFeed: boolean = true;
  setDripFeed: boolean = false;

  mounted() {
    this.getDripFeed();
  }

  getDripFeed() {
    let days = [], hours = [], ms = [];
    for(let x=0;x < 8; x++) {
      days.push(x);
    }
    for(let x=0;x < 24; x++) {
      hours.push(x);
    }
    for(let x=0;x < 60; x++) {
      ms.push(x);
    }
    this.dripFeed = { days: days, hours: hours, minutes: ms, seconds: ms };
  }

  setSelectedDripFeed(type:any, item:any) {
    this.commentsSecondsError = false;
    this.selectedDripFeed[type] = item;
    this.convertToSeconds();
  }

  convertToSeconds() {
    this.commentsSecondsError = false;

    const secondsInADay = 24 * 60 * 60;
    const secondsInAnHour = 60 * 60;
    const secondsInAMinute = 60;
    let computedSeconds = (this.selectedDripFeed.days * secondsInADay) + (this.selectedDripFeed.hours * secondsInAnHour) + (this.selectedDripFeed.minutes * secondsInAMinute) + this.selectedDripFeed.seconds;

    this.hasCorrectDripFeed = true;
    this.order.drip_feed = computedSeconds;

    if(this.order.order_type == 'post_comments' &&  computedSeconds >= 0 && computedSeconds < 15) {
      this.commentsSecondsErrorMsg = this.$t('secondsMinIntervalOrder');
      this.commentsSecondsError = true;
      this.hasCorrectDripFeed = false;
    }
    if(computedSeconds > 604800) {
      this.commentsSecondsErrorMsg = this.$t('intervalLimit');
      this.commentsSecondsError = true;
      this.hasCorrectDripFeed = false;
    }

    this.$emit('get-data', this.order.drip_feed);
    this.$emit('correct-drip', this.hasCorrectDripFeed);
  }

  clearDripFeed() {
    this.selectedDripFeed = { days: 0, hours: 0, minutes: 0, seconds: (this.order.order_type == 'post_comments' ? 15 : 0) };
    this.commentsSecondsError = false;
    this.hasCorrectDripFeed = true;
    this.order.drip_feed = this.order.order_type == 'post_comments' ? 15 : null;

    this.$emit('get-data', this.order.drip_feed);
    this.$emit('correct-drip', this.hasCorrectDripFeed);
  }

  orderTypeClicked(item:any) {
   if(this.order.order_type == 'post_comments') {
    this.selectedDripFeed.seconds = 15;
    this.setDripFeed = true;
   } else {
    this.selectedDripFeed.seconds = 0;
    this.setDripFeed = false;
   }
   this.convertToSeconds();
  }

  convertSecondsToDHMS(sec:any) {
    this.selectedDripFeed = { days: 0, hours: 0, minutes: 0, seconds: 0 };
    this.setDripFeed = sec ? true : false;

    const days = Math.floor(sec / (3600 * 24));
    const hours = Math.floor((sec % (3600 * 24)) / 3600);
    const minutes = Math.floor((sec % 3600) / 60);
    const seconds = sec % 60;
    let time = "";
    
    if(days > 0) {
      time += days + " " + this.$t('days');
      if(hours > 0 || minutes > 0 || seconds > 0) {
        time += ", ";
      }
      this.selectedDripFeed.days = days;
    }
    if(hours > 0) {
      time += hours + " " + this.$t('hours');
      if(minutes > 0 || seconds > 0) {
        time += ", ";
      }
      this.selectedDripFeed.hours = hours;
    }
    if(minutes > 0) {
      time +=  minutes + " " + this.$t('minutes');
      if(seconds > 0) {
        time += ", ";
      }
      this.selectedDripFeed.minutes = minutes;
    }
    if(seconds > 0) {
      time += seconds + " " + this.$t('seconds');
      this.selectedDripFeed.seconds = seconds;
    }
  }
}
