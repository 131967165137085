var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { attrs: { id: "instagram-flatrate-section" } }, [
    _c("div", { staticClass: "custom-content with-image d-flex inner" }, [
      _c(
        "div",
        { staticClass: "left-side" },
        [
          _c("v-img", {
            attrs: {
              width: "100%",
              src: _vm.getImage("flatrate-cover.webp", "flatrate-cover.svg"),
              alt: " A professional woman works on her laptop in a living room with a staircase. Beside the image are positive Instagram comments and  Instagram account insights showing an increase in her reach.",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "right-side position-relative" },
        [
          _c("img", {
            staticClass: "line",
            attrs: {
              src: _vm.getImage("line.webp", "line.svg"),
              alt: "Line Image",
            },
          }),
          _vm._v(" "),
          _c("img", {
            staticClass: "box",
            attrs: {
              src: _vm.getImage("box.webp", "box.svg"),
              alt: "Square Image",
            },
          }),
          _vm._v(" "),
          _c(
            "v-card",
            { staticClass: "custom-card", attrs: { elevation: "0" } },
            [
              _c("v-card-text", [
                _c("div", { staticClass: "innter-content" }, [
                  _c("label", { staticClass: "text-16 text-brand ff-bold" }, [
                    _vm._v(_vm._s(_vm.$t("igFlatrateTop"))),
                  ]),
                  _vm._v(" "),
                  _c("h2", { staticClass: "h_title_1 text-mid-black mb-5" }, [
                    _vm._v(_vm._s(_vm.$t("igFlatrateTitle"))),
                  ]),
                  _vm._v(" "),
                  _c("p", { staticClass: "text-14 ff-bold" }, [
                    _vm._v(_vm._s(_vm.$t("igFlatrateDesc"))),
                  ]),
                ]),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "mt-12 ml-10" },
            [
              _vm._l(_vm.$t("igFlatrateOffers"), function (item, index) {
                return [
                  index == 0
                    ? _c(
                        "div",
                        { staticClass: "d-flex align-center mb-3 ff-bold" },
                        [
                          _c(
                            "v-icon",
                            {
                              staticClass: "text-brand mr-2",
                              attrs: { small: "" },
                            },
                            [_vm._v("mdi-check-circle")]
                          ),
                          _vm._v(" "),
                          _c("img", {
                            staticClass: "mr-1",
                            attrs: {
                              src: require("assets/images/de-flag.png"),
                              alt: "DE Flag Icon",
                              width: "15",
                              height: "100%",
                            },
                          }),
                          _vm._v(" "),
                          _c("span", [_vm._v(_vm._s(item))]),
                        ],
                        1
                      )
                    : _c(
                        "div",
                        { staticClass: "mb-3 ff-bold" },
                        [
                          _c(
                            "v-icon",
                            {
                              staticClass: "text-brand mr-2",
                              attrs: { small: "" },
                            },
                            [_vm._v("mdi-check-circle")]
                          ),
                          _vm._v(" "),
                          _c("span", [_vm._v(_vm._s(item))]),
                        ],
                        1
                      ),
                ]
              }),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  staticClass: "btn-yellow get-started",
                  attrs: { depressed: "", large: "" },
                  on: {
                    click: function ($event) {
                      _vm.$router.push(_vm.localePath(_vm.getStartedRoute()))
                    },
                  },
                },
                [
                  _c(
                    "span",
                    {
                      staticClass:
                        "text-16 letter-spacing-normal font-weight-bold",
                    },
                    [_vm._v(_vm._s(_vm.$t("getStartedTodayBtn")))]
                  ),
                  _vm._v(" "),
                  _c("span", { staticClass: "letter-spacing-normal ff-bold" }, [
                    _vm._v(_vm._s(_vm.$t("getStartedTodayBtnDesc"))),
                  ]),
                ]
              ),
            ],
            2
          ),
        ],
        1
      ),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "custom-content what-makes-diff" },
      [
        _c("h2", {
          staticClass: "h_title_2 mb-7",
          domProps: { innerHTML: _vm._s(_vm.$t("whatMakesItDiff")) },
        }),
        _vm._v(" "),
        _c(
          "v-row",
          [
            _vm._l(_vm.list, function (item) {
              return [
                _c(
                  "v-col",
                  {
                    class: {
                      "pr-5 mb-5":
                        !_vm.$vuetify.breakpoint.xs &&
                        !_vm.$vuetify.breakpoint.sm,
                    },
                    attrs: { cols: "12", md: "6", sm: "6" },
                  },
                  [
                    _c(
                      "v-card",
                      { attrs: { elevation: "0", height: "100%" } },
                      [
                        _c("v-card-text", { staticClass: "px-12 py-8" }, [
                          _c("img", {
                            staticClass: "mb-5",
                            attrs: {
                              src: require(`~/assets/images/${item.icon}`),
                              alt: "Services Icon",
                              width: "35",
                              height: "100%",
                              loading: "lazy",
                            },
                          }),
                          _vm._v(" "),
                          _c("h3", { staticClass: "h_title_4 mb-2" }, [
                            _vm._v(_vm._s(item.title)),
                          ]),
                          _vm._v(" "),
                          _c("p", { staticClass: "text-14 mb-0" }, [
                            _vm._v(_vm._s(item.description)),
                          ]),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            }),
          ],
          2
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }