var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("v-app", { attrs: { dark: "" } }, [
    _c(
      "div",
      { staticClass: "text-center nothing-show" },
      [
        _c("v-icon", { attrs: { "x-large": "" } }, [
          _vm._v("mdi-alert-outline"),
        ]),
        _vm._v(" "),
        _c("h1", { staticClass: "h_title_2" }, [_vm._v("404 Not Found")]),
        _vm._v(" "),
        _c("nuxt-link", { attrs: { to: _vm.localePath("/") } }, [
          _vm._v("Home"),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }