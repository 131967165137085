
import { Component, Vue } from 'vue-property-decorator'
import { getImage } from "~/utils/ImageUtils"

@Component({
  layout: 'landing-page',
})
export default class IGFlatrateSection extends Vue {
  list: any = [];

  mounted() {
    this.list = this.$t('whatMakesItDiffList') as any;
  }
  getImage(filename:any, fallback:any) {
    return getImage(filename, fallback);
  }

  getStartedRoute() {
    if(this.$auth.loggedIn) {
      return '/dashboard/home';
    }
    return '/account/register';
  }
}
