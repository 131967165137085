
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { dateFormat } from '~/utils/DateTimeUtils'
import { LocalStorageFiltersStore } from '~/store';

@Component({})
export default class InstagramAccountsCardList extends Vue {
  @Prop({ default: () => { return null }, required: false }) readonly data!: any
  @Prop({ default: () => { return null }, required: false }) readonly loading!: any
  @Prop({ default: () => { return 1 }, required: false }) readonly currentPage!: any
  @Prop({ default: () => { return 100 }, required: false }) readonly currentPerPage!: number
  @Prop({ default: () => false, required: false }) readonly loaded!: boolean
  @Prop({ default: () => { return false }, required: false }) readonly noAddBtn!: any

  totalData: number = 0;
  page: number = 1;
  perPage: number = 99;
  pageCount: number = 1;
  perPageOptions = [15, 30, 50, 100, 300, 500];
  accounts: any = [];

  mounted() {
    this.page = this.currentPage;
    this.perPage = this.currentPerPage;

    this.getData();
    if(!this.loaded || !this.freezeWatchFlag) {
      this.setParams();
    }
  }

  @Watch('currentPage')
  checkPage() {
    this.page = this.currentPage;
  }

  @Watch('currentPerPage')
  checkPerPage() {
    this.perPage = this.currentPerPage;
  }

  @Watch('loading')
  checkLoading() {
    if(this.loading) {
      this.totalData = 0;
    }
  }

  @Watch('data')
  getCustomerData() {
    this.getData();
  }

  handlePageChange(page: number) {
    if (page !== this.page) {
      this.page = page;
      this.setParams();
    }
  }

  handlePerPageChange(perPage: number) {
    this.page = 1;
    this.perPage = perPage;
    this.setParams();
  }

  setParams() {
    this.accounts = [];
    this.$emit('refresh', { page: this.page, perPage: this.perPage, options: {} });
  }

  getData() {
    if(this.data) {
      this.accounts = this.data.results;
      this.totalData = this.data.count ? this.data.count : 0;

      const count = this.data.count/this.perPage;
      this.pageCount = count == Math.floor(count) ? count : (Math.floor(count) + 1);
      this.page = this.page > this.pageCount ? 1 : this.page;
    }
  }

  checkActiveExpiring(date:any) {
    const sevenDaysFuture = this.$dayjs().add(7, 'day').format('YYYY-MM-DD');
    const today = this.$dayjs().format('YYYY-MM-DD');
    
    if(date >= today && date <= sevenDaysFuture) {
      return true;
    }
    return false
  }

  formatDate(date:any) {
    return dateFormat(this, date);
  }

  get freezeWatchFlag() {
    return LocalStorageFiltersStore.getFreeWatchFlag;
  }
}
