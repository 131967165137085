
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { requiredRule } from '~/utils/FormValidation'
import { BlogsStore } from '@/store'
import { getErrorMessage } from "~/utils/ErrorMessageUtils";

@Component({})
export default class SettingsBlogsForm extends Vue {
  @Prop({ default: 'edit' }) readonly type!: string;
  @Prop({ default() { return {} } }) readonly articleEdit!: any;

  progressLoading: boolean = false;
  fileUpload: any = "";
  fileUploadUrl: any = "";
  hasThumbnail: boolean = false;
  isThumbnailChanged: boolean = false;
  categories: any = [];
  article: any = { publish: false };
  requiredRule: any = [ requiredRule() ];
  fieldErrorMessage: any = {};
  contentExpand: any = [0];

  @Watch('articleEdit')
  checkArticleEdit(val:any) {
    this.article = val;
    if(this.article.content) {
      const editorEn = this.$refs.editorEn as any
      editorEn.setContent(this.article.content);
    }

    if(this.article.german_content) {
      this.contentExpand.push(1);
      setTimeout(() => {
        const editorDe = this.$refs.editorDe as any
        editorDe.setContent(this.article.german_content);
      }, 800)
    }

    this.fileUploadUrl = this.article.thumbnail;
    this.hasThumbnail = this.fileUploadUrl ? true : false;
  }

  mounted() {
    this.getCategories();
  }

  async getCategories() {
    const data = await BlogsStore.fetchCategories();
    if(data.count > 0 && this.$i18n.locale != 'en') {
      this.categories = data.results.map((item:any) => {
        return { id: item.id, name: (item.german_name ? item.german_name : item.name)}
      })
    } else {
      this.categories = data.results;
    }
  }

  fileChanged(newValue: File): void {
    if (newValue == null) {
      this.isThumbnailChanged = false;
      return;
    }
    this.fileUpload = newValue;
    this.isThumbnailChanged = true;

    const reader = new FileReader();
    reader.onload = (e) => {
      this.fileUploadUrl = String(e.target?.result) || "";
      this.hasThumbnail = true;
    };

    reader.readAsDataURL(newValue);
  }

  clearImage() {
    this.fileUploadUrl = null;
    this.hasThumbnail = false;
    this.isThumbnailChanged = true;
  }

  drop(event: any) {
    event.preventDefault();
    event.currentTarget.classList.remove("dragEnter");

    if(event.dataTransfer.files.length > 0) {
      this.fileChanged(event.dataTransfer.files[0]);
    }
  }

  dragover(event: any) {
    event.preventDefault();
    event.currentTarget.classList.add("dragEnter");
  }

  dragleave(event: any) {
    event.preventDefault();
    event.currentTarget.classList.remove("dragEnter");
  }

  async save() {
    const form = this.$refs.articleForm as any;
    const editorEn = this.$refs.editorEn as any;
    const editorDe = this.$refs.editorDe as any;

    if(form.validate()) { 
      let data = new FormData();
      data.append("title", this.article.title);
      data.append("content", editorEn.getHTML());
      data.append("publish", this.article.publish);

      if(this.article.german_title) {
        data.append("german_title", this.article.german_title);
      }
      if(editorDe) {
        data.append("german_content", editorDe.getHTML());
      }

      let sigHeaders = { "content-type": "multipart/form-data" } as any;
      let categories = this.article.categories ? this.article.categories : [];

      if(this.type == "create") {
        data.append("thumbnail", this.fileUpload);
        data.append("categories", JSON.stringify(categories));

        await this.$axios.post('/blogs/articles/', data,
          { headers: sigHeaders }
        ).then((response)=> {
          const data = response.data;
          const snackbar = this.$refs.success as any;
          snackbar.show(this.$t('successSave'));

          setTimeout(() => {
            this.$router.push(this.localePath(`/dashboard/settings/blogs/${data.slug}/edit/`));  
          }, 1500)
        }).catch((error:any) => {
          const message = getErrorMessage(error, this.$t('errorSaveArticle'));
          this.fieldErrorMessage = message.message;
          if(message.show) {
            const snackbar = this.$refs.error as any;
            if(snackbar) { snackbar.show(message); }
          }
        })
      } else {
        if(this.isThumbnailChanged) {
          data.append("thumbnail", this.fileUpload);
        }
        let categories = [] as any;
        this.article.categories.forEach((cat: any) => {
          categories.push(cat.id || cat);
        })
        data.append("categories", JSON.stringify(categories));

        await this.$axios.patch(`/blogs/articles/${this.article.id}/`, data,
          { headers: sigHeaders }
        ).then((response)=> {
          const data = response.data;
          const snackbar = this.$refs.success as any;
          snackbar.show(this.$t('successSave'));

          setTimeout(() => {
            this.$router.push(this.localePath(`/dashboard/settings/blogs/${data.slug}/edit/`));  
          }, 1500)
        }).catch((error:any) => {
          const message = getErrorMessage(error, this.$t('errorUpdateArticle'));
          this.fieldErrorMessage = message.message;
          if(message.show) {
            const snackbar = this.$refs.error as any;
            if(snackbar) { snackbar.show(message); }
          }
        })
      }
    }
    this.$emit('done', false);
  }
}
