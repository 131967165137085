var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "mt-3 mb-3 drip-feed" },
    [
      _c(
        "div",
        { staticClass: "d-flex align-center" },
        [
          !_vm.setDripFeed
            ? _c("v-checkbox", {
                staticClass: "mt-0 pt-0 custom-checkbox",
                attrs: {
                  color: "btn-brand",
                  label: _vm.$t("dripFeed"),
                  ripple: false,
                  "hide-details": "",
                },
                model: {
                  value: _vm.setDripFeed,
                  callback: function ($$v) {
                    _vm.setDripFeed = $$v
                  },
                  expression: "setDripFeed",
                },
              })
            : _c(
                "label",
                { staticClass: "text-16 font-weight-medium text-black" },
                [_vm._v(_vm._s(_vm.$t("dripFeed")))]
              ),
          _vm._v(" "),
          _c(
            "v-tooltip",
            {
              attrs: { bottom: "", "content-class": "custom-tooltip-padding" },
              scopedSlots: _vm._u([
                {
                  key: "activator",
                  fn: function ({ on, attrs }) {
                    return [
                      _c(
                        "v-icon",
                        _vm._g(
                          _vm._b(
                            {
                              staticClass: "ml-1",
                              attrs: { size: "18", color: "grey" },
                            },
                            "v-icon",
                            attrs,
                            false
                          ),
                          on
                        ),
                        [_vm._v("\n          mdi-information\n        ")]
                      ),
                    ]
                  },
                },
              ]),
            },
            [_vm._v(" "), _c("span", [_vm._v(_vm._s(_vm.$t("dripFeedInfo")))])]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "ml-auto d-flex align-center" },
            [
              _vm.setDripFeed
                ? [
                    _vm.selectedDripFeed.days > 0 ||
                    _vm.selectedDripFeed.hours > 0 ||
                    _vm.selectedDripFeed.minutes > 0 ||
                    (_vm.selectedDripFeed.seconds > 15 &&
                      _vm.order.order_type == "post_comments")
                      ? [
                          _c(
                            "v-chip",
                            {
                              staticClass: "px-2",
                              attrs: { small: "", outlined: "", ripple: false },
                              on: { click: _vm.clearDripFeed },
                            },
                            [
                              _c("v-icon", { attrs: { size: "14" } }, [
                                _vm._v("mdi-close"),
                              ]),
                              _vm._v(_vm._s(_vm.$t("clear")) + "\n          "),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _vm.order.order_type != "post_comments"
                            ? _c("v-divider", {
                                staticClass: "mx-2",
                                attrs: { vertical: "" },
                              })
                            : _vm._e(),
                        ]
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.order.order_type != "post_comments"
                      ? _c(
                          "v-chip",
                          {
                            staticClass: "px-2",
                            attrs: { small: "", outlined: "", ripple: false },
                            on: {
                              click: function ($event) {
                                _vm.setDripFeed = false
                                _vm.clearDripFeed()
                              },
                            },
                          },
                          [
                            _c("v-icon", { attrs: { size: "14" } }, [
                              _vm._v("mdi-close"),
                            ]),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]
                : _vm._e(),
            ],
            2
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.setDripFeed
        ? [
            _c(
              "div",
              { staticClass: "mt-7" },
              [
                _c(
                  "v-row",
                  { attrs: { "no-gutters": "" } },
                  [
                    _c("v-col", { attrs: { cols: "6", md: "3", sm: "3" } }, [
                      _c(
                        "div",
                        { staticClass: "text-center mx-2" },
                        [
                          _c(
                            "v-menu",
                            {
                              attrs: { "offset-y": "", bottom: "" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function ({ on, attrs }) {
                                      return [
                                        _c(
                                          "label",
                                          _vm._g(
                                            _vm._b(
                                              { staticClass: "time-title" },
                                              "label",
                                              attrs,
                                              false
                                            ),
                                            on
                                          ),
                                          [
                                            _vm._v(
                                              _vm._s(_vm.selectedDripFeed.days)
                                            ),
                                          ]
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                2442916323
                              ),
                            },
                            [
                              _vm._v(" "),
                              _c(
                                "v-list",
                                {
                                  staticClass: "drip-feed",
                                  attrs: { height: "250" },
                                },
                                [
                                  _vm._l(_vm.dripFeed.days, function (item) {
                                    return [
                                      _c(
                                        "v-list-item",
                                        {
                                          on: {
                                            click: function ($event) {
                                              return _vm.setSelectedDripFeed(
                                                "days",
                                                item
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c("v-list-item-title", [
                                            _vm._v(_vm._s(item)),
                                          ]),
                                        ],
                                        1
                                      ),
                                    ]
                                  }),
                                ],
                                2
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("p", { staticClass: "mb-3 text-uppercase" }, [
                            _vm._v(_vm._s(_vm.$t("days"))),
                          ]),
                        ],
                        1
                      ),
                    ]),
                    _vm._v(" "),
                    _c("v-col", { attrs: { cols: "6", md: "3", sm: "3" } }, [
                      _c(
                        "div",
                        { staticClass: "text-center mx-2" },
                        [
                          _c(
                            "v-menu",
                            {
                              attrs: { "offset-y": "", bottom: "" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function ({ on, attrs }) {
                                      return [
                                        _c(
                                          "label",
                                          _vm._g(
                                            _vm._b(
                                              { staticClass: "time-title" },
                                              "label",
                                              attrs,
                                              false
                                            ),
                                            on
                                          ),
                                          [
                                            _vm._v(
                                              _vm._s(_vm.selectedDripFeed.hours)
                                            ),
                                          ]
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                1585671295
                              ),
                            },
                            [
                              _vm._v(" "),
                              _c(
                                "v-list",
                                {
                                  staticClass: "drip-feed",
                                  attrs: { height: "250" },
                                },
                                [
                                  _vm._l(_vm.dripFeed.hours, function (item) {
                                    return [
                                      _c(
                                        "v-list-item",
                                        {
                                          on: {
                                            click: function ($event) {
                                              return _vm.setSelectedDripFeed(
                                                "hours",
                                                item
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c("v-list-item-title", [
                                            _vm._v(_vm._s(item)),
                                          ]),
                                        ],
                                        1
                                      ),
                                    ]
                                  }),
                                ],
                                2
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("p", { staticClass: "mb-3 text-uppercase" }, [
                            _vm._v(_vm._s(_vm.$t("hours"))),
                          ]),
                        ],
                        1
                      ),
                    ]),
                    _vm._v(" "),
                    _c("v-col", { attrs: { cols: "6", md: "3", sm: "3" } }, [
                      _c(
                        "div",
                        { staticClass: "text-center mx-2" },
                        [
                          _c(
                            "v-menu",
                            {
                              attrs: { "offset-y": "", bottom: "" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function ({ on, attrs }) {
                                      return [
                                        _c(
                                          "label",
                                          _vm._g(
                                            _vm._b(
                                              { staticClass: "time-title" },
                                              "label",
                                              attrs,
                                              false
                                            ),
                                            on
                                          ),
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.selectedDripFeed.minutes
                                              )
                                            ),
                                          ]
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                866428145
                              ),
                            },
                            [
                              _vm._v(" "),
                              _c(
                                "v-list",
                                {
                                  staticClass: "drip-feed",
                                  attrs: { height: "250" },
                                },
                                [
                                  _vm._l(_vm.dripFeed.minutes, function (item) {
                                    return [
                                      _c(
                                        "v-list-item",
                                        {
                                          on: {
                                            click: function ($event) {
                                              return _vm.setSelectedDripFeed(
                                                "minutes",
                                                item
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c("v-list-item-title", [
                                            _vm._v(_vm._s(item)),
                                          ]),
                                        ],
                                        1
                                      ),
                                    ]
                                  }),
                                ],
                                2
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("p", { staticClass: "mb-3 text-uppercase" }, [
                            _vm._v(_vm._s(_vm.$t("minutes"))),
                          ]),
                        ],
                        1
                      ),
                    ]),
                    _vm._v(" "),
                    _c("v-col", { attrs: { cols: "6", md: "3", sm: "3" } }, [
                      _c(
                        "div",
                        { staticClass: "text-center mx-2" },
                        [
                          _c(
                            "v-menu",
                            {
                              attrs: { "offset-y": "", bottom: "" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function ({ on, attrs }) {
                                      return [
                                        _c(
                                          "label",
                                          _vm._g(
                                            _vm._b(
                                              { staticClass: "time-title" },
                                              "label",
                                              attrs,
                                              false
                                            ),
                                            on
                                          ),
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.selectedDripFeed.seconds
                                              )
                                            ),
                                          ]
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                2767792463
                              ),
                            },
                            [
                              _vm._v(" "),
                              _c(
                                "v-list",
                                {
                                  staticClass: "drip-feed",
                                  attrs: { height: "250" },
                                },
                                [
                                  _vm._l(_vm.dripFeed.seconds, function (item) {
                                    return [
                                      _c(
                                        "v-list-item",
                                        {
                                          on: {
                                            click: function ($event) {
                                              return _vm.setSelectedDripFeed(
                                                "seconds",
                                                item
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c("v-list-item-title", [
                                            _vm._v(_vm._s(item)),
                                          ]),
                                        ],
                                        1
                                      ),
                                    ]
                                  }),
                                ],
                                2
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("p", { staticClass: "mb-3 text-uppercase" }, [
                            _vm._v(_vm._s(_vm.$t("seconds"))),
                          ]),
                        ],
                        1
                      ),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _vm.selectedDripFeed.days > 0 ||
            _vm.selectedDripFeed.hours > 0 ||
            _vm.selectedDripFeed.minutes > 0 ||
            _vm.selectedDripFeed.seconds > 0
              ? _c(
                  "div",
                  {
                    staticClass:
                      "d-flex align-center justify-center flex-wrap text-14 mt-2 mb-4",
                  },
                  [
                    _c("span", { staticClass: "mx-2 font-weight-medium" }, [
                      _vm._v(_vm._s(_vm.$t("interval")) + ":"),
                    ]),
                    _vm._v(" "),
                    _vm.selectedDripFeed.days > 0
                      ? [
                          _c("span", { staticClass: "font-weight-bold" }, [
                            _vm._v(_vm._s(_vm.selectedDripFeed.days)),
                          ]),
                          _vm._v(" "),
                          _c("span", { staticClass: "ml-1" }, [
                            _vm._v(_vm._s(_vm.$t("days"))),
                          ]),
                          _vm._v(" "),
                          _vm.selectedDripFeed.hours > 0 ||
                          _vm.selectedDripFeed.minutes > 0 ||
                          _vm.selectedDripFeed.seconds > 0
                            ? _c("span", [_vm._v(", ")])
                            : _vm._e(),
                        ]
                      : _vm._e(),
                    _vm._v(" "),
                    _c("span"),
                    _vm._v(" "),
                    _vm.selectedDripFeed.hours > 0
                      ? [
                          _c("span", { staticClass: "font-weight-bold" }, [
                            _vm._v(_vm._s(_vm.selectedDripFeed.hours)),
                          ]),
                          _vm._v(" "),
                          _c("span", { staticClass: "ml-1" }, [
                            _vm._v(_vm._s(_vm.$t("hours"))),
                          ]),
                          _vm._v(" "),
                          _vm.selectedDripFeed.minutes > 0 ||
                          _vm.selectedDripFeed.seconds > 0
                            ? _c("span", [_vm._v(", ")])
                            : _vm._e(),
                        ]
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.selectedDripFeed.minutes > 0
                      ? [
                          _c("span", { staticClass: "font-weight-bold" }, [
                            _vm._v(_vm._s(_vm.selectedDripFeed.minutes)),
                          ]),
                          _vm._v(" "),
                          _c("span", { staticClass: "ml-1" }, [
                            _vm._v(_vm._s(_vm.$t("minutes"))),
                          ]),
                          _vm._v(" "),
                          _vm.selectedDripFeed.seconds > 0
                            ? _c("span", [_vm._v(", ")])
                            : _vm._e(),
                        ]
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.selectedDripFeed.seconds > 0
                      ? [
                          _c("span", { staticClass: "font-weight-bold" }, [
                            _vm._v(_vm._s(_vm.selectedDripFeed.seconds)),
                          ]),
                          _vm._v(" "),
                          _c("span", { staticClass: "mx-1" }, [
                            _vm._v(_vm._s(_vm.$t("seconds"))),
                          ]),
                        ]
                      : _vm._e(),
                  ],
                  2
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.commentsSecondsError
              ? _c("p", { staticClass: "text-12 error--text text-center" }, [
                  _vm._v(_vm._s(_vm.commentsSecondsErrorMsg)),
                ])
              : _vm._e(),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }