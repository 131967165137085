var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "v-card",
        { staticClass: "custom-cards mt-2", attrs: { disabled: _vm.disabled } },
        [
          _c(
            "v-card-text",
            [
              _c("v-data-table", {
                staticClass: "elevation-0",
                attrs: {
                  headers: _vm.headers,
                  items: _vm.tableItems,
                  "items-per-page": _vm.perPage,
                  "server-items-length": _vm.totalData,
                  loading: _vm.loading,
                  options: _vm.options,
                  "sort-desc": true,
                  "sort-by": "created",
                  "hide-default-footer": "",
                },
                on: {
                  "update:options": function ($event) {
                    _vm.options = $event
                  },
                },
                scopedSlots: _vm._u([
                  {
                    key: "item.created",
                    fn: function ({ item }) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(_vm.formatDateTime(item.created, "date"))
                          ),
                        ]),
                      ]
                    },
                  },
                  {
                    key: "item.time",
                    fn: function ({ item }) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(_vm.formatDateTime(item.created, "time"))
                          ),
                        ]),
                      ]
                    },
                  },
                  {
                    key: "item.purchased_bot_count",
                    fn: function ({ item }) {
                      return [
                        _c(
                          "div",
                          { staticClass: "d-flex align-center justify-end" },
                          [
                            _c("span", { staticClass: "mr-1" }, [
                              _vm._v(_vm._s(item.purchased_bot_count)),
                            ]),
                            _vm._v(" "),
                            _c("img", {
                              attrs: {
                                src: require("assets/images/instagram-golden.webp"),
                                width: "15",
                              },
                            }),
                          ]
                        ),
                      ]
                    },
                  },
                  {
                    key: "item.credits",
                    fn: function ({ item }) {
                      return [
                        _c(
                          "div",
                          { staticClass: "d-flex align-center justify-end" },
                          [
                            _c("span", { staticClass: "mr-1" }, [
                              _vm._v(_vm._s(_vm.getCreditValue(item.credits))),
                            ]),
                            _vm._v(" "),
                            _c("img", {
                              attrs: {
                                src: require("assets/images/icons/twemoji_coin.svg"),
                                width: "15",
                              },
                            }),
                          ]
                        ),
                      ]
                    },
                  },
                  {
                    key: "item.mode_of_payment",
                    fn: function ({ item }) {
                      return [
                        _c("span", [
                          _vm._v(_vm._s(_vm.$t("creditCard")) + ": "),
                        ]),
                        _vm._v(" "),
                        _c("span", { staticClass: "text-capitalize" }, [
                          _vm._v(_vm._s(item.mode_of_payment)),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("pagination", {
                attrs: {
                  "total-items": _vm.totalData,
                  page: _vm.page,
                  "per-page": _vm.perPage,
                  "per-page-options": _vm.perPageOptions,
                },
                on: {
                  "page-change": _vm.handlePageChange,
                  "per-page-change": _vm.handlePerPageChange,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }