var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "v-card",
        { staticClass: "custom-cards" },
        [
          _c(
            "v-card-text",
            { staticClass: "pt-1" },
            [
              _c("v-data-table", {
                staticClass: "elevation-0",
                attrs: {
                  headers: _vm.headers,
                  items: _vm.tableItems,
                  "items-per-page": _vm.perPage,
                  "server-items-length": _vm.totalData,
                  loading: _vm.loading,
                  options: _vm.options,
                  "sort-desc": true,
                  "sort-by": "created",
                  "hide-default-footer": "",
                },
                on: {
                  "update:options": function ($event) {
                    _vm.options = $event
                  },
                },
                scopedSlots: _vm._u([
                  {
                    key: "item.email",
                    fn: function ({ item }) {
                      return [
                        item.first_name
                          ? _c("span", [_vm._v(_vm._s(item.first_name))])
                          : _vm._e(),
                        _vm._v(" "),
                        item.first_name && item.last_name
                          ? _c("span")
                          : _vm._e(),
                        _vm._v(" "),
                        item.last_name
                          ? _c("span", [_vm._v(_vm._s(item.last_name))])
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "div",
                          [
                            _c("span", { staticClass: "text-brand" }, [
                              _vm._v(_vm._s(item.email)),
                            ]),
                            _vm._v(" "),
                            item.email_verified
                              ? _c(
                                  "v-tooltip",
                                  {
                                    attrs: {
                                      bottom: "",
                                      "content-class": "custom-tooltip",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "activator",
                                          fn: function ({ on, attrs }) {
                                            return [
                                              _c(
                                                "v-icon",
                                                _vm._g(
                                                  _vm._b(
                                                    {
                                                      staticClass:
                                                        "custom-icon-small",
                                                      attrs: {
                                                        size: "14",
                                                        color: "success",
                                                      },
                                                    },
                                                    "v-icon",
                                                    attrs,
                                                    false
                                                  ),
                                                  on
                                                ),
                                                [
                                                  _vm._v(
                                                    "\n                  mdi-check-decagram\n                "
                                                  ),
                                                ]
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  },
                                  [
                                    _vm._v(" "),
                                    _c("span", [
                                      _vm._v(_vm._s(_vm.$t("emailVerified"))),
                                    ]),
                                  ]
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                  {
                    key: "item.date_joined",
                    fn: function ({ item }) {
                      return [
                        _c("span", [
                          _vm._v(_vm._s(_vm.formatDateTime(item.date_joined))),
                        ]),
                      ]
                    },
                  },
                  {
                    key: "item.remaining_credits",
                    fn: function ({ item }) {
                      return [
                        _c(
                          "div",
                          { staticClass: "pr-2" },
                          [
                            _c(
                              "nuxt-link",
                              {
                                attrs: {
                                  to: _vm.localePath(
                                    `/dashboard/settings/transactions?user=${item.id}`
                                  ),
                                },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "d-flex align-center justify-end",
                                  },
                                  [
                                    item.remaining_credits
                                      ? _c("span", { staticClass: "mr-1" }, [
                                          _vm._v(
                                            _vm._s(
                                              _vm.formatCredits(
                                                item.remaining_credits
                                              )
                                            )
                                          ),
                                        ])
                                      : _c("span", { staticClass: "mr-1" }, [
                                          _vm._v("0"),
                                        ]),
                                    _vm._v(" "),
                                    _c("img", {
                                      attrs: {
                                        src: require("assets/images/icons/twemoji_coin.svg"),
                                        width: "15",
                                      },
                                    }),
                                  ]
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                  {
                    key: "item.last_login",
                    fn: function ({ item }) {
                      return [
                        item.last_login
                          ? _c("span", [
                              _vm._v(
                                _vm._s(_vm.formatDateTime(item.last_login))
                              ),
                            ])
                          : _c("span", [_vm._v("---")]),
                      ]
                    },
                  },
                  {
                    key: "item.actions",
                    fn: function ({ item }) {
                      return [
                        _c(
                          "div",
                          { staticClass: "d-flex align-center" },
                          [
                            _c("ActionButton", {
                              attrs: {
                                data: {
                                  icon: "mdi-sale-outline",
                                  icon_color: "info--text",
                                  label: _vm.$t("userDiscounts"),
                                },
                              },
                              on: {
                                clicked: function ($event) {
                                  return _vm.$emit("user-discounts", item)
                                },
                              },
                            }),
                            _vm._v(" "),
                            _c("ActionButton", {
                              attrs: {
                                data: {
                                  icon: "mdi-eye-outline",
                                  icon_color: "text-brand",
                                  label: _vm.$t("view"),
                                },
                              },
                              on: {
                                clicked: function ($event) {
                                  _vm.$router.push(
                                    _vm.localePath(
                                      `/dashboard/admin/users/${item.id}/view`
                                    )
                                  )
                                },
                              },
                            }),
                            _vm._v(" "),
                            _c("ActionButton", {
                              attrs: {
                                data: {
                                  icon: "mdi-pencil-outline",
                                  icon_color: "warning--text",
                                  label: _vm.$t("edit"),
                                },
                              },
                              on: {
                                clicked: function ($event) {
                                  _vm.$router.push(
                                    _vm.localePath(
                                      `/dashboard/admin/users/${item.id}/edit`
                                    )
                                  )
                                },
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("pagination", {
                attrs: {
                  "total-items": _vm.totalData,
                  page: _vm.page,
                  "per-page": _vm.perPage,
                  "per-page-options": _vm.perPageOptions,
                },
                on: {
                  "page-change": _vm.handlePageChange,
                  "per-page-change": _vm.handlePerPageChange,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }