var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { attrs: { id: "footer-section" } },
    [
      _c(
        "v-row",
        { attrs: { align: "center" } },
        [
          _c("v-col", { attrs: { cols: "12", sm: "6" } }, [
            _c("img", {
              attrs: {
                src: require("assets/images/logo-white.svg"),
                alt: "Logo",
                width: "225",
                height: "100%",
                loading: "lazy",
              },
            }),
          ]),
          _vm._v(" "),
          _c(
            "v-col",
            {
              staticClass: "d-flex justify-end",
              attrs: { cols: "12", sm: "6" },
            },
            [
              _c(
                "div",
                { staticClass: "contact-us" },
                [
                  _c("label", { staticClass: "text-14 white--text" }, [
                    _vm._v(_vm._s(_vm.$t("subscribeToNewsLetter"))),
                  ]),
                  _vm._v(" "),
                  _c(
                    "v-form",
                    {
                      ref: "contactForm",
                      attrs: { onSubmit: "return false;" },
                      on: { submit: _vm.sendUsMessage },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "d-flex flex-wrap my-2" },
                        [
                          _c("v-text-field", {
                            staticClass: "search-input",
                            attrs: {
                              outlined: "",
                              dense: "",
                              placeholder: _vm.$t("yourEmailAddress"),
                              rules: _vm.emailRule,
                              "hide-details": "auto",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "prepend-inner",
                                fn: function () {
                                  return [
                                    _c("div", {
                                      staticClass: "input-background",
                                    }),
                                  ]
                                },
                                proxy: true,
                              },
                            ]),
                            model: {
                              value: _vm.contactUs.email,
                              callback: function ($$v) {
                                _vm.$set(_vm.contactUs, "email", $$v)
                              },
                              expression: "contactUs.email",
                            },
                          }),
                          _vm._v(" "),
                          _c(
                            "v-btn",
                            {
                              staticClass: "btn-brand ml-1 custom",
                              attrs: {
                                depressed: "",
                                type: "submit",
                                loading: _vm.loading,
                              },
                            },
                            [_vm._v(_vm._s(_vm.$t("subscribe")))]
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("v-divider", { staticClass: "mt-5", attrs: { color: "#fff" } }),
      _vm._v(" "),
      _c(
        "v-row",
        { staticClass: "mt-1" },
        [
          _c("v-col", { attrs: { cols: "12", sm: "5" } }, [
            _c("label", { staticClass: "white--text ff-bold" }, [
              _vm._v(
                "COPYRIGHT © " +
                  _vm._s(new Date().getFullYear()) +
                  " -  HYPEHEAVEN | ALL RIGHTS RESERVED"
              ),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "v-col",
            {
              staticClass: "d-flex justify-end",
              attrs: { cols: "12", sm: "7" },
            },
            [
              _c(
                "div",
                { staticClass: "d-flex flex-wrap menu-item" },
                [
                  _c("nuxt-link", { attrs: { to: _vm.localePath("/") } }, [
                    _c("span", [_vm._v(_vm._s(_vm.$t("contactUs")))]),
                  ]),
                  _vm._v(" "),
                  _c("span", { staticClass: "mx-3" }, [_vm._v("|")]),
                  _vm._v(" "),
                  _c("nuxt-link", { attrs: { to: _vm.localePath("/") } }, [
                    _c("span", [_vm._v(_vm._s(_vm.$t("privacyPolicy")))]),
                  ]),
                  _vm._v(" "),
                  _c("span", { staticClass: "mx-3" }, [_vm._v("|")]),
                  _vm._v(" "),
                  _c("nuxt-link", { attrs: { to: _vm.localePath("/") } }, [
                    _c("span", [_vm._v(_vm._s(_vm.$t("termsAndConditions")))]),
                  ]),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("snackbar-error", { ref: "error" }),
      _vm._v(" "),
      _c("snackbar-success", { ref: "success" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }