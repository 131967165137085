var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", [
    _c("div", { attrs: { id: "smm-panels" } }, [
      _c(
        "div",
        { staticClass: "custom-content pt-15" },
        [
          _c("p", {
            staticClass: "h_title_2 mb-15",
            domProps: { innerHTML: _vm._s(_vm.$t("smmPanelTitle")) },
          }),
          _vm._v(" "),
          _c(
            "v-row",
            { attrs: { justify: "center" } },
            [
              _vm._l(_vm.smmPanelCards, function (item) {
                return [
                  _c(
                    "v-col",
                    { attrs: { cols: "12", md: "4", sm: "6" } },
                    [
                      _c(
                        "v-card",
                        {
                          staticClass: "panel-card",
                          attrs: { elevation: "0" },
                        },
                        [
                          _c(
                            "v-card-text",
                            {
                              staticClass: "pa-8 pb-5 d-flex flex-column h-100",
                            },
                            [
                              _c(
                                "label",
                                { staticClass: "h_title_3 text-deep-purple" },
                                [_vm._v(_vm._s(item.num))]
                              ),
                              _vm._v(" "),
                              _c(
                                "p",
                                { staticClass: "text-brand h_title_4 mt-8" },
                                [_vm._v(_vm._s(item.title))]
                              ),
                              _vm._v(" "),
                              _c(
                                "p",
                                {
                                  staticClass:
                                    "text-16 line-height-p ff-bold mt-auto",
                                },
                                [_vm._v(_vm._s(item.description))]
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              }),
            ],
            2
          ),
          _vm._v(" "),
          _c("div", { staticClass: "welcome-title" }, [
            _c("h2", { staticClass: "h_title_2" }, [
              _vm._v(_vm._s(_vm.$t("welcomeHHTitle"))),
            ]),
            _vm._v(" "),
            _c("img", {
              staticClass: "mt-10",
              attrs: {
                src: require("assets/images/arrow-down.svg"),
                alt: "Arrow Down Icon",
                width: "50",
              },
            }),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "custom-content welcome-cover" }, [
        _c("div", { staticClass: "d-flex inner" }, [
          _c("div", { staticClass: "left-side" }, [
            _c("h2", {
              staticClass: "h_title_2 mb-5",
              domProps: { innerHTML: _vm._s(_vm.$t("welcomeHH")) },
            }),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "mt-7" },
              [
                _vm._l(_vm.$t("welcomHHDescriptions"), function (item) {
                  return [
                    _c("p", { staticClass: "ff-bold text-16" }, [
                      _vm._v(_vm._s(item)),
                    ]),
                  ]
                }),
              ],
              2
            ),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "right-side" },
            [
              _c("v-img", {
                attrs: {
                  width: "100%",
                  src: _vm.getImage("insta-cover.webp", "insta-cover.svg"),
                  alt: " A woman in a floral outfit reaches upward against a clear sky, beside a graphic illustration showing increasing Instagram insights and 'Daily Views' indicating 1000+ views.",
                },
              }),
            ],
            1
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "custom-content mt-15" }, [
        _c("h2", {
          staticClass: "h_title_2 text-center",
          domProps: { innerHTML: _vm._s(_vm.$t("expHHPlatformTitle")) },
        }),
        _vm._v(" "),
        _c("div", { staticClass: "welcome-title my-5" }, [
          _c("p", { staticClass: "ff-bold text-16 mx-15" }, [
            _vm._v(_vm._s(_vm.$t("expHHPlatformDescription"))),
          ]),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { attrs: { id: "social-media" } }, [
      _c(
        "div",
        {
          staticClass:
            "custom-content d-flex flex-wrap justify-space-between align-center",
        },
        [
          _c("v-img", {
            attrs: {
              src: _vm.getImage("twitter.webp", "twitter.svg"),
              alt: "X or Twitter logo",
              width: "180",
            },
          }),
          _vm._v(" "),
          _c("v-img", {
            attrs: {
              src: _vm.getImage("youtube.webp", "youtube.svg"),
              alt: "Youtube logo",
              width: "180",
            },
          }),
          _vm._v(" "),
          _c("v-img", {
            attrs: {
              src: _vm.getImage("instagram.webp", "instagram.svg"),
              alt: "Instagram logo",
              width: "250",
            },
          }),
          _vm._v(" "),
          _c("v-img", {
            attrs: {
              src: _vm.getImage("tiktok.webp", "tiktok.svg"),
              alt: "Tiktok logo with coming soon text",
              width: "180",
            },
          }),
          _vm._v(" "),
          _c("v-img", {
            attrs: {
              src: _vm.getImage("facebook.webp", "facebook.svg"),
              alt: "Facebook Logo",
              width: "180",
            },
          }),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }