
import { Component, Prop, Vue } from 'vue-property-decorator'
import { requiredRule } from '~/utils/FormValidation'

@Component({})
export default class InstagramAccountEditForm extends Vue {
  @Prop({ default: () => {}, required: false }) readonly usersData!: any
  @Prop({ default: 'instabot' }) readonly urlType!: any

  userData: any = {};
  maxFollowerPerDay: any = 0;
  fileAccept: any = [requiredRule(), (value:any) => !value || value.type == 'text/plain' || this.$t('onlyTxtFileAllowed')];

  mounted() {
    this.userData = {
      id: this.usersData.id,
      pct_like: this.usersData.pct_like,
      pct_story: this.usersData.pct_story,
      pct_comment: this.usersData.pct_comment,
      pct_share: this.usersData.pct_share,
      pct_save: this.usersData.pct_save,
      max_follower_count: this.usersData.max_follower_count,
      daily_follower_limit_pct: this.usersData.daily_follower_limit_pct,
      current_follower_count: this.usersData.current_follower_count
    };
  }

  async save() {
    let params = {
      pct_like: this.userData.pct_like,
      pct_story: this.userData.pct_story,
      pct_comment: this.userData.pct_comment,
      pct_share: this.userData.pct_share,
      pct_save: this.userData.pct_save,
      daily_follower_limit_pct: this.userData.daily_follower_limit_pct
    } as any;

    if(this.userData.comments_text) {
      params.comments_text = this.userData.comments_text
    }
    await this.$axios.patch(`/${this.urlType}/instagram-accounts/${this.userData.id}/`, params)
    .then((response:any) => {
      this.$emit('success');
    }).catch((error:any) => {
      this.$emit('error', error);
    })
  }

  getUploadedData(data:any) {
    this.userData.comments_text = data;
  }
}
