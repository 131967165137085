
import { Component, Vue, Prop } from 'vue-property-decorator'

@Component({})
export default class MobileProxyApiFlatratePricingModal extends Vue {
  @Prop({ default: [] }) readonly pricing!: any;

  modal: boolean = false;

  show() {
    this.modal = true;
  }

  getSelectedPackage(id:any) {
    this.$emit('select-package', id);
    this.modal = false;
  }
}
