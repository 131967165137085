
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { AdminStore } from '~/store';
import { requiredRule } from '~/utils/FormValidation'

@Component({})
export default class InstagramAccountEditForm extends Vue {
  @Prop({ default: () => {}, required: false }) readonly accountData!: any

  menuDate: boolean = false;
  loading: boolean = false;
  requiredRule: any = [ requiredRule() ];
  data: any = {};

  mounted() {
    this.data = { ...this.accountData, user: this.accountData?.user?.id || null };
  }

  @Watch('accountData')
  checkAccountData() {
    this.data = { ...this.accountData, user: this.accountData?.user?.id || null };
  }

  async save() {
    const form = this.$refs.form as any;
    if(form.validate()) {
      this.loading = true;
      const params = {
        username: this.data.username,
        paid_until: this.data.paid_until,
        max_follower_count: this.data.max_follower_count,
        user: this.data.user
      }
      await this.$axios.patch(`/management/instagram-accounts/${this.data.id}/`, params)
      .then(() => {
        this.$emit('success');
      }).catch((error:any) => {
        this.$emit('error', { error: error, default: this.$t('errorSaveData') })
      })
      this.loading = false;
    }
  }
  
  getUser(item:any) {
    if(item) {
      if(!item.first_name && !item.last_name) {
        return item.email;
      } else {
        return (item?.first_name || "") + " " + (item?.last_name || "");
      }
    } else {
      return "";
    }
  }

  get list() {
    return AdminStore.getENUM;
  }
}
