var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _vm.progressLoading
        ? _c(
            "div",
            { staticClass: "text-center" },
            [
              _c("span", [_vm._v("Loading Preview")]),
              _vm._v(" "),
              _c("v-progress-linear", {
                staticClass: "my-1",
                attrs: {
                  indeterminate: _vm.progressLoading,
                  active: _vm.progressLoading,
                  color: "btn-brand",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      !_vm.progressLoading && (_vm.hasPreview || _vm.hasError)
        ? _c(
            "v-card",
            { attrs: { outlined: "" } },
            [
              _c("v-card-text", { staticClass: "pa-3" }, [
                !_vm.hasError
                  ? _c("div", { staticClass: "d-flex" }, [
                      _c(
                        "div",
                        {
                          staticClass: "rounded",
                          staticStyle: {
                            width: "110px",
                            height: "70px",
                            background: "#000",
                          },
                        },
                        [
                          _c("v-img", {
                            attrs: {
                              src: _vm.preview.image,
                              width: "110",
                              height: "70",
                              position: "center",
                              contain: "",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("p", { staticClass: "ml-3 mb-0 description" }, [
                        _vm._v(_vm._s(_vm.preview.description)),
                      ]),
                    ])
                  : _c(
                      "div",
                      { staticClass: "text-center text-brand" },
                      [
                        _c(
                          "v-icon",
                          {
                            staticClass: "mr-1 text-brand custom-icon-small",
                            attrs: { small: "" },
                          },
                          [_vm._v("mdi-instagram")]
                        ),
                        _vm._v(" "),
                        _c("label", [_vm._v(_vm._s(_vm.errorMessage))]),
                      ],
                      1
                    ),
              ]),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }