var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-dialog",
    {
      attrs: { persistent: "", "max-width": "600" },
      model: {
        value: _vm.modal,
        callback: function ($$v) {
          _vm.modal = $$v
        },
        expression: "modal",
      },
    },
    [
      _c(
        "v-card",
        { attrs: { id: "instagram-orders" } },
        [
          _c("v-card-title", { staticClass: "pb-0" }, [
            _c(
              "h2",
              {
                staticClass:
                  "h_title_3 font-weight-bold text-black text-center",
              },
              [_vm._v(_vm._s(_vm.$t("editOrder")))]
            ),
          ]),
          _vm._v(" "),
          _c(
            "v-card-text",
            { staticClass: "pa-6 pt-0" },
            [
              _c("v-divider", { staticClass: "mt-3 mb-5" }),
              _vm._v(" "),
              _c(
                "v-form",
                {
                  ref: "orderForm",
                  attrs: { onSubmit: "return false;" },
                  on: { submit: _vm.save },
                },
                [
                  _c("instagram-orders-drip-feed", {
                    ref: "dripFeed",
                    staticClass: "mb-5 mr-1",
                    attrs: { order: _vm.order },
                    on: {
                      "get-data": function ($event) {
                        _vm.order.drip_feed = $event
                      },
                      "correct-drip": function ($event) {
                        _vm.hasCorrectDripFeed = $event
                      },
                    },
                  }),
                  _vm._v(" "),
                  _c(
                    "label",
                    { staticClass: "text-16 font-weight-medium text-black" },
                    [_vm._v(_vm._s(_vm.$t("targetURL")))]
                  ),
                  _vm._v(" "),
                  _c("v-text-field", {
                    ref: "usernameField",
                    staticClass: "mt-2 custom-normal-input",
                    attrs: {
                      outlined: "",
                      "hide-details": "auto",
                      "background-color": "white",
                      placeholder: _vm.$t("enterURL"),
                      "error-messages": _vm.fieldErrorMessage.target_url,
                      rules: _vm.urlRule,
                    },
                    on: { keyup: _vm.checkUrl },
                    model: {
                      value: _vm.order.target_url,
                      callback: function ($$v) {
                        _vm.$set(_vm.order, "target_url", $$v)
                      },
                      expression: "order.target_url",
                    },
                  }),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "mt-1" },
                    [
                      _c("span", [
                        _vm._v(_vm._s(_vm.$t("supportedUrl")) + ": "),
                      ]),
                      _vm._v(" "),
                      _vm.order.order_type == "story_views"
                        ? [
                            _c("span", [_vm._v("https://www.instagram.com/")]),
                            _vm._v(" "),
                            _c(
                              "span",
                              { staticClass: "font-weight-bold font-italic" },
                              [_vm._v(_vm._s(_vm.$t("username")))]
                            ),
                            _vm._v(" "),
                            _c("span", [_vm._v("/")]),
                          ]
                        : _vm.order.order_type == "like_story_sequences" ||
                          _vm.order.order_type == "story_link_clicks"
                        ? [
                            _c("span", [
                              _vm._v("https://www.instagram.com/stories/"),
                            ]),
                            _vm._v(" "),
                            _c(
                              "span",
                              { staticClass: "font-weight-bold font-italic" },
                              [_vm._v(_vm._s(_vm.$t("username")))]
                            ),
                            _vm._v(" "),
                            _c("span", [_vm._v("/")]),
                            _vm._v(" "),
                            _c(
                              "span",
                              { staticClass: "font-weight-bold font-italic" },
                              [_vm._v("XXXXXX")]
                            ),
                            _vm._v(" "),
                            _c("span", [_vm._v("/")]),
                          ]
                        : _vm.order.order_type == "reel_views"
                        ? [
                            _c("span", [
                              _vm._v("https://www.instagram.com/reel/"),
                            ]),
                            _vm._v(" "),
                            _c(
                              "span",
                              { staticClass: "font-weight-bold font-italic" },
                              [_vm._v("XXXXXX")]
                            ),
                            _vm._v(" "),
                            _c("span", [_vm._v("/")]),
                          ]
                        : [
                            _c("div", [
                              _c("span", [
                                _vm._v("https://www.instagram.com/p/"),
                              ]),
                              _vm._v(" "),
                              _c(
                                "span",
                                { staticClass: "font-weight-bold font-italic" },
                                [_vm._v("XXXXXX")]
                              ),
                              _vm._v(" "),
                              _c("span", [_vm._v("/")]),
                            ]),
                            _vm._v(" "),
                            _c("div", [
                              _c("span", [
                                _vm._v("https://www.instagram.com/reel/"),
                              ]),
                              _vm._v(" "),
                              _c(
                                "span",
                                { staticClass: "font-weight-bold font-italic" },
                                [_vm._v("XXXXXX")]
                              ),
                              _vm._v(" "),
                              _c("span", [_vm._v("/")]),
                            ]),
                          ],
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _c("v-divider", { staticClass: "my-5" }),
                  _vm._v(" "),
                  _vm.hasError
                    ? _c("alert-error", {
                        staticClass: "mb-5",
                        attrs: { "error-message": _vm.errorMessage },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.success
                    ? _c("alert-success", {
                        staticClass: "mb-5",
                        attrs: { "success-message": _vm.$t("successSave") },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "text-right" },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "text-brand",
                          attrs: {
                            block: _vm.$vuetify.breakpoint.xs ? true : false,
                            outlined: "",
                            depressed: "",
                          },
                          on: { click: _vm.leave },
                        },
                        [_vm._v("\n            " + _vm._s(_vm.$t("cancel")))]
                      ),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        {
                          staticClass: "btn-brand px-8",
                          class: _vm.$vuetify.breakpoint.xs ? "mt-3" : "ml-2",
                          attrs: {
                            block: _vm.$vuetify.breakpoint.xs ? true : false,
                            depressed: "",
                            loading: _vm.loading,
                            type: "submit",
                          },
                        },
                        [_vm._v("\n            " + _vm._s(_vm.$t("save")))]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }