import Store from '@/utils/StoreInstance'
import { Module, VuexModule, Action, Mutation } from 'vuex-module-decorators'

@Module({stateFactory: true, store: Store, name: 'local-filters', namespaced: true})
export default class LocalStorageFilters extends VuexModule {
  filters: any = {};
  freezeWatch: boolean = false;
  
  @Mutation
  resetFilters() {
    this.filters = {};
  }
  @Mutation
  setFilters(item:any) {
    this.filters[item.tableName] = item.filters;
  }

  @Action({commit: 'setFilters', rawError: true})
  async fetchFilters(table:any) {
    const filters = localStorage.getItem(table);
    return { tableName: table, filters: filters };
  }

  @Action({commit: 'setFilters', rawError: true})
  async saveFilters(item:any) {
    localStorage.setItem(item.tableName, JSON.stringify(item.filters));
    return { ...item };
  }

  get localFilters() {
    return this.filters;
  }


  @Mutation
  setFlag(item:any) {
    this.freezeWatch = item;
  }
  get getFreeWatchFlag() {
    return this.freezeWatch;
  }
}