
import { Component, Vue } from 'vue-property-decorator'

@Component({})
export default class CookieConsent extends Vue {
  hasConsent: boolean = true;
  loading: boolean = false;

  async mounted() {
    if(await this.getCookie('consent')) {
      this.hasConsent = true;
    } else {
      this.hasConsent = false;
    }
  }

  getCookie(name:any) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`) as any;
    if (parts.length === 2) {
      return parts.pop().split(';').shift();
    }
  }

  async consent() {
    this.loading = true;
    document.cookie = "consent=true; expires=" + this.$dayjs().add(1, 'day').format('ddd, D MMM YYYY hh:mm:ss') + ";";
    if(await this.getCookie('consent')) {
      this.hasConsent = true;
    }
    this.loading = false;
  }
}
