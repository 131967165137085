<template>
  <v-app dark>
    <div class="text-center nothing-show">
      <v-icon x-large>mdi-alert-outline</v-icon>
      <h1 class="h_title_2">404 Not Found</h1>
      <nuxt-link :to="localePath('/')">Home</nuxt-link>
    </div>
  </v-app>
</template>

<script>
export default {
  name: 'EmptyLayout',
  layout: 'empty',
}
</script>
