var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "language-selection",
      class: _vm.$vuetify.breakpoint.xs ? "my-5" : "ml-5",
    },
    [
      _c(
        "v-menu",
        {
          attrs: {
            "offset-y": "",
            left: "",
            "content-class": "mt-3 services-menu",
          },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function ({ on, attrs }) {
                return [
                  _c(
                    "div",
                    _vm._g(
                      _vm._b(
                        { staticClass: "d-flex align-center" },
                        "div",
                        attrs,
                        false
                      ),
                      on
                    ),
                    [
                      _vm.language == "en"
                        ? [
                            _c("img", {
                              attrs: {
                                src: require("assets/images/en-flag.png"),
                                width: "15",
                                height: "100%",
                              },
                            }),
                            _vm._v(" "),
                            !_vm.isIconOnly
                              ? _c(
                                  "span",
                                  { staticClass: "ml-2 font-weight-medium" },
                                  [_vm._v("English")]
                                )
                              : _vm._e(),
                          ]
                        : _vm.language == "de"
                        ? [
                            _c("img", {
                              attrs: {
                                src: require("assets/images/de-flag.png"),
                                width: "15",
                                height: "100%",
                              },
                            }),
                            _vm._v(" "),
                            !_vm.isIconOnly
                              ? _c(
                                  "span",
                                  { staticClass: "ml-2 font-weight-medium" },
                                  [_vm._v("German")]
                                )
                              : _vm._e(),
                          ]
                        : [
                            _c("v-icon", { staticClass: "mr-3" }, [
                              _vm._v("mdi-translate"),
                            ]),
                          ],
                      _vm._v(" "),
                      _c(
                        "v-icon",
                        { staticClass: "ml-1", attrs: { size: "20" } },
                        [_vm._v("mdi-chevron-down")]
                      ),
                    ],
                    2
                  ),
                ]
              },
            },
          ]),
        },
        [
          _vm._v(" "),
          _c(
            "v-list",
            { staticClass: "py-2", attrs: { dense: "" } },
            [
              _vm._l(_vm.locales, function (item) {
                return [
                  _c(
                    "v-list-item",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.changeLanguage(item.code)
                        },
                      },
                    },
                    [
                      _c(
                        "v-list-item-content",
                        [
                          _c(
                            "v-list-item-title",
                            { staticClass: "d-flex align-center" },
                            [
                              item.code == "en"
                                ? _c("img", {
                                    attrs: {
                                      src: require("assets/images/en-flag.png"),
                                      width: "20",
                                    },
                                  })
                                : item.code == "de"
                                ? _c("img", {
                                    attrs: {
                                      src: require("assets/images/de-flag.png"),
                                      width: "20",
                                    },
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              _c(
                                "span",
                                {
                                  staticClass: "ml-2 text-12",
                                  class: {
                                    "text-brand font-weight-medium":
                                      _vm.language == item.code,
                                  },
                                },
                                [_vm._v(_vm._s(item.name))]
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              }),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }