var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { attrs: { id: "testimonial-section" } }, [
    _c("div", { staticClass: "text-center" }, [
      _c("label", { staticClass: "text-16 ff-bold text-brand" }, [
        _vm._v(_vm._s(_vm.$t("testimonialsTop"))),
      ]),
      _vm._v(" "),
      _c("h2", {
        staticClass: "h_title_2 fw-bold mt-2",
        domProps: { innerHTML: _vm._s(_vm.$t("testimonialsTitle")) },
      }),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "testimonial-container" },
      [
        _c(
          "div",
          {
            staticClass: "arrow left-arrow cursor-pointer",
            on: { click: _vm.prevSlide },
          },
          [
            _c("img", {
              attrs: {
                src: require("assets/images/left-circle-arrow.svg"),
                alt: "Left Circle Arrow Icon",
                width: "55",
                height: "100%",
                loading: "lazy",
              },
            }),
          ]
        ),
        _vm._v(" "),
        _vm._l(_vm.testimonials, function (testimonial, index) {
          return _c("div", { key: index, class: _vm.getCardClass(index) }, [
            _c("div", { staticClass: "inner" }, [
              _c("div", { staticClass: "d-flex align-center user" }, [
                _c("img", {
                  attrs: {
                    src: _vm.getImage(
                      testimonial.image + ".webp",
                      testimonial.image + ".svg"
                    ),
                    alt: "Testimonial Image",
                    width: "60",
                    height: "60",
                    loading: "lazy",
                  },
                }),
                _vm._v(" "),
                _c("div", { staticClass: "ml-3" }, [
                  _c("h3", { staticClass: "h_title_4 text-deep-purple" }, [
                    _vm._v(_vm._s(testimonial.name)),
                  ]),
                  _vm._v(" "),
                  _c("label", { staticClass: "text-16 text-grey" }, [
                    _vm._v(_vm._s(testimonial.role)),
                  ]),
                ]),
                _vm._v(" "),
                _c("img", {
                  staticClass: "ml-auto",
                  attrs: {
                    src: require("assets/images/stars.svg"),
                    alt: "Stars Icon",
                    width: "130",
                    height: "50",
                    loading: "lazy",
                  },
                }),
              ]),
              _vm._v(" "),
              _c("p", { staticClass: "ff-bold mb-7 mt-5 line-height-p" }, [
                _vm._v(_vm._s(testimonial.text)),
              ]),
            ]),
          ])
        }),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "arrow right-arrow cursor-pointer",
            on: { click: _vm.nextSlide },
          },
          [
            _c("img", {
              attrs: {
                src: require("assets/images/right-circle-arrow.svg"),
                alt: "Right Cirlce Arrow Icon",
                width: "55",
                height: "100%",
                loading: "lazy",
              },
            }),
          ]
        ),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }