
import { Component, Vue, Prop } from 'vue-property-decorator'

@Component({})
export default class OrderTypesSelection extends Vue {
  @Prop({ default: () => { return {} }, required: false }) readonly item!: any
  @Prop({ default: () => { return "" }, required: false }) readonly customClass!: any
  @Prop({ default: () => { return "30" }, required: false }) readonly size!: any
  @Prop({ default: () => false, required: false }) readonly iconOnly!: boolean
}
