
import { Component, Vue } from 'vue-property-decorator'
import { getImage } from "~/utils/ImageUtils"

@Component({
  layout: 'landing-page',
})
export default class SMMPanelsSection extends Vue {
  smmPanelCards: any = [];

  mounted() {
    this.smmPanelCards = this.$t('smmPanelsCards') as any;
  }
  
  getImage(filename:any, fallback:any) {
    return getImage(filename, fallback);
  }
}
