var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("el-date-picker", {
    staticClass: "custom-date-picker-input",
    attrs: {
      type: "daterange",
      "range-separator": "-",
      format: _vm.dateFormat,
      "value-format": "yyyy-MM-dd",
      "start-placeholder": _vm.$t("startDate"),
      "end-placeholder": _vm.$t("endData"),
      "popper-class": "custom-date-picker-popup",
      clearable: false,
    },
    on: { change: _vm.getDate },
    model: {
      value: _vm.dateSelected,
      callback: function ($$v) {
        _vm.dateSelected = $$v
      },
      expression: "dateSelected",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }