var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "12", md: "6", "offset-md": "3" } },
            [
              !_vm.isEdit
                ? _c(
                    "div",
                    { staticClass: "mt-10 text-center" },
                    [
                      _c(
                        "v-avatar",
                        { attrs: { size: "60", color: "btn-brand" } },
                        [
                          _vm.profilePic
                            ? _c("img", { attrs: { src: _vm.profilePic } })
                            : _c(
                                "v-icon",
                                {
                                  staticClass: "white--text",
                                  attrs: { size: "40" },
                                },
                                [_vm._v("mdi-account")]
                              ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "d-flex align-center cursor-pointer justify-center mt-5",
                          on: {
                            click: function ($event) {
                              _vm.isEdit = true
                              _vm.editForm()
                            },
                          },
                        },
                        [
                          _c(
                            "v-icon",
                            {
                              staticClass: "text-purple-2",
                              attrs: { size: "14" },
                            },
                            [_vm._v("mdi-square-edit-outline")]
                          ),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              staticClass:
                                "ml-1 text-purple-2 font-weight-medium text-14",
                            },
                            [_vm._v(_vm._s(_vm.$t("editProfile")))]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.userLoading
                        ? _c("v-progress-circular", {
                            staticClass: "ml-1 mt-5",
                            attrs: {
                              size: 25,
                              width: 3,
                              indeterminate: _vm.userLoading,
                              color: "btn-brand",
                            },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _c("h2", { staticClass: "h_title_1 mt-5" }, [
                        _vm._v(_vm._s(_vm.currentUser.name)),
                      ]),
                      _vm._v(" "),
                      _c(
                        "label",
                        {
                          staticClass:
                            "text-16 font-weight-medium text-dark-grey",
                        },
                        [_vm._v(_vm._s(_vm.currentUser.email))]
                      ),
                    ],
                    1
                  )
                : _c(
                    "v-card",
                    { attrs: { elevation: "0" } },
                    [
                      _c(
                        "v-card-text",
                        { staticClass: "px-10" },
                        [
                          _c(
                            "div",
                            { staticClass: "d-flex align-center" },
                            [
                              _c(
                                "v-avatar",
                                { attrs: { size: "60", color: "btn-brand" } },
                                [
                                  _vm.profilePic
                                    ? _c("img", {
                                        attrs: { src: _vm.profilePic },
                                      })
                                    : _c(
                                        "v-icon",
                                        {
                                          staticClass: "white--text",
                                          attrs: { size: "40" },
                                        },
                                        [_vm._v("mdi-account")]
                                      ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c("div", { staticClass: "text-left ml-3" }, [
                                _c(
                                  "h3",
                                  { staticClass: "text-16 font-weight-medium" },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.currentUser.name != " "
                                          ? _vm.currentUser.name
                                          : _vm.currentUser.email
                                      )
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "label",
                                  { staticClass: "text-14 text-dark-grey" },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.$t("updatedLast")) +
                                        ": " +
                                        _vm._s(
                                          _vm
                                            .$dayjs(
                                              _vm.currentUser.modified + "Z"
                                            )
                                            .format("MMMM DD, YYYY")
                                        )
                                    ),
                                  ]
                                ),
                              ]),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-form",
                            {
                              ref: "editForm",
                              attrs: { onSubmit: "return false;" },
                              on: { submit: _vm.save },
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "mt-5" },
                                [
                                  _c(
                                    "label",
                                    {
                                      staticClass:
                                        "text-16 font-weight-medium text-black",
                                    },
                                    [_vm._v(_vm._s(_vm.$t("firstName")))]
                                  ),
                                  _vm._v(" "),
                                  _c("v-text-field", {
                                    staticClass: "mt-2 mb-5",
                                    attrs: { outlined: "", "hide-details": "" },
                                    model: {
                                      value: _vm.editUser.first_name,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.editUser,
                                          "first_name",
                                          $$v
                                        )
                                      },
                                      expression: "editUser.first_name",
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c(
                                    "label",
                                    {
                                      staticClass:
                                        "text-16 font-weight-medium text-black",
                                    },
                                    [_vm._v(_vm._s(_vm.$t("lastName")))]
                                  ),
                                  _vm._v(" "),
                                  _c("v-text-field", {
                                    staticClass: "mt-2 mb-5",
                                    attrs: { outlined: "", "hide-details": "" },
                                    model: {
                                      value: _vm.editUser.last_name,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.editUser, "last_name", $$v)
                                      },
                                      expression: "editUser.last_name",
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c(
                                    "label",
                                    {
                                      staticClass:
                                        "text-16 font-weight-medium text-black",
                                    },
                                    [_vm._v(_vm._s(_vm.$t("email")))]
                                  ),
                                  _vm._v(" "),
                                  _c("v-text-field", {
                                    staticClass: "mt-2 mb-5",
                                    attrs: {
                                      outlined: "",
                                      "hide-details": "",
                                      disabled: "",
                                    },
                                    model: {
                                      value: _vm.editUser.email,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.editUser, "email", $$v)
                                      },
                                      expression: "editUser.email",
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "d-flex align-center" },
                                    [
                                      !_vm.isChangePass
                                        ? [
                                            _c(
                                              "label",
                                              {
                                                staticClass:
                                                  "text-16 font-weight-medium text-black",
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(_vm.$t("password"))
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "text-purple-2 font-weight-medium text-14 ml-auto cursor-pointer",
                                                on: {
                                                  click: function ($event) {
                                                    _vm.isChangePass = true
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                    " +
                                                    _vm._s(_vm.$t("change"))
                                                ),
                                              ]
                                            ),
                                          ]
                                        : _c(
                                            "v-icon",
                                            {
                                              staticClass:
                                                "ml-auto cursor-pointer",
                                              on: {
                                                click: function ($event) {
                                                  _vm.isChangePass = false
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                  mdi-close-circle"
                                              ),
                                            ]
                                          ),
                                    ],
                                    2
                                  ),
                                  _vm._v(" "),
                                  _vm.isChangePass
                                    ? _c(
                                        "div",
                                        [
                                          _vm.urlType == "instabot"
                                            ? [
                                                _c(
                                                  "label",
                                                  {
                                                    staticClass:
                                                      "text-16 font-weight-medium text-black",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t("currentPass")
                                                      )
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c("v-text-field", {
                                                  staticClass: "mt-2 mb-5",
                                                  attrs: {
                                                    outlined: "",
                                                    placeholder:
                                                      _vm.$t(
                                                        "enterCurrentPass"
                                                      ),
                                                    "hide-details": "auto",
                                                    rules: _vm.passwordRule,
                                                    "append-icon":
                                                      _vm.showOldPassword
                                                        ? "mdi-eye"
                                                        : "mdi-eye-off",
                                                    type: _vm.showOldPassword
                                                      ? "text"
                                                      : "password",
                                                    "error-messages":
                                                      _vm.fieldErrorMessage
                                                        .old_password,
                                                  },
                                                  on: {
                                                    "click:append": function (
                                                      $event
                                                    ) {
                                                      _vm.showOldPassword =
                                                        !_vm.showOldPassword
                                                    },
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.editUser.old_password,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.editUser,
                                                        "old_password",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "editUser.old_password",
                                                  },
                                                }),
                                              ]
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _c(
                                            "label",
                                            {
                                              staticClass:
                                                "text-16 font-weight-medium text-black",
                                            },
                                            [_vm._v(_vm._s(_vm.$t("newPass")))]
                                          ),
                                          _vm._v(" "),
                                          _c("v-text-field", {
                                            staticClass: "mt-2 mb-5",
                                            attrs: {
                                              outlined: "",
                                              placeholder:
                                                _vm.$t("enterNewPass"),
                                              "hide-details": "auto",
                                              rules: _vm.passwordRule,
                                              "append-icon": _vm.showPassword
                                                ? "mdi-eye"
                                                : "mdi-eye-off",
                                              type: _vm.showPassword
                                                ? "text"
                                                : "password",
                                              "error-messages":
                                                _vm.fieldErrorMessage.password,
                                            },
                                            on: {
                                              "click:append": function (
                                                $event
                                              ) {
                                                _vm.showPassword =
                                                  !_vm.showPassword
                                              },
                                            },
                                            model: {
                                              value: _vm.editUser.password,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.editUser,
                                                  "password",
                                                  $$v
                                                )
                                              },
                                              expression: "editUser.password",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c(
                                            "label",
                                            {
                                              staticClass:
                                                "text-16 font-weight-medium text-black",
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.$t("confirmNewPass"))
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c("v-text-field", {
                                            staticClass: "mt-1 mb-5",
                                            attrs: {
                                              outlined: "",
                                              placeholder:
                                                _vm.$t("confirmYourNewPass"),
                                              "hide-details": "auto",
                                              rules: _vm.passwordRule,
                                              "append-icon":
                                                _vm.showConfirmPassword
                                                  ? "mdi-eye"
                                                  : "mdi-eye-off",
                                              type: _vm.showConfirmPassword
                                                ? "text"
                                                : "password",
                                              "error-messages":
                                                _vm.fieldErrorMessage.password,
                                            },
                                            on: {
                                              "click:append": function (
                                                $event
                                              ) {
                                                _vm.showConfirmPassword =
                                                  !_vm.showConfirmPassword
                                              },
                                            },
                                            model: {
                                              value:
                                                _vm.editUser.confirm_password,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.editUser,
                                                  "confirm_password",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "editUser.confirm_password",
                                            },
                                          }),
                                        ],
                                        2
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _vm.hasError
                                ? _c("alert-error", {
                                    staticClass: "mt-3",
                                    attrs: {
                                      "error-message": _vm.errorMessage,
                                    },
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.success
                                ? _c("alert-success", {
                                    staticClass: "mt-3",
                                    attrs: {
                                      "success-message": _vm.$t("successSave"),
                                    },
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              _c(
                                "v-btn",
                                {
                                  staticClass: "btn-brand mt-7",
                                  attrs: {
                                    depressed: "",
                                    block: "",
                                    large: "",
                                    type: "submit",
                                    loading: _vm.loading,
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(_vm.$t("saveChanges"))
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "v-btn",
                                {
                                  staticClass: "text-brand mt-3",
                                  attrs: {
                                    outlined: "",
                                    depressed: "",
                                    block: "",
                                    large: "",
                                  },
                                  on: {
                                    click: function ($event) {
                                      _vm.isEdit = false
                                      _vm.editForm()
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(_vm.$t("cancel"))
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("snackbar-error", { ref: "error" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }