
import { Component, Vue } from 'vue-property-decorator'
import { AccountsStore } from '@/store'
import { getErrorMessage } from "~/utils/ErrorMessageUtils"

@Component({})
export default class InstagramRestartSubscription extends Vue {
  modal: boolean = false;
  loading: boolean = false;
  success: boolean = false;
  hasError: boolean = false;
  errorMessage: any = "";
  accountId: any = null;
  urlType: any = 'instabot';
  data: any = { max_follower_count: 500 };

  mounted() {
    if(this.$auth && this.$auth.user && this.$auth.user.is_admin) {
      this.data.user = this.$auth.user.id;
    }
  }

  show(id:any, type: any = 'instabot', maxCount: any = 500) {
    this.accountId = id;
    this.modal = true;
    this.hasError = false;
    this.urlType = type;
    this.data.max_follower_count = maxCount;
  }

  get igSubMinFollowers() {
    return parseInt(process.env.IG_SUB_MIN_FOLLOWERS || '100');
  }

  async renew() {
    this.loading = true;
    await this.$axios.post(`/${this.urlType}/instagram-accounts/${this.accountId}/resubscribe/`, this.data)
    .then(() => {
      this.success = true;
      setTimeout(() => {
        this.data.max_follower_count = 500;
        this.success = false;
        this.modal = false;
        this.$emit('success');
      }, 1500)
    }).catch((error:any) => {
      this.errorMessage = getErrorMessage(error, this.$t('errorSaveData'));
      this.hasError = true;
    })
    this.loading = false;
  }

  get currentAccount() {
    return AccountsStore.getCurrentAccount;
  }

  get credits() {
    return AccountsStore.getCredits;
  }
}
