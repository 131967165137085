import { $axios } from '@/utils/Api'
import Store from '@/utils/StoreInstance'
import { Module, VuexModule, Action, Mutation } from 'vuex-module-decorators'

@Module({stateFactory: true, store: Store, name: 'admin', namespaced: true})
export default class AdminModule extends VuexModule {
  enum: any = {};
  configs: any = [];
  proxy: any = [];
  users: any = [];
  reportFilter: any = {};
  revenueReport: any = null;
  overview: any = {};
  discounts: any = {};

  @Mutation
  resetENUM() {
    this.enum = {};
  }
  @Mutation
  setENUM(item: any) {
    this.enum = item;
  }
  @Action({commit: 'setENUM', rawError: true})
  async fetchENUM() {
    const { data: results } = await $axios.get('/management/enum/');
    return results;
  }
  get getENUM():any {
    return this.enum;
  }

  @Mutation
  setConfigs(item: any) {
    this.configs = item;
  }
  @Action({commit: 'setConfigs', rawError: true})
  async fetchConfigs() {
    const { data: results } = await $axios.get('/management/configs/');
    return results;
  }
  get getConfigs():any {
    return this.configs;
  }

  @Mutation
  setProxyConfigs(item: any) {
    this.proxy = item;
  }
  @Action({commit: 'setProxyConfigs', rawError: true})
  async fetchProxyConfigs(param:any) {
    const { data: results } = await $axios.get('/proxyapi/' + param.type);
    return results;
  }
  get getProxyConfigs():any {
    return this.proxy;
  }

  // Get Home Overview
  @Mutation
  setOverview(item: any) {
    this.overview = item;
  }
  @Action({commit: 'setOverview', rawError: true})
  async fetchOverview() {
    const { data: results } = await $axios.get('/core/overview/');
    return results;
  }
  get getOverview():any {
    return this.overview;
  }

  // Get Users by Admin
  @Mutation
  setUsers(item: any) {
    this.users = item;
  }
  @Action({commit: 'setUsers', rawError: true})
  async fetchUsers(params:any) {
    const { data: results } = await $axios.get('/management/users/', { params: params });
    return results;
  }
  get getUsers():any {
    return this.users;
  }

  // Get Report Filter
  @Mutation
  setReportFilter(item: any) {
    this.reportFilter = item;
  }
  @Action({commit: 'setReportFilter', rawError: true})
  async fetchReportFilter() {
    const { data: results } = await $axios.get('/management/reports/transactions/monthly_revenue_filter_choices/');
    return results;
  }
  get getReportFilter():any {
    return this.reportFilter;
  }

  // Get Revenue Report
  @Mutation
  setRevenueReport(item: any) {
    this.revenueReport = item;
  }
  @Action({commit: 'setRevenueReport', rawError: true})
  async fetchRevenueReport(params:any) {
    const { data: results } = await $axios.get(`/management/reports/transactions/monthly_revenue/`, { params: params });
    return results;
  }
  get getsetRevenueReport():any {
    return this.revenueReport;
  }


  // Discounts
  @Mutation
  setDiscounts(item: any) {
    this.discounts = item;
  }
  get getDiscounts():any {
    return this.discounts;
  }
}
