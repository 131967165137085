var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return !_vm.pageLoading
    ? _c(
        "div",
        [
          !_vm.isMobile
            ? [
                _c("settings-transactions-table", {
                  attrs: {
                    data: _vm.allData,
                    loading: _vm.progressLoading,
                    "current-page": _vm.page,
                    "current-per-page": _vm.perPage,
                    "is-per-user": true,
                  },
                  on: {
                    refresh: function ($event) {
                      return _vm.refreshTable($event)
                    },
                  },
                }),
              ]
            : [
                _c("v-progress-linear", {
                  staticClass: "mb-1",
                  attrs: {
                    indeterminate: _vm.progressLoading,
                    active: _vm.progressLoading,
                    color: "btn-brand",
                  },
                }),
                _vm._v(" "),
                _c("standard-card-list", {
                  attrs: {
                    componentName: "settings-transactions-card-list",
                    data: _vm.allData,
                    loading: _vm.progressLoading,
                    "current-page": _vm.page,
                    "current-per-page": _vm.perPage,
                    "no-add-btn": true,
                  },
                  on: {
                    refresh: function ($event) {
                      return _vm.refreshTable($event)
                    },
                  },
                }),
              ],
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }