
import { Component, Vue } from 'vue-property-decorator'
import { getImage } from "~/utils/ImageUtils"

@Component({
  layout: 'landing-page',
})
export default class StarTestimonialSection extends Vue {
  testimonial: any = null;

  mounted() {
    const data = this.$t('testimonials') as any;
    if(data.length > 0) {
      this.testimonial = data[1];
    }
  }
  
  getImage(filename:any, fallback:any) {
    return getImage(filename, fallback);
  }
}
