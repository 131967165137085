var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "v-card",
        { staticClass: "custom-cards" },
        [
          _c(
            "v-card-text",
            { staticClass: "py-2" },
            [
              _c(
                "div",
                { staticClass: "d-flex align-center" },
                [
                  _c("h3", [_vm._v(_vm._s(_vm.$t("categories")))]),
                  _vm._v(" "),
                  _vm.$auth && _vm.$auth.user && _vm.$auth.user.is_admin
                    ? _c(
                        "v-btn",
                        {
                          staticClass: "ml-auto",
                          attrs: { icon: "", small: "", color: "primary" },
                          on: {
                            click: function ($event) {
                              _vm.$router.push(
                                _vm.localePath(
                                  "/dashboard/settings/blogs/categories-configuration"
                                )
                              )
                            },
                          },
                        },
                        [
                          _c("v-icon", { attrs: { small: "" } }, [
                            _vm._v("mdi-cog"),
                          ]),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _c("v-divider", { staticClass: "mt-2" }),
              _vm._v(" "),
              _c("v-progress-linear", {
                attrs: {
                  indeterminate: _vm.progressLoading,
                  active: _vm.progressLoading,
                  color: "btn-brand",
                },
              }),
              _vm._v(" "),
              _c(
                "v-list",
                { staticClass: "category-list", attrs: { dense: "" } },
                [
                  _c(
                    "v-list-item",
                    {
                      staticClass: "pl-0",
                      class: { active: _vm.activeId == 0 },
                      attrs: { ripple: false },
                      on: {
                        click: function ($event) {
                          return _vm.filter({ id: null, name: "" })
                        },
                      },
                    },
                    [
                      _c(
                        "v-list-item-content",
                        { staticClass: "py-0" },
                        [
                          _c("v-list-item-title", [
                            _vm._v(_vm._s(_vm.$t("all"))),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm._l(_vm.categories, function (item) {
                    return _c(
                      "v-list-item",
                      {
                        key: item.id,
                        staticClass: "pl-0",
                        class: { active: _vm.activeId == item.id },
                        attrs: { ripple: false },
                        on: {
                          click: function ($event) {
                            return _vm.filter(item)
                          },
                        },
                      },
                      [
                        _c(
                          "v-list-item-content",
                          { staticClass: "py-0" },
                          [
                            _vm.$i18n.locale == "en"
                              ? _c("v-list-item-title", [
                                  _vm._v(_vm._s(item.name)),
                                ])
                              : _c("v-list-item-title", [
                                  _vm._v(
                                    _vm._s(
                                      item.german_name
                                        ? item.german_name
                                        : item.name
                                    )
                                  ),
                                ]),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  }),
                ],
                2
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("settings-blogs-modal-categories", {
        ref: "categoriesModal",
        on: { success: _vm.getCategories },
      }),
      _vm._v(" "),
      _c("snackbar-error", { ref: "error" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }