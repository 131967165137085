var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "v-row",
        [
          !_vm.noAddBtn
            ? _c(
                "v-col",
                {
                  staticClass: "pb-0",
                  attrs: { cols: "12", md: "3", sm: "6" },
                },
                [
                  _c(
                    "v-card",
                    {
                      staticClass: "custom-cards bordered",
                      attrs: {
                        height: "100%",
                        ripple: false,
                        to: _vm.getLink(),
                      },
                    },
                    [
                      _c(
                        "v-card-text",
                        { staticClass: "d-flex align-center justify-center" },
                        [
                          _c(
                            "div",
                            { staticClass: "text-center" },
                            [
                              _c("v-icon", {}, [
                                _vm._v("mdi-plus-circle-outline"),
                              ]),
                              _vm._v(" "),
                              _c(
                                "h3",
                                {
                                  staticClass:
                                    "text-16 font-weight-medium mt-3",
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$auth &&
                                        _vm.$auth.user &&
                                        _vm.$auth.user.is_admin
                                        ? _vm.$t("addTransaction")
                                        : _vm.$t("addCredit")
                                    )
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm._l(_vm.data, function (item) {
            return [
              _c(
                "v-col",
                {
                  staticClass: "pb-0",
                  attrs: { cols: "12", md: "3", sm: "6" },
                },
                [
                  _c(
                    "v-card",
                    {
                      staticClass: "custom-cards bordered",
                      attrs: { height: "100%" },
                    },
                    [
                      _c(
                        "v-card-text",
                        { staticClass: "px-4 pt-3 pb-2" },
                        [
                          _c("div", { staticClass: "d-flex" }, [
                            _c("div", { staticClass: "w-100" }, [
                              _c("div", { staticClass: "d-flex" }, [
                                _c(
                                  "h1",
                                  {
                                    staticClass:
                                      "text-14 username text-capitalize",
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        item.payment_method.replace(/_/g, " ")
                                      )
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "label",
                                  {
                                    staticClass:
                                      "ml-auto mr-1 font-weight-medium",
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.formatDate(item.payment_date))
                                    ),
                                  ]
                                ),
                              ]),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "d-flex mt-1" },
                                [
                                  _c("span", { staticClass: "mr-1" }, [
                                    _vm._v(_vm._s(_vm.formatFloat(item.price))),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    { staticClass: "text-uppercase" },
                                    [_vm._v(_vm._s(item.currency))]
                                  ),
                                  _vm._v(" "),
                                  _c("v-divider", {
                                    staticClass: "mx-3",
                                    attrs: { vertical: "" },
                                  }),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "d-flex align-center justify-end mr-8",
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "mr-1 font-weight-medium",
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.formatFloat(item.credit_qty)
                                            )
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("img", {
                                        attrs: {
                                          src: require("assets/images/icons/twemoji_coin.svg"),
                                          width: "15",
                                        },
                                      }),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("v-divider", { staticClass: "mt-2" }),
                          _vm._v(" "),
                          _vm.$auth && _vm.$auth.user && _vm.$auth.user.is_admin
                            ? _c(
                                "div",
                                { staticClass: "mt-2 line-height-normal" },
                                [
                                  _c(
                                    "nuxt-link",
                                    {
                                      attrs: {
                                        to: `/dashboard/admin/users/${item.user.id}/view`,
                                      },
                                    },
                                    [
                                      item.user.first_name
                                        ? _c(
                                            "span",
                                            { staticClass: "text-brand" },
                                            [
                                              _vm._v(
                                                _vm._s(item.user.first_name)
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      item.user.first_name &&
                                      item.user.last_name
                                        ? _c("span", {
                                            staticClass: "text-brand",
                                          })
                                        : _vm._e(),
                                      _vm._v(" "),
                                      item.user.last_name
                                        ? _c(
                                            "span",
                                            { staticClass: "text-brand" },
                                            [
                                              _vm._v(
                                                _vm._s(item.user.last_name)
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _c("div", [
                                        _c(
                                          "span",
                                          { staticClass: "text-brand" },
                                          [_vm._v(_vm._s(item.user.email))]
                                        ),
                                      ]),
                                    ]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c("p", { staticClass: "mb-0 mt-2" }, [
                            _c("label", { staticClass: "font-weight-medium" }, [
                              _vm._v(_vm._s(_vm.$t("addtlInfo")) + ":"),
                            ]),
                            _vm._v(" "),
                            _c("span", [
                              _vm._v(_vm._s(item.payment_meta_data)),
                            ]),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }