
import { Component, Vue } from 'vue-property-decorator'

@Component({})
export default class SettingsAddCreditsModal extends Vue {
  modal: boolean = false;

  show() {
    this.modal = true;
  }
}
