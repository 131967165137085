var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "main-breadcrumbs" },
    [
      _vm.items.length > 0
        ? [
            _c("v-breadcrumbs", {
              staticClass: "py-3 px-1",
              attrs: { items: _vm.items },
              scopedSlots: _vm._u(
                [
                  {
                    key: "divider",
                    fn: function () {
                      return [_c("v-icon", [_vm._v("mdi-chevron-right")])]
                    },
                    proxy: true,
                  },
                ],
                null,
                false,
                3681283568
              ),
            }),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }