
import { Component, Vue } from 'vue-property-decorator'
import { ProxyAPIStore } from '~/store';
import { getErrorMessage } from '~/utils/ErrorMessageUtils'

@Component({})
export default class MobileProxyApiAddRemovePortsModal extends Vue {
  modal: boolean = false;
  type: any = "";
  ports: any = null;
  subscription: any = {};
  success: boolean = false;
  hasError: boolean = false;
  errorMessage: any = "";
  loading: boolean = false;
  isAdmin: boolean = false;

  show(item:any) {
    this.ports = null;
    this.type = item.type;
    this.subscription = item.subscription;
    this.isAdmin = item?.isAdmin || false;
    this.modal = true;
    this.hasError = false;
  }

  checkPorts() {
    this.hasError = false;
    if(this.type == 'remove') {
      const ports = parseInt(this.subscription.ports) - parseInt(this.ports);
      if(ports < 0) {
        this.hasError = true;
        this.errorMessage = [this.$t('toRemovePorts')];
        this.ports = null;
      }
    }
  }

  async addRemovePorts() {
    const ports = this.type == 'add' 
      ? parseInt(this.subscription.ports) + parseInt(this.ports)
      : parseInt(this.subscription.ports) - parseInt(this.ports);

    let data = { ports: ports } as any;
    if(this.isAdmin) {
      data.user = this.subscription?.user.id;
    }
    
    this.loading = true;
    await this.$axios.patch('/proxyapi/subscription/', data)
    .then((response:any) => {
      this.success = true;
      
      setTimeout(() => {
        this.success = false;
        this.modal = false;
        this.$emit('success');
        ProxyAPIStore.setSubscription(response.data);
      }, 1500)
    }).catch((error:any) => {
      this.errorMessage = getErrorMessage(error, this.$t('errorSaveData'));
      this.hasError = true;
    })
    this.loading = false;
  }
}
