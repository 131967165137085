
import { Component, Vue, Prop } from 'vue-property-decorator'
import { BlogsStore } from '@/store'
import { getErrorMessage } from "~/utils/ErrorMessageUtils";

@Component({})
export default class SettingsBlogsCategoriesConfig extends Vue {
  @Prop({ default: false }) readonly isMobile!: Boolean
  @Prop({ default: () => ({}) }) readonly default!: any

  categories: any = [];
  progressLoading: boolean = false;
  activeId: any = 0;

  async mounted() {
    this.progressLoading = true;
    await this.getCategories();
    this.progressLoading = false;
  }

  async getCategories() {
    await BlogsStore.fetchCategories()
    .then((response:any) => {
      this.categories = response.results;
      if(this.default.cat) {
        this.activeId = this.default.cat;
      }
    }).catch((error) => {
      const message = getErrorMessage(error, this.$t('errorFetchCategories'));
      const snackbar = this.$refs.error as any;
      if(snackbar) { snackbar.show(message); }
    })
  }

  filter(item:any) {
    this.activeId =  item.id;
    this.$emit('filter', item.id);
  }

  showConfig() {
    if(this.isMobile) {
      this.$router.push(this.localePath('/dashboard/settings/blogs/categories-configuration'))
    } else {
      const modal = this.$refs.categoriesModal as any;
      if(modal) {
        modal.show()
      }
    } 
  }
}
