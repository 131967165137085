export const getErrorMessage = (error:any, msg:any) => {
  let show = true;
  if(error && error.response) {
    if(error.response.status == '404' || error.response.status == '500') {
      return { message: Array.isArray(msg) ? msg : [msg], show: show};
    } else {
      let message: any = [];
      if(typeof error.response.data != 'string') {
        Object.keys(error.response.data).forEach(key => {
          if(Array.isArray(error.response.data[key])) {
            let msg = "";
            for (var val of error.response.data[key]) {
              msg += val + ".";
            }
            if(key != 'non_field_errors') {
              message[key] = msg;
              show = false;
            } else {
              message.push(msg);
            }
          } else {
            message.push(error.response.data[key]);
          }
        });
      } else {
        message.push(error.response.data)
      }
     
      return { message: message, show: show };
    }
  } else {
    return error;
  }
}