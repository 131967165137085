var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { attrs: { id: "followers-compute" } }, [
    _c(
      "div",
      { staticClass: "custom-content" },
      [
        _c("h2", { staticClass: "h_title_2 text-center mb-3" }, [
          _vm._v(_vm._s(_vm.$t("computeTitle"))),
        ]),
        _vm._v(" "),
        _c(
          "p",
          { staticClass: "d-flex align-center justify-center text-18 ff-bold" },
          [
            _c(
              "v-icon",
              {
                staticClass: "custom-icon-small",
                attrs: { size: "18", color: "btn-brand" },
              },
              [_vm._v("mdi-currency-usd")]
            ),
            _vm._v(" "),
            _c("span", { staticClass: "text-brand" }, [
              _vm._v(_vm._s(_vm.$t("computePerFollower"))),
            ]),
          ],
          1
        ),
        _vm._v(" "),
        _c("v-slider", {
          attrs: {
            "tick-labels": _vm.followersCountLabel,
            max: 5,
            ripple: false,
            step: "1",
            "tick-size": "11",
            "thumb-color": "#7378DF",
            "track-color": "#D1D2D5",
            "track-fill-color": "#7378DF",
            height: "100",
            "hide-details": "",
            "aria-label": "Choose a value",
          },
          on: { change: _vm.calculateAmount },
          model: {
            value: _vm.followersCount,
            callback: function ($$v) {
              _vm.followersCount = $$v
            },
            expression: "followersCount",
          },
        }),
        _vm._v(" "),
        _c("h2", { staticClass: "h_title_1 text-center text-mid-black mt-8" }, [
          _vm._v("$" + _vm._s(_vm.estimateAmount)),
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "text-18 ff-bold text-center text-grey mb-0" }, [
          _vm._v(_vm._s(_vm.$t("perMonth"))),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "text-center" },
          [
            _c(
              "v-btn",
              {
                staticClass: "btn-yellow get-started",
                attrs: { depressed: "", large: "" },
                on: {
                  click: function ($event) {
                    _vm.$router.push(_vm.localePath(_vm.getStartedRoute()))
                  },
                },
              },
              [
                _c(
                  "span",
                  {
                    staticClass:
                      "text-16 letter-spacing-normal font-weight-bold",
                  },
                  [_vm._v(_vm._s(_vm.$t("getStartedTodayBtn")))]
                ),
                _vm._v(" "),
                _c("span", { staticClass: "letter-spacing-normal ff-bold" }, [
                  _vm._v(_vm._s(_vm.$t("getStartedTodayBtnDesc"))),
                ]),
              ]
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }