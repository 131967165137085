
import { Component, Vue, Prop } from 'vue-property-decorator'

@Component({})
export default class InstagramAccountDetails extends Vue {
  @Prop({ default: () => {}, required: false }) readonly usersData!: any

  formatFloat(num:any) {
    return parseFloat(num);
  }

  writeToFile() {
    if(this.usersData.comments_text && this.usersData.comments_text.length > 0) {
      const content = (this.usersData.comments_text).join('\n');
      const blob = new Blob([content], { type: 'text/plain' });
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = 'CommentsList' + this.usersData.id + this.$dayjs().format('YYYYMMDDHHMM') + '.txt';
      link.click();
    }
  }
}
