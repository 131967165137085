
export const dateFormat = (self: any, date:any) => {
  return self.$dayjs(date).format("YYYY-MM-DD");
}

export const timeFormat = (self: any, date:any) => {
  return self.$dayjs(date).format("hh:mm A");
}

export const dateTimeFormat = (self: any, date:any) => {
  return self.$dayjs(date).format("YYYY-MM-DD hh:mm A");
}