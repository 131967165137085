var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c("v-file-input", {
        staticClass: "custom-file-picker",
        attrs: {
          outlined: "",
          dense: "",
          label: _vm.$t("commentFile"),
          "truncate-length": "15",
          "prepend-icon": "",
          "prepend-inner-icon": "mdi-paperclip",
          "hide-details": "auto",
          accept: ".txt",
          "background-color": "white",
          rules: _vm.fileAccept,
        },
        on: { change: _vm.handleFileUpload },
      }),
      _vm._v(" "),
      _vm.hasLabel
        ? _c("div", { staticClass: "mt-1" }, [
            _c("label", [_vm._v(_vm._s(_vm.$t("parsed")) + ": ")]),
            _vm._v(" "),
            _c("label", { staticClass: "font-weight-medium" }, [
              _vm._v(
                _vm._s(
                  _vm.$t("totalComments", {
                    count: _vm.formatCount(_vm.extractedText.length),
                  })
                )
              ),
            ]),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }