
import { Component, Vue, Prop } from 'vue-property-decorator'

@Component({})
export default class InstagramAccountsProgressBar extends Vue {
  @Prop({ default: () => { return {} }, required: false }) readonly account!: any

  checkBorderRadius(num:any, max:any) {
    if(num < max) {
      return 'rounded rounded-r-0';
    } else if(num > max) {
      return 'rounded';
    } else if(this.getPercentageValue(num,max) == 100) {
      return 'rounded rounded-l-0';
    }
  }

  getPercentageValue(num:any, max:any) {
    const followersCount = num ? num : 0;
    const number = followersCount/max;
    const percentage = Math.round(number*100);
    const total = (num > 0 && percentage < 25) ? percentage + 10 : percentage;
    return total > 100 ? 100 : total;
  }
}
