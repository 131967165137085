var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { attrs: { id: "overview-section" } }, [
    _c("div", { staticClass: "custom-content" }, [
      _c("div", { staticClass: "d-flex inner" }, [
        _c(
          "div",
          { staticClass: "left-side position-relative" },
          [
            _c("p", { staticClass: "top-text mb-2 mt-15" }, [
              _vm._v(_vm._s(_vm.$t("overviewTop"))),
            ]),
            _vm._v(" "),
            _c("h1", {
              staticClass: "h_title_1 text-black",
              domProps: { innerHTML: _vm._s(_vm.$t("overviewTitle")) },
            }),
            _vm._v(" "),
            _c("p", { staticClass: "mt-5 text-14 description" }, [
              _vm._v(_vm._s(_vm.$t("overviewDesc"))),
            ]),
            _vm._v(" "),
            _c(
              "v-btn",
              {
                staticClass: "mt-3 custom-btn",
                attrs: { depressed: "", color: "btn-yellow" },
                on: {
                  click: function ($event) {
                    _vm.$router.push(_vm.localePath(_vm.getStartedRoute()))
                  },
                },
              },
              [_vm._v(_vm._s(_vm.$t("getStarted")) + "\n        ")]
            ),
            _vm._v(" "),
            _c("v-img", {
              staticClass: "origami",
              attrs: {
                width: "245",
                src: _vm.getImage("origami.webp", "origami.png"),
                alt: "Origami paper airplane with dotted line",
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "right-side" },
          [
            _c("v-img", {
              attrs: {
                width: "100%",
                src: _vm.getImage("overview-chart.webp", "overview-chart.png"),
                alt: "Four graphic illustrations: a rocket launch dashboard with a worker, a line graph showing monthly data analytics, and bar charts tracking growth in social media engagement and numerical data of Instagram growth.",
              },
            }),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }