var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { attrs: { id: "send-message-section" } }, [
    _c(
      "div",
      { staticClass: "custom-content text-center" },
      [
        _c("label", { staticClass: "text-16 ff-bold text-brand" }, [
          _vm._v(_vm._s(_vm.$t("contactTop"))),
        ]),
        _vm._v(" "),
        _c("h2", { staticClass: "h_title_2 fw-bold mt-3 mb-1" }, [
          _vm._v(_vm._s(_vm.$t("contactTitle"))),
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "text-16 mx-15" }, [
          _vm._v(_vm._s(_vm.$t("contactDescription"))),
        ]),
        _vm._v(" "),
        _c(
          "v-form",
          {
            ref: "contactForm",
            attrs: { onSubmit: "return false;" },
            on: { submit: _vm.sendUsMessage },
          },
          [
            _c(
              "div",
              { staticClass: "mt-10 text-left" },
              [
                _c("v-text-field", {
                  staticClass: "mb-5",
                  attrs: {
                    placeholder: _vm.$t("emailAddress"),
                    outlined: "",
                    "hide-details": "auto",
                    rules: _vm.emailRule,
                  },
                  model: {
                    value: _vm.contactUs.email,
                    callback: function ($$v) {
                      _vm.$set(_vm.contactUs, "email", $$v)
                    },
                    expression: "contactUs.email",
                  },
                }),
                _vm._v(" "),
                _c("v-textarea", {
                  staticClass: "mb-5",
                  attrs: {
                    placeholder: _vm.$t("yourMessage"),
                    outlined: "",
                    rows: "6",
                    "hide-details": "auto",
                    rules: _vm.requiredRule,
                  },
                  model: {
                    value: _vm.contactUs.message,
                    callback: function ($$v) {
                      _vm.$set(_vm.contactUs, "message", $$v)
                    },
                    expression: "contactUs.message",
                  },
                }),
                _vm._v(" "),
                _vm.hasError
                  ? _c("alert-error", {
                      staticClass: "mt-5",
                      attrs: { "error-message": _vm.errorMessage },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.success
                  ? _c("alert-success", {
                      staticClass: "mt-5",
                      attrs: { "success-message": _vm.$t("successSent") },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "v-btn",
                  {
                    attrs: {
                      depressed: "",
                      large: "",
                      type: "submit",
                      loading: _vm.loading,
                      color: "btn-brand",
                    },
                  },
                  [_vm._v(_vm._s(_vm.$t("contactSubmit")) + "\n        ")]
                ),
              ],
              1
            ),
          ]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }