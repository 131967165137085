
import { Component, Vue } from 'vue-property-decorator'
import { getImage } from "~/utils/ImageUtils"

@Component({})
export default class TestimonialSection extends Vue {
  currentSlide: any = 1;
  testimonials: any = [];

  mounted() {
    this.testimonials = this.$t('testimonials') as any;
  }

  nextSlide() {
    this.currentSlide = (this.currentSlide + 1) % this.testimonials.length;
  }

  prevSlide() {
    this.currentSlide = (this.currentSlide - 1 + this.testimonials.length) % this.testimonials.length;
  }

  getCardClass(index:any) {
    if (index === this.currentSlide) return "testimonial-card center-div";
    if (index === (this.currentSlide + 1) % this.testimonials.length)
      return "testimonial-card right-div";
    if (index === (this.currentSlide - 1 + this.testimonials.length) % this.testimonials.length)
      return "testimonial-card left-div";
    return "testimonial-card hidden";
  }

  getImage(filename:any, fallback:any) {
    return getImage(filename, fallback);
  }
}
