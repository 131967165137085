var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "v-card",
        { staticClass: "custom-cards" },
        [
          _c(
            "v-card-text",
            { staticClass: "pt-1" },
            [
              _c("v-data-table", {
                staticClass: "elevation-0",
                attrs: {
                  headers: _vm.headers,
                  items: _vm.tableItems,
                  "items-per-page": _vm.perPage,
                  "server-items-length": _vm.totalData,
                  loading: _vm.loading,
                  options: _vm.options,
                  "hide-default-footer": "",
                },
                on: {
                  "update:options": function ($event) {
                    _vm.options = $event
                  },
                },
                scopedSlots: _vm._u([
                  {
                    key: "item.user",
                    fn: function ({ item }) {
                      return [
                        item.user.first_name
                          ? _c("span", [_vm._v(_vm._s(item.user.first_name))])
                          : _vm._e(),
                        _vm._v(" "),
                        item.user.first_name && item.user.last_name
                          ? _c("span")
                          : _vm._e(),
                        _vm._v(" "),
                        item.user.last_name
                          ? _c("span", [_vm._v(_vm._s(item.user.last_name))])
                          : _vm._e(),
                        _vm._v(" "),
                        _c("div", [
                          _c("span", { staticClass: "caption text-brand" }, [
                            _vm._v(_vm._s(item.user.email)),
                          ]),
                        ]),
                      ]
                    },
                  },
                  {
                    key: "item.subscription",
                    fn: function ({ item }) {
                      return [
                        item.status == "active"
                          ? [
                              item.auto_renew
                                ? _c(
                                    "div",
                                    [
                                      _c(
                                        "v-icon",
                                        {
                                          attrs: {
                                            size: "14",
                                            color: "success",
                                          },
                                        },
                                        [_vm._v("mdi-autorenew")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "span",
                                        {
                                          staticClass: "font-weight-medium",
                                          staticStyle: {
                                            "white-space": "nowrap",
                                          },
                                        },
                                        [_vm._v(_vm._s(_vm.$t("renew")) + ": ")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "datetime mb-0 success--text",
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.formatDate(item.paid_until)
                                            )
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  )
                                : !item.auto_renew &&
                                  item.pricing_mode == "dynamic"
                                ? _c(
                                    "div",
                                    [
                                      _c(
                                        "v-icon",
                                        {
                                          attrs: {
                                            size: "14",
                                            color: "success",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "mdi-checkbox-marked-circle-outline"
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "span",
                                        {
                                          staticClass: "font-weight-medium",
                                          staticStyle: {
                                            "white-space": "nowrap",
                                          },
                                        },
                                        [_vm._v(_vm._s(_vm.$t("noExpiry")))]
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ]
                          : _c(
                              "div",
                              [
                                _c(
                                  "v-icon",
                                  {
                                    staticClass: "mr-1",
                                    attrs: { size: "14", color: "error" },
                                  },
                                  [_vm._v("mdi-alert-circle-check-outline")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "font-weight-medium text-capitalize",
                                  },
                                  [_vm._v(_vm._s(item.status))]
                                ),
                              ],
                              1
                            ),
                      ]
                    },
                  },
                  {
                    key: "item.pricing_mode",
                    fn: function ({ item }) {
                      return [
                        _c("span", { staticClass: "text-capitalize" }, [
                          _vm._v(
                            _vm._s(
                              item.pricing_mode ? item.pricing_mode : "---"
                            )
                          ),
                        ]),
                      ]
                    },
                  },
                  {
                    key: "item.actions",
                    fn: function ({ item }) {
                      return [
                        _c("ActionButton", {
                          attrs: {
                            data: {
                              icon: "mdi-cogs",
                              icon_color: "warning--text",
                              label: _vm.$t("manage"),
                            },
                          },
                          on: {
                            clicked: function ($event) {
                              _vm.$router.push(
                                _vm.localePath(
                                  `/dashboard/mobile-proxy-api/subscription/${item.id}/account`
                                )
                              )
                            },
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("pagination", {
                attrs: {
                  "total-items": _vm.totalData,
                  page: _vm.page,
                  "per-page": _vm.perPage,
                  "per-page-options": _vm.perPageOptions,
                },
                on: {
                  "page-change": _vm.handlePageChange,
                  "per-page-change": _vm.handlePerPageChange,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }