
import { Component, Vue, Prop } from 'vue-property-decorator'
import { dateFormat, timeFormat } from '~/utils/DateTimeUtils'
import { convertBytes } from '~/utils/ConvertBytesUtils'

@Component({})
export default class ProxyApiSubscriptionsReportingCardList extends Vue {
  @Prop({ default: () => { return null }, required: false }) readonly data!: any

  formatDateTime(data:any, type:string) {
    if(type == 'date') {
      return dateFormat(this, data);
    } else {
      return timeFormat(this, data);
    }
  }

  convertBytes(val:any, decimals:any = 0) {
    return convertBytes(val, decimals);
  }
}
