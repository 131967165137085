var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { attrs: { id: "features-section" } }, [
    _c("div", { staticClass: "custom-content" }, [
      _c(
        "div",
        {
          staticClass:
            "inner-section d-flex align-center justify-space-between flex-wrap",
        },
        [
          _c("div", { staticClass: "d-flex align-center feature-item" }, [
            _c("img", {
              attrs: {
                src: require("assets/images/puzzle.svg"),
                alt: "Puzzle Icon",
                width: "55",
                height: "100%",
                loading: "lazy",
              },
            }),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "ml-4 custom-label",
                staticStyle: { width: "140px" },
              },
              [
                _c("label", { staticClass: "white--text" }, [
                  _vm._v(_vm._s(_vm.$t("feature1"))),
                ]),
              ]
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "d-flex align-center feature-item" }, [
            _c("img", {
              attrs: {
                src: require("assets/images/secured.svg"),
                alt: "Secured Icon",
                width: "55",
                height: "100%",
                loading: "lazy",
              },
            }),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "ml-4 custom-label",
                staticStyle: { width: "140px" },
              },
              [
                _c("label", { staticClass: "white--text" }, [
                  _vm._v(_vm._s(_vm.$t("feature2"))),
                ]),
              ]
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "d-flex align-center feature-item" }, [
            _c("img", {
              attrs: {
                src: require("assets/images/network.svg"),
                alt: "Network Icon",
                width: "55",
                height: "100%",
                loading: "lazy",
              },
            }),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "ml-4 custom-label",
                staticStyle: { width: "180px" },
              },
              [
                _c("label", { staticClass: "white--text" }, [
                  _vm._v(_vm._s(_vm.$t("feature3"))),
                ]),
              ]
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "d-flex align-center feature-item" }, [
            _c("img", {
              attrs: {
                src: require("assets/images/verified.svg"),
                alt: "Verified Icon",
                width: "55",
                height: "100%",
                loading: "lazy",
              },
            }),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "ml-4 custom-label",
                staticStyle: { width: "160px" },
              },
              [
                _c("label", { staticClass: "white--text" }, [
                  _vm._v(_vm._s(_vm.$t("feature4"))),
                ]),
              ]
            ),
          ]),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }