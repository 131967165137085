
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'

@Component({})
export default class InstagramOrders extends Vue {
  @Prop({ default: () => { return false }, required: false }) readonly isMobile!: Boolean
  @Prop({ default: () => { return false }, required: false }) readonly isCustomTop!: Boolean
  @Prop({ default: () => { return false }, required: false }) readonly fullWidth!: Boolean
  @Prop({ default: () => { return [] }, required: false }) readonly sortList!: any
  @Prop({ default: () => { return null }, required: false }) readonly default!: any

  sortData: any = { value: '', asc: false };

  mounted() {
    this.splitSort(this.default);
  }

  manualSortData() {
    const ordering = this.sortData.asc ? this.sortData.value : ('-' + this.sortData.value);
    this.$emit('query', {
      ordering: ordering,
      sort: { sortBy: [this.sortData.value], sortDesc: [this.sortData.asc]}
    });
  }

  manualSortDataOrder() {
    if(this.sortData.value) {
      this.manualSortData();
    }
  }

  splitSort(val:any) {
    if(val) {
      const sort = val.split('-');
      if(sort.length > 1) {
        this.sortData = { value: sort[1], asc: false };
      } else {
        this.sortData = { value:  sort[0], asc: true };
      }
    }
  }

  @Watch('default')
  checkDefaultSort(val:any) {
    this.splitSort(val);
  }
}
