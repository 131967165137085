<template>
  <!-- <div id="chart"></div> -->
  <div ref="chartContainer"></div>
</template>

<script>
export default {
  props: {
    data: {
      type: Array,
      default: () => [10, 20, 30, 40, 50]
    }
  },
  mounted() {
    // const data = [10, 20, 30, 40, 50];

    // const svg = this.$d3.select('#chart')
    //   .append('svg')
    //   .attr('width', 300)
    //   .attr('height', 200);

    // svg.selectAll('rect')
    //   .data(data)
    //   .enter()
    //   .append('rect')
    //   .attr('x', (d, i) => i * 40)
    //   .attr('y', d => 200 - d)
    //   .attr('width', 30)
    //   .attr('height', d => d)
    //   .attr('fill', 'blue');

    if(this.data) {
      this.drawChart();
    }
  },
  watch: {
    data: {
      immediate: true, // Call the watcher immediately on initial render
      handler(newData) {
        // this.drawChart(newData);
      }
    }
  },
  methods: {
    drawChart() {
      // Use this.$d3 to access D3.js library
      // const svg = this.$d3.select(this.$refs.chartContainer)
      //   .append('svg')
      //   .attr('width', 300)
      //   .attr('height', 200);

      // svg.selectAll('rect')
      //   .data(this.data)
      //   .enter()
      //   .append('rect')
      //   .attr('x', (d, i) => i * 40)
      //   .attr('y', d => 200 - d)
      //   .attr('width', 30)
      //   .attr('height', d => d)
      //   .attr('fill', 'blue');

      const aapl = [
        { date: `2020-01-01`, close: 30.30 },
        { date: `2020-02-01`, close: 10.50 },
        { date: `2020-03-01`, close: 50 },
        { date: `2020-04-01`, close: 25 },
        { date: `2020-05-01`, close: 70.10 },
      ]
      const width = 928;
      const height = 500;
      const marginTop = 20;
      const marginRight = 30;
      const marginBottom = 30;
      const marginLeft = 40;

      // Declare the x (horizontal position) scale.
      const x = this.$d3.scaleUtc(this.$d3.extent(aapl, d => d.close), [marginLeft, width - marginRight]);

      // Declare the y (vertical position) scale.
      const y = this.$d3.scaleLinear([0, this.$d3.max(aapl, d => d.close)], [height - marginBottom, marginTop]);

      // Declare the line generator.
      const line = this.$d3.line()
          .x(d => x(d.close))
          .y(d => y(d.close));

      // Create the SVG container.
      const svg = this.$d3.select(this.$refs.chartContainer)
          .append("svg")
          .attr("width", width)
          .attr("height", height)
          .attr("viewBox", [0, 0, width, height])
          .attr("style", "max-width: 100%; height: auto; height: intrinsic;");

      // Add the x-axis.
      svg.append("g")
          .attr("transform", `translate(0,${height - marginBottom})`)
          .call(this.$d3.axisBottom(x).ticks(width / 80).tickSizeOuter(0));

      // Add the y-axis, remove the domain line, add grid lines and a label.
      svg.append("g")
          .attr("transform", `translate(${marginLeft},0)`)
          .call(this.$d3.axisLeft(y).ticks(height / 40))
          .call(g => g.select(".domain").remove())
          .call(g => g.selectAll(".tick line").clone()
              .attr("x2", width - marginLeft - marginRight)
              .attr("stroke-opacity", 0.1))
          .call(g => g.append("text")
              .attr("x", -marginLeft)
              .attr("y", 10)
              .attr("fill", "currentColor")
              .attr("text-anchor", "start")
              .text("↑ Daily close ($)"));

      // Append a path for the line.
      svg.append("path")
          .attr("fill", "none")
          .attr("stroke", "steelblue")
          .attr("stroke-width", 1.5)
          .attr("d", line(aapl));






      
      // return svg.node();

      // const data = [
      //   { x: 0, y: 10 },
      //   { x: 1, y: 20 },
      //   { x: 2, y: 30 },
      //   { x: 3, y: 25 },
      //   { x: 4, y: 40 },
      //   { x: 5, y: 35 }
      // ];

      // // Set up dimensions and margins
      // const width = 400;
      // const height = 300;
      // const margin = { top: 20, right: 20, bottom: 30, left: 40 };
      // const innerWidth = width - margin.left - margin.right;
      // const innerHeight = height - margin.top - margin.bottom;

      // // Create an SVG element
      // const svg = this.$d3.select(this.$refs.chartContainer)
      //   .append("svg")
      //   .attr("width", width)
      //   .attr("height", height)
      //   .append("g")
      //   .attr("transform", `translate(${margin.left},${margin.top})`);

      // // Create scales
      // const xScale = this.$d3.scaleLinear()
      //   .domain([0, this.$d3.max(data, d => d.x)])
      //   .range([0, innerWidth]);

      // const yScale = this.$d3.scaleLinear()
      //   .domain([0, this.$d3.max(data, d => d.y)])
      //   .range([innerHeight, 0]);

      // // Create line generator
      // const line = this.$d3.line()
      //   .x(d => xScale(d.x))
      //   .y(d => yScale(d.y));

      // // Append path element for the line chart
      // svg.append("path")
      //   .datum(data)
      //   .attr("class", "line")
      //   .attr("d", line);

      // // Add x-axis
      // svg.append("g")
      //   .attr("transform", `translate(0,${innerHeight})`)
      //   .call(this.$d3.axisBottom(xScale));

      // // Add y-axis
      // svg.append("g")
      //   .call(this.$d3.axisLeft(yScale));

      // // Style the line
      // svg.select(".line")
      //   .attr("fill", "none")
      //   .attr("stroke", "steelblue")
      //   .attr("stroke-width", 2);
      
    }
  }
};
</script>