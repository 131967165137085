var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-dialog",
    {
      attrs: {
        "max-width": "450",
        persistent: _vm.data.persistent ? _vm.data.persistent : false,
      },
      model: {
        value: _vm.modal,
        callback: function ($$v) {
          _vm.modal = $$v
        },
        expression: "modal",
      },
    },
    [
      _c(
        "v-card",
        [
          _c("v-card-text", { staticClass: "pa-7" }, [
            _c("img", {
              staticClass: "logo mb-7",
              attrs: {
                src: require("@/assets/images/logo-text.svg"),
                width: "190",
                height: "50",
              },
            }),
            _vm._v(" "),
            _vm.data.title
              ? _c("h2", { staticClass: "h_title_4 mb-3 text-black" }, [
                  _vm._v(_vm._s(_vm.data.title)),
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("p", { staticClass: "text-16 text-justify" }, [
              _vm._v(_vm._s(_vm.data.subTitle)),
            ]),
            _vm._v(" "),
            _vm.data.btnLabel
              ? _c(
                  "div",
                  { staticClass: "text-right" },
                  [
                    _c(
                      "v-btn",
                      {
                        staticClass: "btn-brand mt-3 w-50",
                        attrs: { depressed: "", large: "" },
                        on: { click: _vm.leave },
                      },
                      [_vm._v(_vm._s(_vm.data.btnLabel))]
                    ),
                  ],
                  1
                )
              : _vm._e(),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }