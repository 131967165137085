var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { attrs: { id: "mobile-proxy" } },
    [
      _c(
        "v-row",
        { attrs: { align: "center", "no-gutters": "" } },
        [
          _c(
            "v-col",
            { attrs: { cols: "12", md: _vm.filters.cardView ? "5" : "4" } },
            [
              _c("navigation-breadcrumbs", {
                attrs: { items: _vm.breadcrums },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-col",
            {
              staticClass: "text-right d-flex align-center pb-1",
              class: { "flex-wrap": _vm.$vuetify.breakpoint.xs },
              attrs: { cols: "12", md: _vm.filters.cardView ? "7" : "8" },
            },
            [
              _c("users-dropdown", {
                attrs: {
                  "is-mobile": _vm.isMobile,
                  "custom-class": _vm.$vuetify.breakpoint.xs
                    ? "w-100 mb-5"
                    : _vm.$vuetify.breakpoint.sm
                    ? "w-100 mb-3"
                    : "ml-auto",
                  default: _vm.filters.user,
                },
                on: {
                  "get-data": function ($event) {
                    _vm.filters.user = $event ? $event.id : null
                    _vm.handleFilters()
                  },
                },
              }),
              _vm._v(" "),
              _c("v-divider", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.$vuetify.breakpoint.xs,
                    expression: "!$vuetify.breakpoint.xs",
                  },
                ],
                staticClass: "mx-4",
                attrs: { vertical: "" },
              }),
              _vm._v(" "),
              _c(
                "div",
                {
                  class: {
                    "w-100 mb-5": _vm.$vuetify.breakpoint.xs,
                    "w-100 mb-3": _vm.$vuetify.breakpoint.sm,
                  },
                  staticStyle: { width: "200px" },
                },
                [
                  _c("v-autocomplete", {
                    staticClass: "search-input",
                    attrs: {
                      placeholder: _vm.$t("filterByStatus"),
                      dense: _vm.isMobile ? false : true,
                      outlined: "",
                      items: _vm.statusList,
                      "item-text": "text",
                      "item-value": "id",
                      "hide-details": "",
                      clearable: "",
                    },
                    on: {
                      change: function ($event) {
                        return _vm.handleFilters()
                      },
                    },
                    model: {
                      value: _vm.status,
                      callback: function ($$v) {
                        _vm.status = $$v
                      },
                      expression: "status",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              !_vm.filters.cardView
                ? [
                    !_vm.isMobile
                      ? _c("v-divider", {
                          staticClass: "mx-4",
                          attrs: { vertical: "" },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "v-btn",
                      {
                        staticClass: "btn-brand mr-1 px-2 custom-btn",
                        class: { "ml-5": _vm.$vuetify.breakpoint.sm },
                        attrs: { depressed: "" },
                        on: {
                          click: function ($event) {
                            _vm.$router.push(
                              _vm.localePath(
                                "/dashboard/mobile-proxy-api/subscription/account"
                              )
                            )
                          },
                        },
                      },
                      [
                        _c(
                          "v-icon",
                          { staticClass: "mr-1", attrs: { small: "" } },
                          [_vm._v("mdi-plus-circle-outline")]
                        ),
                        _vm._v(" "),
                        _c("span", [_vm._v(_vm._s(_vm.$t("add")))]),
                      ],
                      1
                    ),
                  ]
                : _vm._e(),
              _vm._v(" "),
              _c("v-divider", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.$vuetify.breakpoint.xs,
                    expression: "!$vuetify.breakpoint.xs",
                  },
                ],
                staticClass: "mx-4",
                attrs: { vertical: "" },
              }),
              _vm._v(" "),
              _vm.filters.cardView
                ? _c(
                    "v-tooltip",
                    {
                      attrs: {
                        bottom: "",
                        "open-delay": "150",
                        "content-class": "action-btn-tooltip",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function ({ on, attrs }) {
                              return [
                                _c(
                                  "v-icon",
                                  _vm._g(
                                    _vm._b(
                                      {
                                        staticClass: "grey--text mr-2",
                                        class: {
                                          "ml-auto": _vm.$vuetify.breakpoint.xs,
                                        },
                                        on: {
                                          click: function ($event) {
                                            _vm.filters.cardView = false
                                          },
                                        },
                                      },
                                      "v-icon",
                                      attrs,
                                      false
                                    ),
                                    on
                                  ),
                                  [_vm._v("mdi-table")]
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        900006855
                      ),
                    },
                    [
                      _vm._v(" "),
                      _c("span", [_vm._v(_vm._s(_vm.$t("tableView")))]),
                    ]
                  )
                : _c(
                    "v-tooltip",
                    {
                      attrs: {
                        bottom: "",
                        "open-delay": "150",
                        "content-class": "action-btn-tooltip",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "activator",
                          fn: function ({ on, attrs }) {
                            return [
                              _c(
                                "v-icon",
                                _vm._g(
                                  _vm._b(
                                    {
                                      staticClass: "grey--text mr-2",
                                      class: {
                                        "ml-auto": _vm.$vuetify.breakpoint.xs,
                                      },
                                      on: {
                                        click: function ($event) {
                                          _vm.filters.cardView = true
                                        },
                                      },
                                    },
                                    "v-icon",
                                    attrs,
                                    false
                                  ),
                                  on
                                ),
                                [_vm._v("mdi-cards")]
                              ),
                            ]
                          },
                        },
                      ]),
                    },
                    [
                      _vm._v(" "),
                      _c("span", [_vm._v(_vm._s(_vm.$t("cardView")))]),
                    ]
                  ),
            ],
            2
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "custom-content" },
        [
          !_vm.filters.cardView
            ? [
                _c("mobile-proxy-api-table", {
                  staticClass: "mt-3",
                  attrs: {
                    data: _vm.subscriptions,
                    loading: _vm.progressLoading,
                    "current-page": _vm.page,
                    "current-per-page": _vm.perPage,
                    "current-options": _vm.options,
                    loaded: _vm.loaded,
                  },
                  on: {
                    refresh: function ($event) {
                      return _vm.refreshTable($event)
                    },
                  },
                }),
              ]
            : [
                _c("v-progress-linear", {
                  staticClass: "mt-2 mb-1",
                  attrs: {
                    indeterminate: _vm.progressLoading,
                    active: _vm.progressLoading,
                    color: "btn-brand",
                  },
                }),
                _vm._v(" "),
                _c("mobile-proxy-api-card-list", {
                  attrs: {
                    data: _vm.subscriptions,
                    loading: _vm.progressLoading,
                    "current-page": _vm.page,
                    "current-per-page": _vm.perPage,
                    loaded: _vm.loaded,
                  },
                  on: {
                    refresh: function ($event) {
                      return _vm.refreshTable($event)
                    },
                  },
                }),
              ],
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }