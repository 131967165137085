var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-card",
    { staticClass: "custom-cards" },
    [
      _c(
        "v-card-text",
        { staticClass: "pt-1" },
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { col: "12" } },
                [
                  _c("v-data-table", {
                    staticClass: "elevation-0",
                    attrs: {
                      headers: _vm.headers,
                      items: _vm.tableItems,
                      "items-per-page": _vm.perPage,
                      "server-items-length": _vm.totalData,
                      loading: _vm.loading,
                      options: _vm.options,
                      "sort-desc": true,
                      "sort-by": "payment_date",
                      "hide-default-footer": "",
                    },
                    on: {
                      "update:options": function ($event) {
                        _vm.options = $event
                      },
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "item.payment_date",
                        fn: function ({ item }) {
                          return [
                            item.payment_date
                              ? _c("span", [
                                  _vm._v(
                                    _vm._s(_vm.formatDate(item.payment_date))
                                  ),
                                ])
                              : _c("span", [_vm._v("---")]),
                          ]
                        },
                      },
                      {
                        key: "item.user",
                        fn: function ({ item }) {
                          return [
                            _c(
                              "div",
                              { staticClass: "line-height-normal" },
                              [
                                _c(
                                  "nuxt-link",
                                  {
                                    attrs: {
                                      to: `/dashboard/admin/users/${item.user.id}/view`,
                                      target: "_blank",
                                    },
                                  },
                                  [
                                    _c("span", { staticClass: "text-black" }, [
                                      _vm._v(_vm._s(_vm.getName(item))),
                                    ]),
                                    _vm._v(" "),
                                    _c("div", [
                                      _c(
                                        "span",
                                        { staticClass: "text-brand" },
                                        [_vm._v(_vm._s(item.user.email))]
                                      ),
                                    ]),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                      {
                        key: "item.price",
                        fn: function ({ item }) {
                          return [
                            _c("div", { staticClass: "mr-3" }, [
                              _c("span", [
                                _vm._v(_vm._s(_vm.formatFloat(item.price))),
                              ]),
                              _vm._v(" "),
                              _c("span", { staticClass: "text-uppercase" }, [
                                _vm._v(_vm._s(item.currency)),
                              ]),
                            ]),
                          ]
                        },
                      },
                      {
                        key: "item.credit_qty",
                        fn: function ({ item }) {
                          return [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "d-flex align-center justify-end mr-8",
                              },
                              [
                                _c("span", { staticClass: "mr-1" }, [
                                  _vm._v(
                                    _vm._s(_vm.formatFloat(item.credit_qty))
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("img", {
                                  attrs: {
                                    src: require("assets/images/icons/twemoji_coin.svg"),
                                    width: "15",
                                  },
                                }),
                              ]
                            ),
                          ]
                        },
                      },
                      {
                        key: "item.payment_method",
                        fn: function ({ item }) {
                          return [
                            _c("span", { staticClass: "text-capitalize" }, [
                              _vm._v(
                                _vm._s(item.payment_method.replace(/_/g, " "))
                              ),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("pagination", {
                    attrs: {
                      "total-items": _vm.totalData,
                      page: _vm.page,
                      "per-page": _vm.perPage,
                      "per-page-options": _vm.perPageOptions,
                    },
                    on: {
                      "page-change": _vm.handlePageChange,
                      "per-page-change": _vm.handlePerPageChange,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }