var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-dialog",
    {
      attrs: { persistent: "", "max-width": "700" },
      model: {
        value: _vm.modal,
        callback: function ($$v) {
          _vm.modal = $$v
        },
        expression: "modal",
      },
    },
    [
      _c(
        "v-card",
        { attrs: { id: "categories-modal" } },
        [
          _c(
            "v-card-text",
            { staticClass: "pa-7 pt-5" },
            [
              _c(
                "div",
                { staticClass: "d-flex align-center" },
                [
                  _c(
                    "h2",
                    { staticClass: "h_title_3 font-weight-bold text-black" },
                    [_vm._v(_vm._s(_vm.$t("categoriesConfig")))]
                  ),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      staticClass: "ml-auto",
                      attrs: { icon: "", small: "" },
                      on: {
                        click: function ($event) {
                          _vm.modal = false
                        },
                      },
                    },
                    [_c("v-icon", [_vm._v("mdi-close")])],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("v-divider", { staticClass: "mt-3" }),
              _vm._v(" "),
              _c("settings-blogs-categories-form", {
                on: {
                  success: function ($event) {
                    return _vm.$emit("success")
                  },
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }