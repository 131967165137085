
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({})
export default class ColorPickerModal extends Vue {
  modal: boolean = false
  color: any = {}
  swatches = [
    ['#FF0000', '#AA0000', '#550000'],
    ['#FFFF00', '#AAAA00', '#555500'],
    ['#00FF00', '#00AA00', '#005500'],
    ['#00FFFF', '#00AAAA', '#005555'],
    ['#0000FF', '#0000AA', '#000000'],
  ] as any

  getRGB(str: string){
    var match = str.match(/rgba?\((\d{1,3}), ?(\d{1,3}), ?(\d{1,3})\)?(?:, ?(\d(?:\.\d?))\))?/);
    return match ? {
      r: match[1],
      g: match[2],
      b: match[3],
      a: 1
    } : {};
  }
  show(selectColor: string): void {
    this.color = this.getRGB(selectColor)
    this.modal = true
  }
  close(): void {
    this.modal = false
  }
  pick(): void {
    if ('hexa' in this.color) {
      this.$emit('colorPicked', this.color.hexa)
    } else {
      const hexString = `#${parseInt(this.color.r).toString(16)}${parseInt(this.color.g).toString(16)}${parseInt(this.color.b).toString(16)}00`
      const rgbString = `rgb(${this.color.r}, ${this.color.g}, ${this.color.b}, 1)`
      this.$emit('colorPicked', rgbString)
    }

    this.close()
  }
}
