var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "custom-content" },
        [
          _c("v-progress-linear", {
            staticClass: "mt-2 mb-1",
            attrs: {
              indeterminate: _vm.progressLoading,
              active: _vm.progressLoading,
              color: "btn-brand",
            },
          }),
          _vm._v(" "),
          _vm.currentUser
            ? _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12", md: "6" } },
                    [
                      _c(
                        "v-card",
                        {
                          staticClass: "custom-cards",
                          attrs: { disabled: _vm.isEdit, height: "100%" },
                        },
                        [
                          !_vm.progressLoading
                            ? _c(
                                "v-card-text",
                                { staticClass: "py-5 px-7 d-flex flex-column" },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "w-100",
                                      class: {
                                        "div-disabled": _vm.isEditAccount,
                                      },
                                    },
                                    [
                                      _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            {
                                              staticClass:
                                                "d-flex align-center",
                                              attrs: {
                                                cols: "12",
                                                md: "8",
                                                sm: "6",
                                              },
                                            },
                                            [
                                              _c(
                                                "v-avatar",
                                                {
                                                  attrs: {
                                                    size: "60",
                                                    color: "btn-brand",
                                                  },
                                                },
                                                [
                                                  _vm.currentUser.profile_pic
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "img-border",
                                                        },
                                                        [
                                                          _c("img", {
                                                            staticClass:
                                                              "img-container",
                                                            attrs: {
                                                              src:
                                                                "data:image/jpeg;base64," +
                                                                _vm.currentUser
                                                                  .profile_pic,
                                                            },
                                                          }),
                                                        ]
                                                      )
                                                    : _c(
                                                        "v-icon",
                                                        {
                                                          staticClass:
                                                            "white--text",
                                                        },
                                                        [_vm._v("mdi-account")]
                                                      ),
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                { staticClass: "ml-3" },
                                                [
                                                  _c(
                                                    "h3",
                                                    {
                                                      staticClass:
                                                        "text-16 font-weight-bold text-black",
                                                    },
                                                    [
                                                      _vm._v(
                                                        "@" +
                                                          _vm._s(
                                                            _vm.currentUser
                                                              .username
                                                          ) +
                                                          "\n                      "
                                                      ),
                                                      _c(
                                                        "v-tooltip",
                                                        {
                                                          attrs: {
                                                            bottom: "",
                                                            "content-class":
                                                              "custom-tooltip",
                                                          },
                                                          scopedSlots: _vm._u(
                                                            [
                                                              {
                                                                key: "activator",
                                                                fn: function ({
                                                                  on,
                                                                  attrs,
                                                                }) {
                                                                  return [
                                                                    _c(
                                                                      "v-icon",
                                                                      _vm._g(
                                                                        _vm._b(
                                                                          {
                                                                            staticClass:
                                                                              "custom-icon",
                                                                            attrs:
                                                                              {
                                                                                color:
                                                                                  _vm
                                                                                    .currentUser
                                                                                    .status ==
                                                                                    "active" ||
                                                                                  _vm
                                                                                    .currentUser
                                                                                    .status ==
                                                                                    "cancel_on_period_end"
                                                                                    ? "success"
                                                                                    : "",
                                                                                small:
                                                                                  "",
                                                                              },
                                                                          },
                                                                          "v-icon",
                                                                          attrs,
                                                                          false
                                                                        ),
                                                                        on
                                                                      ),
                                                                      [
                                                                        _vm._v(
                                                                          "\n                            mdi-check-decagram\n                          "
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                },
                                                              },
                                                            ],
                                                            null,
                                                            false,
                                                            673253948
                                                          ),
                                                        },
                                                        [
                                                          _vm._v(" "),
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "text-capitalize",
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm
                                                                    .currentUser
                                                                    .status
                                                                    ? _vm.currentUser.status.replace(
                                                                        /_/g,
                                                                        " "
                                                                      )
                                                                    : ""
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  !_vm.progressLoading
                                                    ? _c(
                                                        "instagram-accounts-progress-bar",
                                                        {
                                                          staticClass: "mt-3",
                                                          attrs: {
                                                            account:
                                                              _vm.currentUser,
                                                          },
                                                        }
                                                      )
                                                    : _vm._e(),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "v-col",
                                            {
                                              staticClass: "pr-5 d-flex",
                                              class: {
                                                "flex-column":
                                                  !_vm.$vuetify.breakpoint.xs,
                                              },
                                              attrs: {
                                                cols: "12",
                                                md: "4",
                                                sm: "6",
                                              },
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "d-flex align-center cursor-pointer",
                                                  class: {
                                                    "ml-auto":
                                                      !_vm.$vuetify.breakpoint
                                                        .xs,
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      _vm.isEditAccount = true
                                                    },
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "v-icon",
                                                    {
                                                      staticClass:
                                                        "text-purple-2",
                                                      attrs: { size: "18" },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "mdi-square-edit-outline"
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "ml-1 text-purple-2 text-16",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(_vm.$t("edit"))
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "nuxt-link",
                                                {
                                                  staticClass:
                                                    "mt-auto ml-auto",
                                                  attrs: {
                                                    to: _vm.localePath(
                                                      _vm.getReportLink()
                                                    ),
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "v-icon",
                                                    {
                                                      staticClass:
                                                        "text-brand custom-icon",
                                                      attrs: { size: "25" },
                                                    },
                                                    [_vm._v("mdi-chart-box")]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "ml-2 text-purple-2 font-weight-medium text-14",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t("viewReport")
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c("v-divider", { staticClass: "my-4" }),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  !_vm.isEditAccount
                                    ? [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "text-14 font-weight-medium",
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "d-flex align-center flex-wrap",
                                              },
                                              [
                                                _c("h3", [
                                                  _vm._v(
                                                    _vm._s(_vm.$t("status"))
                                                  ),
                                                ]),
                                                _vm._v(" "),
                                                _vm.currentUser.status ==
                                                  "unpaid" ||
                                                _vm.currentUser.status ==
                                                  "cancelled"
                                                  ? [
                                                      _c(
                                                        "v-btn",
                                                        {
                                                          staticClass:
                                                            "px-2 ml-auto",
                                                          attrs: {
                                                            depressed: "",
                                                            color: "btn-brand",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.$refs.renewSubscription.show(
                                                                _vm.currentUser
                                                                  .id,
                                                                _vm.urlType,
                                                                _vm.currentUser
                                                                  .max_follower_count
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "v-icon",
                                                            {
                                                              staticClass:
                                                                "custom",
                                                              attrs: {
                                                                small: "",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                "mdi-restart"
                                                              ),
                                                            ]
                                                          ),
                                                          _vm._v(" "),
                                                          _c("span", [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "reactivate"
                                                                )
                                                              )
                                                            ),
                                                          ]),
                                                        ],
                                                        1
                                                      ),
                                                    ]
                                                  : [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "d-flex align-center ml-auto",
                                                        },
                                                        [
                                                          _c(
                                                            "v-icon",
                                                            {
                                                              staticClass:
                                                                "custom custom-icon mr-1",
                                                              attrs: {
                                                                small: "",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                "mdi-autorenew"
                                                              ),
                                                            ]
                                                          ),
                                                          _vm._v(" "),
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "text-12 custom-icon",
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "autoRenew"
                                                                  )
                                                                ) + ": "
                                                              ),
                                                            ]
                                                          ),
                                                          _vm._v(" "),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "cursor-pointer",
                                                              on: {
                                                                click:
                                                                  _vm.autoRenewModal,
                                                              },
                                                            },
                                                            [
                                                              _c("v-switch", {
                                                                staticClass:
                                                                  "mt-0 pt-0 ml-2 mr-n3 custom-switch",
                                                                attrs: {
                                                                  color:
                                                                    "btn-brand",
                                                                  inset: "",
                                                                  dense: "",
                                                                  disabled: "",
                                                                  ripple: false,
                                                                  "hide-details":
                                                                    "",
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm.autoRenew,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.autoRenew =
                                                                        $$v
                                                                    },
                                                                  expression:
                                                                    "autoRenew",
                                                                },
                                                              }),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                              ],
                                              2
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "d-flex align-center flex-wrap",
                                                class: {
                                                  "mt-2":
                                                    _vm.currentUser.status !=
                                                      "unpaid" &&
                                                    _vm.currentUser.status !=
                                                      "cancelled",
                                                },
                                              },
                                              [
                                                !_vm.isExtend
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticClass: "mt-auto",
                                                      },
                                                      [
                                                        _vm.currentUser
                                                          .status == "active"
                                                          ? _c(
                                                              "div",
                                                              [
                                                                _c(
                                                                  "v-icon",
                                                                  {
                                                                    attrs: {
                                                                      size: "18",
                                                                      color:
                                                                        "success",
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "mdi-autorenew"
                                                                    ),
                                                                  ]
                                                                ),
                                                                _vm._v(" "),
                                                                _c("span", [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "activeRenewOn"
                                                                      )
                                                                    ) + " "
                                                                  ),
                                                                ]),
                                                                _vm._v(" "),
                                                                _vm.currentUser
                                                                  .paid_until
                                                                  ? _c(
                                                                      "span",
                                                                      {
                                                                        staticClass:
                                                                          "font-weight-medium success--text",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            _vm.formatDate(
                                                                              _vm
                                                                                .currentUser
                                                                                .paid_until
                                                                            )
                                                                          )
                                                                        ),
                                                                      ]
                                                                    )
                                                                  : _c("span", [
                                                                      _vm._v(
                                                                        "---"
                                                                      ),
                                                                    ]),
                                                              ],
                                                              1
                                                            )
                                                          : _vm.currentUser
                                                              .status ==
                                                            "cancel_on_period_end"
                                                          ? _c(
                                                              "div",
                                                              [
                                                                _c(
                                                                  "v-icon",
                                                                  {
                                                                    attrs: {
                                                                      size: "18",
                                                                      color:
                                                                        _vm.checkActiveExpiring(
                                                                          _vm
                                                                            .currentUser
                                                                            .paid_until
                                                                        )
                                                                          ? "warning"
                                                                          : "success",
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "\n                        mdi-checkbox-marked-circle-outline\n                      "
                                                                    ),
                                                                  ]
                                                                ),
                                                                _vm._v(" "),
                                                                _c("span", [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "activeExpireOn"
                                                                      )
                                                                    ) + " "
                                                                  ),
                                                                ]),
                                                                _vm._v(" "),
                                                                _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "font-weight-medium",
                                                                    class:
                                                                      _vm.checkActiveExpiring(
                                                                        _vm
                                                                          .currentUser
                                                                          .paid_until
                                                                      )
                                                                        ? "warning--text"
                                                                        : "success--text",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "\n                        " +
                                                                        _vm._s(
                                                                          _vm.formatDate(
                                                                            _vm
                                                                              .currentUser
                                                                              .paid_until
                                                                          )
                                                                        ) +
                                                                        "\n                      "
                                                                    ),
                                                                  ]
                                                                ),
                                                              ],
                                                              1
                                                            )
                                                          : _vm.currentUser
                                                              .status ==
                                                              "unpaid" ||
                                                            _vm.currentUser
                                                              .status ==
                                                              "cancelled"
                                                          ? _c("div", [
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "text-left font-weight-medium",
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-icon",
                                                                    {
                                                                      attrs: {
                                                                        size: "18",
                                                                        color:
                                                                          "error",
                                                                      },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "mdi-alert-circle-check-outline"
                                                                      ),
                                                                    ]
                                                                  ),
                                                                  _vm._v(" "),
                                                                  _c(
                                                                    "span",
                                                                    {
                                                                      staticClass:
                                                                        "text-14",
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          _vm.$t(
                                                                            "inactive"
                                                                          )
                                                                        )
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ])
                                                          : _vm._e(),
                                                      ]
                                                    )
                                                  : _c(
                                                      "div",
                                                      { staticClass: "w-100" },
                                                      [
                                                        _c(
                                                          "v-card",
                                                          {
                                                            attrs: {
                                                              outlined: "",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "v-card-text",
                                                              [
                                                                _vm.$auth &&
                                                                _vm.$auth
                                                                  .user &&
                                                                _vm.$auth.user
                                                                  .is_admin
                                                                  ? _c(
                                                                      "users-credits-dropdown",
                                                                      {
                                                                        staticClass:
                                                                          "mb-3",
                                                                        attrs: {
                                                                          "user-filter":
                                                                            _vm.currentAccount,
                                                                          "is-filtered": true,
                                                                        },
                                                                        on: {
                                                                          selected:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              _vm.extendData.user =
                                                                                $event
                                                                            },
                                                                        },
                                                                      }
                                                                    )
                                                                  : _vm._e(),
                                                                _vm._v(" "),
                                                                _c(
                                                                  "label",
                                                                  {
                                                                    staticClass:
                                                                      "text-16 text-black font-weight-medium",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm.$t(
                                                                          "numOfMonths"
                                                                        )
                                                                      )
                                                                    ),
                                                                  ]
                                                                ),
                                                                _vm._v(" "),
                                                                _c(
                                                                  "v-text-field",
                                                                  {
                                                                    staticClass:
                                                                      "mt-2",
                                                                    attrs: {
                                                                      outlined:
                                                                        "",
                                                                      placeholder:
                                                                        "0",
                                                                      type: "number",
                                                                      "background-color":
                                                                        "white",
                                                                      "hide-details":
                                                                        "",
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        _vm
                                                                          .extendData
                                                                          .num_cycle,
                                                                      callback:
                                                                        function (
                                                                          $$v
                                                                        ) {
                                                                          _vm.$set(
                                                                            _vm.extendData,
                                                                            "num_cycle",
                                                                            $$v
                                                                          )
                                                                        },
                                                                      expression:
                                                                        "extendData.num_cycle",
                                                                    },
                                                                  }
                                                                ),
                                                                _vm._v(" "),
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "text-right mt-4",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "v-btn",
                                                                      {
                                                                        staticClass:
                                                                          "text-brand ml-2",
                                                                        attrs: {
                                                                          depressed:
                                                                            "",
                                                                          loading:
                                                                            _vm.loadingExtend,
                                                                        },
                                                                        on: {
                                                                          click:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              _vm.isExtend = false
                                                                            },
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            _vm.$t(
                                                                              "cancel"
                                                                            )
                                                                          )
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _vm._v(" "),
                                                                    _c(
                                                                      "v-btn",
                                                                      {
                                                                        staticClass:
                                                                          "btn-brand ml-2",
                                                                        attrs: {
                                                                          depressed:
                                                                            "",
                                                                          disabled:
                                                                            !_vm
                                                                              .extendData
                                                                              .num_cycle ||
                                                                            _vm
                                                                              .extendData
                                                                              .num_cycle ==
                                                                              0 ||
                                                                            !_vm
                                                                              .extendData
                                                                              .user,
                                                                          loading:
                                                                            _vm.loadingExtend,
                                                                        },
                                                                        on: {
                                                                          click:
                                                                            _vm.saveExtendSubscription,
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            _vm.$t(
                                                                              "save"
                                                                            )
                                                                          )
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                _vm._v(" "),
                                                !_vm.isExtend
                                                  ? [
                                                      _vm.currentUser.status !=
                                                        "unpaid" &&
                                                      _vm.currentUser.status !=
                                                        "cancelled" &&
                                                      _vm.currentUser.status !=
                                                        "active"
                                                        ? _c(
                                                            "v-chip",
                                                            {
                                                              staticClass:
                                                                "px-2",
                                                              class: _vm
                                                                .$vuetify
                                                                .breakpoint.xs
                                                                ? "mt-3"
                                                                : _vm
                                                                    .currentUser
                                                                    .status ==
                                                                  "active"
                                                                ? "ml-auto"
                                                                : "ml-5",
                                                              attrs: {
                                                                small: "",
                                                                color:
                                                                  "btn-brand",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    _vm.isExtend = true
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "v-icon",
                                                                {
                                                                  staticClass:
                                                                    "custom mr-1",
                                                                  attrs: {
                                                                    small: "",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "mdi-update"
                                                                  ),
                                                                ]
                                                              ),
                                                              _vm._v(" "),
                                                              _c("span", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "extend"
                                                                    )
                                                                  )
                                                                ),
                                                              ]),
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e(),
                                                      _vm._v(" "),
                                                      _vm.currentUser.status ==
                                                        "active" ||
                                                      _vm.currentUser.status ==
                                                        "cancel_on_period_end"
                                                        ? _c(
                                                            "v-menu",
                                                            {
                                                              attrs: {
                                                                "offset-y": "",
                                                                left: "",
                                                              },
                                                              scopedSlots:
                                                                _vm._u(
                                                                  [
                                                                    {
                                                                      key: "activator",
                                                                      fn: function ({
                                                                        on,
                                                                        attrs,
                                                                      }) {
                                                                        return [
                                                                          _c(
                                                                            "v-icon",
                                                                            _vm._g(
                                                                              _vm._b(
                                                                                {
                                                                                  staticClass:
                                                                                    "ml-auto",
                                                                                },
                                                                                "v-icon",
                                                                                attrs,
                                                                                false
                                                                              ),
                                                                              on
                                                                            ),
                                                                            [
                                                                              _vm._v(
                                                                                "mdi-dots-horizontal"
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ]
                                                                      },
                                                                    },
                                                                  ],
                                                                  null,
                                                                  false,
                                                                  3457405954
                                                                ),
                                                            },
                                                            [
                                                              _vm._v(" "),
                                                              _c(
                                                                "v-list",
                                                                {
                                                                  staticClass:
                                                                    "py-0",
                                                                  attrs: {
                                                                    dense: "",
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-list-item",
                                                                    {
                                                                      on: {
                                                                        click:
                                                                          function (
                                                                            $event
                                                                          ) {
                                                                            return _vm.showModal(
                                                                              "cancelSubscription"
                                                                            )
                                                                          },
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "v-list-item-title",
                                                                        {
                                                                          staticClass:
                                                                            "red--text",
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              _vm.$t(
                                                                                "cancelSubscription"
                                                                              )
                                                                            )
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e(),
                                                    ]
                                                  : _vm._e(),
                                              ],
                                              2
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "d-flex align-center flex-wrap mt-7",
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "text-14 font-weight-medium",
                                              },
                                              [
                                                _c(
                                                  "h3",
                                                  { staticClass: "mb-2" },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t("followers")
                                                      )
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "d-flex align-center mt-3",
                                                  },
                                                  [
                                                    _c("img", {
                                                      staticClass: "mb-auto",
                                                      attrs: {
                                                        src: require("assets/images/instagram-golden.webp"),
                                                        width: "25",
                                                      },
                                                    }),
                                                    _vm._v(" "),
                                                    _c(
                                                      "h3",
                                                      {
                                                        staticClass:
                                                          "h_title_2 font-weight-bold text-black ml-3",
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.currentUser
                                                              .max_follower_count
                                                              ? _vm.currentUser
                                                                  .max_follower_count
                                                              : 0
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c("v-divider", {
                                                      staticClass: "mx-2",
                                                      attrs: { vertical: "" },
                                                    }),
                                                    _vm._v(" "),
                                                    _c("img", {
                                                      attrs: {
                                                        src: require("assets/images/icons/twemoji_coin.svg"),
                                                        width: "15",
                                                      },
                                                    }),
                                                    _vm._v(" "),
                                                    _c(
                                                      "h4",
                                                      {
                                                        staticClass:
                                                          "percentage font-weight-bold ml-1",
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.getMonthlySubscription()
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              {
                                                class: _vm.$vuetify.breakpoint
                                                  .xs
                                                  ? "mt-3"
                                                  : "ml-auto mt-auto",
                                              },
                                              [
                                                _c(
                                                  "v-chip",
                                                  {
                                                    staticClass: "px-2",
                                                    attrs: {
                                                      small: "",
                                                      outlined: "",
                                                      color: "btn-brand",
                                                      disabled:
                                                        _vm.isExtend ||
                                                        (_vm.currentUser
                                                          .status != "active" &&
                                                          _vm.currentUser
                                                            .status !=
                                                            "cancel_on_period_end"),
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.showModal(
                                                          "addFollowers"
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "v-icon",
                                                      {
                                                        staticClass:
                                                          "custom mr-1",
                                                        attrs: { small: "" },
                                                      },
                                                      [
                                                        _vm._v(
                                                          "mdi-plus-circle-outline"
                                                        ),
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t("upgrade")
                                                        )
                                                      ),
                                                    ]),
                                                  ],
                                                  1
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "v-chip",
                                                  {
                                                    staticClass: "px-2",
                                                    attrs: {
                                                      small: "",
                                                      outlined: "",
                                                      color: "btn-brand",
                                                      disabled:
                                                        _vm.isExtend ||
                                                        _vm.currentUser
                                                          .status != "active",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.showModal(
                                                          "removeFollowers"
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "v-icon",
                                                      {
                                                        staticClass:
                                                          "custom mr-1",
                                                        attrs: { small: "" },
                                                      },
                                                      [
                                                        _vm._v(
                                                          "mdi-minus-circle-outline"
                                                        ),
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t("downgrade")
                                                        )
                                                      ),
                                                    ]),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                      ]
                                    : [
                                        _c("instagram-accounts-form", {
                                          attrs: {
                                            "account-data": _vm.currentUser,
                                          },
                                          on: {
                                            cancel: function ($event) {
                                              _vm.isEditAccount = false
                                            },
                                            success: _vm.showSuccess,
                                            error: _vm.showError,
                                          },
                                        }),
                                      ],
                                ],
                                2
                              )
                            : _c(
                                "div",
                                {
                                  staticClass:
                                    "pa-5 text-center text-14 grey--text",
                                },
                                [
                                  _c("span", [
                                    _vm._v(_vm._s(_vm.$t("loading")) + "..."),
                                  ]),
                                ]
                              ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", md: "6" } },
                    [
                      _c(
                        "v-card",
                        {
                          staticClass: "custom-cards manage-account",
                          attrs: {
                            disabled: _vm.isExtend || _vm.isEditAccount,
                          },
                        },
                        [
                          _c(
                            "v-card-title",
                            { staticClass: "px-7" },
                            [
                              _c("h2", { staticClass: "mt-1" }, [
                                _vm._v(_vm._s(_vm.$t("engagementSettings"))),
                              ]),
                              _vm._v(" "),
                              _c("v-spacer"),
                              _vm._v(" "),
                              _vm.currentUser.status &&
                              (_vm.currentUser.status == "active" ||
                                _vm.currentUser.status ==
                                  "cancel_on_period_end")
                                ? [
                                    !_vm.isEdit
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "d-flex align-center cursor-pointer",
                                            on: {
                                              click: function ($event) {
                                                _vm.isEdit = true
                                              },
                                            },
                                          },
                                          [
                                            _c(
                                              "v-icon",
                                              {
                                                staticClass:
                                                  "text-purple-2 custom",
                                                attrs: { size: "18" },
                                              },
                                              [
                                                _vm._v(
                                                  "mdi-square-edit-outline"
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "ml-1 text-purple-2 text-16",
                                              },
                                              [_vm._v(_vm._s(_vm.$t("edit")))]
                                            ),
                                          ],
                                          1
                                        )
                                      : _c(
                                          "div",
                                          {
                                            class: {
                                              "mt-2":
                                                _vm.$vuetify.breakpoint.xs,
                                            },
                                          },
                                          [
                                            _c(
                                              "v-btn",
                                              {
                                                staticClass: "text-brand",
                                                attrs: {
                                                  depressed: "",
                                                  outlined: "",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    _vm.isEdit = false
                                                  },
                                                },
                                              },
                                              [_vm._v(_vm._s(_vm.$t("cancel")))]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "v-btn",
                                              {
                                                staticClass: "btn-brand",
                                                attrs: {
                                                  depressed: "",
                                                  loading: _vm.loadingSave,
                                                },
                                                on: {
                                                  click: _vm.saveAccountForm,
                                                },
                                              },
                                              [_vm._v(_vm._s(_vm.$t("save")))]
                                            ),
                                          ],
                                          1
                                        ),
                                  ]
                                : _vm._e(),
                            ],
                            2
                          ),
                          _vm._v(" "),
                          _c("v-divider", { staticClass: "mx-5" }),
                          _vm._v(" "),
                          _vm.isEdit
                            ? _c("instagram-accounts-settings-form", {
                                ref: "accountForm",
                                attrs: {
                                  "users-data": _vm.currentUser,
                                  "url-type": _vm.urlType,
                                },
                                on: {
                                  success: _vm.accountFormSuccess,
                                  error: _vm.accountFormError,
                                },
                              })
                            : _c("instagram-accounts-details", {
                                attrs: { "users-data": _vm.currentUser },
                              }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c("h3", { staticClass: "mt-4" }, [
            _vm._v(_vm._s(_vm.$t("creditTransactions"))),
          ]),
          _vm._v(" "),
          _c("instagram-accounts-subscription-table", {
            attrs: {
              disabled: _vm.isEdit || _vm.isExtend,
              "current-user": _vm.currentUser,
              "url-type": _vm.urlType,
            },
            on: { error: _vm.showError },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("instagram-modal-cancel-subscription", {
        ref: "cancelSubscription",
        on: {
          success: function ($event) {
            return _vm.cancelSubscription("success", $event)
          },
          error: function ($event) {
            return _vm.cancelSubscription("error", $event)
          },
        },
      }),
      _vm._v(" "),
      _c("instagram-modal-add-followers", {
        ref: "addFollowers",
        on: { success: _vm.getCurrentUser },
      }),
      _vm._v(" "),
      _c("instagram-modal-remove-followers", {
        ref: "removeFollowers",
        on: { success: _vm.getCurrentUser },
      }),
      _vm._v(" "),
      _c("modal-delete", {
        ref: "autoRenew",
        on: { delete: _vm.autoRenewFunction },
      }),
      _vm._v(" "),
      _c("instagram-modal-restart-subscription", {
        ref: "renewSubscription",
        on: { success: _vm.getCurrentUser },
      }),
      _vm._v(" "),
      _c("snackbar-success", { ref: "success" }),
      _vm._v(" "),
      _c("snackbar-error", { ref: "error" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }