var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { attrs: { id: "faq-section" } }, [
    _c(
      "div",
      { staticClass: "custom-content text-center" },
      [
        _c("label", { staticClass: "text-16 ff-bold text-brand" }, [
          _vm._v(_vm._s(_vm.content.title)),
        ]),
        _vm._v(" "),
        _c("h2", { staticClass: "h_title_2 fw-bold mb-12" }, [
          _vm._v(_vm._s(_vm.content.subTitle)),
        ]),
        _vm._v(" "),
        _c(
          "v-expansion-panels",
          {
            attrs: { flat: "", tile: "" },
            model: {
              value: _vm.faqPanel,
              callback: function ($$v) {
                _vm.faqPanel = $$v
              },
              expression: "faqPanel",
            },
          },
          [
            _vm._l(_vm.content.questions, function (item, index) {
              return [
                _c(
                  "v-expansion-panel",
                  [
                    _c(
                      "v-expansion-panel-header",
                      {
                        attrs: {
                          "expand-icon":
                            _vm.faqPanel == index
                              ? "mdi-minus-circle-outline"
                              : "mdi-plus-circle-outline",
                        },
                      },
                      [
                        _c("h2", { staticClass: "text-14 fw-bold ff-bold" }, [
                          _vm._v(_vm._s(item.question)),
                        ]),
                      ]
                    ),
                    _vm._v(" "),
                    _c("v-expansion-panel-content", [
                      _c("p", {
                        staticClass: "mt-3 mb-1 text-left",
                        domProps: { innerHTML: _vm._s(item.answer) },
                      }),
                    ]),
                  ],
                  1
                ),
              ]
            }),
          ],
          2
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }