
import { Component, Vue, Prop } from 'vue-property-decorator'
import { convertBytes } from '~/utils/ConvertBytesUtils';

@Component({})
export default class MobileProxyApiFlatratePricing extends Vue {
  @Prop({ default: () => false }) readonly isModal!: any
  @Prop({ default: [] }) readonly pricing!: any;

  selectPackage(id:any) {
    if(this.isModal) {
      this.$emit('select-package', id);
    }
  }

  convertBytes(val:any, decimals:any = 0) {
    return convertBytes(val, decimals);
  }
}
