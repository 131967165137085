var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-dialog",
    {
      attrs: { width: "350" },
      model: {
        value: _vm.modal,
        callback: function ($$v) {
          _vm.modal = $$v
        },
        expression: "modal",
      },
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-card-text",
            [
              _c("v-color-picker", {
                staticClass: "ma-2 mt-5",
                attrs: {
                  "show-swatches": "",
                  swatches: _vm.swatches,
                  "canvas-height": "300",
                },
                model: {
                  value: _vm.color,
                  callback: function ($$v) {
                    _vm.color = $$v
                  },
                  expression: "color",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("v-divider"),
          _vm._v(" "),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _vm._v(" "),
              _c(
                "v-btn",
                { attrs: { color: "grey" }, on: { click: _vm.close } },
                [_vm._v("\n        " + _vm._s(_vm.$t("cancel")) + "\n      ")]
              ),
              _vm._v(" "),
              _c(
                "v-btn",
                { attrs: { color: "primary" }, on: { click: _vm.pick } },
                [
                  _vm._v(
                    "\n        " + _vm._s(_vm.$t("chooseColor")) + "\n      "
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }