var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "v-card",
        { staticClass: "custom-cards" },
        [
          _c(
            "v-card-text",
            { staticClass: "pa-7" },
            [
              _c("div", { staticClass: "d-flex align-center flex-wrap" }, [
                _c(
                  "h2",
                  {
                    staticClass:
                      "h_title_2 font-weight-bold text-black line-height-normal",
                  },
                  [_vm._v(_vm._s(_vm.$t("selectPricingMode")))]
                ),
                _vm._v(" "),
                !_vm.isAdmin || (_vm.isAdmin && _vm.$route.params.id != "new")
                  ? _c(
                      "div",
                      {
                        class: _vm.$vuetify.breakpoint.xs ? "mt-3" : "ml-auto",
                      },
                      [
                        _c(
                          "v-btn",
                          {
                            staticClass: "text-brand mr-2",
                            attrs: { depressed: "", outlined: "" },
                            on: { click: _vm.cancelClicked },
                          },
                          [_vm._v(_vm._s(_vm.$t("cancel")))]
                        ),
                        _vm._v(" "),
                        _c(
                          "v-btn",
                          {
                            staticClass: "btn-brand",
                            attrs: { depressed: "", loading: _vm.loading },
                            on: { click: _vm.subscribe },
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.$route.params.id == "new"
                                  ? _vm.$t("subscribeMobileProxy")
                                  : _vm.$t("updatePricing")
                              )
                            ),
                          ]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ]),
              _vm._v(" "),
              _c(
                "v-row",
                { staticClass: "price-mode mt-5" },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12", md: "3", sm: "6" } },
                    [
                      _c(
                        "v-card",
                        {
                          class: { active: _vm.active.dynamic },
                          attrs: { height: "215", ripple: false },
                          on: {
                            click: function ($event) {
                              return _vm.selectPlan("dynamic")
                            },
                          },
                        },
                        [
                          _c(
                            "v-card-text",
                            {
                              staticClass: "d-flex align-center justify-center",
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "text-center" },
                                [
                                  _c("v-icon", { attrs: { large: "" } }, [
                                    _vm._v("mdi-sync-circle"),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "h3",
                                    {
                                      staticClass:
                                        "h_title_3 font-weight-medium text-black mt-5 mb-2",
                                    },
                                    [_vm._v(_vm._s(_vm.$t("dynamic")))]
                                  ),
                                  _vm._v(" "),
                                  _c("span", { staticClass: "text-14" }, [
                                    _vm._v(_vm._s(_vm.$t("payAsYouGo"))),
                                  ]),
                                ],
                                1
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", md: "3", sm: "6" } },
                    [
                      _c(
                        "v-card",
                        {
                          class: { active: _vm.active.flatrate },
                          attrs: { height: "215", ripple: false },
                          on: {
                            click: function ($event) {
                              return _vm.selectPlan("flatrate")
                            },
                          },
                        },
                        [
                          _c(
                            "v-card-text",
                            {
                              staticClass: "d-flex align-center justify-center",
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "text-center" },
                                [
                                  _c("v-icon", { attrs: { large: "" } }, [
                                    _vm._v("mdi-package"),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "h3",
                                    {
                                      staticClass:
                                        "h_title_3 font-weight-medium text-black mt-5 mb-2",
                                    },
                                    [_vm._v(_vm._s(_vm.$t("flatrate")))]
                                  ),
                                  _vm._v(" "),
                                  _c("span", { staticClass: "text-14" }, [
                                    _vm._v(_vm._s(_vm.$t("fixedMonthly"))),
                                  ]),
                                ],
                                1
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.active.dynamic
        ? _c(
            "div",
            [
              _c(
                "h3",
                {
                  staticClass:
                    "h_title_3 font-weight-bold text-black mt-5 mb-3",
                },
                [_vm._v(_vm._s(_vm.$t("aboutDynamicPricing")))]
              ),
              _vm._v(" "),
              _c("p", { staticClass: "pre-wrap" }, [
                _vm._v(_vm._s(_vm.$t("aboutDynamicPricingDetails"))),
              ]),
              _vm._v(" "),
              _c("v-divider", { staticClass: "mt-7" }),
              _vm._v(" "),
              _c("v-progress-linear", {
                staticClass: "mb-5",
                attrs: {
                  indeterminate: _vm.progressLoading,
                  active: _vm.progressLoading,
                },
              }),
              _vm._v(" "),
              _c("mobile-proxy-api-dynamic-pricing", {
                attrs: { pricing: _vm.pricing.dynamic },
              }),
            ],
            1
          )
        : _c(
            "div",
            [
              _c(
                "h3",
                {
                  staticClass:
                    "h_title_3 font-weight-bold text-black mt-5 mb-3",
                },
                [_vm._v(_vm._s(_vm.$t("aboutFlatratePricing")))]
              ),
              _vm._v(" "),
              _c("p", { staticClass: "pre-wrap" }, [
                _vm._v(_vm._s(_vm.$t("aboutFlatratePricingDetails"))),
              ]),
              _vm._v(" "),
              _c("mobile-proxy-api-flatrate-pricing", {
                attrs: { pricing: _vm.pricing.flat },
              }),
            ],
            1
          ),
      _vm._v(" "),
      _c("mobile-proxy-api-modal-flatrate-pricing", {
        ref: "flatratePricing",
        attrs: { pricing: _vm.pricing.flat },
        on: {
          "select-package": function ($event) {
            return _vm.saveSubscription($event)
          },
        },
      }),
      _vm._v(" "),
      _c("snackbar-error", { ref: "error" }),
      _vm._v(" "),
      _c("snackbar-success", { ref: "success" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }