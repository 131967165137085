
import { Component, Vue } from "vue-property-decorator";

@Component({})
export default class StandardDeleteModal extends Vue {
  modal: boolean = false;
  loading: boolean = false;
  success: boolean = false;
  content: any = {};

  async show(content: any) {
    this.modal = true;
    this.success = false;
    this.content = content;
  }

  leave(): void {
    this.loading = false
    this.modal = false;
  }

  async remove() {
    this.loading = true;
    this.$emit('delete', this.content);
  }
}
