var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(_vm.componentName, {
        tag: "component",
        attrs: { data: _vm.customData, "no-add-btn": _vm.noAddBtn },
        on: {
          "cancel-event": function ($event) {
            return _vm.$emit("cancel-event", $event)
          },
          "edit-event": function ($event) {
            return _vm.$emit("edit-event", $event)
          },
        },
      }),
      _vm._v(" "),
      _c("pagination", {
        attrs: {
          "total-items": _vm.totalData,
          page: _vm.page,
          "per-page": _vm.perPage,
          "per-page-options": _vm.perPageOptions,
        },
        on: {
          "page-change": _vm.handlePageChange,
          "per-page-change": _vm.handlePerPageChange,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }