var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-form",
    { ref: "form" },
    [
      _c(
        "v-row",
        { staticClass: "mt-3" },
        [
          _c(
            "v-col",
            { attrs: { cols: "12", md: "6", sm: "6" } },
            [
              _c("users-credits-dropdown", {
                attrs: { "field-error": _vm.fieldErrorMessage },
                on: {
                  selected: function ($event) {
                    _vm.transaction.user = $event
                  },
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-col",
            { attrs: { cols: "12", md: "6", sm: "6" } },
            [
              _c(
                "label",
                { staticClass: "text-16 font-weight-medium text-black" },
                [_vm._v(_vm._s(_vm.$t("paymentDate")))]
              ),
              _vm._v(" "),
              _c(
                "v-menu",
                {
                  attrs: {
                    "close-on-content-click": false,
                    "nudge-right": 40,
                    transition: "scale-transition",
                    "offset-y": "",
                    "min-width": "auto",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function ({ on, attrs }) {
                        return [
                          _c(
                            "v-text-field",
                            _vm._g(
                              _vm._b(
                                {
                                  staticClass: "mt-2",
                                  attrs: {
                                    "hide-details": "auto",
                                    placeholder: _vm.$t("selectPaymentDate"),
                                    readonly: "",
                                    outlined: "",
                                    "background-color": "white",
                                    rules: _vm.requiredRule,
                                  },
                                  model: {
                                    value: _vm.transaction.payment_date,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.transaction,
                                        "payment_date",
                                        $$v
                                      )
                                    },
                                    expression: "transaction.payment_date",
                                  },
                                },
                                "v-text-field",
                                attrs,
                                false
                              ),
                              on
                            )
                          ),
                        ]
                      },
                    },
                  ]),
                  model: {
                    value: _vm.menuDate,
                    callback: function ($$v) {
                      _vm.menuDate = $$v
                    },
                    expression: "menuDate",
                  },
                },
                [
                  _vm._v(" "),
                  _c("v-date-picker", {
                    attrs: { "no-title": "" },
                    on: {
                      input: function ($event) {
                        _vm.menuDate = false
                      },
                    },
                    model: {
                      value: _vm.transaction.payment_date,
                      callback: function ($$v) {
                        _vm.$set(_vm.transaction, "payment_date", $$v)
                      },
                      expression: "transaction.payment_date",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-col",
            { staticClass: "pt-1", attrs: { cols: "12", md: "6", sm: "6" } },
            [
              _c(
                "label",
                { staticClass: "text-16 font-weight-medium text-black" },
                [_vm._v(_vm._s(_vm.$t("price")))]
              ),
              _vm._v(" "),
              _c("v-text-field", {
                staticClass: "mt-2",
                attrs: {
                  outlined: "",
                  type: "number",
                  placeholder: _vm.$t("enterPrice"),
                  "background-color": "white",
                  rules: _vm.requiredRule,
                  "error-messages": _vm.fieldErrorMessage.price,
                  "hide-details": "auto",
                },
                model: {
                  value: _vm.transaction.price,
                  callback: function ($$v) {
                    _vm.$set(_vm.transaction, "price", $$v)
                  },
                  expression: "transaction.price",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-col",
            { staticClass: "pt-1", attrs: { cols: "12", md: "6", sm: "6" } },
            [
              _c(
                "label",
                { staticClass: "text-16 font-weight-medium text-black" },
                [_vm._v(_vm._s(_vm.$t("currency")))]
              ),
              _vm._v(" "),
              _c("v-select", {
                staticClass: "mt-2",
                attrs: {
                  outlined: "",
                  "background-color": "white",
                  placeholder: _vm.$t("selectCurrency"),
                  items: _vm.list.currency_choices,
                  "item-text": "text",
                  "item-value": "value",
                  rules: _vm.requiredRule,
                  "error-messages": _vm.fieldErrorMessage.currency,
                  "hide-details": "auto",
                },
                model: {
                  value: _vm.transaction.currency,
                  callback: function ($$v) {
                    _vm.$set(_vm.transaction, "currency", $$v)
                  },
                  expression: "transaction.currency",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-col",
            { staticClass: "pt-1", attrs: { cols: "12", md: "6", sm: "6" } },
            [
              _c(
                "label",
                { staticClass: "text-16 font-weight-medium text-black" },
                [_vm._v(_vm._s(_vm.$t("creditQuantity")))]
              ),
              _vm._v(" "),
              _c("v-text-field", {
                staticClass: "mt-2",
                attrs: {
                  outlined: "",
                  type: "number",
                  placeholder: _vm.$t("enterQuantity"),
                  "background-color": "white",
                  rules: _vm.requiredRule,
                  "error-messages": _vm.fieldErrorMessage.credit_qty,
                  "hide-details": "auto",
                },
                model: {
                  value: _vm.transaction.credit_qty,
                  callback: function ($$v) {
                    _vm.$set(_vm.transaction, "credit_qty", $$v)
                  },
                  expression: "transaction.credit_qty",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-col",
            { staticClass: "pt-1", attrs: { cols: "12", md: "6", sm: "6" } },
            [
              _c(
                "label",
                { staticClass: "text-16 font-weight-medium text-black" },
                [_vm._v(_vm._s(_vm.$t("paymentMethod")))]
              ),
              _vm._v(" "),
              _c("v-select", {
                staticClass: "mt-2",
                attrs: {
                  outlined: "",
                  "background-color": "white",
                  placeholder: _vm.$t("selectPaymentMethod"),
                  items: _vm.list.payment_method_choices,
                  "item-text": "text",
                  "item-value": "value",
                  rules: _vm.requiredRule,
                  "error-messages": _vm.fieldErrorMessage.payment_method,
                  "hide-details": "auto",
                },
                model: {
                  value: _vm.transaction.payment_method,
                  callback: function ($$v) {
                    _vm.$set(_vm.transaction, "payment_method", $$v)
                  },
                  expression: "transaction.payment_method",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-col",
            { staticClass: "pt-1", attrs: { cols: "12" } },
            [
              _c(
                "label",
                { staticClass: "text-16 font-weight-medium text-black" },
                [_vm._v(_vm._s(_vm.$t("addtlInfo")))]
              ),
              _vm._v(" "),
              _c("v-textarea", {
                staticClass: "mt-2",
                attrs: {
                  outlined: "",
                  rows: "3",
                  placeholder: _vm.$t("enterInfo"),
                  "background-color": "white",
                  "error-messages": _vm.fieldErrorMessage.payment_meta_data,
                  "hide-details": "auto",
                },
                model: {
                  value: _vm.transaction.payment_meta_data,
                  callback: function ($$v) {
                    _vm.$set(_vm.transaction, "payment_meta_data", $$v)
                  },
                  expression: "transaction.payment_meta_data",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.hasError
        ? _c("alert-error", {
            staticClass: "mt-5",
            attrs: { "error-message": _vm.errorMessage },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.success
        ? _c("alert-success", {
            staticClass: "mt-5",
            attrs: { "success-message": _vm.$t("successSave") },
          })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "text-right mt-5" },
        [
          _vm.hasCancel
            ? _c(
                "v-btn",
                {
                  staticClass: "text-brand",
                  attrs: {
                    block: _vm.$vuetify.breakpoint.xs ? true : false,
                    outlined: "",
                    depressed: "",
                  },
                  on: { click: _vm.cancel },
                },
                [_vm._v("\n      " + _vm._s(_vm.$t("cancel")))]
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "v-btn",
            {
              staticClass: "btn-brand px-8",
              class: _vm.$vuetify.breakpoint.xs ? "mb-3" : "ml-2",
              attrs: {
                block: _vm.$vuetify.breakpoint.xs ? true : false,
                depressed: "",
                loading: _vm.loading,
              },
              on: { click: _vm.save },
            },
            [_vm._v("\n      " + _vm._s(_vm.$t("add")))]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }