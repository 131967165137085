
import { Component, Vue, Prop } from 'vue-property-decorator'
import { dateFormat } from '~/utils/DateTimeUtils'

@Component({})
export default class SettingsTransactionsCardList extends Vue {
  @Prop({ default: () => { return null }, required: false }) readonly data!: any
  @Prop({ default: () => { return false }, required: false }) readonly noAddBtn!: any

  formatDate(data:any) {
    return dateFormat(this, data);
  }

  formatFloat(num:any) {
    return parseFloat(num);
  }

  getLink() {
    if(this.$auth && this.$auth.user && this.$auth.user.is_admin) {
      return this.localePath('/dashboard/settings/transactions/add-manual');
    } else {
      return this.localePath('/dashboard/settings/transactions/add-credits');
    }
  }
}
