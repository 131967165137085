var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-tooltip",
    {
      attrs: {
        bottom: "",
        "open-delay": "150",
        "content-class": "action-btn-tooltip",
      },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function ({ on, attrs }) {
            return [
              _c(
                "v-btn",
                _vm._g(
                  _vm._b(
                    {
                      staticClass: "action-btn",
                      attrs: {
                        depressed: "",
                        small: "",
                        color: _vm.data.icon_color,
                        disabled: _vm.disabled,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.$emit("clicked")
                        },
                      },
                    },
                    "v-btn",
                    attrs,
                    false
                  ),
                  on
                ),
                [
                  _c("v-icon", { attrs: { small: "" } }, [
                    _vm._v(_vm._s(_vm.data.icon)),
                  ]),
                ],
                1
              ),
            ]
          },
        },
      ]),
    },
    [_vm._v(" "), _c("span", [_vm._v(_vm._s(_vm.data.label))])]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }