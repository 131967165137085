var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-form",
    {
      ref: "form",
      attrs: { onSubmit: "return false;" },
      on: { submit: _vm.save },
    },
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "12", md: "6", sm: "6" } },
            [
              _c(
                "label",
                { staticClass: "text-16 font-weight-medium text-black" },
                [
                  _vm._v(
                    _vm._s(_vm.$t("firstName")) +
                      " (" +
                      _vm._s(_vm.$t("optional")) +
                      ")"
                  ),
                ]
              ),
              _vm._v(" "),
              _c("v-text-field", {
                staticClass: "mt-1",
                attrs: {
                  outlined: "",
                  placeholder: _vm.$t("enterFirstName"),
                  "hide-details": "auto",
                },
                model: {
                  value: _vm.user.first_name,
                  callback: function ($$v) {
                    _vm.$set(_vm.user, "first_name", $$v)
                  },
                  expression: "user.first_name",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-col",
            { attrs: { cols: "12", md: "6", sm: "6" } },
            [
              _c(
                "label",
                { staticClass: "text-16 font-weight-medium text-black" },
                [
                  _vm._v(
                    _vm._s(_vm.$t("lastName")) +
                      " (" +
                      _vm._s(_vm.$t("optional")) +
                      ")"
                  ),
                ]
              ),
              _vm._v(" "),
              _c("v-text-field", {
                staticClass: "mt-1",
                attrs: {
                  outlined: "",
                  placeholder: _vm.$t("enterLastName"),
                  "hide-details": "auto",
                },
                model: {
                  value: _vm.user.last_name,
                  callback: function ($$v) {
                    _vm.$set(_vm.user, "last_name", $$v)
                  },
                  expression: "user.last_name",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "12" } },
            [
              _c(
                "label",
                { staticClass: "text-16 font-weight-medium text-black" },
                [_vm._v(_vm._s(_vm.$t("emailAddress")))]
              ),
              _vm._v(" "),
              _c("v-text-field", {
                staticClass: "mt-1 mb-5",
                attrs: {
                  outlined: "",
                  placeholder: _vm.$t("enterYourEmail"),
                  "hide-details": "auto",
                  rules: _vm.emailRule,
                  "error-messages": _vm.fieldErrorMessage.email,
                },
                model: {
                  value: _vm.user.email,
                  callback: function ($$v) {
                    _vm.$set(_vm.user, "email", $$v)
                  },
                  expression: "user.email",
                },
              }),
              _vm._v(" "),
              _c(
                "label",
                { staticClass: "text-16 font-weight-medium text-black" },
                [_vm._v(_vm._s(_vm.$t("password")))]
              ),
              _vm._v(" "),
              _c("v-text-field", {
                staticClass: "mt-1 mb-5",
                attrs: {
                  outlined: "",
                  placeholder: _vm.$t("enterYourPass"),
                  "hide-details": "auto",
                  rules: _vm.passwordRule,
                  "append-icon": _vm.showPassword ? "mdi-eye" : "mdi-eye-off",
                  type: _vm.showPassword ? "text" : "password",
                  "error-messages": _vm.fieldErrorMessage.password,
                },
                on: {
                  "click:append": function ($event) {
                    _vm.showPassword = !_vm.showPassword
                  },
                },
                model: {
                  value: _vm.user.password,
                  callback: function ($$v) {
                    _vm.$set(_vm.user, "password", $$v)
                  },
                  expression: "user.password",
                },
              }),
              _vm._v(" "),
              _c(
                "label",
                { staticClass: "text-16 font-weight-medium text-black" },
                [_vm._v(_vm._s(_vm.$t("confirmPass")))]
              ),
              _vm._v(" "),
              _c("v-text-field", {
                staticClass: "mt-1",
                attrs: {
                  outlined: "",
                  placeholder: _vm.$t("confirmYourPass"),
                  "hide-details": "auto",
                  rules: _vm.passwordRule,
                  "append-icon": _vm.showConfirmPassword
                    ? "mdi-eye"
                    : "mdi-eye-off",
                  type: _vm.showConfirmPassword ? "text" : "password",
                  "error-messages": _vm.fieldErrorMessage.confirm_password,
                },
                on: {
                  "click:append": function ($event) {
                    _vm.showConfirmPassword = !_vm.showConfirmPassword
                  },
                },
                model: {
                  value: _vm.user.confirm_password,
                  callback: function ($$v) {
                    _vm.$set(_vm.user, "confirm_password", $$v)
                  },
                  expression: "user.confirm_password",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.hasError
        ? _c("alert-error", {
            staticClass: "mt-5",
            attrs: { "error-message": _vm.errorMessage },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.success
        ? _c("alert-success", {
            staticClass: "mt-5",
            attrs: { "success-message": _vm.successMessage },
          })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "v-btn",
        {
          staticClass: "btn-brand mt-8",
          attrs: {
            depressed: "",
            block: "",
            large: "",
            type: "submit",
            loading: _vm.loading,
          },
        },
        [_vm._v("\n    " + _vm._s(_vm.$t("save")))]
      ),
      _vm._v(" "),
      _vm.hasCancel
        ? _c(
            "v-btn",
            {
              staticClass: "text-brand mt-3",
              attrs: { outlined: "", depressed: "", block: "", large: "" },
              on: { click: _vm.leave },
            },
            [_vm._v("\n    " + _vm._s(_vm.$t("cancel")))]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }