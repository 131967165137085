
import { Component, Vue } from 'vue-property-decorator'
import { getImage } from "~/utils/ImageUtils"

@Component({
  layout: 'landing-page',
})
export default class BlackhatForumSection extends Vue {
  
  getImage(filename:any, fallback:any) {
    return getImage(filename, fallback);
  }
}
