var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return !_vm.loading
    ? _c(
        "div",
        [
          _c(
            "v-row",
            [
              !_vm.noAddBtn
                ? _c(
                    "v-col",
                    {
                      staticClass: "pb-0",
                      attrs: { cols: "12", md: "3", sm: "6" },
                    },
                    [
                      _c(
                        "v-card",
                        {
                          staticClass: "custom-cards bordered",
                          attrs: {
                            height: "100%",
                            ripple: false,
                            to: _vm.localePath(
                              "/dashboard/instagram/accounts/add"
                            ),
                          },
                        },
                        [
                          _c(
                            "v-card-text",
                            {
                              staticClass: "d-flex align-center justify-center",
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "text-center" },
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      attrs: { size: "25", color: "btn-brand" },
                                    },
                                    [_vm._v("mdi-plus-circle-outline")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "h3",
                                    {
                                      staticClass:
                                        "h_title_3 font-weight-medium mt-3 text-brand",
                                    },
                                    [_vm._v(_vm._s(_vm.$t("addAccount")))]
                                  ),
                                ],
                                1
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm._l(_vm.accounts, function (account) {
                return [
                  _c(
                    "v-col",
                    {
                      staticClass: "pb-0",
                      attrs: { cols: "12", md: "3", sm: "6" },
                    },
                    [
                      _c(
                        "v-card",
                        {
                          staticClass: "custom-cards bordered",
                          attrs: { height: "100%" },
                        },
                        [
                          _c(
                            "v-card-text",
                            { staticClass: "px-4 pt-3 pb-2" },
                            [
                              _c(
                                "div",
                                { staticClass: "d-flex" },
                                [
                                  _c(
                                    "v-avatar",
                                    {
                                      attrs: { size: "65", color: "btn-brand" },
                                    },
                                    [
                                      account.profile_pic
                                        ? _c(
                                            "div",
                                            { staticClass: "img-border list" },
                                            [
                                              _c("img", {
                                                staticClass:
                                                  "img-container list",
                                                attrs: {
                                                  src:
                                                    "data:image/jpeg;base64," +
                                                    account.profile_pic,
                                                },
                                              }),
                                            ]
                                          )
                                        : _c(
                                            "v-icon",
                                            {
                                              staticClass: "white--text",
                                              attrs: { large: "" },
                                            },
                                            [_vm._v("mdi-account")]
                                          ),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "ml-3 overview" }, [
                                    _c(
                                      "div",
                                      { staticClass: "d-flex align-center" },
                                      [
                                        _c(
                                          "h3",
                                          {
                                            staticClass:
                                              "text-16 mt-1 username",
                                          },
                                          [
                                            _vm._v(
                                              "@" + _vm._s(account.username)
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "v-tooltip",
                                          {
                                            attrs: {
                                              bottom: "",
                                              "content-class": "custom-tooltip",
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "activator",
                                                  fn: function ({ on, attrs }) {
                                                    return [
                                                      _c(
                                                        "v-icon",
                                                        _vm._g(
                                                          _vm._b(
                                                            {
                                                              staticClass:
                                                                "mt-1 ml-1",
                                                              attrs: {
                                                                color:
                                                                  account.status ==
                                                                    "active" ||
                                                                  account.status ==
                                                                    "cancel_on_period_end"
                                                                    ? "success"
                                                                    : "",
                                                                small: "",
                                                              },
                                                            },
                                                            "v-icon",
                                                            attrs,
                                                            false
                                                          ),
                                                          on
                                                        ),
                                                        [
                                                          _vm._v(
                                                            "\n                        mdi-check-decagram\n                      "
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              true
                                            ),
                                          },
                                          [
                                            _vm._v(" "),
                                            _c(
                                              "span",
                                              {
                                                staticClass: "text-capitalize",
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    account.status
                                                      ? account.status.replace(
                                                          /_/g,
                                                          " "
                                                        )
                                                      : ""
                                                  )
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    account.paid_until
                                      ? _c(
                                          "div",
                                          { staticClass: "text-12" },
                                          [
                                            account.status == "active"
                                              ? [
                                                  _c(
                                                    "v-icon",
                                                    {
                                                      attrs: {
                                                        size: "14",
                                                        color: "success",
                                                      },
                                                    },
                                                    [_vm._v("mdi-autorenew")]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "font-weight-medium",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t("renew")
                                                        ) + ": "
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "datetime mb-0 success--text",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.formatDate(
                                                            account.paid_until
                                                          )
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              : account.status ==
                                                "cancel_on_period_end"
                                              ? [
                                                  _c(
                                                    "v-icon",
                                                    {
                                                      attrs: {
                                                        size: "14",
                                                        color:
                                                          _vm.checkActiveExpiring(
                                                            account.paid_until
                                                          )
                                                            ? "warning"
                                                            : "success",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                      mdi-checkbox-marked-circle-outline\n                    "
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "font-weight-medium",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t("expire")
                                                        ) + ":"
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "datetime mb-0",
                                                      class:
                                                        _vm.checkActiveExpiring(
                                                          account.paid_until
                                                        )
                                                          ? "warning--text"
                                                          : "success--text",
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                      " +
                                                          _vm._s(
                                                            _vm.formatDate(
                                                              account.paid_until
                                                            )
                                                          ) +
                                                          "\n                    "
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              : account.status == "unpaid" ||
                                                account.status == "cancelled"
                                              ? [
                                                  _c(
                                                    "v-icon",
                                                    {
                                                      attrs: {
                                                        size: "14",
                                                        color: "error",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "mdi-alert-circle-check-outline"
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "font-weight-medium",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t("inactive")
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              : _vm._e(),
                                          ],
                                          2
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "d-flex align-center justify-center mt-2",
                                        staticStyle: { "margin-left": "2px" },
                                      },
                                      [
                                        _c(
                                          "nuxt-link",
                                          {
                                            attrs: {
                                              to: _vm.localePath(
                                                `/dashboard/instagram/accounts/${account.id}/view`
                                              ),
                                            },
                                          },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "font-weight-medium text-purple-2 text-12",
                                              },
                                              [_vm._v(_vm._s(_vm.$t("manage")))]
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c("v-divider", {
                                          staticClass: "mx-2",
                                          attrs: { vertical: "" },
                                        }),
                                        _vm._v(" "),
                                        _c(
                                          "nuxt-link",
                                          {
                                            attrs: {
                                              to: _vm.localePath(
                                                `/dashboard/instagram/reporting?account=${account.username}`
                                              ),
                                            },
                                          },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "font-weight-medium text-purple-2 text-12 report",
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(_vm.$t("viewReport"))
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ]),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c("v-divider", { staticClass: "mt-2" }),
                              _vm._v(" "),
                              _c("instagram-accounts-progress-bar", {
                                staticClass: "mt-3",
                                attrs: { account: account },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              }),
              _vm._v(" "),
              _vm.accounts && _vm.accounts.length == 0
                ? [
                    _c(
                      "v-col",
                      { staticClass: "pb-0", attrs: { cols: "12", md: "3" } },
                      [
                        _c(
                          "v-card",
                          {
                            staticClass: "custom-cards bordered",
                            attrs: { height: "100%", ripple: false },
                          },
                          [
                            _c(
                              "v-card-text",
                              {
                                staticClass:
                                  "d-flex align-center justify-center",
                              },
                              [
                                _c("div", { staticClass: "text-center" }, [
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "text-14 font-weight-regular text-dark-grey",
                                    },
                                    [_vm._v(_vm._s(_vm.$t("noDataAvail")))]
                                  ),
                                ]),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                : _vm._e(),
            ],
            2
          ),
          _vm._v(" "),
          _vm.pageCount > 1
            ? _c(
                "div",
                { staticClass: "mt-10" },
                [
                  _c("pagination", {
                    attrs: {
                      "total-items": _vm.totalData,
                      page: _vm.page,
                      "per-page": _vm.perPage,
                      "per-page-options": _vm.perPageOptions,
                    },
                    on: {
                      "page-change": _vm.handlePageChange,
                      "per-page-change": _vm.handlePerPageChange,
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }