
import { Component, Vue, Watch, Prop } from 'vue-property-decorator'
import { dateTimeFormat } from '~/utils/DateTimeUtils'
import { LocalStorageFiltersStore } from '~/store';

@Component({})
export default class SettingsTransactionsTable extends Vue {
  @Prop({ default: () => { return null }, required: false }) readonly data!: any
  @Prop({ default: () => { return null }, required: false }) readonly loading!: any
  @Prop({ default: () => { return 1 }, required: false }) readonly currentPage!: number
  @Prop({ default: () => { return 100 }, required: false }) readonly currentPerPage!: number
  @Prop({ default: () => { return false }, required: false }) readonly isPerUser!: number

  tableItems: any = [];
  totalData: number = 0;
  page: number = 1;
  perPage: number = 100;
  perPageOptions = [15, 30, 50, 100, 300, 500];
  options: any = {};
  headers = [
    { text: this.$t('paymentDate'), value: "payment_date" },
    { text: this.$t('price'), value: "price", align: 'right' },
    { text: this.$t('creditQuantity'), value: "credit_qty", align: 'center' },
    { text: this.$t('modePayment'), value: "payment_method", },
  ];

  mounted() {
    const user = this.$auth?.user || {};
    if(user.is_admin) {
      if(!this.isPerUser) {
        this.headers.push({ text: this.$t('user'), value: "user" });
      }
      this.headers.push({ text: this.$t('addtlInfo'), value: "payment_meta_data"});
    } else {
      this.headers.push({ text: this.$t('addtlInfo'), value: "payment_meta_data"});
    }

    this.page = this.currentPage;
    this.perPage = this.currentPerPage;

    this.getData();
    this.setParams();
  }

  @Watch('options', { deep: true })
  query() {
    if(!this.freezeWatchFlag) {
      this.setParams();
    }
  }

  @Watch('currentPage')
  checkPage() {
    this.page = this.currentPage;
  }

  @Watch('currentPerPage')
  checkPerPage() {
    this.perPage = this.currentPerPage;
  }

  @Watch('loading')
  checkLoading() {
    if(this.loading) {
      this.totalData = 0;
      this.tableItems = [];
    }
  }

  @Watch('data')
  getCustomData() {
    this.getData();
  }

  handlePageChange(page: number) {
    if (page !== this.page) {
      this.page = page;
      this.setParams();
    }
  }

  handlePerPageChange(perPage: number) {
    this.page = 1;
    this.perPage = perPage;
    this.setParams();
  }

  setParams() {
    this.tableItems = [];
    this.$emit('refresh', { page: this.page, perPage: this.perPage, options: this.options });
  }

  getData() {
    if(this.data) {
      this.totalData = this.data.count ? this.data.count : 0;
      this.tableItems = this.data.count > 0 ? this.data.results : [];

      const count = this.data.count/this.perPage;
      const pageCount = count == Math.floor(count) ? count : (Math.floor(count) + 1);
      this.page = this.page > pageCount ? 1 : this.page;
    }
  }

  getName(item:any) {
    return (item.user?.first_name || "") + " " + (item.user?.last_name || "");
  }

  formatDate(data:any) {
    return dateTimeFormat(this, data);
  }

  formatFloat(num:any) {
    return parseFloat(num);
  }

  get freezeWatchFlag() {
    return LocalStorageFiltersStore.getFreeWatchFlag;
  }
}
