
import { Component, Vue } from 'vue-property-decorator'
import { getErrorMessage } from '~/utils/ErrorMessageUtils';
import { requiredRule, validateURL } from '~/utils/FormValidation'

@Component({})
export default class InstagramOrdersEdit extends Vue {
  modal: boolean = false;
  order: any = {};
  fieldErrorMessage: any = {};
  urlRule: any = [ requiredRule(), validateURL() ];
  hasCorrectDripFeed: boolean = true;
  loading: boolean = false;
  hasError: boolean = false;
  success: boolean = false;
  errorMessage: any = "";

  show(item:any) {
    this.success = false;
    this.hasError = false;
    this.modal = true;
    this.order = item;

    setTimeout(() => {
      const dripFeed = this.$refs.dripFeed as any;
      if(dripFeed) {
        dripFeed.convertSecondsToDHMS(item.drip_feed);
      }
    }, 300)
  }

  leave() {
    this.modal = false;
  }

  async save() {
    const form = this.$refs.orderForm as any;
    if(form.validate()) {
      this.loading = true;
      await this.$axios.patch(`/management/update_order/${this.order.id}/`, {
        target_url: this.order.target_url,
        drip_feed: (this.order.drip_feed ? this.order.drip_feed : 0)
      }).then(() => {
        this.success = true;
        setTimeout(() => {
          this.success = false;
          this.$emit('success');
          this.leave();
        }, 1500)
      }).catch((error:any) => {
        this.errorMessage = getErrorMessage(error, this.$t('errorSubmitForm'));
        this.fieldErrorMessage = this.errorMessage.message;
        this.hasError = this.errorMessage.show;
      })
      this.loading = false;
    }
  }

  checkUrl() {
    if(this.order.target_url) {
      const url = this.order.target_url.split("?");
      if(url.length > 0) {
        this.order.target_url = url[0];
      }
    }
  }
}
