
import { Component, Vue } from 'vue-property-decorator'
import { dateTimeFormat } from '~/utils/DateTimeUtils';

@Component({})
export default class InstagramOrderDetailsModal extends Vue {
  modal: boolean = false;
  details: any = {};

  show(item:any) {
    this.details = item;
    this.modal = true;
  }

  formatDateTime(data:any) {
    return dateTimeFormat(this, data);
  }

  formatReferenceNum(num:any, length:any) {
    var len = length - ('' + num).length;
    return (len > 0 ? new Array(++len).join('0') : '') + num;
  }
}
