
import { Component, Vue } from 'vue-property-decorator'
import { getErrorMessage } from '~/utils/ErrorMessageUtils';
import { requiredRule, validEmailRule } from '~/utils/FormValidation'

@Component({})
export default class SendUsMessageSection extends Vue {
  contactUs: any = {};
  emailRule: any = [requiredRule(), validEmailRule()];
  requiredRule: any = [requiredRule()];
  loading: boolean = false;
  success: boolean = false;
  hasError: boolean = false;
  errorMessage: any = "";

  async sendUsMessage() {
    this.hasError = false;
    const form = this.$refs.contactForm as any;
    if(form.validate()) {
      this.loading = true;
      await this.$axios.post('/mailing/contact/', this.contactUs)
      .then(() => {
        form.reset();
        this.success = true;
        setTimeout(() => {
          this.success = false;
        }, 3000)
      }).catch((error:any) => {
        this.errorMessage = getErrorMessage(error, this.$t('errorSendMessage'));
        this.hasError = true;
      })
      this.loading = false;
    }
  }
}
