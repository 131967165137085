var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-dialog",
    {
      attrs: { persistent: "", "max-width": "500" },
      model: {
        value: _vm.modal,
        callback: function ($$v) {
          _vm.modal = $$v
        },
        expression: "modal",
      },
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-card-title",
            [
              _vm.order.status == "cancelled"
                ? _c(
                    "span",
                    { staticClass: "h_title_3 font-weight-medium text-black" },
                    [_vm._v(_vm._s(_vm.$t("refundOrder")))]
                  )
                : _c(
                    "span",
                    { staticClass: "h_title_3 font-weight-medium text-black" },
                    [_vm._v(_vm._s(_vm.$t("cancelOrder")))]
                  ),
              _vm._v(" "),
              _c(
                "v-icon",
                { staticClass: "ml-auto", on: { click: _vm.leave } },
                [_vm._v("mdi-close")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-card-text",
            [
              _c("v-divider", { staticClass: "mb-3" }),
              _vm._v(" "),
              _c(
                "v-card",
                { staticClass: "mb-3", attrs: { outlined: "" } },
                [
                  _c(
                    "v-card-text",
                    { staticClass: "pt-3" },
                    [
                      _c("h1", { staticClass: "text-14 mb-2" }, [
                        _vm._v(_vm._s(_vm.$t("orderDetails"))),
                      ]),
                      _vm._v(" "),
                      _c(
                        "v-row",
                        [
                          _c("v-col", { attrs: { cols: "12", md: "6" } }, [
                            _c("label", { staticClass: "font-weight-medium" }, [
                              _vm._v(_vm._s(_vm.$t("service")) + ":"),
                            ]),
                            _vm._v(" "),
                            _vm.order.order_type
                              ? _c(
                                  "h2",
                                  {
                                    staticClass:
                                      "text-14 username text-capitalize",
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.order.order_type.replace(/_/g, " ")
                                      )
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "d-flex align-center mt-1 ml-auto text-14",
                              },
                              [
                                _c("instagram-orders-order-types-selection", {
                                  attrs: {
                                    item: {
                                      value: _vm.order.order_type,
                                      count: _vm.order.quantity,
                                    },
                                    size: 14,
                                    "custom-class": "mr-1",
                                  },
                                }),
                                _vm._v(" "),
                                _vm.order.price
                                  ? [
                                      _c("v-divider", {
                                        staticClass: "mx-3",
                                        attrs: { vertical: "" },
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "font-weight-medium text-black",
                                        },
                                        [_vm._v(_vm._s(_vm.order.price))]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-icon",
                                        {
                                          staticClass: "text-brand ml-1",
                                          attrs: { size: "12" },
                                        },
                                        [_vm._v("mdi-tag-outline")]
                                      ),
                                    ]
                                  : _vm._e(),
                              ],
                              2
                            ),
                          ]),
                          _vm._v(" "),
                          _c("v-col", { attrs: { cols: "12", md: "6" } }, [
                            _c("label", { staticClass: "font-weight-medium" }, [
                              _vm._v(_vm._s(_vm.$t("customer")) + ":"),
                            ]),
                            _vm._v(" "),
                            _vm.order.user
                              ? _c("div", { staticClass: "text-14" }, [
                                  _vm.order.user.first_name
                                    ? _c("span", [
                                        _vm._v(
                                          _vm._s(_vm.order.user.first_name)
                                        ),
                                      ])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.order.user.first_name &&
                                  _vm.order.user.last_name
                                    ? _c("span")
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.order.user.last_name
                                    ? _c("span", [
                                        _vm._v(
                                          _vm._s(_vm.order.user.last_name)
                                        ),
                                      ])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c("div", [
                                    _c("span", { staticClass: "text-brand" }, [
                                      _vm._v(_vm._s(_vm.order.user.email)),
                                    ]),
                                  ]),
                                ])
                              : _vm._e(),
                          ]),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "p",
                        { staticClass: "mt-3 text-14 mb-0" },
                        [
                          _c("label", { staticClass: "font-weight-medium" }, [
                            _vm._v(_vm._s(_vm.$t("details")) + ":"),
                          ]),
                          _vm._v(" "),
                          _vm.order.order_type == "followers"
                            ? [
                                _c("span", [
                                  _vm._v("@" + _vm._s(_vm.order.ig_username)),
                                ]),
                              ]
                            : [
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      "overflow-wrap": "break-word",
                                    },
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(_vm._s(_vm.order.target_url)),
                                    ]),
                                  ]
                                ),
                              ],
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              !_vm.errorShown && !_vm.success
                ? _c("alert-error", {
                    attrs: {
                      "error-message": [
                        _vm.order.status == "cancelled"
                          ? _vm.$t("doYouWantToRefundOrder")
                          : _vm.$t("doYouWantToCancelOrder"),
                      ],
                    },
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.success
                ? _c("alert-success", {
                    attrs: { "success-message": _vm.successMessage },
                  })
                : _vm._e(),
              _vm._v(" "),
              !_vm.success
                ? _c(
                    "div",
                    { staticClass: "text-right" },
                    [
                      _vm.order.status != "cancelled"
                        ? [
                            _c(
                              "v-btn",
                              {
                                attrs: {
                                  depressed: "",
                                  color: "btn-brand-error",
                                  loading: _vm.loading.cancel,
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.cancelRefundOrder("cancel")
                                  },
                                },
                              },
                              [
                                _c(
                                  "v-icon",
                                  { staticClass: "mr-1", attrs: { small: "" } },
                                  [_vm._v("mdi-cancel")]
                                ),
                                _vm._v(" "),
                                _c("span", [_vm._v(_vm._s(_vm.$t("cancel")))]),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "v-btn",
                              {
                                attrs: {
                                  depressed: "",
                                  color: "btn-brand-error",
                                  loading: _vm.loading.cancel_refund,
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.cancelRefundOrder(
                                      "cancel_refund"
                                    )
                                  },
                                },
                              },
                              [
                                _c(
                                  "v-icon",
                                  {
                                    staticClass: "mr-1 custom-icon-small",
                                    attrs: { small: "" },
                                  },
                                  [_vm._v("mdi-cash-refund")]
                                ),
                                _vm._v(" "),
                                _c("span", [
                                  _vm._v(_vm._s(_vm.$t("cancelAndRefund"))),
                                ]),
                              ],
                              1
                            ),
                          ]
                        : [
                            _c(
                              "v-btn",
                              {
                                attrs: {
                                  depressed: "",
                                  color: "btn-brand-error",
                                  loading: _vm.loading.refund,
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.cancelRefundOrder("refund")
                                  },
                                },
                              },
                              [
                                _c(
                                  "v-icon",
                                  {
                                    staticClass: "mr-1 custom-icon-small",
                                    attrs: { small: "" },
                                  },
                                  [_vm._v("mdi-cash-refund")]
                                ),
                                _vm._v(" "),
                                _c("span", [_vm._v(_vm._s(_vm.$t("refund")))]),
                              ],
                              1
                            ),
                          ],
                    ],
                    2
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.hasError
                ? _c("alert-error", {
                    staticClass: "mt-3",
                    attrs: { "error-message": _vm.errorMessage },
                  })
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }