var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-dialog",
    {
      attrs: { persistent: "", "max-width": "400" },
      model: {
        value: _vm.modal,
        callback: function ($$v) {
          _vm.modal = $$v
        },
        expression: "modal",
      },
    },
    [
      _c(
        "v-card",
        [
          _c("v-card-text", { staticClass: "pa-6" }, [
            _c(
              "h2",
              {
                staticClass:
                  "h_title_2 font-weight-bold text-black mb-2 text-capitalize",
              },
              [
                _vm._v(
                  _vm._s(
                    _vm.type == "add"
                      ? _vm.$t("addPorts")
                      : _vm.$t("removePorts")
                  )
                ),
              ]
            ),
            _vm._v(" "),
            _c("label", { staticClass: "text-16 text-dark-grey" }, [
              _vm._v(_vm._s(_vm.$t("socialProduct3Title"))),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "mt-8" },
              [
                _c(
                  "label",
                  { staticClass: "text-16 font-weight-medium text-black" },
                  [_vm._v(_vm._s(_vm.$t("howManyPorts")))]
                ),
                _vm._v(" "),
                _c("v-text-field", {
                  staticClass: "mt-2",
                  attrs: { outlined: "", type: "number", "hide-details": "" },
                  on: { keyup: _vm.checkPorts },
                  model: {
                    value: _vm.ports,
                    callback: function ($$v) {
                      _vm.ports = $$v
                    },
                    expression: "ports",
                  },
                }),
                _vm._v(" "),
                _vm.hasError
                  ? _c("alert-error", {
                      staticClass: "mt-5",
                      attrs: { "error-message": _vm.errorMessage },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.success
                  ? _c("alert-success", {
                      staticClass: "mt-5",
                      attrs: { "success-message": _vm.$t("successSave") },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "v-btn",
                  {
                    staticClass: "btn-brand mt-8",
                    attrs: {
                      depressed: "",
                      block: "",
                      large: "",
                      disabled: !_vm.ports,
                      loading: _vm.loading,
                    },
                    on: { click: _vm.addRemovePorts },
                  },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(
                          _vm.type == "add"
                            ? _vm.$t("addPorst")
                            : _vm.$t("removePorts")
                        )
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "v-btn",
                  {
                    staticClass: "text-brand mt-3",
                    attrs: {
                      outlined: "",
                      depressed: "",
                      block: "",
                      large: "",
                    },
                    on: {
                      click: function ($event) {
                        _vm.modal = false
                      },
                    },
                  },
                  [_vm._v("\n          " + _vm._s(_vm.$t("cancel")))]
                ),
              ],
              1
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }