var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-menu",
    {
      attrs: {
        left: "",
        bottom: "",
        "offset-y": true,
        "close-on-content-click": false,
        value: _vm.filterShown,
        "content-class": "custom-menu",
      },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function ({ on, attrs }) {
            return [
              _c(
                "div",
                _vm._g(
                  _vm._b(
                    {
                      staticClass: "d-flex align-center customer-filter",
                      class: { "ml-5 mt-1": _vm.$vuetify.breakpoint.xs },
                      on: {
                        click: function ($event) {
                          _vm.filterShown = true
                        },
                      },
                    },
                    "div",
                    attrs,
                    false
                  ),
                  on
                ),
                [
                  _c(
                    "v-icon",
                    { staticClass: "mr-1 text-brand", attrs: { small: "" } },
                    [_vm._v("mdi-sort")]
                  ),
                  _vm._v(" "),
                  _c(
                    "label",
                    {
                      staticClass:
                        "cursor-pointer font-weight-medium text-brand",
                    },
                    [_vm._v(_vm._s(_vm.$t("sort")))]
                  ),
                  _vm._v(" "),
                  _c("v-icon", [_vm._v("mdi-menu-down")]),
                ],
                1
              ),
            ]
          },
        },
      ]),
    },
    [
      _vm._v(" "),
      _c(
        "v-card",
        { attrs: { width: "200" } },
        [
          _c(
            "v-card-text",
            { staticClass: "px-5" },
            [
              _c(
                "div",
                { staticClass: "d-flex align-center" },
                [
                  _c("label", [_vm._v(_vm._s(_vm.$t("sortBy")))]),
                  _vm._v(" "),
                  _c("v-switch", {
                    staticClass: "mt-0 pt-0 ml-auto",
                    attrs: {
                      color: "btn-brand",
                      label: _vm.ascending ? "ASC" : "DESC",
                      ripple: false,
                      "hide-details": "",
                    },
                    on: { change: _vm.getAsc },
                    model: {
                      value: _vm.ascending,
                      callback: function ($$v) {
                        _vm.ascending = $$v
                      },
                      expression: "ascending",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("v-divider", { staticClass: "mt-2" }),
              _vm._v(" "),
              _c(
                "v-radio-group",
                {
                  staticClass: "mt-2",
                  attrs: { "hide-details": "" },
                  on: { change: _vm.getField },
                  model: {
                    value: _vm.sortField,
                    callback: function ($$v) {
                      _vm.sortField = $$v
                    },
                    expression: "sortField",
                  },
                },
                [
                  _c("v-radio", {
                    attrs: {
                      label: _vm.$t("username"),
                      value: "username",
                      color: "btn-brand",
                      ripple: false,
                    },
                  }),
                  _vm._v(" "),
                  _c("v-radio", {
                    attrs: {
                      label: _vm.$t("created"),
                      value: "created",
                      color: "btn-brand",
                      ripple: false,
                    },
                  }),
                  _vm._v(" "),
                  _c("v-radio", {
                    attrs: {
                      label: _vm.$t("paidUntil"),
                      value: "paid_until",
                      color: "btn-brand",
                      ripple: false,
                    },
                  }),
                  _vm._v(" "),
                  _c("v-radio", {
                    attrs: {
                      label: _vm.$t("followers"),
                      value: "current_follower_count",
                      color: "btn-brand",
                      ripple: false,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }