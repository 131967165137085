
import { Component, Vue, Prop } from 'vue-property-decorator'
import { dateTimeFormat } from '~/utils/DateTimeUtils'

@Component({})
export default class InstagramOrdersCardList extends Vue {
  @Prop({ default: () => { return null }, required: false }) readonly data!: any
  @Prop({ default: () => { return false }, required: false }) readonly noAddBtn!: any

  formatDateTime(data:any) {
    return dateTimeFormat(this, data);
  }

  writeToFile(item:any) {
    if(item.comments_text) {
      const content = JSON.parse(item.comments_text).join('\n');
      const blob = new Blob([content], { type: 'text/plain' });
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = 'CommentsList' + item.id + this.$dayjs(item.created).format('YYYYMMDDHHMM') + '.txt';
      link.click();
    }
  }

  checkIfHasDownloadBtn(data:any) {
    if(data) {
      const check = JSON.parse(data);
      return check.length > 0 ? true : false;
    } else {
      return false;
    }
  }

  convertSecondsToDHMS(sec:any) {
    const days = Math.floor(sec / (3600 * 24));
    const hours = Math.floor((sec % (3600 * 24)) / 3600);
    const minutes = Math.floor((sec % 3600) / 60);
    const seconds = sec % 60;
    let time = "";
    
    if(days > 0) {
      time += days + " " + this.$t('days');
      if(hours > 0 || minutes > 0 || seconds > 0) {
        time += ", ";
      }
    }
    if(hours > 0) {
      time += hours + " " + this.$t('hours');
      if(minutes > 0 || seconds > 0) {
        time += ", ";
      }
    }
    if(minutes > 0) {
      time +=  minutes + " " + this.$t('minutes');
      if(seconds > 0) {
        time += ", ";
      }
    }
    if(seconds > 0) {
      time += seconds + " " + this.$t('seconds');
    }

    return time;
  }
}
