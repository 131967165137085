
import { Component, Mixins, Watch } from 'vue-property-decorator'
import TableFiltersMixin from '~/mixins/local-storage-filters'
import { TransactionsStore } from '~/store';

@Component({})
export default class AdminTransactionsTable extends Mixins(TableFiltersMixin) {
  allData: any = [];
  progressLoading: boolean = false;
  page: number = 1;
  perPage: number = 100;
  options: any = {};
  filters: any = {};
  loaded: boolean = false;
  showActive: any = 1;
  isMobile: boolean = false;
  pageLoading: boolean = true;
  holderFilters: any = {};

  async mounted() {
    setTimeout(() => {
      if(this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.md) {
        this.isMobile = true;
        this.perPage = 9;
      }
      this.pageLoading = false;
    }, 500)
  }

  @Watch('$vuetify.breakpoint.width')
  checkBreakpoint() {
    if(this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.md) {
      this.isMobile = true;
    } else if(this.$vuetify.breakpoint.lg) {
      this.isMobile = false;
    }
  }

  @Watch('holderFilters')
  checkFilters(val:any) {
    if(!val.hasOwnProperty('payment_method')) {
      val.payment_method = JSON.stringify(["crypto","stripe_checkout","stripe_manual","bank_transfer","refund"]);
    }
    this.filters = { ...this.filters, ...val };
    this.$emit('filters', this.filters);
  }

  tableFilters(item:any = null) {
    this.page = 1;
    if(item && item.sort) {
      this.options = { ...item.sort };
      delete item.sort;
    }
    this.filters = { ...this.filters, ...item };
    this.preGetData();
  }

  refreshTable(item:any) {
    this.page = item.page;
    this.perPage = item.perPage;
    this.options = item.options;
    this.preGetData();
  }

   // Fetch and save filters to local storage
   async preGetData() {
    await this.getLocalFilters(this, 'HH_admin_view_transactions');
    await this.getData();

    const params = {
      page: this.page,
      perPage: this.perPage,
      options: this.options,
      filters: { ...this.filters }
    }

    await this.saveLocalFilters(params, 'HH_admin_view_transactions');
  }

  async getData() {
    this.progressLoading = true;

    const { sortBy, sortDesc } = this.options
    let orderBy = sortBy ? sortBy[0] : '';
    let orderDesc = sortDesc ? sortDesc[0] : false;

    if(orderBy) {
      this.filters.ordering = orderDesc ? ('-' + orderBy) : orderBy;
    } else {
      this.filters.ordering = null;
    }

    const params = {
      offset: this.page === 1 ? 0 : (this.page - 1) * this.perPage,
      limit: this.perPage,
      user: this.$route.params.id,
      ...this.filters,
    }

    await TransactionsStore.fetchOrders(params)
    .then((response:any) => {
      this.allData = response;
    }).catch((error:any) => {
      this.$emit('error', { error: error, default: this.$t('errorFetchingData') })
    })

    this.progressLoading = false;
  }
}
