
import { Component, Vue, Prop } from 'vue-property-decorator'
import { AccountsStore, AdminStore } from '~/store'
import { dateFormat } from '~/utils/DateTimeUtils';
import { getErrorMessage } from "~/utils/ErrorMessageUtils"

@Component({
  head(this: InstagramViewAccount): object { return { title: this.$t('viewInstaAccountPageTitle') } },
})
export default class InstagramViewAccount extends Vue {
  @Prop({ default: 'instabot' }) readonly urlType!: any

  isExtend: boolean = false;
  isEdit: boolean = false;
  loadingSave: boolean = false;
  currentUser: any = {};
  progressLoading: boolean = false;
  loadingExtend: boolean = false;
  extendData: any = { num_cycle: 1, user: null };
  autoRenew: boolean = false;
  isEditAccount: boolean = false;

  async mounted() {
    await this.getCurrentUser();
    this.$emit('current-user', this.currentUser);

    const user = this.$auth?.user || {};
    if(user?.is_admin) {
      this.extendData.user = user?.id;
      AdminStore.fetchENUM();
    } else {
      this.extendData.user = this.currentUser?.user;
    }
  }

  async getCurrentUser() {
    this.progressLoading = true;
    await AccountsStore.fetchCurrentAccount({ type: this.urlType, id: this.$route.params.id })
    .then((response:any) => {
      this.currentUser = response;
      this.autoRenew = this.currentUser.status == 'active' ? true : false;
    }).catch((error:any) => {
      const message = getErrorMessage(error, this.$t('errorFetchingData'));
      const snackbar = this.$refs.error as any;
      if(snackbar) { snackbar.show(message); }
    })
    this.progressLoading = false;
  }

  showModal(ref:any) {
    const modal = this.$refs[ref] as any;
    if(modal) {
      modal.show(this.currentUser, this.urlType );
    }
  }

  checkBorderRadius(num:any, max:any) {
    if(num < max) {
      return 'rounded rounded-r-0';
    } else if(num > max) {
      return 'rounded';
    } else if(this.getPercentageValue(num,max) == 100) {
      return 'rounded rounded-l-0';
    }
  }

  getPercentageValue(num:any, max:any) {
    const followersCount = num ? num : 0;
    const number = followersCount/max;
    const percentage = Math.round(number*100);
    const total = (num > 0 && percentage < 25) ? percentage + 10 : percentage;
    return total > 100 ? 100 : total;
  }

  getMonthlySubscription() {
    return this.currentUser.max_follower_count ? Math.floor(this.currentUser.max_follower_count*.15) : '';
  }

  saveAccountForm() {
    const modal = this.$refs.accountForm as any;
    if(modal) {
      this.loadingSave = true;
      modal.save();
    }
  }

  accountFormSuccess() {
    this.isEdit = false;
    this.loadingSave = false;
    const snackbar = this.$refs.success as any;
    snackbar.show(this.$t('successSave'));
    this.getCurrentUser();
  }

  accountFormError(error:any) {
    this.loadingSave = false;
    const message = getErrorMessage(error, this.$t('errorSaveData'));
    const snackbar = this.$refs.error as any;
    if(snackbar) { snackbar.show(message); }
  }

  cancelSubscription(type:any, item:any) {
    if(type == 'success') {
      const snackbar = this.$refs.success as any;
      snackbar.show(this.$t('successCancelSub'));
    } else {
      const message = getErrorMessage(item, this.$t('errorSaveData'));
      const snackbar = this.$refs.error as any;
      if(snackbar) { snackbar.show(message); }
    }
    this.getCurrentUser();
  }

  autoRenewModal(id:any) {
    let content = {} as any;
    if(this.autoRenew) {
      content = {
        id: id,
        type: 'disable',
        successMsg: this.$t('successUpdate'),
        btnLbl: this.$t('proceed'),
        ...this.$t('disableAutoRenewModalContent') as any
      }
    } else {
      content = {
        id: id,
        type: 'enable',
        successMsg: this.$t('successUpdate'),
        btnLbl: this.$t('proceed'),
        ...this.$t('autoRenewModalContent') as any
      }
    }

    const modal = this.$refs.autoRenew as any;
    modal.show(content);
  }

  autoRenewFunction(item:any) {
    if(item.type == 'enable') {
      this.stopCancellation();
    } else {
      this.cancelOnPeriod();
    }
  }

  async stopCancellation() {
    await this.$axios.post(`/${this.urlType}/instagram-accounts/${this.currentUser.id}/uncancel_on_period_end/`)
    .then(() => {
      this.getCurrentUser();

      const modal = this.$refs.autoRenew as any;
      modal.success = true;
      setTimeout(() => {
        modal.leave();
      }, 2000)
    }).catch((error:any) => {
      const message = getErrorMessage(error, this.$t('errorUpdateData'));
      const snackbar = this.$refs.error as any;
      if(snackbar) { snackbar.show(message); }
    })
  }

  async cancelOnPeriod() {
    await this.$axios.post(`/${this.urlType}/instagram-accounts/${this.currentUser.id}/cancel_on_period_end/`)
    .then(() => {
      this.getCurrentUser();

      const modal = this.$refs.autoRenew as any;
      modal.success = true;
      setTimeout(() => {
        modal.leave();
      }, 2000)
    }).catch((error:any) => {
      const message = getErrorMessage(error, this.$t('errorUpdateData'));
      const snackbar = this.$refs.error as any;
      if(snackbar) { snackbar.show(message); }
    })
  }

  checkActiveExpiring(date:any) {
    const sevenDaysFuture = this.$dayjs().add(7, 'day').format('YYYY-MM-DD');
    const today = this.$dayjs().format('YYYY-MM-DD');
    
    if(date >= today && date <= sevenDaysFuture) {
      return true;
    }
    return false
  }

  getReportLink() {
    if(this.urlType == "instabot") {
      return `/dashboard/instagram/reporting?account=${this.currentUser.username}`;
    } else {
      return `/dashboard/instagram/reporting?a_id=${this.currentUser.id}&a_account=${this.currentUser.username}`;
    }
  }

  async saveExtendSubscription() {
    this.loadingExtend = true;
    await this.$axios.post(`/${this.urlType}/instagram-accounts/${this.$route.params.id}/extend_subscription/`, this.extendData)
    .then(() => {
      const snackbar = this.$refs.success as any;
      snackbar.show(this.$t('successExtendSub'));
      
      this.isExtend = false;
      this.getCurrentUser();
    }).catch((error:any) => {
      const message = getErrorMessage(error, this.$t('errorUpdateData'));
      const snackbar = this.$refs.error as any;
      if(snackbar) { snackbar.show(message); }
    })
    this.loadingExtend = false;
  }

  formatDate(date:any) {
    return dateFormat(this, date);
  }

  showError(item:any) {
    const message = getErrorMessage(item.error, item.default);
    const snackbar = this.$refs.error as any;
    if(snackbar) { snackbar.show(message); }
  }

  async showSuccess() {
    const snackbar = this.$refs.success as any;
    if(snackbar) { snackbar.show(this.$t('successSave')); }

    this.isEditAccount = false;
    await this.getCurrentUser();
    this.$emit('current-user', { ...this.currentUser, isUpdate: true });
  }

  get currentAccount() {
    return AccountsStore.getCurrentAccount;
  }
}
