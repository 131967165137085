var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "v-card-text",
        { staticClass: "percentage-form pa-7" },
        [
          _c(
            "v-row",
            { attrs: { align: "center" } },
            [
              _c("v-col", { attrs: { cols: "8", md: "7" } }, [
                _c(
                  "div",
                  { staticClass: "d-flex align-center" },
                  [
                    _c("v-icon", { staticClass: "text-brand" }, [
                      _vm._v("mdi-account-group"),
                    ]),
                    _vm._v(" "),
                    _c(
                      "label",
                      {
                        staticClass:
                          "text-16 font-weight-medium text-black ml-2",
                      },
                      [_vm._v(_vm._s(_vm.$t("maxDailyGrowth")))]
                    ),
                    _vm._v(" "),
                    _c(
                      "v-tooltip",
                      {
                        attrs: {
                          bottom: "",
                          "content-class": "custom-tooltip",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "activator",
                            fn: function ({ on, attrs }) {
                              return [
                                _c(
                                  "v-icon",
                                  _vm._g(
                                    _vm._b(
                                      {
                                        staticClass:
                                          "mt-auto ml-1 custom-icon-small",
                                        attrs: { size: "18" },
                                      },
                                      "v-icon",
                                      attrs,
                                      false
                                    ),
                                    on
                                  ),
                                  [
                                    _vm._v(
                                      "\n                mdi-help-circle\n              "
                                    ),
                                  ]
                                ),
                              ]
                            },
                          },
                        ]),
                      },
                      [
                        _vm._v(" "),
                        _c("span", [
                          _vm._v(_vm._s(_vm.$t("setPercentForMaxFollowers"))),
                        ]),
                      ]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "grey--text text--darken-1",
                    class: { "ml-8": !_vm.$vuetify.breakpoint.xs },
                  },
                  [
                    _vm.userData.daily_follower_limit_pct < 100
                      ? _c("span", { staticClass: "text-12" }, [
                          _vm._v(
                            _vm._s(
                              _vm.$t("maxDailyGrowthLabel", {
                                limit: (
                                  _vm.userData.current_follower_count *
                                  (_vm.userData.daily_follower_limit_pct / 100)
                                ).toFixed(0),
                              })
                            )
                          ),
                        ])
                      : _c("span", { staticClass: "text-12" }, [
                          _vm._v(
                            _vm._s(
                              _vm.$t("maxDailyGrowthLabel", {
                                limit: _vm.userData.current_follower_count,
                              })
                            )
                          ),
                        ]),
                  ]
                ),
              ]),
              _vm._v(" "),
              _c(
                "v-col",
                { attrs: { cols: "4", md: "5" } },
                [
                  _c("v-text-field", {
                    attrs: {
                      outlined: "",
                      dense: "",
                      small: "",
                      "hide-details": "auto",
                      type: "number",
                      suffix: "%",
                      "background-color": "white",
                    },
                    model: {
                      value: _vm.userData.daily_follower_limit_pct,
                      callback: function ($$v) {
                        _vm.$set(_vm.userData, "daily_follower_limit_pct", $$v)
                      },
                      expression: "userData.daily_follower_limit_pct",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "mt-8" },
            [
              _c("div", { staticClass: "d-flex align-center" }, [
                _c(
                  "div",
                  { staticClass: "d-flex align-center" },
                  [
                    _c("v-icon", { staticClass: "text-brand" }, [
                      _vm._v("mdi-thumb-up"),
                    ]),
                    _vm._v(" "),
                    _c(
                      "label",
                      {
                        staticClass:
                          "text-16 font-weight-medium text-black ml-2",
                      },
                      [_vm._v(_vm._s(_vm.$t("likePercent")))]
                    ),
                    _vm._v(" "),
                    _c(
                      "v-tooltip",
                      {
                        attrs: {
                          bottom: "",
                          "content-class": "custom-tooltip",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "activator",
                            fn: function ({ on, attrs }) {
                              return [
                                _c(
                                  "v-icon",
                                  _vm._g(
                                    _vm._b(
                                      {
                                        staticClass:
                                          "mt-auto ml-1 custom-icon-small",
                                        attrs: { size: "18" },
                                      },
                                      "v-icon",
                                      attrs,
                                      false
                                    ),
                                    on
                                  ),
                                  [
                                    _vm._v(
                                      "\n                mdi-help-circle\n              "
                                    ),
                                  ]
                                ),
                              ]
                            },
                          },
                        ]),
                      },
                      [
                        _vm._v(" "),
                        _c("span", [
                          _vm._v(_vm._s(_vm.$t("setPercentForLikePost"))),
                        ]),
                      ]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "ml-auto" },
                  [
                    _c("v-text-field", {
                      staticClass: "custom-label-input range-label",
                      attrs: {
                        outlined: "",
                        dense: "",
                        "hide-details": "",
                        type: "number",
                        suffix: "%",
                        "background-color": "white",
                      },
                      model: {
                        value: _vm.userData.pct_like,
                        callback: function ($$v) {
                          _vm.$set(_vm.userData, "pct_like", $$v)
                        },
                        expression: "userData.pct_like",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("v-slider", {
                attrs: {
                  hint: _vm.$t("likePercent"),
                  max: "100",
                  min: "0",
                  color: "btn-brand",
                  "track-color": "btn-brand-track",
                  "hide-details": "",
                  step: 0.1,
                },
                model: {
                  value: _vm.userData.pct_like,
                  callback: function ($$v) {
                    _vm.$set(_vm.userData, "pct_like", $$v)
                  },
                  expression: "userData.pct_like",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "mt-5" },
            [
              _c("div", { staticClass: "d-flex align-center" }, [
                _c(
                  "div",
                  { staticClass: "d-flex align-center" },
                  [
                    _c("v-icon", { staticClass: "text-brand" }, [
                      _vm._v("mdi-content-save"),
                    ]),
                    _vm._v(" "),
                    _c(
                      "label",
                      {
                        staticClass:
                          "text-16 font-weight-medium text-black ml-2",
                      },
                      [_vm._v(_vm._s(_vm.$t("savePercent")))]
                    ),
                    _vm._v(" "),
                    _c(
                      "v-tooltip",
                      {
                        attrs: {
                          bottom: "",
                          "content-class": "custom-tooltip",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "activator",
                            fn: function ({ on, attrs }) {
                              return [
                                _c(
                                  "v-icon",
                                  _vm._g(
                                    _vm._b(
                                      {
                                        staticClass:
                                          "mt-auto ml-1 custom-icon-small",
                                        attrs: { size: "18" },
                                      },
                                      "v-icon",
                                      attrs,
                                      false
                                    ),
                                    on
                                  ),
                                  [
                                    _vm._v(
                                      "\n                mdi-help-circle\n              "
                                    ),
                                  ]
                                ),
                              ]
                            },
                          },
                        ]),
                      },
                      [
                        _vm._v(" "),
                        _c("span", [
                          _vm._v(_vm._s(_vm.$t("setPercentForSavePost"))),
                        ]),
                      ]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "ml-auto" },
                  [
                    _c("v-text-field", {
                      staticClass: "custom-label-input range-label",
                      attrs: {
                        outlined: "",
                        dense: "",
                        small: "",
                        "hide-details": "",
                        type: "number",
                        suffix: "%",
                        "background-color": "white",
                      },
                      model: {
                        value: _vm.userData.pct_save,
                        callback: function ($$v) {
                          _vm.$set(_vm.userData, "pct_save", $$v)
                        },
                        expression: "userData.pct_save",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("v-slider", {
                attrs: {
                  hint: _vm.$t("savePercent"),
                  max: "100",
                  min: "0",
                  color: "btn-brand",
                  "track-color": "btn-brand-track",
                  "hide-details": "",
                  step: 0.1,
                },
                model: {
                  value: _vm.userData.pct_save,
                  callback: function ($$v) {
                    _vm.$set(_vm.userData, "pct_save", $$v)
                  },
                  expression: "userData.pct_save",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("v-divider", { staticClass: "mt-3" }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "mt-5" },
            [
              _c("div", { staticClass: "d-flex align-center" }, [
                _c(
                  "div",
                  { staticClass: "d-flex align-center" },
                  [
                    _c("v-icon", { staticClass: "text-brand" }, [
                      _vm._v("mdi-eye"),
                    ]),
                    _vm._v(" "),
                    _c(
                      "label",
                      {
                        staticClass:
                          "text-16 font-weight-medium text-black ml-2",
                      },
                      [_vm._v(_vm._s(_vm.$t("storyPercent")))]
                    ),
                    _vm._v(" "),
                    _c(
                      "v-tooltip",
                      {
                        attrs: {
                          bottom: "",
                          "content-class": "custom-tooltip",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "activator",
                            fn: function ({ on, attrs }) {
                              return [
                                _c(
                                  "v-icon",
                                  _vm._g(
                                    _vm._b(
                                      {
                                        staticClass:
                                          "mt-auto ml-1 custom-icon-small",
                                        attrs: { size: "18" },
                                      },
                                      "v-icon",
                                      attrs,
                                      false
                                    ),
                                    on
                                  ),
                                  [
                                    _vm._v(
                                      "\n                mdi-help-circle\n              "
                                    ),
                                  ]
                                ),
                              ]
                            },
                          },
                        ]),
                      },
                      [
                        _vm._v(" "),
                        _c("span", [
                          _vm._v(_vm._s(_vm.$t("setPercentForWatchStory"))),
                        ]),
                      ]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "ml-auto" },
                  [
                    _c("v-text-field", {
                      staticClass: "custom-label-input range-label",
                      attrs: {
                        outlined: "",
                        dense: "",
                        small: "",
                        "hide-details": "",
                        type: "number",
                        suffix: "%",
                        "background-color": "white",
                      },
                      model: {
                        value: _vm.userData.pct_story,
                        callback: function ($$v) {
                          _vm.$set(_vm.userData, "pct_story", $$v)
                        },
                        expression: "userData.pct_story",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("v-slider", {
                attrs: {
                  hint: _vm.$t("storyPercent"),
                  max: "100",
                  min: "0",
                  color: "btn-brand",
                  "track-color": "btn-brand-track",
                  "hide-details": "",
                  step: 0.1,
                },
                model: {
                  value: _vm.userData.pct_story,
                  callback: function ($$v) {
                    _vm.$set(_vm.userData, "pct_story", $$v)
                  },
                  expression: "userData.pct_story",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("v-divider", { staticClass: "mt-3" }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "mt-5" },
            [
              _c("div", { staticClass: "d-flex align-center" }, [
                _c(
                  "div",
                  { staticClass: "d-flex align-center" },
                  [
                    _c("v-icon", { staticClass: "text-brand" }, [
                      _vm._v("mdi-comment-plus"),
                    ]),
                    _vm._v(" "),
                    _c(
                      "label",
                      {
                        staticClass:
                          "text-16 font-weight-medium text-black ml-2",
                      },
                      [_vm._v(_vm._s(_vm.$t("commentPercent")))]
                    ),
                    _vm._v(" "),
                    _c(
                      "v-tooltip",
                      {
                        attrs: {
                          bottom: "",
                          "content-class": "custom-tooltip",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "activator",
                            fn: function ({ on, attrs }) {
                              return [
                                _c(
                                  "v-icon",
                                  _vm._g(
                                    _vm._b(
                                      {
                                        staticClass:
                                          "mt-auto ml-1 custom-icon-small",
                                        attrs: { size: "18" },
                                      },
                                      "v-icon",
                                      attrs,
                                      false
                                    ),
                                    on
                                  ),
                                  [
                                    _vm._v(
                                      "\n                mdi-help-circle\n              "
                                    ),
                                  ]
                                ),
                              ]
                            },
                          },
                        ]),
                      },
                      [
                        _vm._v(" "),
                        _c("span", [
                          _vm._v(_vm._s(_vm.$t("setPercentForCommentPost"))),
                        ]),
                      ]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "ml-auto" },
                  [
                    _c("v-text-field", {
                      staticClass: "custom-label-input range-label",
                      attrs: {
                        outlined: "",
                        dense: "",
                        small: "",
                        "hide-details": "",
                        type: "number",
                        suffix: "%",
                        "background-color": "white",
                      },
                      model: {
                        value: _vm.userData.pct_comment,
                        callback: function ($$v) {
                          _vm.$set(_vm.userData, "pct_comment", $$v)
                        },
                        expression: "userData.pct_comment",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("v-slider", {
                attrs: {
                  hint: _vm.$t("commentPercent"),
                  max: "100",
                  min: "0",
                  color: "btn-brand",
                  "track-color": "btn-brand-track",
                  "hide-details": "",
                  step: 0.1,
                },
                model: {
                  value: _vm.userData.pct_comment,
                  callback: function ($$v) {
                    _vm.$set(_vm.userData, "pct_comment", $$v)
                  },
                  expression: "userData.pct_comment",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-row",
            { staticClass: "mt-3", attrs: { align: "center" } },
            [
              _c(
                "v-col",
                { attrs: { cols: "6", md: "6" } },
                [
                  _c("v-icon", { staticClass: "text-brand" }, [
                    _vm._v("mdi-comment-text-multiple"),
                  ]),
                  _vm._v(" "),
                  _c(
                    "label",
                    {
                      staticClass: "text-16 font-weight-medium text-black ml-2",
                    },
                    [_vm._v(_vm._s(_vm.$t("commentFile")))]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-col",
                { attrs: { cols: "6", md: "6" } },
                [
                  _c("text-file-parser", {
                    attrs: { "has-label": false, "is-required": false },
                    on: {
                      "get-data": function ($event) {
                        return _vm.getUploadedData($event)
                      },
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }