import { render, staticRenderFns } from "./ig-flatrate-section.vue?vue&type=template&id=5c6849dd"
import script from "./ig-flatrate-section.vue?vue&type=script&lang=ts"
export * from "./ig-flatrate-section.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('5c6849dd')) {
      api.createRecord('5c6849dd', component.options)
    } else {
      api.reload('5c6849dd', component.options)
    }
    module.hot.accept("./ig-flatrate-section.vue?vue&type=template&id=5c6849dd", function () {
      api.rerender('5c6849dd', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "components/landing/ig-flatrate-section.vue"
export default component.exports