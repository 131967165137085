var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-dialog",
    {
      attrs: { persistent: "", "max-width": "500" },
      model: {
        value: _vm.modal,
        callback: function ($$v) {
          _vm.modal = $$v
        },
        expression: "modal",
      },
    },
    [
      _c(
        "v-card",
        [
          _c("v-card-title", [
            _c(
              "span",
              { staticClass: "h_title_3 font-weight-medium text-black" },
              [_vm._v(_vm._s(_vm.content.title))]
            ),
          ]),
          _vm._v(" "),
          _c(
            "v-card-text",
            [
              _c("v-divider", { staticClass: "mb-3" }),
              _vm._v(" "),
              !_vm.success
                ? _c("alert-error", {
                    attrs: { "error-message": [_vm.content.errorMsg] },
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.success
                ? _c("alert-success", {
                    attrs: { "success-message": _vm.content.successMsg },
                  })
                : _vm._e(),
              _vm._v(" "),
              !_vm.success
                ? _c(
                    "div",
                    { staticClass: "text-right" },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            depressed: "",
                            color: "btn-brand-gray mr-2",
                          },
                          on: { click: _vm.leave },
                        },
                        [_vm._v(_vm._s(_vm.$t("cancel")))]
                      ),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            depressed: "",
                            color: "btn-brand",
                            loading: _vm.loading,
                          },
                          on: { click: _vm.remove },
                        },
                        [_vm._v(_vm._s(_vm.content.btnLbl))]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }