
import { Component, Vue, Prop } from "vue-property-decorator";
import { BlogsStore } from "~/store";
import { getErrorMessage } from "~/utils/ErrorMessageUtils";

@Component({})
export default class BlogsCategoriesForm extends Vue {
  loading: boolean = false;
  editLoading: boolean = false;
  progressLoading: boolean = false;
  isEdit: boolean = false;
  success: boolean = false;
  hasError: boolean = false;
  errorMessage: any = "Error";
  editId: any = "";
  categoryName: any = "";
  editData: any = {};
  fieldErrorMessage: any = { add: {}, edit: {} };
  categories: any = [];
  categoryGermanName: any = "";

  mounted() {
    this.getCategories();
  }

  async getCategories() {
    this.progressLoading = true;
    await BlogsStore.fetchCategories()
    .then((response:any) => {
      this.categories = response.results;
    }).catch((error) => {
      const message = getErrorMessage(error, this.$t('errorFetchCategories'));
      const snackbar = this.$refs.error as any;
      if(snackbar) { snackbar.show(message); }
    })
    this.progressLoading = false;
  }

  async save() {
    this.loading = true;
    this.success = false;
    this.hasError = false;
    this.fieldErrorMessage.add = {};

    let data = { name: this.categoryName } as any;
    if(this.categoryGermanName) {
      data.german_name = this.categoryGermanName;
    }

    await this.$axios.post('/blogs/categories/', data)
    .then((res) => {
      this.categoryName = null;
      this.categoryGermanName = null;
      this.success = true;
      this.$emit('success');
      this.getCategories();

      setTimeout(() => {
        this.success = false;
      }, 2000)
    }).catch((error:any) => {
      if (error.response) {
        this.errorMessage = getErrorMessage(error, this.$t('errorSaveCategory'));
        this.fieldErrorMessage.add = this.errorMessage.message;
        this.hasError = this.errorMessage.show;
        setTimeout(() => {
          this.hasError = false;
        }, 2000)
      }
    })
    this.loading = false;
  }

  editItem(item:any) {
    this.editId = item.id;
    this.isEdit = true;
    this.editData = {
      id: item.id,
      name: item.name,
      slug: item.slug,
      german_name: item.german_name,
    }
  }

  async editCategory() {
    this.hasError = false;
    this.editLoading = true;

    let data = { name: this.editData.name } as any;
    if(this.editData.german_name) {
      data.german_name = this.editData.german_name;
    }

    await this.$axios.patch(`/blogs/categories/${this.editData.id}/`, data)
    .then((res) => {
      this.isEdit = false;
      this.editId = 0;
      this.success = true;
      this.$emit('success');
      this.getCategories();

      setTimeout(() => {
        this.success = false;
      }, 2000)
      
    }).catch((error:any) => {
      if (error.response) {
        this.errorMessage = getErrorMessage(error, this.$t('errorUpdateCategory'));
        this.fieldErrorMessage.edit = this.errorMessage.message;
        this.hasError = this.errorMessage.show;
        setTimeout(() => {
          this.hasError = false;
        }, 2000)
      }
    })
    this.editLoading = false;
  }

  deleteCategoryModal(id:any) {
    const content = {
      id: id,
      ...this.$t('categoryModalDelete') as any
    }

    const modal = this.$refs.deleteCategory as any;
    modal.show(content);
  }

  deletedCategory() {
    const modal = this.$refs.deleteCategory as any;
    modal.success = true;
    setTimeout(() => {
      modal.leave();
    }, 2000)

    this.getCategories();
  }

  async deleteCategory(item:any) {
    await this.$axios.delete(`/blogs/categories/${item.id}/`)
    .then(() => {
      const modal = this.$refs.deleteCategory as any;
      modal.success = true;
      setTimeout(() => {
        this.getCategories();
        this.$emit('success');
        modal.leave();
      }, 1500)
    })
    .catch((error:any) => {
      if (error.response) {
        this.errorMessage = getErrorMessage(error, this.$t('errorDeleteCategory'));
        this.hasError = this.errorMessage.show;
      }
    })
  }
}
