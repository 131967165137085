
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'

@Component({})
export default class CustomDateRangePicker extends Vue {
  dateSelected: any = "";

  @Prop({ default: "" }) readonly dateFormat!: any;
  @Prop({ default: [] }) readonly defaultDate!: any;

  mounted() {
    this.dateSelected = this.defaultDate;
  }
  
  @Watch('defaultDate')
  getDefaultDate(val:any) {
    this.dateSelected = val;
  }

  getDate() {
    this.$emit("date-filter", this.dateSelected);
  }
}
