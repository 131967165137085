
import { Component, Vue } from 'vue-property-decorator'
import { getImage } from "~/utils/ImageUtils"

@Component({
  layout: 'landing-page',
})
export default class ProcessSteps extends Vue {
  processList: any = [];

  mounted() {
    this.processList = this.$t('processList') as any;
  }
  
  getImage(filename:any, fallback:any) {
    return getImage(filename, fallback);
  }
}
