var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "v-card-text",
        { staticClass: "pa-7" },
        [
          _c(
            "v-row",
            [
              _c("v-col", { attrs: { cols: "12", md: "6" } }, [
                _c(
                  "div",
                  { staticClass: "d-flex align-center" },
                  [
                    _c("v-icon", { staticClass: "text-dark-grey" }, [
                      _vm._v("mdi-account-group"),
                    ]),
                    _vm._v(" "),
                    _c(
                      "label",
                      { staticClass: "text-16 grey--text text--darken-1 ml-2" },
                      [_vm._v(_vm._s(_vm.$t("maxDailyGrowth")))]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div", { staticClass: "grey--text" }, [
                  _vm.usersData.daily_follower_limit_pct < 100
                    ? _c("span", { staticClass: "text-12" }, [
                        _vm._v(
                          _vm._s(
                            _vm.$t("maxDailyGrowthLabel", {
                              limit: (
                                _vm.usersData.current_follower_count *
                                (_vm.usersData.daily_follower_limit_pct / 100)
                              ).toFixed(0),
                            })
                          )
                        ),
                      ])
                    : _c("span", { staticClass: "text-12" }, [
                        _vm._v(
                          _vm._s(
                            _vm.$t("maxDailyGrowthLabel", {
                              limit: _vm.usersData.current_follower_count,
                            })
                          )
                        ),
                      ]),
                ]),
                _vm._v(" "),
                _vm.usersData.daily_follower_limit_pct
                  ? _c("h2", { staticClass: "percentage" }, [
                      _vm._v(
                        _vm._s(
                          _vm.formatFloat(
                            _vm.usersData.daily_follower_limit_pct
                          )
                        ) + "%"
                      ),
                    ])
                  : _vm._e(),
              ]),
              _vm._v(" "),
              _c(
                "v-col",
                {
                  staticClass: "d-flex flex-column",
                  attrs: { cols: "12", md: "6" },
                },
                [
                  _c(
                    "div",
                    { staticClass: "d-flex align-center" },
                    [
                      _c("v-icon", { staticClass: "text-dark-grey" }, [
                        _vm._v("mdi-thumb-up"),
                      ]),
                      _vm._v(" "),
                      _c(
                        "label",
                        {
                          staticClass: "text-16 grey--text text--darken-1 ml-2",
                        },
                        [_vm._v(_vm._s(_vm.$t("likePercent")))]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.usersData.pct_like
                    ? _c("h2", { staticClass: "percentage mt-auto" }, [
                        _vm._v(
                          _vm._s(_vm.formatFloat(_vm.usersData.pct_like)) + "%"
                        ),
                      ])
                    : _vm._e(),
                ]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-row",
            { staticClass: "mt-5" },
            [
              _c("v-col", { attrs: { cols: "12", md: "6" } }, [
                _c(
                  "div",
                  { staticClass: "d-flex align-center" },
                  [
                    _c("v-icon", { staticClass: "text-dark-grey" }, [
                      _vm._v("mdi-content-save"),
                    ]),
                    _vm._v(" "),
                    _c(
                      "label",
                      { staticClass: "text-16 grey--text text--darken-1 ml-2" },
                      [_vm._v(_vm._s(_vm.$t("savePercent")))]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _vm.usersData.pct_save
                  ? _c("h2", { staticClass: "percentage mt-1" }, [
                      _vm._v(
                        _vm._s(_vm.formatFloat(_vm.usersData.pct_save)) + "%"
                      ),
                    ])
                  : _vm._e(),
              ]),
              _vm._v(" "),
              _c("v-col", { attrs: { cols: "12", md: "6" } }, [
                _c(
                  "div",
                  { staticClass: "d-flex align-center" },
                  [
                    _c("v-icon", { staticClass: "text-dark-grey" }, [
                      _vm._v("mdi-eye"),
                    ]),
                    _vm._v(" "),
                    _c(
                      "label",
                      { staticClass: "text-16 grey--text text--darken-1 ml-2" },
                      [_vm._v(_vm._s(_vm.$t("storyPercent")))]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _vm.usersData.pct_story
                  ? _c("h2", { staticClass: "percentage mt-1" }, [
                      _vm._v(
                        _vm._s(_vm.formatFloat(_vm.usersData.pct_story)) + "%"
                      ),
                    ])
                  : _vm._e(),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-row",
            { staticClass: "mt-5" },
            [
              _c("v-col", { attrs: { cols: "12", md: "6" } }, [
                _c(
                  "div",
                  { staticClass: "d-flex align-center" },
                  [
                    _c("v-icon", { staticClass: "text-dark-grey" }, [
                      _vm._v("mdi-comment-plus"),
                    ]),
                    _vm._v(" "),
                    _c(
                      "label",
                      { staticClass: "text-16 grey--text text--darken-1 ml-2" },
                      [_vm._v(_vm._s(_vm.$t("commentPercent")))]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _vm.usersData.pct_comment
                  ? _c("h2", { staticClass: "percentage mt-1" }, [
                      _vm._v(
                        _vm._s(_vm.formatFloat(_vm.usersData.pct_comment)) + "%"
                      ),
                    ])
                  : _vm._e(),
              ]),
              _vm._v(" "),
              _c(
                "v-col",
                { attrs: { cols: "12", md: "6" } },
                [
                  _c(
                    "div",
                    { staticClass: "d-flex align-center mb-5" },
                    [
                      _c("v-icon", { staticClass: "text-dark-grey" }, [
                        _vm._v("mdi-comment-text-multiple"),
                      ]),
                      _vm._v(" "),
                      _c(
                        "label",
                        {
                          staticClass: "text-16 grey--text text--darken-1 ml-2",
                        },
                        [_vm._v("Comment File")]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.usersData.comments_text &&
                  _vm.usersData.comments_text.length > 0
                    ? _c(
                        "v-chip",
                        {
                          staticClass: "px-2",
                          attrs: {
                            small: "",
                            outlined: "",
                            color: "btn-brand",
                          },
                          on: { click: _vm.writeToFile },
                        },
                        [
                          _c(
                            "v-icon",
                            {
                              staticClass: "custom mr-1",
                              attrs: { small: "" },
                            },
                            [_vm._v("mdi-download")]
                          ),
                          _vm._v(" "),
                          _c("span", [_vm._v(_vm._s(_vm.$t("donwload")))]),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }