var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-snackbar",
    {
      attrs: { color: "green", shaped: "", elevation: "24", right: "" },
      scopedSlots: _vm._u([
        {
          key: "action",
          fn: function ({ attrs }) {
            return [
              _c(
                "v-btn",
                _vm._b(
                  {
                    attrs: { color: "white", text: "" },
                    on: {
                      click: function ($event) {
                        _vm.successSnackbar = false
                      },
                    },
                  },
                  "v-btn",
                  attrs,
                  false
                ),
                [_vm._v(_vm._s(_vm.$t("close")))]
              ),
            ]
          },
        },
      ]),
      model: {
        value: _vm.successSnackbar,
        callback: function ($$v) {
          _vm.successSnackbar = $$v
        },
        expression: "successSnackbar",
      },
    },
    [
      _c("v-icon", { attrs: { small: "" } }, [_vm._v("mdi-alert-circle")]),
      _vm._v(" " + _vm._s(_vm.successMessage) + "\n    "),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }