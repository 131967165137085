var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c("p", { staticClass: "text-14 text-dark-grey text-center mt-3" }, [
        _vm._v(_vm._s(_vm.$t("enterNumCreditsToBuy"))),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "text-center", on: { click: _vm.editable } }, [
        _c("img", {
          staticClass: "mb-1",
          attrs: {
            src: require("assets/images/icons/twemoji_coin.svg"),
            width: "50",
          },
        }),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "position-relative" },
          [
            _c("v-text-field", {
              ref: "field",
              staticClass: "custom-input mt-0 pt-0",
              attrs: { placeholder: "0", "hide-details": "" },
              on: {
                keyup: _vm.allowNumbers,
                focus: function ($event) {
                  return $event.target.select()
                },
              },
              model: {
                value: _vm.quantity,
                callback: function ($$v) {
                  _vm.quantity = $$v
                },
                expression: "quantity",
              },
            }),
            _vm._v(" "),
            _vm.computedBonus
              ? _c("span", { staticClass: "bonus-credits" }, [
                  _vm._v("+" + _vm._s(_vm.computedBonus)),
                ])
              : _vm._e(),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("p", { staticClass: "text-14 text-black text-center mt-5 mb-1" }, [
        _vm._v(_vm._s(_vm.$t("creditConversion"))),
      ]),
      _vm._v(" "),
      _vm.hasBonus
        ? _c("p", { ref: "topupInfo", staticClass: "text-brand text-center" })
        : _vm._e(),
      _vm._v(" "),
      _vm.computedBonus
        ? [
            _c("v-divider", { staticClass: "mx-15" }),
            _vm._v(" "),
            _c("p", {
              ref: "topupTotal",
              staticClass: "text-14 text-center mb-0 mt-3 font-weight-medium",
            }),
          ]
        : _vm._e(),
      _vm._v(" "),
      _c(
        "v-btn",
        {
          staticClass: "btn-brand mt-8",
          attrs: {
            depressed: "",
            block: "",
            large: "",
            disabled: !_vm.quantity || _vm.checkToDisable("stripe"),
            loading: _vm.loadingCredit,
          },
          on: {
            click: function ($event) {
              return _vm.payViaCreditCard("cc")
            },
          },
        },
        [
          _c("v-icon", { staticClass: "mr-1", attrs: { size: "20" } }, [
            _vm._v("mdi-credit-card-outline"),
          ]),
          _vm._v(_vm._s(_vm.$t("payViaCreditCard"))),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-btn",
        {
          staticClass: "text-brand mt-3",
          attrs: {
            outlined: "",
            depressed: "",
            block: "",
            large: "",
            disabled: !_vm.quantity || _vm.checkToDisable("bitcoin"),
            loading: _vm.loadingCrypto,
          },
          on: {
            click: function ($event) {
              return _vm.payViaCreditCard("btc")
            },
          },
        },
        [
          _c("v-icon", { staticClass: "mr-1", attrs: { size: "22" } }, [
            _vm._v("mdi-bitcoin"),
          ]),
          _vm._v(_vm._s(_vm.$t("payViaCrypto"))),
        ],
        1
      ),
      _vm._v(" "),
      _vm.hasError
        ? _c("alert-error", {
            staticClass: "mt-5",
            attrs: { "error-message": _vm.errorMessage },
          })
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }