
import { Component, Vue } from "vue-property-decorator";

@Component({})
export default class SnackbarSuccess extends Vue {
  successSnackbar: boolean = false;
  successMessage: any = "";

  show(msg: any = null) {
    this.successMessage = msg ? msg : this.$t('successSave');
    this.successSnackbar = true;
  } 
}
