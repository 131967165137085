
import { Component, Vue, Watch } from 'vue-property-decorator'
import { getErrorMessage } from '~/utils/ErrorMessageUtils'
import { requiredRule, validEmailRule } from '~/utils/FormValidation'

@Component({
  layout: 'landing-page',
})
export default class Footer extends Vue {
  isIndex: boolean = false;
  contactUs: any = { message: "Subscribe to newsletter" };
  emailRule: any = [requiredRule(), validEmailRule()];
  loading: boolean = false;
  success: boolean = false;
  hasError: boolean = false;
  errorMessage: any = "";

  @Watch('$route')
  checkRoute() {
    const route = this.$route as any;
    this.isIndex = route && route.name == 'index' ? true : false;
  }

  async sendUsMessage() {
    this.hasError = false;
    const form = this.$refs.contactForm as any;
    if(form.validate()) {
      this.loading = true;
      await this.$axios.post('/mailing/contact/', this.contactUs)
      .then(() => {
        form.reset();
        const snackbar = this.$refs.success as any;
        snackbar.show(this.$t('successSent'));

        this.contactUs = { message: "Subscribe to newsletter" };
      }).catch((error:any) => {
        const message = getErrorMessage(error, this.$t('errorSendMessage'));
        const snackbar = this.$refs.error as any;
        if(snackbar) { snackbar.show(message); }
      })
      this.loading = false;
    }
  }
}
