
import { Component, Vue, Prop } from 'vue-property-decorator'

@Component({})
export default class LanguageSelection extends Vue {
  @Prop({ default: false }) readonly isIconOnly!: Boolean

  language: any = "";
  locales: any = [];

  mounted() {
    this.language = this.$i18n.locale;
    this.locales = this.$i18n.locales;
  }

  changeLanguage(code:any) {
    this.language = code;
    this.$emit('overlay', true);

    setTimeout(() => {
      this.$i18n.setLocale(code)
      this.$emit('overlay', false);
    }, 1500)
    
  }
}
