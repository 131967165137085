var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-dialog",
    {
      attrs: { persistent: "", "max-width": "600" },
      model: {
        value: _vm.modal,
        callback: function ($$v) {
          _vm.modal = $$v
        },
        expression: "modal",
      },
    },
    [
      _c(
        "v-card",
        [
          _c("v-card-title", { staticClass: "pb-0" }, [
            _c(
              "h2",
              {
                staticClass:
                  "h_title_3 font-weight-bold text-black text-center",
              },
              [_vm._v(_vm._s(_vm.$t("addManualTransaction")))]
            ),
          ]),
          _vm._v(" "),
          _c(
            "v-card-text",
            { staticClass: "pa-6 pt-0" },
            [
              _c("v-divider", { staticClass: "mt-3" }),
              _vm._v(" "),
              _c("settings-transactions-add-manual-form", {
                on: { success: _vm.success, cancel: _vm.leave },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }